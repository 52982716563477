import { NavLink as RouterNavLink } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from 'theme'

export const Container = styled.div({
  display: 'block',
  marginTop: '1rem',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: colors.gray.dark,
  textAlign: 'center',
})

export const BoldLink = styled(RouterNavLink)({
  fontWeight: '700',
  color: colors.primary.light,
  '&:hover': {
    color: colors.primary.dark,
  },
})
