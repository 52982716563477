import { chowlyApi } from 'config'
import { generatePath } from 'react-router'

import client from '../chowly'
import { MenuMappingScheduleCreateInput, MenuMappingScheduleResponse } from '../models'
import { joinAvailabilitiesAndPeriods } from './helpers'

export const createMenuMappingSchedule = async (payload: MenuMappingScheduleCreateInput) => {
  const { data } = await client.post<MenuMappingScheduleResponse>(
    chowlyApi.menuMappingSchedules,
    payload,
  )
  return data
}

export const updateMenuMappingSchedule = async (
  id: string,
  payload: MenuMappingScheduleCreateInput,
) => {
  const url = generatePath(chowlyApi.menuMappingSchedule, { id })
  const { data } = await client.put<MenuMappingScheduleResponse>(url, payload)
  return data
}

export const getMenuMappingSchedule = async (id: string, params?: { include: string }) => {
  const url = generatePath(chowlyApi.menuMappingSchedule, { id })
  const { data } = await client.get<MenuMappingScheduleResponse>(url, { params })
  return joinAvailabilitiesAndPeriods(data)
}

export const deleteTimePeriod = async (id: string) => {
  const url = generatePath(chowlyApi.menuMappingTimePeriod, { id })
  const { data } = await client.delete<MenuMappingScheduleResponse>(url)
  return data
}
