import * as Styled from './styles'

function CardLink({ title, route, icon }) {
  return (
    <Styled.Link to={route}>
      <Styled.Card>
        <Styled.TitleContainer>
          <Styled.Title>{title}</Styled.Title>
        </Styled.TitleContainer>
        <Styled.IconWrap>
          <img src={icon} alt={`${title} icon `} />
        </Styled.IconWrap>
      </Styled.Card>
    </Styled.Link>
  )
}

export default CardLink
