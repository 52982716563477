import { FC } from 'react'

import * as Styled from './styles'

type Props = {
  url?: string
  title?: string
  top?: number
}

const SigmaEmbed: FC<Props> = ({ url, title, top }) => (
  <Styled.Container>
    <Styled.Embed src={url} title={title} scrolling='no' top={top} />
  </Styled.Container>
)

SigmaEmbed.displayName = 'SigmaEmbed'

export default SigmaEmbed
