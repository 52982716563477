import { ReactComponent as InformationIcon } from 'assets/icons/info-icon.svg'
import styled from 'styled-components'
import { breakpoints, colors, fontWeight } from 'theme'

type StatusProps = { isSuccess: boolean }

export const FieldLabel = styled.label({
  width: '40%',
  maxWidth: '50%',
  display: 'inline-block',
  position: 'relative',
  fontSize: '1rem',
  fontWeight: fontWeight.bold,
  color: colors.gray.darkest,
  margin: '.5rem',
})

export const LocationFormItem = styled.div({
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '85vw',
  [`@media ${breakpoints.md}`]: {
    maxWidth: '60vw',
  },
})

export const InfoIcon = styled(InformationIcon)({
  marginTop: '0.5rem',
  marginLeft: '1rem',
  marginBottom: '1rem',
})

export const StatusText = styled.h4<StatusProps>(
  {
    width: '35%',
    display: 'block',
    fontSize: '1rem',
    fontWeight: fontWeight.bold,
    color: colors.red.DEFAULT,
    marginTop: '.5rem',
    textTransform: 'uppercase',
  },
  ({ isSuccess }) =>
    isSuccess && {
      color: colors.green[500],
    },
)
