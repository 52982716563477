import { chowlyApi } from 'config'
import { generatePath } from 'react-router'

import client from '../chowly'
import {
  MenuMappingOverrideSectionInput,
  MenuMappingOverrideSectionResponse,
  MenuMappingOverrideSectionsResponse,
} from '../models'
import { joinScheduleAndSubsections } from './helpers'
import { JoinedMenuMappingOverrideSection } from './types'

export const createMenuMappingOverrideSection = async (
  payload: MenuMappingOverrideSectionInput,
) => {
  const { data } = await client.post<MenuMappingOverrideSectionResponse>(
    chowlyApi.menuMappingOverrideSections,
    payload,
  )
  return data
}

export const updateMenuMappingOverrideSection = async (
  id: string,
  payload: MenuMappingOverrideSectionInput,
) => {
  const url = generatePath(chowlyApi.menuMappingOverrideSection, { id })
  const { data } = await client.put<MenuMappingOverrideSectionResponse>(url, payload)
  return data
}

export const deleteMenuMappingOverrideSection = async (id: string) => {
  const url = generatePath(chowlyApi.menuMappingOverrideSection, { id })
  const { data } = await client.delete(url)
  return data
}

export const getMenuMappingOverrideSection = async (
  menuMappingOverrideSectionId: string,
  params?: { include: string },
) => {
  const url = generatePath(chowlyApi.menuMappingOverrideSection, {
    id: menuMappingOverrideSectionId,
  })
  const { data } = await client.get<MenuMappingOverrideSectionResponse>(url, { params })
  return joinScheduleAndSubsections(data) as JoinedMenuMappingOverrideSection
}

export const getMenuMappingOverrideSections = async (
  orderPlatformId: string,
  params?: { include: string },
) => {
  const url = chowlyApi.menuMappingOverrideSections
  const { data } = await client.get<MenuMappingOverrideSectionsResponse>(url, {
    params: { ...params, order_platform_id: orderPlatformId },
  })
  return joinScheduleAndSubsections(data) as JoinedMenuMappingOverrideSection[]
}
