import { confirmUser } from 'api'
import { ReactComponent as ChowlyLogo } from 'assets/logo.svg'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import AlertContainer from '../AlertContainer/AlertContainer'
import * as Styled from './styles'

function SignupConfirmation() {
  const [searchParams] = useSearchParams()
  const confirmationToken = searchParams.get('confirmation_token')
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await confirmUser(confirmationToken || '')
        setIsError(data.attributes.confirmed ? false : true)
      } catch {
        setIsError(true)
      }
    })()
  }, [])

  return (
    <Styled.Container>
      <Styled.Header>
        <ChowlyLogo />
      </Styled.Header>
      <Styled.Title>Your Account</Styled.Title>

      {isError && (
        <AlertContainer
          type={'danger'}
          messages={[
            'An error occurred while confirming your account.',
            'Please contact support for further assistance.',
          ]}
        />
      )}
      {!isError && (
        <>
          <AlertContainer
            type={'success'}
            messages={'Your account has been successfully confirmed.  Please click below to login'}
          />
          <Styled.LoginLink to='/login'>Login</Styled.LoginLink>
        </>
      )}
    </Styled.Container>
  )
}

export default SignupConfirmation
