import { OnboardingLocation, OnboardingLocationCreateInput } from 'api'
import { ModalFooter, SubmitButton } from 'components/ModalButtons'
import { SingleSelect } from 'components/Select'
import { googlePlaces } from 'config'
import { Field, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Address, allTimezones } from 'utils'
import * as Yup from 'yup'

import * as Styled from './styles'

const autocompleteOptions: google.maps.places.AutocompleteOptions = {
  componentRestrictions: { country: ['us', 'ca', 'mx', 'jp'] },
  fields: ['place_id', 'formatted_phone_number', 'name', 'address_component', 'geometry.location'],
  strictBounds: false,
  types: ['establishment'],
}

const currencies = [
  { label: 'USD', value: 'USD' },
  { label: 'CAD', value: 'CAD' },
  { label: 'JPY', value: 'JPY' },
  { label: 'MXN', value: 'MXN' },
]

const timezones = Object.values(allTimezones).map((tz) => ({ label: tz, value: tz }))

const validationSchema = Yup.object({
  google_place_id: Yup.string().notRequired(),
  name: Yup.string().trim().required('Restaurant Name is required'),
  zipcode: Yup.string()
    .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Zipcode is invalid')
    .required('Zipcode is required'),
  phone_number: Yup.string()
    .matches(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im, 'Phone is invalid')
    .required('Phone is required'),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  currency: Yup.string()
    .oneOf(['USD', 'CAD', 'JPY', 'MXN'], 'Currency is invalid')
    .required('Currency is required'),
  timezone: Yup.string()
    .oneOf(Object.values(allTimezones))
    .label('Timezone is invalid')
    .required('Timezone is required'),
  id: Yup.string().notRequired(),
})

type Props = {
  onSubmit: (values: OnboardingLocationCreateInput) => Promise<void>
  isInModal?: boolean
  defaultValues?: OnboardingLocation | null
  submitButtonTitle?: string
}

const LocationSearch = ({ isInModal, defaultValues, submitButtonTitle, onSubmit }: Props) => {
  const [showAddress, setShowAddress] = useState(false)
  const [enableAddress, setEnableAddress] = useState(false)

  const initialValues: OnboardingLocationCreateInput = {
    address: defaultValues?.address ?? '',
    city: defaultValues?.city ?? '',
    currency: defaultValues?.currency.toUpperCase() ?? '',
    google_place_id: defaultValues?.google_place_id ?? '',
    name: defaultValues?.name ?? '',
    phone_number: defaultValues?.phone_number ?? '',
    state: defaultValues?.state ?? '',
    zipcode: defaultValues?.zipcode ?? '',
    current_step: defaultValues?.current_step ?? '',
    timezone: defaultValues?.timezone ?? '',
  }

  useEffect(() => {
    if (defaultValues) {
      setShowAddress(true)
    }
  }, [defaultValues])

  const displayAddress = () => {
    setEnableAddress(!enableAddress)
    setShowAddress(!showAddress)
  }

  const SubmitButtonWrapper = isInModal ? ModalFooter : Styled.SubmitButtonWrapper

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {(props) => (
        <>
          <Styled.ScrollView isScrollEnabled={isInModal}>
            <Styled.Label htmlFor='pac-input'>Google Place Search</Styled.Label>
            <Styled.PlacesSearch
              apiKey={googlePlaces.token}
              options={autocompleteOptions}
              data-testid='autocomplete'
              onPlaceSelected={(place) => {
                const address = new Address(place)
                if (!showAddress) {
                  setShowAddress(true)
                }
                props.setValues(address.toInput())
              }}
            />
            <Styled.Word>OR</Styled.Word>
            <Styled.Link onClick={displayAddress} isVisible={!showAddress}>
              Enter Address Manually
            </Styled.Link>
            <Styled.AddressContainer isVisible={showAddress} isEnabled={enableAddress}>
              <Styled.RestaurantName name='name' id='name' label='Restaurant Name' />

              <Styled.AddressField
                id='address'
                data-testid='address'
                name='address'
                label='Address'
                isEnabled={enableAddress}
              />
            </Styled.AddressContainer>
            <Styled.FieldsWrap isVisible={showAddress} isEnabled={enableAddress}>
              <Styled.InputSmall name='city' label='City' data-testid='city' />
              <Styled.InputSmall name='state' label='State' data-testid='state' />
              <Styled.InputSmall name='zipcode' label='Zip Code' data-testid='zipCode' />
            </Styled.FieldsWrap>
            <Styled.FieldsWrap isVisible={true} isEnabled={true}>
              <Styled.InputSmall
                name='phone_number'
                label='Phone Number'
                data-testid='phoneNumber'
              />
              <div>
                <Styled.Label>TimeZone</Styled.Label>
                <SingleSelect
                  value={
                    timezones.find((option) => option.value === props.values.timezone) ?? {
                      value: '',
                      label: '',
                    }
                  }
                  options={timezones}
                  name='timezone'
                  ariaLabel='timeZone'
                  onChange={(option) => {
                    props.setFieldValue('timezone', option)
                  }}
                  styles={Styled.selectStyles}
                  isError={!!props.errors.timezone}
                />
                <Styled.ErrorMessage>{props.errors.timezone}</Styled.ErrorMessage>
              </div>
              <div>
                <Styled.Label>Currency</Styled.Label>
                <SingleSelect
                  value={
                    currencies.find((option) => option.value === props.values.currency) ?? {
                      value: '',
                      label: '',
                    }
                  }
                  options={currencies}
                  name='currency'
                  onChange={(option) => props.setFieldValue('currency', option)}
                  ariaLabel='currency'
                  styles={Styled.selectStyles}
                  isError={!!props.errors.currency}
                />
                <Styled.ErrorMessage>{props.errors.currency}</Styled.ErrorMessage>
              </div>
            </Styled.FieldsWrap>
            <Field type='hidden' name='id' />
            <Field type='hidden' name='google_place_id' />
          </Styled.ScrollView>
          <SubmitButtonWrapper>
            <SubmitButton
              type='submit'
              navigation={props.submitForm}
              buttonText={submitButtonTitle}
            />
          </SubmitButtonWrapper>
        </>
      )}
    </Formik>
  )
}

export default LocationSearch
