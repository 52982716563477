import { MenuMappingItemDetails } from 'api'
import { Accordion, MenuItem } from 'components'
import { useMenuMappingItems } from 'hooks'
import { FC } from 'react'

import * as Styled from './styles'

type Props = {
  subsectionId: string
  title: string
  onItemClick: (itemId: MenuMappingItemDetails['id']) => void
}

/**
 * @deprecated
 * TODO: This is still used for old PreviewAllMenus and onboarding but should remove once menu override concept was completely removed from prod.
 */
const MenuSubsectionDeprecated: FC<Props> = ({ subsectionId, title, onItemClick }) => {
  const items = useMenuMappingItems(subsectionId)
  const noItems = items.length < 1
  const numberOfItems = noItems ? '(Category has no items)' : `(${items.length} Menu Items)`

  return (
    <Accordion
      key={subsectionId}
      expandByDefault
      isError={noItems}
      title={
        <Styled.Title>
          {title}
          <span>{numberOfItems}</span>
        </Styled.Title>
      }
      content={
        <Styled.ItemsGrid>
          {items.map((item) => (
            <MenuItem key={item.id} data={item} onClick={() => onItemClick(item.id)} />
          ))}
        </Styled.ItemsGrid>
      }
    />
  )
}

MenuSubsectionDeprecated.displayName = 'MenuSubsectionDeprecated'

export default MenuSubsectionDeprecated
