import 'tippy.js/dist/tippy.css'

import Tippy from '@tippyjs/react'
import helpIcon from 'assets/icons/help-nav-item.svg'
import { FC } from 'react'

import * as Styled from './styles'

const ChowlyHelpButton: FC = () => {
  return (
    <Tippy content='Chowly Help' placement='left' theme='light'>
      <Styled.IconWrapper href='https://chowly.help/s/' target='_blank' rel='noreferrer noopener'>
        <Styled.Avatar src={helpIcon} alt='nav' />
      </Styled.IconWrapper>
    </Tippy>
  )
}

export default ChowlyHelpButton
