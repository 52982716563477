import tzlookup from 'tz-lookup'
import { allTimezones } from 'utils/DateTime'

import { OnboardingLocationCreateInput } from '../api'

export class Address {
  place: google.maps.places.PlaceResult
  name: string
  address: string
  address1: string
  city: string
  state: string
  phone: string
  country: string
  placeId: string
  postalCode: string
  postalCodeSuffix?: string

  readonly countryToCurrency: Record<string, string> = {
    US: 'USD',
    CA: 'CAD',
    JP: 'JPY',
    MX: 'MXN',
  }

  constructor(place: google.maps.places.PlaceResult) {
    this.place = place
    this.name = place?.name || ''
    this.address = ''
    this.address1 = ''
    this.city = ''
    this.state = ''
    this.country = ''
    this.phone = this.place?.formatted_phone_number || ''
    this.placeId = this.place?.place_id || ''
    this.postalCode = ''
    this.setAddressFields()
  }

  toInput = (): OnboardingLocationCreateInput => {
    return {
      name: this.name,
      phone_number: this.phone,
      address: this.streetAddress(),
      city: this.city,
      state: this.state,
      zipcode: this.zip,
      currency: this.currency(),
      timezone: this.timezone(),
      google_place_id: this.placeId,
      current_step: 'pos' /** * Submitting this form will change current_step to pos.*/,
    }
  }

  timezone = () => {
    const location = this.place?.geometry?.location
    if (location?.lat() && location?.lng()) {
      const tz = tzlookup(location.lat(), location.lng()).toLowerCase()
      return allTimezones[tz as keyof typeof allTimezones]
    } else {
      // Per Google Eastern is the most populous US TZ
      return 'Eastern Time (US & Canada)'
    }
  }

  streetAddress = () => {
    return `${this.address} ${this.address1}`
  }

  currency = () => {
    return this.countryToCurrency[this.country]
  }

  setAddressFields() {
    const location = this.place?.address_components as google.maps.GeocoderAddressComponent[]
    location?.forEach((component) => {
      const componentType = component.types[0]
      switch (componentType) {
        case 'street_number': {
          this.address = component.long_name
          break
        }
        case 'route': {
          if (component.short_name != null) {
            this.address1 += component.short_name
          }
          break
        }
        case 'postal_code': {
          this.postalCode = component.long_name
          break
        }
        case 'postal_code_suffix': {
          this.postalCodeSuffix = component.long_name
          break
        }
        case 'locality': {
          this.city = component.long_name
          break
        }
        case 'administrative_area_level_1': {
          this.state = component.short_name
          break
        }
        case 'country': {
          this.country = component.short_name
          break
        }
      }
    })
  }

  get zip() {
    return [this.postalCode, this.postalCodeSuffix].filter(Boolean).join('-')
  }
}
