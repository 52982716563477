import { LoadingSkeleton } from 'components'
import { useEffect, useState } from 'react'
import { FormValueTypes } from 'types'

import * as Styled from './styles'

type Props = {
  currentUpcharge: number | string
  onSubmit: (value: FormValueTypes) => Promise<void>
  isLoading?: boolean
}

export const LocationUpcharge = ({ currentUpcharge, onSubmit, isLoading }: Props) => {
  const [upcharge, setUpcharge] = useState<number | string>(0)
  const [disableUpchargeSave, setDisableUpchargeSave] = useState(true)

  const handleUpchargeActions = (type: string) => {
    if (type === 'Cancel') setUpcharge(currentUpcharge)
  }

  useEffect(() => {
    const disableCheck =
      currentUpcharge === upcharge || (upcharge === 0 && currentUpcharge === null)

    setDisableUpchargeSave(disableCheck)
  }, [upcharge])

  useEffect(() => {
    if (currentUpcharge) {
      setUpcharge(currentUpcharge)
    }
  }, [currentUpcharge])

  return (
    <Styled.LocationStatusContainer>
      <Styled.UpchargeContainer>
        <Styled.UpchargeLabelWrapper>
          <Styled.UpchargeHeader>Location Upcharge</Styled.UpchargeHeader>
        </Styled.UpchargeLabelWrapper>
        <Styled.SliderContainer>
          <Styled.UpchargeText>
            {isLoading ? <LoadingSkeleton /> : `${currentUpcharge}%`}
          </Styled.UpchargeText>
          {isLoading ? (
            <LoadingSkeleton />
          ) : (
            <Styled.Input
              value={upcharge}
              type='number'
              pattern='-?[0-9]*'
              min='-100'
              max='100'
              onChange={(e) => setUpcharge(e.target.value)}
              disabled={isLoading}
            />
          )}
          <Styled.SliderText>
            {isLoading ? (
              <LoadingSkeleton />
            ) : (
              'Upcharge percentage will apply to all baskets prior to tax.'
            )}
          </Styled.SliderText>
        </Styled.SliderContainer>
        <Styled.ButtonRowContainer>
          <Styled.ApplyButtonContainer>
            <Styled.BaseButton
              onClick={() => handleUpchargeActions('Cancel')}
              variant='solid'
              disabled={disableUpchargeSave} // false when toggle is implemented
            >
              Cancel
            </Styled.BaseButton>
          </Styled.ApplyButtonContainer>
          <Styled.ApplyButtonContainer>
            <Styled.BaseButton
              onClick={() => {
                onSubmit({ upcharge })
                setDisableUpchargeSave(true)
              }}
              variant='solid'
              disabled={disableUpchargeSave}
            >
              Apply
            </Styled.BaseButton>
          </Styled.ApplyButtonContainer>
        </Styled.ButtonRowContainer>
      </Styled.UpchargeContainer>
    </Styled.LocationStatusContainer>
  )
}

export default LocationUpcharge
