const PosConfig: { disabled: { [key: string]: string[] } } = {
  disabled: {
    ncrmenu: ['upcharge'],
  },
}

export const isPosFeatureDisabled: (feature: string, pos: string | undefined) => boolean = (
  feature,
  pos,
) => {
  if (pos && pos.toLowerCase() in PosConfig.disabled) {
    return PosConfig.disabled[pos.toLowerCase()].includes(feature)
  }
  return false
}
