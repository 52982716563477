import { getAllPartnerLocations } from 'api'
import { useCallback, useEffect, useState } from 'react'
import { LocationSummary } from 'types/Partner'
import formatLocations from 'utils/formatLocations'

export type LocationsData = {
  locations: LocationSummary[]
  isLoading: boolean
  totalPages: number
  pageSize: number
  isError: boolean
}

export type UseGetPartnerLocations = () => [
  {
    locations: LocationSummary[]
    totalPages: number
    pageSize: number
    isLoading: boolean
    isError: boolean
    selfOnboardedCount: number
  },
  (page?: number, query?: string) => Promise<void>,
]

const useGetPartnerLocations: UseGetPartnerLocations = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [locations, setLocations] = useState<LocationSummary[]>([])
  const [totalPages, setTotalPages] = useState(0)
  const [selfOnboardedCount, setSelfOnboardedCount] = useState(0)
  const pageSize = 25

  const fetchLocations = useCallback(async (page?: number, query?: string) => {
    setIsLoading(true)
    try {
      const params = {
        include: 'order_platforms',
        'fields[order_platform]': 'aasm_state,name,partner_id,coo_url',
      }
      const { data, included, links, meta } = await getAllPartnerLocations(page, query, params)
      const _locations = formatLocations(data, included)

      // Cache the locations data fetched previously
      setLocations((state) =>
        Number.isInteger(page) ? [...state, ..._locations] : [..._locations],
      )

      setSelfOnboardedCount((state) => state + (meta?.self_onboarded || 0))
      if (links?.last) {
        const lastPageNumber = Number(new URLSearchParams(links.last).get('page'))
        if (lastPageNumber && Number.isInteger(lastPageNumber)) {
          // Page number starts with 1 so the last page number is equal to the number of total pages
          setTotalPages(lastPageNumber)
        }
      }
    } catch (e) {
      console.error(e)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const refetch = async (page?: number, query?: string) => {
    await fetchLocations(page, query)
  }

  useEffect(() => {
    fetchLocations()
  }, [])

  return [
    {
      locations,
      totalPages,
      pageSize,
      isLoading,
      isError,
      selfOnboardedCount,
    },
    refetch,
  ]
}

export default useGetPartnerLocations
