import { chowlyApi } from 'config'
import { generatePath } from 'react-router-dom'

import client from '../chowly'
import { MenuImportStatusResponse } from '../models'

export type ImportStatusQueryParams = {
  limit: number | string
  partnerId: number | string
}

export const getImportMenuStatuses = async (params: Partial<ImportStatusQueryParams>) => {
  const url = generatePath(chowlyApi.menuImportStatus)

  const { data } = await client.get<MenuImportStatusResponse>(url, {
    params: {
      partner_id: params.partnerId,
      limit: params.limit || 4,
    },
  })
  return data
}
