import axios from 'axios'
import { chowlyApi } from 'config'

import { finalizeLocation } from '../dashboard/onboardingLocations'
import { PartnerResponse, SquareLocationsResponse, SquareUrlResponse } from '../models'

export const startSquareOauth = async (accessToken: string, onboardingLocationId: string) => {
  const { data } = await axios.get<SquareUrlResponse>(chowlyApi.squareUrl, {
    baseURL: chowlyApi.hostUrl,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    params: {
      app_redirect_url: window.location.href,
      onboarding_location_id: onboardingLocationId,
      access_token: accessToken,
    },
  })
  return data['squareUrl']
}

export const retrieveSquareLocations = async (
  accessToken: string,
  onboardingLocationId: string,
) => {
  const { data } = await axios.get<SquareLocationsResponse>(chowlyApi.squareLocationsUrl, {
    baseURL: chowlyApi.hostUrl,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    params: {
      onboarding_location_id: onboardingLocationId,
      access_token: accessToken,
    },
  })

  await finalizeLocation(onboardingLocationId, true)

  return data['locations']
}

export const submitSquareLocation = async (
  accessToken: string | null,
  onboardingLocationId: string | null,
  locationId: string | null,
) => {
  const {
    data: { data },
  } = await axios.get<PartnerResponse>(chowlyApi.squareUrl, {
    baseURL: chowlyApi.hostUrl,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    params: {
      onboarding_location_id: onboardingLocationId,
      access_token: accessToken,
      location_id: locationId,
    },
  })
  return data
}
