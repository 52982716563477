import { getPartners } from 'api'
import { appSettings } from 'config'
import { ChowlyUser } from 'libs/auth0-react/auth-state'

type TrackedUser = {
  name: string | undefined
  email: string
  locationsCount?: number | undefined
  employee?: boolean
}

export const identifyUser = async (user: ChowlyUser, userId: number) => {
  if (appSettings.isProduction) {
    const loggedInUser: TrackedUser = {
      name: user.name,
      email: user.email ?? '',
    }
    if (user.email?.includes('@chowly')) {
      loggedInUser.employee = true
    } else {
      const partnersMetadataResponse = await getPartners({
        meta_only: true,
        partner_status: 'not_churned',
      })

      loggedInUser.locationsCount = partnersMetadataResponse.total
      loggedInUser.employee = false
    }

    window.heap?.identify(String(userId))
    window.heap?.addUserProperties({ email: user.email })
  }
}
