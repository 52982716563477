import { FC } from 'react'

import * as Styled from './styles'

type Props = {
  navigation: () => void
  backText?: string
}

const BackButton: FC<Props> = ({ navigation, backText, ...props }) => {
  return (
    <Styled.BackButton onClick={navigation} type='button' {...props}>
      {backText || 'Back'}
    </Styled.BackButton>
  )
}

export default BackButton
