import {
  createMenuMappingServiceAvailability,
  createMenuMappingTimePeriod,
  formatServiceAvailabilityPayload,
  formatTimePeriodPayload,
} from 'api/menuMapping/availabilities'
import { createMenuMappingSection, formatMenuSectionPayload } from 'api/menuMapping/menus'
import { FormattedAvailabilities } from 'types/Menu'

// A single function to create menu section, service availabilities (days of week) and time periods for a menu.
const createNewMenuSectionWithAvailabilities = async (
  menuId: string,
  menuTitle: string,
  availabilities: FormattedAvailabilities, // these should already have all days for the week (service availabilities) in them, even if they are empty.
) => {
  if (!menuId) {
    throw new Error('Menu id is required')
  }
  if (!menuTitle) {
    throw new Error('The menu section title is missing')
  }

  // CREATE A NEW MENU SECTION
  const menuSectionPayload = formatMenuSectionPayload({ title: menuTitle }, menuId)
  const menuSectionResult = await createMenuMappingSection(menuSectionPayload)

  const menuSectionId = menuSectionResult.data.id

  if (!menuSectionId) {
    throw new Error('Failed to create menu section. Please try again later.')
  }

  const serviceAvailabilitiesAndTimePeriodsResult = await Promise.all(
    Object.entries(availabilities).map(async ([dayOfWeek, timePeriods]) => {
      // CREATE A NEW SERVICE AVAILABILITY (DAY OF WEEK)
      let serviceAvailabilityResult
      let serviceAvailabilityId: string

      const allTimePeriodsEmpty = !timePeriods[0]?.start_time || !timePeriods[0]?.end_time

      if (!allTimePeriodsEmpty) {
        const serviceAvailabilityPayload = formatServiceAvailabilityPayload(
          { day_of_week: dayOfWeek, enabled: true },
          menuSectionId,
        )

        try {
          serviceAvailabilityResult = await createMenuMappingServiceAvailability(
            serviceAvailabilityPayload,
          )
          if (!serviceAvailabilityResult?.data?.id) {
            return Promise.reject(`Failed to create service availability: ${dayOfWeek}`)
          }
          serviceAvailabilityId = serviceAvailabilityResult.data.id
        } catch (err) {
          return Promise.reject(`Failed to create service availability: ${dayOfWeek}`)
        }

        const timePeriodsPromisesResult = await Promise.all(
          timePeriods.map(async (timePeriod) => {
            if (timePeriod.start_time && timePeriod.end_time) {
              const attributes = {
                start_time: timePeriod.start_time,
                end_time: timePeriod.end_time,
              }
              const timePeriodPayload = formatTimePeriodPayload(attributes, serviceAvailabilityId)

              // CREATE A TIME PERIOD FOR THE SERVICE AVAILABILITY
              try {
                const timePeriodResult = await createMenuMappingTimePeriod(timePeriodPayload)

                if (timePeriodResult?.data) {
                  return Promise.resolve(timePeriodResult.data)
                }
              } catch (err) {
                return Promise.reject(`Failed to create time period: ${timePeriod}`)
              }
            }
          }),
        )

        return Promise.resolve({
          serviceAvailability: serviceAvailabilityResult,
          timePeriods: timePeriodsPromisesResult,
        })
      } else {
        // if there are days with no time periods, we want to mark them as disabled.
        const formattedPayload = formatServiceAvailabilityPayload(
          { day_of_week: dayOfWeek, enabled: false },
          menuSectionId,
        )

        // CREATE A NEW DISABLED SERVICE AVAILABILITY (DAY OF WEEK) without time periods
        let serviceAvailabilityResult

        try {
          serviceAvailabilityResult = await createMenuMappingServiceAvailability(formattedPayload)
        } catch (err) {
          return Promise.reject(`Failed to create service availability: ${dayOfWeek}`)
        }

        return Promise.resolve({
          disabledServiceAvailability: serviceAvailabilityResult,
        })
      }
    }),
  )

  return {
    menuSection: menuSectionResult,
    serviceAvailabilitiesAndTimePeriodsResult,
  }
}

export default createNewMenuSectionWithAvailabilities
