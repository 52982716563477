import { FC, ReactNode } from 'react'

import * as Styled from './styles'

type Props = {
  isOpen: boolean
  onClose?: () => void
  children: ReactNode
  width?: string
  borderRadius?: string
  height?: string
}

const Modal: FC<Props> = ({ children, isOpen, onClose, width, height, borderRadius }) => (
  <>
    <Styled.Overlay isVisible={isOpen} onClick={onClose} />
    <Styled.ModalContainer
      isVisible={isOpen}
      width={width}
      height={height}
      borderRadius={borderRadius}
    >
      {children}
    </Styled.ModalContainer>
  </>
)

export default Modal
