export const getSavedItem = <T = string>(storage: Storage, key: string): T | null => {
  const savedValue = storage.getItem(key)
  if (savedValue) {
    try {
      return JSON.parse(savedValue) as T
    } catch (error) {
      console.warn('Invalid storage value.', error)
    }
  }
  return null
}

export const saveItem = (storage: Storage, key: string, item: unknown) => {
  storage.setItem(key, JSON.stringify(item))
}

export const removeSavedItem = (storage: Storage, key: string) => {
  storage.removeItem(key)
}

export const clearStorage = (storage: Storage) => storage.clear()
