import { ReactComponent as ChowlyLogo } from 'assets/logo.svg'
import AlertContainer from 'components/AlertContainer/AlertContainer'
import styled from 'styled-components'
import { borderRadius, breakpoints, colors, fontWeight, typography } from 'theme'

const UberEatsAuthLinkCheck = () => {
  const title = 'Continue to log into Uber Eats'
  const message =
    'Please continue to log into Uber Eats to allow Chowly integrate your account. Please note that once you open this link, you will not be able to use it again. If you cannot access the link, please ask your Chowly representative to generate a new one for you.'
  const link = new URLSearchParams(window.location.search).get('link') ?? '/'

  return (
    <Styled.Container>
      <Styled.Header>
        <ChowlyLogo />
      </Styled.Header>
      <Styled.Title>{title}</Styled.Title>
      <AlertContainer type={'primary'} messages={message} />
      <Styled.Link href={link}>Continue</Styled.Link>
    </Styled.Container>
  )
}

const Styled = {
  Container: styled.div({
    padding: '2rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '1rem',
    maxWidth: '64rem',
    margin: '0 auto',
  }),
  Header: styled.div({
    marginBottom: '2rem',
  }),
  Title: styled.h1({
    ...typography.xl,
    fontWeight: fontWeight.bold,
    color: colors.primary.dark,
    textAlign: 'center',
    [`@media ${breakpoints.md}`]: {
      ...typography.xxxl,
    },
  }),
  Link: styled.a({
    padding: '0.5rem 1rem',
    backgroundColor: colors.primary.light,
    color: colors.white,
    borderRadius: borderRadius.DEFAULT,
  }),
}

export default UberEatsAuthLinkCheck
