import { DialogClose, DialogContent } from 'components/Dialog'
import styled, { css } from 'styled-components'
import { borderRadius, colors, fontWeight, shadow, transition } from 'theme'

export const Wrapper = styled(DialogContent)({
  backgroundColor: colors.white,
  padding: '2rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
})

export const Content = styled.div({
  width: '100%',
  maxWidth: '90rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  columnGap: '2rem',
  rowGap: '2rem',
})

export const Description = styled.div({
  maxWidth: '48rem',
})

export const Actions = styled.div({
  display: 'flex',
  columnGap: '1rem',
})

const buttonStyles = () =>
  css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: borderRadius.lg,
    color: colors.white,
    backgroundColor: colors.primary.light,
    boxShadow: shadow.DEFAULT,
    fontWeight: fontWeight.semibold,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    padding: '0.75rem 0.5rem',
    columnGap: '0.75rem',
    letterSpacing: '0.05rem',
    minWidth: '8rem',
    whiteSpace: 'nowrap',
    ...transition,
    '&:hover': {
      backgroundColor: colors.primary.dark,
    },
  })

const outlineStyle = css({
  backgroundColor: colors.white,
  color: colors.primary.DEFAULT,
  borderColor: colors.primary.light,
  borderWidth: 1,
  '&:hover': {
    backgroundColor: colors.blue[100],
  },
})

export const OutlineButton = styled(DialogClose)(buttonStyles, outlineStyle)

export const PrimaryButton = styled(DialogClose)(buttonStyles)
