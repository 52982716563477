import { useCloseModal } from 'hooks'
import { Dispatch, FC, SetStateAction } from 'react'

import { Container, ExitLink, StatusName, StatusType } from './styles'

type Props = {
  title: string
  descriptions: { title: string; description: string }[]
  updateRenderDictionary: Dispatch<SetStateAction<boolean>>
}
const DescriptionModal: FC<Props> = ({ title, descriptions, updateRenderDictionary }) => {
  const closeModal = useCloseModal()
  const onCloseModal = () => {
    closeModal()
    updateRenderDictionary(false)
  }
  return (
    <Container>
      <StatusType>{title}</StatusType>
      <div>
        {descriptions.map((status) => {
          return (
            <div key={Math.random()}>
              <StatusName>{status?.title}:</StatusName> <p>{status?.description}</p>
            </div>
          )
        })}
      </div>
      <ExitLink onClick={onCloseModal}>Exit</ExitLink>
    </Container>
  )
}

export default DescriptionModal
