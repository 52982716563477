import { chowlyApi } from 'config'
import { generatePath } from 'react-router-dom'

import client from '../chowly'
import { ProvisioningStatusResponse } from '../models'

export const provision = async (id: number) => {
  const url = generatePath(chowlyApi.provision, { id: String(id) })
  const { data } = await client.post(url)
  return data
}

export const deprovision = async (id: number) => {
  const url = generatePath(chowlyApi.deprovision, { id: String(id) })
  const { data } = await client.post(url)
  return data
}

export const getProvisioningStatus = async (id: number) => {
  const url = generatePath(chowlyApi.provisioningStatus, { id: String(id) })
  const { data } = await client.get<ProvisioningStatusResponse>(url)
  return data
}

export const triggerMenuUpdate = async (id: number) => {
  const url = generatePath(chowlyApi.triggerMenuUpdate, { id: String(id) })
  const { data } = await client.post(url)
  return data
}
