import { Button, Toast } from 'components'

import * as Styled from './styles'

function ToastView() {
  const addStandardToast = () => {
    Toast.primary(
      {
        message: 'Lorem ipsum dolor sit amet consectetur.',
        buttonTitle: 'Button',
      },
      { toastId: Date.now(), icon: false },
    )
  }
  const addInfoToast = () => {
    Toast.info(
      {
        message: 'Lorem ipsum dolor sit amet consectetur.',
        buttonTitle: 'Button',
      },
      { toastId: Date.now() },
    )
  }
  const addSuccessToast = () => {
    Toast.success(
      {
        message: 'Lorem ipsum dolor sit amet consectetur.',
        buttonTitle: 'Button',
      },
      { toastId: Date.now() },
    )
  }
  const addWarningToast = () => {
    Toast.warning(
      {
        message: 'Lorem ipsum dolor sit amet consectetur.',
        buttonTitle: 'Button',
      },
      { toastId: Date.now() },
    )
  }
  const addErrorToast = () => {
    Toast.error(
      {
        message: 'Lorem ipsum dolor sit amet consectetur.',
        buttonTitle: 'Button',
      },
      { toastId: Date.now() },
    )
  }
  const addLoadingToast = () => {
    const toastId = Date.now()
    Toast.loading(
      {
        message: 'Lorem ipsum dolor sit amet consectetur.',
        buttonTitle: 'Button',
        onButtonClick: () => {
          Toast.dismiss(toastId)
        },
      },
      { toastId },
    )
  }

  return (
    <Styled.Container>
      <Styled.Grid>
        <Button onClick={addStandardToast}>Add Standard Toast</Button>
        <Button onClick={addInfoToast}>Add Info Toast</Button>
        <Button onClick={addSuccessToast}>Add Success Toast</Button>
        <Button onClick={addWarningToast}>Add Warning Toast</Button>
        <Button danger={true} onClick={addErrorToast}>
          Add Error Toast
        </Button>
        <Button onClick={addLoadingToast}>Add Loading Toast</Button>
      </Styled.Grid>
    </Styled.Container>
  )
}

export default ToastView
