import Modal from 'features/modal/Modal'
import { useEffect, useState } from 'react'

import * as Styled from './styles'

type Props = {
  width?: string
}
/**
 * Chilipiper is a 3rd party service for booking calls with Chowly's CSM reps to upsell customers onto additional platforms.
 */
export const ChilipiperSection = ({ width }: Props) => {
  const [openModal, setOpenModal] = useState(false)

  // This is a workaround to enable closing the modal from within the iframe close icon. Chilipiper docs don't have this anywhere.
  useEffect(() => {
    const messageHandler = (event: any) => {
      // Check if the message is from the expected origin
      if (event.origin === process.env.REACT_APP_CHILIPIPER_ORIGIN) {
        const messageAction = event.data.action

        if (messageAction === 'close') {
          setOpenModal(false)
        }
      }
    }
    // Add the message event listener
    window.addEventListener('message', messageHandler)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [])

  return (
    <Styled.ChilipiperMessageSection width={width}>
      <Styled.ChilipiperToastMessage>
        Interested in adding additional platforms to your Chowly account?
      </Styled.ChilipiperToastMessage>
      <Styled.ChilipiperToastLink onClick={() => setOpenModal(true)}>
        Get in touch to learn more
        <Styled.ChevronRightIcon />
      </Styled.ChilipiperToastLink>
      <Modal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        width='fit-content'
        borderRadius='1rem'
      >
        <Styled.ChilipiperEmbed
          src={process.env.REACT_APP_CHILIPIPER_CALENDAR_LINK_QUEUE}
          loading='lazy'
        ></Styled.ChilipiperEmbed>
      </Modal>
    </Styled.ChilipiperMessageSection>
  )
}
