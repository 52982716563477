import { chowlyApi } from 'config'

import client from '../chowly'
import { UserResponse } from '../models'

export const confirmUser = async (confirmationToken: string) => {
  const { data } = await client.get<UserResponse>(
    `${chowlyApi.signupConfirmation}?confirmation_token=${confirmationToken}`,
  )
  return data
}
