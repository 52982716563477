import { Location, retrieveToosLocations } from 'api'
import { useGetLocationPartners } from 'hooks'
import { useAuth0 } from 'libs/auth0-react'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { activePlatformIdState } from 'state/atoms'
import { showError } from 'utils'

const useGetToosPartnerLocation = () => {
  const { accessToken } = useAuth0()
  const [locations, setLocations] = useState<Location[]>()
  const { partner } = useGetLocationPartners()
  const partnerId = partner?.attributes.id
  const partnerName = partner?.attributes.name
  const orderPlatformId = useRecoilValue(activePlatformIdState)

  useEffect(() => {
    if (accessToken && partnerId && orderPlatformId) {
      ;(async () => {
        try {
          const locs = await retrieveToosLocations({
            accessToken,
            partnerId,
            orderPlatformId,
          })
          setLocations(locs.data.map((resource) => resource.attributes))
        } catch (err) {
          showError(err)
          console.error(err)
        }
      })()
    }
  }, [accessToken, partnerId, orderPlatformId])

  return {
    orderPlatformId,
    locations,
    partnerId,
    partnerName,
  }
}

export default useGetToosPartnerLocation
