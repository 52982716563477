import axios from 'axios'
import { auth0 } from 'config'
import { PasswordResetRequest } from 'types'

export const resetPassword = async ({ email }: PasswordResetRequest) => {
  const payload = {
    client_id: auth0.clientId,
    email,
    connection: auth0.connection,
  }
  return await axios.post(`https://${auth0.domain}/dbconnections/change_password`, payload)
}
