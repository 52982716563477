import { ReactComponent as ChowlyLogo } from 'assets/logo.svg'
import { Hero, PrivacyTerms } from 'components'
import { useAuth0 } from 'libs/auth0-react'
import { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import * as Styled from './styles'

type LoginRouteState = { from?: { pathname: string } } | null

function Login() {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const from = (location.state as LoginRouteState)?.from?.pathname || '/'
  const justVerified = Boolean(searchParams.get('email_just_verified'))
  const isInvalidGoogleUser = Boolean(searchParams.get('invalid_google_user'))

  useEffect(() => {
    if (isAuthenticated) {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true })
    }
  }, [from, isAuthenticated])

  useEffect(() => {
    if (justVerified) {
      toast.success('Your email has been verified. Please login to continue.')
    }
  }, [justVerified])

  useEffect(() => {
    if (isInvalidGoogleUser) {
      toast.error(
        'Oops... You are not eligible for Google login. Please create an account without using Google login.',
      )
    }
  }, [isInvalidGoogleUser])

  const handleLogin = () => loginWithRedirect({ appState: { returnTo: from } })

  return (
    <Styled.Container>
      <Styled.SectionLeft>
        <Hero />
      </Styled.SectionLeft>
      <Styled.SectionRight>
        <Styled.ContentWrapper>
          <Styled.Header>
            <Styled.Logo />
            <Styled.SignUpLinkWrap>
              <Styled.AnchorSignUpLink href='https://chowly.help/s/article/Creating-a-Restaurant-Control-Center-Account'>
                I want to Sign Up
              </Styled.AnchorSignUpLink>
            </Styled.SignUpLinkWrap>
          </Styled.Header>
          <Styled.Content>
            <Styled.LogoContainer>
              <ChowlyLogo />
            </Styled.LogoContainer>
            <Styled.Title>Welcome Back!</Styled.Title>
            <Styled.Description> Let’s get started. Sign in below.</Styled.Description>
            <Styled.LoginButton onClick={handleLogin}>Sign In</Styled.LoginButton>
            <PrivacyTerms />
          </Styled.Content>
          <Styled.OuterLink href='https://aboyeur.chowly.app' target='_blank'>
            Looking for Aboyeur?
          </Styled.OuterLink>
        </Styled.ContentWrapper>
      </Styled.SectionRight>
    </Styled.Container>
  )
}

export default Login
