import { useEffect } from 'react'
import { useMatch } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { activePartnerIdState } from 'state/atoms'

const useRouteLocationId = (locationId?: string) => {
  const matchedResult = useMatch('/manage-locations/:locationId/*')
  const setActivePartnerId = useSetRecoilState(activePartnerIdState)
  const partnerId = matchedResult?.params.locationId || locationId || null
  useEffect(() => {
    setActivePartnerId(partnerId ?? null)
  }, [partnerId])
  return partnerId
}

export default useRouteLocationId
