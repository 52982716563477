import { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'

import { modalState } from '../state/atoms'

type ModalName = 'HowToVideoModal' | 'OnboardingModal' | 'ManageAccount' | 'AddPlatform'

const useOpenModal = () => {
  const setModalState = useSetRecoilState(modalState)
  return useCallback((modalName: ModalName | null) => {
    setModalState({ isOpen: true, modalName })
  }, [])
}

export default useOpenModal
