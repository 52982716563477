import './dropdown.css'

import { TextField } from 'components/Form'
import AutoComplete from 'react-google-autocomplete'
import styled from 'styled-components'
import { borderRadius, colors, fontWeight, typography } from 'theme'

export const selectStyles = {
  menu: {
    width: '100%',
  },
  menuList: {
    backgroundColor: colors.blue['60'],
    fontSize: typography.base.fontSize,
  },
  container: {
    width: '100%',
    height: 48,
  },
  control: {
    width: '100%',
    height: 48,
  },
}

export const ScrollView = styled.div<{ isScrollEnabled?: boolean }>(
  ({ isScrollEnabled }) =>
    isScrollEnabled && {
      flex: 1,
      overflowY: 'auto',
    },
)

export const Label = styled.label({
  fontWeight: fontWeight.bold,
  color: colors.gray.darkest,
  display: 'block',
  padding: '2px 0',
  margin: 0,
})

export const PlacesSearch = styled(AutoComplete)({
  backgroundColor: colors.blue['60'],
  borderRadius: borderRadius.lg,
  fontSize: typography.base.fontSize,
  padding: '2% ',
  marginBottom: '10px',
  width: '100%',
})

export const RestaurantName = styled(TextField)({
  backgroundColor: colors.blue['60'],
  borderRadius: borderRadius.lg,
  fontSize: typography.base.fontSize,
  padding: '10px 15px',
  width: '100%',
})

export const InputSmall = styled(TextField)({
  backgroundColor: colors.blue['60'],
  fontSize: typography.base.fontSize,
  height: 48,
})

export const Link = styled.button<{ isVisible: boolean }>(
  {
    color: colors.primary.light,
    fontWeight: 700,
    marginBottom: '1rem',
  },
  ({ isVisible }) =>
    !isVisible && {
      display: 'none',
      opacity: 0,
    },
)

export const Word = styled.h3({
  margin: '10px 0',
})

type AddressProps = { isVisible: boolean; isEnabled: boolean }

export const AddressField = styled(TextField)<AddressProps>(
  { backgroundColor: colors.blue['60'] },
  ({ isEnabled }) =>
    !isEnabled && {
      pointerEvents: 'none',
      backgroundColor: colors.gray.DEFAULT,
    },
)

export const AddressContainer = styled.div<AddressProps>(
  {},
  ({ isVisible }) =>
    !isVisible && {
      display: 'none',
      opacity: 0,
    },
)

export const FieldsWrap = styled.div<AddressProps>(
  {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: 25,
  },
  ({ isVisible }) =>
    !isVisible && {
      display: 'none',
      opacity: 0,
    },
  ({ isEnabled }) =>
    !isEnabled && {
      pointerEvents: 'none',
      input: { backgroundColor: colors.gray.DEFAULT },
    },
)

export const FieldLabel = styled.label({
  display: 'block',
  fontSize: typography.base.fontSize,
  fontWeight: fontWeight.bold,
  color: colors.gray.darkest,
  marginBottom: '0.25rem',
})

export const ErrorMessage = styled.span({
  color: colors.red.DEFAULT,
})

export const SubmitButtonWrapper = styled.div({
  marginTop: '2rem',
  width: '100%',
  textAlign: 'center',
})
