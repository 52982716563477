import { ActivationCodeResponse } from 'api/models'
import { chowlyApi } from 'config'

import client from '../chowly'

export const getActivationCodes = async ({
  partnerId,
  kind,
}: {
  partnerId: number
  kind: string
}) => {
  const { data } = await client.post<ActivationCodeResponse>(chowlyApi.activationCodes, {
    activation_code: {
      kind,
      partner_id: partnerId,
    },
  })
  return data
}
