import styled from 'styled-components'
import { borderRadius, colors, shadow, transition } from 'theme'

export const Wrapper = styled.label({
  cursor: 'pointer',
  position: 'relative',
  display: 'inline-block',
  // marginTop: '0.25rem',
  // marginBottom: '0.25rem',
})

export const Checkbox = styled.input.attrs({ type: 'checkbox' })({
  position: 'absolute',
  width: 1,
  height: 1,
  padding: 0,
  margin: -1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  borderWidth: 0,
})

export const Track = styled.div(
  {
    borderRadius: borderRadius.xxl,
    width: '1.875rem',
    height: '1rem',
    backgroundColor: ({ isToggled }) => (isToggled ? colors.blue['300'] : colors.gray['medium']),
    boxShadow: shadow.inner,
  },
  ({ activeColor, isToggled }) =>
    activeColor &&
    isToggled && {
      backgroundColor: activeColor,
    },
)

export const Knob = styled.div(
  {
    position: 'absolute',
    backgroundColor: colors.gray['450'],
    width: '.875rem',
    height: '.875rem',
    borderRadius: borderRadius.full,
    top: '.1rem',
    left: '.1rem',
    boxShadow: shadow.DEFAULT,
    ...transition,
  },
  ({ isToggled }) =>
    isToggled && {
      transform: 'translateX(100%)',
    },
)
