import { Button } from 'components'
import styled from 'styled-components'
import { breakpoints, colors, fontFamily, fontWeight, typography } from 'theme'

export const Container = styled.div<{ width?: number | string }>(
  {
    overflow: 'auto',
    position: 'relative',
    borderRadius: '8px',
    gap: '16px',
    width: '600px',
    height: 'auto',
    padding: '16px 16px 32px 16px',
    [`@media ${breakpoints.xs}`]: {
      // width: '100%',
      maxHeight: 'fit-content',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
    },
    backgroundColor: colors.white,
  },
  ({ width }) => ({ width }),
)

export const TableRow = styled.tr({
  [`@media ${breakpoints.xxs}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '107px',
  },
})

export const Table = styled.table<{ width?: number | string }>(
  {
    overflow: 'auto',
    position: 'relative',
    borderRadius: '8px',
    gap: '16px',
    [`@media ${breakpoints.xs}`]: {
      width: '342px',
    },
    width: '620px',
  },
  ({ width }) => ({ width }),
)

export const HeaderCell = styled.th<{ width?: number | string }>(
  {
    padding: '1rem',
    [`@media ${breakpoints.xs}`]: {
      padding: '1rem 0 1rem 0',
    },
    fontFamily: fontFamily.inter,
    fontSize: typography.xxs.fontSize,
    fontWeight: fontWeight.normal,
    lineHeight: '18px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: colors.gray.medium,
  },
  ({ width }) => ({ width }),
)

export const BodyCell = styled.td({
  padding: '8px 16px 8px 16px',
  borderRadius: '6px',
  gap: '8px',
  display: 'inline-flex',
  fontFamily: fontFamily.inter,
  fontSize: typography.xxs.fontSize,
  fontWeight: fontWeight.semibold,
  lineHeight: typography.xxs.lineHeight,
  letterSpacing: '0em',
  textAlign: 'left',
  color: colors.primary.medium,
})

export const Header = styled.div({
  fontFamily: fontFamily.inter,
  fontSize: typography.xl.fontSize,
  fontWeight: fontWeight.semibold,
  lineHeight: typography.xl.lineHeight,
  letterSpacing: '0em',
  textAlign: 'left',
  color: colors.primary.medium,
  display: 'flex',
  justifyContent: 'space-between',
  [`@media ${breakpoints.xs}`]: {
    paddingLeft: '0px',
  },
})

export const Subheader = styled.div({
  fontFamily: fontFamily.inter,
  fontSize: typography.semiXS.fontSize,
  fontWeight: fontWeight.normal,
  lineHeight: typography.semiXS.lineHeight,
  letterSpacing: '0em',
  textAlign: 'left',
  color: colors.primary.medium,
  [`@media ${breakpoints.xs}`]: {
    paddingLeft: '0px',
  },
})

export const ColumnHeader = styled.div({
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight.normal,
  fontSize: typography.xxs.fontSize,
  lineHeight: typography.xxs.lineHeight,
  color: colors.gray[600],
  marginTop: '40px',
})

export const PlatformRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 0px',
  borderBottom: '1px solid #D7D7D7',
  flexWrap: 'nowrap',
  [`@media ${breakpoints.xs}`]: {
    gap: '16px',
  },
})

export const PlatformButtons = styled.div({
  display: 'flex',
  gap: '8px',
  [`@media ${breakpoints.xxs}`]: {
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
})

export const PlatformName = styled.div({
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight.semibold,
  fontSize: '14px',
})

export const PlatformPlaceholder = styled.div({
  color: colors.gray.medium,
  fontWeight: fontWeight.semibold,
  fontSize: typography.semiXS.fontSize,
})

export const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '6px',
  [`@media ${breakpoints.xs}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    height: '88px',
  },
})

export const Dropdown = styled.div({
  fontFamily: fontFamily.inter,
  fontSize: typography.semiXS.fontSize,
  fontWeight: fontWeight.semibold,
  lineHeight: typography.semiXS.lineHeight,
  letterSpacing: '0em',
  textAlign: 'center',
  backgroundColor: colors.white,
  color: colors.primary.medium,
  width: 'inherit',
  // height: '94px',
  padding: '16px 12px 16px 12px',
  borderRadius: '8px',
  borderStyle: 'solid',
  gap: '16px',
  top: '100%',
  position: 'absolute',
  boxShadow: '0px 4px 60px 0px #0000001A',
})

export const ComingSoon = styled.div({
  color: colors.gray.medium,
  fontFamily: fontFamily.inter,
  fontSize: typography.semiXS.fontSize,
  fontWeight: fontWeight.semibold,
  lineHeight: typography.semiXS.lineHeight,
  letterSpacing: '0em',
  textAlign: 'right',
})

export const BaseButton = styled(Button)<{ remove?: boolean; width?: string }>(
  {
    minWidth: 'auto',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
    fontFamily: fontFamily.inter,
    fontSize: typography.semiXS.fontSize,
    fontWeight: fontWeight.semibold,
    lineHeight: typography.semiXS.lineHeight,
    letterSpacing: '0em',
    backgroundColor: colors.blue['700'],
    borderStyle: 'solid',
    color: colors.white,
    borderColor: colors.primary.light,
    borderWidth: 1,
    borderRadius: '6px',
    width: '125px',
    height: '39px',
    padding: '8px 16px 8px 16px',
    gap: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  ({ remove }) =>
    remove && {
      backgroundColor: 'transparent',
      borderColor: colors.red.darkRed,
      color: colors.red.darkRed,
      width: '125px',
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  ({ width }) =>
    width && {
      width,
      minWidth: 'max-content',
    },
)

export const SuccessStatus = styled.div({
  width: '39px',
  height: '15px',
  fontFamily: fontFamily.inter,
  fontSize: typography.xxxs.fontSize,
  fontWeight: '500',
  lineHeight: typography.xxxs.lineHeight,
  letterSpacing: '0em',
  color: colors.green.darkGreen,
})

export const EnabledIcon = styled.img({
  width: '6px',
  height: '6px',
})

export const AvailableGroup = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  // paddingBottom: '16px',
  [`@media ${breakpoints.xxs}`]: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'baseline',
  },
})

export const EllipseGroup = styled.div({
  alignItems: 'center',
  gap: '5px',
  display: 'flex',
  paddingTop: '8px',
})

export const Icon = styled.img({
  width: '24px',
  height: '24px',
})

export const StatusIcon = styled.img<{ iconSize?: string }>(
  {
    width: '6px',
    height: '6px',
    marginLeft: '12px',
    [`@media ${breakpoints.xxs}`]: {
      marginLeft: 0,
    },
  },
  ({ iconSize }) =>
    iconSize && {
      width: `${iconSize}px`,
      height: `${iconSize}px`,
    },
)

type StatusProps = { isSuccess?: boolean }

export const StatusContainer = styled.div({
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  flexWrap: 'wrap-reverse',
})

export const StatusText = styled.h4<StatusProps>(
  {
    display: 'block',
    fontSize: '10px',
    fontWeight: fontWeight.medium,
    color: colors.red.darkRed,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  ({ isSuccess }) =>
    isSuccess && {
      color: colors.green.darkGreen,
    },
)
