import { ReactComponent as ResyncIcon } from 'assets/icons/reload-icon.svg'
import outline_icon_button_pause from 'assets/icons/outline-icon-button-pause.svg'
import outline_icon_button_play from 'assets/icons/outline-icon-button-play.svg'
import { FC, useState } from 'react'

import ResetButtonGroup from './ResetButtonGroup'
import * as Styled from './styles'

type Props = {
  navigation: () => void
  buttonText?: string
  size?: string
  disabled?: boolean
  useWarning?: boolean
  page?: string
}

const ResetButton: FC<Props> = ({
  navigation,
  useWarning,
  size,
  disabled,
  buttonText,
  page,
  ...props
}) => {
  const text = buttonText || 'Start Over'
  const [showWarning, setShowWarning] = useState(false)

  const proceedToSubmit = () => {
    if (useWarning) {
      setShowWarning(true)
    } else {
      navigation()
    }
  }

  const closeWarning = () => {
    setShowWarning(false)
  }

  return (
    <>
      {!showWarning && (
        <>
          {page !== 'location' &&
            <Styled.BackButton
              onClick={proceedToSubmit}
              disabled={disabled}
              size={size}
              type='button'
              text={text}
              {...props}
            >
              {text === 'Resync Menu' ? (
                <>
                  <Styled.IconContainer>
                    <ResyncIcon />
                  </Styled.IconContainer>
                  {text}
                </>
              ) : (
                text
              )}
            </Styled.BackButton>
          }
          <>
            {text === 'Resume' && (
              <Styled.IconContainer 
                onClick={proceedToSubmit}
                disabled={disabled}
                size={size}
                type='button'
                text={text}
                {...props}
              >
                <Styled.Icon src={outline_icon_button_play} alt='playIcon' />
              </Styled.IconContainer>
            ) }
            {text === 'Pause' && (
              <Styled.IconContainer 
                onClick={proceedToSubmit}
                disabled={disabled}
                size={size}
                type='button'
                text={text}
                {...props}
              >
                <Styled.Icon src={outline_icon_button_pause} alt='pauseIcon' />
              </Styled.IconContainer>
            )}
          </>
        </>
      )}
      {showWarning && <ResetButtonGroup navigation={navigation} closeWarning={closeWarning} />}
    </>
  )
}

export default ResetButton
