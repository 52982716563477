import { Button } from 'components'
import styled from 'styled-components'
import theme, { colors, fontFamily, fontWeight, inset0, typography } from 'theme'

type DrawerProps = { isVisible: boolean }

export const Overlay = styled.div<DrawerProps>(
  {
    position: 'fixed',
    ...inset0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 21,
    opacity: 0,
    // backgroundColor: `${colors.gray.darkest}c0`,
    visibility: 'hidden',
  },
  ({ isVisible }) =>
    isVisible && {
      visibility: 'visible',
      opacity: 1,
    },
)

export const DrawerConatiner = styled.div<DrawerProps>(
  {
    backgroundColor: colors.white,
    width: '40vw',
    minWidth: '450px',
    height: '100vh',
    borderRadius: '4% 0 0 0%',
    boxShadow: '0px 0px 20px 0px',
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: 30,
    top: '0',
    right: '0',
    visibility: 'hidden',
  },
  ({ isVisible }) =>
    isVisible && {
      visibility: 'visible',
    },
)

export const PlatformBttnSection = styled.div({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  flexDirection: 'column',
})

export const PlatformSection = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  maxHeight: '60vh',
})

export const PlatformWarningContainer = styled.div({
  position: 'relative',
  display: 'flex',
  width: '100%',
  marginTop: '12px',
  justifyContent: 'center',
  fontWeight: fontWeight.normal,
  fontSize: typography.xxxs.fontSize,
  fontFamily: fontFamily.inter,
  lineHeight: typography.xxxs.lineHeight,
  color: colors.primary.medium,
})

export const CheckBoxLabel = styled.label({
  display: 'flex',
  flexDirection: 'row',
})

export const WarningCheckBoxWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

export const CheckBoxInput = styled.input({
  margin: '0 .5rem',
})

export const ConfirmTextWrapper = styled.div({
  color: colors.blue[700],
})

export const SectionColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

export const SectionLabels = styled.div({
  margin: '.35rem',
  fontWeight: fontWeight.semibold,
})

export const SectionDataFields = styled.div({
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight.medium,
  fontSize: typography.xxs.fontSize,
  color: colors.primary.medium,
  width: '100%',
})

export const IconWrapper = styled.div({
  height: '50px',
  width: '50px',
  position: 'absolute',
  top: 0,
  left: 0,
  margin: '1.3rem .8rem',
  '&:hover': {
    cursor: 'pointer',
  },
})

const AddPlatformText = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: '2rem 0',
}

export const AddPlatformTitle = styled.div({
  ...AddPlatformText,
})

export const AddPlatformDiscription = styled.div({
  ...AddPlatformText,
  color: colors.blue[700],
})

export const BttnWrapper = styled.div({
  margin: '2.5rem 0 0 0',
  display: 'flex',
  gap: '0.75rem',
  justifyContent: 'flex-end',
})

export const DrawerFooter = styled.div({
  bottom: '2rem',
  display: 'flex',
  flexDirection: 'column',
  columnGap: '0.75rem',
  paddingTop: '3rem',
})

export const ButtonSection = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '8px',
})

export const LocationRadioLabel = styled.label({
  display: 'flex',
  fontSize: typography.xxs.fontSize,
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight.medium,
  cursor: 'pointer',
  padding: '16px 0',
  position: 'relative',
  borderBottom: '1px solid #D7D7D7',
})

export const LocationRadioInput = styled.input({
  margin: '0 1rem',
  cursor: 'pointer',
})

export const ProgressContainer = styled.div({
  width: '90%',
  margin: '1.5rem auto 0',
})

export const Progress = styled.progress({
  width: '100%',
  transition: 'all .3s ease',
  '&::-moz-progress-bar': {
    background: colors.blue[100],
    borderRadius: '2rem',
    border: `1px solid ${colors.blue[100]}`,
  },
  '&::-moz-progress-value': {
    background: colors.primary.light,
    borderRadius: '2rem',
    border: `1px solid ${colors.primary.light}`,
  },
  '&::-webkit-progress-value': {
    background: colors.primary.light,
    borderRadius: '2rem',
    border: `1px solid ${colors.primary.light}`,
  },
  '&::-webkit-progress-bar': {
    background: colors.blue[100],
    borderRadius: '2rem',
    border: `1px solid ${colors.blue[100]}`,
  },
  color: colors.primary.light,
  borderRadius: '2rem',
  border: `1px solid ${colors.blue[100]}`,
  borderColor: colors.blue[100],
})

export const ProgressBarLabel = styled.label({
  width: 'auto',
  textAlign: 'center',
  display: 'block',
  color: colors.primary.light,
  fontWeight: fontWeight.semibold,
  fontSize: 16,
  lineHeight: 1,
  margin: '1rem',
})

export const StatusText = styled.div<{
  hasError?: boolean
  noTransform?: boolean
  success?: boolean
}>(
  {
    fontWeight: fontWeight.normal,
    fontSize: '1.2rem',
    color: colors.black,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  ({ noTransform }) => noTransform && { textTransform: 'none' },
  ({ hasError }) => hasError && { color: colors.red.DEFAULT, textTransform: 'none' },
  ({ success }) => success && { color: colors.green[500], textTransform: 'none' },
)

export const RetryButton = styled.button({
  marginLeft: '0.5rem',
  fontWeight: fontWeight.bold,
  fontSize: '1.2rem',
  lineHeight: '1.5rem',
  color: colors.primary.light,
})

export const PlatformWarningMessage = styled.div({
  fontFamily: fontFamily.inter,
  fontSize: '.625rem',
  color: theme.colors.primary.medium,
  textAlign: 'end',
})

export const PlatformRestart = styled.div({
  color: theme.colors.blue[700],
  fontSize: '.8rem',
  textDecoration: 'underline',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

export const TextArea = styled.textarea({
  width: '100%',
  padding: '0.75rem',
  border: '1px solid #D7D7D7',
})

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '2.5rem 0 0 0',
  gap: '12px',
})

export const FlexRow = styled.div({
  display: 'flex',
  columnGap: '0.25rem',
})

export const PrimaryText = styled.span({
  color: colors.primary.light,
})

export const TextButton = styled.button({
  fontWeight: fontWeight.bold,
  lineHeight: '1.5rem',
  color: colors.primary.light,
})

export const ContainerWithBottomActions = styled.div({
  height: '100%',
  overflow: 'auto',
})

export const AuthLinkTitle = styled.div({
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight.bold,
  fontSize: typography.xxs.fontSize,
  lineHeight: '19.5px',
})

export const BaseButton = styled(Button)<{
  variant?: string
  remove?: boolean
  disabled?: boolean
}>(
  {
    width: 'fit-content',
    minWidth: 'fit-content',
    padding: '8px 16px 8px 16px',
    fontFamily: fontFamily.inter,
    fontSize: typography.semiXS.fontSize,
    fontWeight: fontWeight.semibold,
    lineHeight: typography.semiXS.lineHeight,
    textWrap: 'nowrap',
    letterSpacing: '0em',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    color: colors.blue[700],
    borderColor: colors.primary.light,
    borderWidth: 1,
    height: '39px',
    '&:hover': {
      backgroundColor: colors.blue[100],
    },
  },
  ({ variant }) =>
    variant === 'solid' && {
      backgroundColor: colors.blue[700],
      color: colors.gray[450],
      border: 'unset',
    },
  ({ remove }) =>
    remove && {
      backgroundColor: 'transparent',
      borderColor: colors.red.darkRed,
      color: colors.red.darkRed,
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
)
