import {
  Auth0Client,
  Auth0ClientOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  PopupConfigOptions,
  PopupLoginOptions,
  RedirectLoginResult,
  User,
} from '@auth0/auth0-spa-js'
import * as chowlyApiClient from 'api/chowly'
import { getFeatureFlags } from 'api/users'
import { auth0 } from 'config'
import React, { ReactNode, useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { FeatureFlag } from 'types/react-feature-flags/FeatureFlags'

import { initialAuthState } from './auth-state'
import Auth0Context, {
  Auth0ContextInterface,
  LogoutOptions,
  RedirectLoginOptions,
} from './auth0-context'
import { reducer } from './reducer'
import { hasAuthParams, loginError, tokenError } from './utils'

/**
 * The state of the application before the user was redirected to the login page.
 */
export type AppState = {
  returnTo?: string
  [key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

/**
 * The main configuration to instantiate the `Auth0Provider`.
 */
export interface Auth0ProviderOptions extends Auth0ClientOptions {
  /**
   * The child nodes your Provider has wrapped
   */
  children?: React.ReactNode
  /**
   * By default this removes the code and state parameters from the url when you are redirected from the authorize page.
   * It uses `window.history` but you might want to overwrite this if you are using a custom router, like `react-router-dom`
   * See the EXAMPLES.md for more info.
   */
  onRedirectCallback?: (appState?: AppState, user?: User) => void
  /**
   * By default, if the page url has code/state params, the SDK will treat them as Auth0's and attempt to exchange the
   * code for a token. In some cases the code might be for something else (another OAuth SDK perhaps). In these
   * instances you can instruct the client to ignore them eg
   *
   * ```jsx
   * <Auth0Provider
   *   clientId={clientId}
   *   domain={domain}
   *   skipRedirectCallback={window.location.pathname === '/stripe-oauth-callback'}
   * >
   * ```
   */
  skipRedirectCallback?: boolean
  /**
   * Context to be used when creating the Auth0Provider, defaults to the internally created context.
   *
   * This allows multiple Auth0Providers to be nested within the same application, the context value can then be
   * passed to useAuth0, withAuth0, or withAuthenticationRequired to use that specific Auth0Provider to access
   * auth state and methods specifically tied to the provider that the context belongs to.
   *
   * When using multiple Auth0Providers in a single application you should do the following to ensure sessions are not
   * overwritten:
   *
   * * Configure a different redirect_uri for each Auth0Provider, and set skipRedirectCallback for each provider to ignore
   * the others redirect_uri
   * * If using localstorage for both Auth0Providers, ensure that the audience and scope are different for so that the key
   * used to store data is different
   *
   * For a sample on using multiple Auth0Providers review the [React Account Linking Sample](https://github.com/auth0-samples/auth0-link-accounts-sample/tree/react-variant)
   */
  context?: React.Context<Auth0ContextInterface>
}

const __VERSION__ = '2.1.0'

/**
 * @ignore
 */
const toAuth0ClientOptions = (opts: Auth0ProviderOptions): Auth0ClientOptions => {
  return {
    ...opts,
    auth0Client: {
      name: 'auth0-react',
      version: __VERSION__,
    },
  }
}

/**
 * @ignore
 */
const onRedirectCallback = (appState?: AppState): void => {
  window.history.replaceState({}, document.title, appState?.returnTo || window.location.pathname)
}

const { domain, clientId, audience } = auth0

export const auth0Client = new Auth0Client(
  toAuth0ClientOptions({
    domain,
    clientId,
    authorizationParams: { redirect_uri: window.location.origin, audience },
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    cacheLocation: 'localstorage',
  }),
)

/**
 * ```jsx
 * <Auth0Provider
 *   domain={domain}
 *   clientId={clientId}
 *   redirectUri={window.location.origin}>
 *   <MyApp />
 * </Auth0Provider>
 * ```
 *
 * Provides the Auth0Context to its child components.
 */
type Props = {
  children: ReactNode
  skipRedirectCallback?: boolean
}
const Auth0Provider = ({ children, skipRedirectCallback }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialAuthState)
  const didInitialise = useRef(false)

  useEffect(() => {
    if (didInitialise.current) {
      return
    }
    didInitialise.current = true
    ;(async (): Promise<void> => {
      try {
        let user: User | undefined
        let accessToken: string | undefined
        let featureFlags: FeatureFlag[] = []

        if (hasAuthParams() && !skipRedirectCallback) {
          const { appState } = await auth0Client.handleRedirectCallback()
          user = await auth0Client.getUser()
          onRedirectCallback(appState)
        } else {
          await auth0Client.checkSession()
          user = await auth0Client.getUser()
        }
        if (user) {
          accessToken = await auth0Client.getTokenSilently()

          chowlyApiClient.authorize(accessToken)
          featureFlags = await getFeatureFlags()
        }

        dispatch({ type: 'INITIALISED', user, accessToken, authorizedFeatures: featureFlags })
      } catch (error) {
        dispatch({ type: 'ERROR', error: loginError(error) })
      }
    })()
  }, [onRedirectCallback, skipRedirectCallback])

  const loginWithRedirect = useCallback(
    (opts?: RedirectLoginOptions): Promise<void> => auth0Client.loginWithRedirect(opts),
    [],
  )

  const loginWithPopup = useCallback(
    async (options?: PopupLoginOptions, config?: PopupConfigOptions): Promise<void> => {
      dispatch({ type: 'LOGIN_POPUP_STARTED' })
      try {
        await auth0Client.loginWithPopup(options, config)
      } catch (error) {
        dispatch({ type: 'ERROR', error: loginError(error) })
        return
      }
      const user = await auth0Client.getUser()
      dispatch({ type: 'LOGIN_POPUP_COMPLETE', user })
    },
    [],
  )

  const logout = useCallback(async (opts: LogoutOptions = {}): Promise<void> => {
    await auth0Client.logout(opts)
    if (opts.openUrl || opts.openUrl === false) {
      chowlyApiClient.unauthorize()
      dispatch({ type: 'LOGOUT' })
    }
  }, [])

  const getAccessTokenSilently = useCallback(async (opts?: GetTokenSilentlyOptions) => {
    let accessToken
    try {
      accessToken = await auth0Client.getTokenSilently(opts)
    } catch (error) {
      throw tokenError(error)
    } finally {
      chowlyApiClient.authorize(accessToken)
      dispatch({
        type: 'GET_ACCESS_TOKEN_COMPLETE',
        user: await auth0Client.getUser(),
        accessToken,
      })
    }
    return accessToken
  }, [])

  const getAccessTokenWithPopup = useCallback(
    async (
      opts?: GetTokenWithPopupOptions,
      config?: PopupConfigOptions,
    ): Promise<string | undefined> => {
      let accessToken
      try {
        accessToken = await auth0Client.getTokenWithPopup(opts, config)
      } catch (error) {
        throw tokenError(error)
      } finally {
        chowlyApiClient.authorize(accessToken)

        dispatch({
          type: 'GET_ACCESS_TOKEN_COMPLETE',
          user: await auth0Client.getUser(),
          accessToken,
        })
      }
      return accessToken
    },
    [],
  )

  const getIdTokenClaims = useCallback(() => auth0Client.getIdTokenClaims(), [])

  const handleRedirectCallback = useCallback(async (url?: string): Promise<RedirectLoginResult> => {
    try {
      const result = await auth0Client.handleRedirectCallback(url)
      dispatch({
        type: 'HANDLE_REDIRECT_COMPLETE',
        user: await auth0Client.getUser(),
        accessToken: await auth0Client.getTokenSilently(),
      })
      return result
    } catch (error) {
      throw tokenError(error)
    }
  }, [])

  const contextValue = useMemo(() => {
    return {
      ...state,
      getAccessTokenSilently,
      getAccessTokenWithPopup,
      getIdTokenClaims,
      loginWithRedirect,
      loginWithPopup,
      logout,
      handleRedirectCallback,
    }
  }, [
    state,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    getIdTokenClaims,
    loginWithRedirect,
    loginWithPopup,
    logout,
    handleRedirectCallback,
  ])

  return <Auth0Context.Provider value={contextValue}>{children}</Auth0Context.Provider>
}

export default Auth0Provider
