import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg'
import { ReactComponent as ErrorIcon } from 'assets/icons/notification-error.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/notification-info.svg'
import { ReactComponent as SuccessIcon } from 'assets/icons/notification-success.svg'
import { ReactComponent as WarningIcon } from 'assets/icons/notification-warn.svg'
import { ToastOptions, toast } from 'react-toastify'

import * as Styled from './styles'

export type ToastContentProps = {
  message: string
  icon?: boolean
  buttonTitle?: string
  onButtonClick?: () => void
}

const ToastContent = ({ message, buttonTitle, onButtonClick }: ToastContentProps) => (
  <Styled.Container>
    <Styled.Message>{message}</Styled.Message>
    {buttonTitle && (
      <Styled.Button onClick={onButtonClick}>
        <div>{buttonTitle}</div>
        <ChevronRightIcon />
      </Styled.Button>
    )}
  </Styled.Container>
)

const Toast = {
  primary: (props: ToastContentProps, options?: ToastOptions) => {
    return toast(<ToastContent {...props} />, { icon: <InfoIcon />, ...options })
  },
  loading: (props: ToastContentProps, options?: ToastOptions) => {
    return toast.loading(<ToastContent {...props} />, options)
  },
  success: (props: ToastContentProps, options?: ToastOptions) => {
    return toast.success(<ToastContent {...props} />, { icon: <SuccessIcon />, ...options })
  },
  info: (props: ToastContentProps, options?: ToastOptions) => {
    return toast.info(<ToastContent {...props} />, { icon: <InfoIcon />, ...options })
  },
  error: (props: ToastContentProps, options?: ToastOptions) => {
    return toast.error(<ToastContent {...props} />, { icon: <ErrorIcon />, ...options })
  },
  warning: (props: ToastContentProps, options?: ToastOptions) => {
    return toast.warning(<ToastContent {...props} />, { icon: <WarningIcon />, ...options })
  },
  dismiss: toast.dismiss,
  clearWaitingQueue: toast.clearWaitingQueue,
  isActive: toast.isActive,
  update: toast.update,
  done: toast.done,
}

export default Toast
