import { FormattedMenuSectionInfo } from 'api'
import ArrowBackIcon from 'assets/icons/chevron-left.svg'
import ArrowForwardIcon from 'assets/icons/chevron-right-small.svg'
import CloseIcon from 'assets/icons/outline-icon-button-close.svg'
import EllipseIcon from 'assets/icons/outline-icon-button-ellipse.svg'
import { ActionBar, Button } from 'components'
import Modal from 'features/modal/Modal'
import { useIsMobile } from 'hooks'
import { useEffect, useRef } from 'react'
import theme from 'theme'

import ModifierSubsection from '../ModifierSubsection'
import * as Styled from '../styles'

type Props = {
  item: FormattedMenuSectionInfo
  isOpen: boolean
  onClose: () => void
  isEditable?: boolean
  itemCount: number
  totalItemsCount: number
  setSelectedItemInModal: (item: FormattedMenuSectionInfo) => void
  allMenuItems: FormattedMenuSectionInfo[]
}

const MenuItemModal = ({
  item,
  isOpen,
  onClose,
  isEditable,
  itemCount,
  totalItemsCount,
  setSelectedItemInModal,
  allMenuItems,
}: Props) => {
  const { isMobile } = useIsMobile()
  const leftButtonRef = useRef<HTMLButtonElement>(null)
  const rightButtonRef = useRef<HTMLButtonElement>(null)

  // const resetChanges = () => {}
  // const saveChanges = () => {}

  const onSelectNewItem = (newItemIdx: number) => {
    const item = allMenuItems[newItemIdx]

    if (item) {
      setSelectedItemInModal(item)
    }
  }

  // Allows for hotkeys on left/right buttons
  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement> | KeyboardEvent) => {
    if (e.key === 'ArrowRight' && itemCount < totalItemsCount) {
      e.preventDefault()
      if (rightButtonRef.current) {
        rightButtonRef.current.focus()
      }
      // since we're looking at the index, we don't add a 1, it's already the right value.
      const newItemIdx = itemCount
      onSelectNewItem(newItemIdx)
    }

    if (e.key === 'ArrowLeft' && itemCount > 1) {
      e.preventDefault()
      if (leftButtonRef.current) {
        leftButtonRef.current.focus()
      }

      // previous item and -1 for index.
      const newItemIdx = itemCount - 1 - 1
      onSelectNewItem(newItemIdx)
    }
  }

  // listens to the keydown event.
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  return (
    <Modal width='100%' height='100%' isOpen={isOpen} onClose={onClose}>
      <ActionBar position='top' padding='0 16px'>
        <Styled.ButtonContainer>
          <Styled.IconButton margin='auto 0 auto auto' type='button' onClick={onClose}>
            <img src={CloseIcon} alt='close' />
          </Styled.IconButton>
        </Styled.ButtonContainer>
      </ActionBar>
      <div
        style={{
          overflow: 'scroll',
          width: '100%',
          marginTop: '64px',
          marginBottom: isEditable ? '64px' : 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Styled.ItemViewContainer isMobile={isMobile}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 5px' }}>
            <Styled.Subtitle>{item.attributes.title}</Styled.Subtitle>
            <div style={{ display: 'flex' }}>
              <Styled.IconButton
                type='button'
                ref={leftButtonRef}
                disabled={itemCount <= 1}
                onClick={() => {
                  if (itemCount > 1) {
                    // previous item and -1 for index.
                    const newItemIdx = itemCount - 1 - 1
                    onSelectNewItem(newItemIdx)
                  }
                }}
                onKeyDown={onKeyDown}
              >
                <img src={ArrowBackIcon} alt='arrow back' width={24} height={24} />
              </Styled.IconButton>
              <Styled.ItemViewCount>{`${itemCount}/${totalItemsCount}`}</Styled.ItemViewCount>
              <Styled.IconButton
                type='button'
                ref={rightButtonRef}
                disabled={itemCount >= totalItemsCount}
                onClick={() => {
                  if (itemCount < totalItemsCount) {
                    // need to pass in index so don't need to add a 1.
                    onSelectNewItem(itemCount)
                  }
                }}
                onKeyDown={onKeyDown}
              >
                <img src={ArrowForwardIcon} alt='arrow next' width={24} height={24} />
              </Styled.IconButton>
            </div>
          </div>
          <div
            style={{
              background: theme.colors.gray[450],
              height: 'auto',
              width: '100%',
              padding: '24px',
            }}
          >
            <div style={{ display: 'flex', gap: 24 }}>
              <Styled.ItemViewImageContainer>
                <Styled.ItemViewProductTitle>Product Image</Styled.ItemViewProductTitle>
                <Styled.ItemViewImageBox>
                  <div style={{ height: '100%' }}>
                    <img
                      src={item.attributes.image_url}
                      alt='product'
                      style={{ borderRadius: 9.6, height: '48px', width: 'auto', margin: 'auto' }}
                    />
                  </div>
                  <div style={{ position: 'absolute', bottom: '12px', right: '12px' }}>
                    <Styled.IconButton>
                      <img src={EllipseIcon} alt='upload' />
                    </Styled.IconButton>
                  </div>
                </Styled.ItemViewImageBox>
                <Styled.ItemViewProductDescription>
                  Logo image that is shown on the top of every page. Recommended minimum resolution:
                  500px x 500px. Accepted file formats: .png, .jpg, .webP.{' '}
                </Styled.ItemViewProductDescription>
              </Styled.ItemViewImageContainer>
              <div
                style={{
                  width: '404px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 16,
                  flexBasis: isMobile ? '50%' : undefined,
                }}
              >
                <Styled.ItemViewProductTitle>Product Title</Styled.ItemViewProductTitle>
                <Styled.Input value={item.attributes.title} disabled={true} />
                <Styled.ItemViewProductTitle>Product Description</Styled.ItemViewProductTitle>
                <Styled.TextArea value={item.attributes.description} rows={5} disabled={true} />
              </div>
            </div>
          </div>
          <div
            style={{
              background: theme.colors.gray[450],
              width: '100%',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: 24, padding: '24px' }}>
              <Styled.ItemViewProductTitle>Modifiers</Styled.ItemViewProductTitle>
              <Styled.MenuTable padding='0'>
                {item.children.customizations?.map((customization) => (
                  <ModifierSubsection
                    key={customization.id}
                    subsection={customization}
                    groupModifiers={customization.children.customization_options}
                    isItemPreview={true}
                  />
                ))}
              </Styled.MenuTable>
            </div>
          </div>
          {/* Only show this if there are changes to the item */}
        </Styled.ItemViewContainer>
      </div>
      {isEditable && (
        <ActionBar position='bottom'>
          <Styled.ButtonContainer>
            <Button
              variant='textonly'
              narrow
              // onClick={resetChanges} // reset all menu updates
            >
              Cancel
            </Button>
            <Button
              variant='solid'
              narrow
              // onClick={saveChanges}
            >
              Save
            </Button>
          </Styled.ButtonContainer>
        </ActionBar>
      )}
    </Modal>
  )
}

export default MenuItemModal
