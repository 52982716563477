import Tippy from '@tippyjs/react'
import styled from 'styled-components'
import { colors, fontWeight } from 'theme'

export const Container = styled.span({
  display: 'inline-flex',
  alignItems: 'center',
})

export const TotalIssues = styled.span<{ $isWarning?: boolean }>(
  {
    fontSize: '1rem',
    fontWeight: fontWeight.bold,
    lineHeight: 1,
    color: colors.red[500],
    marginRight: '0.5rem',
  },
  ({ $isWarning }) =>
    $isWarning && {
      color: colors.orange.DEFAULT,
    },
)

export const IssuesTooltip = styled(Tippy)<{ $isWarning?: boolean }>(
  {
    backgroundColor: colors.red.DEFAULT,
    borderRadius: '16px 0 16px 16px',
    width: 280,
    '.tippy-arrow': {
      color: colors.red.DEFAULT,
      width: 20,
      height: 20,
    },
    '&[data-placement^=bottom]>.tippy-arrow:before': {
      top: -10,
      borderWidth: '0 10px 10px',
    },
  },
  ({ $isWarning }) =>
    $isWarning && {
      backgroundColor: colors.orange.DEFAULT,
      '.tippy-arrow': {
        color: colors.orange.DEFAULT,
      },
    },
)

export const IssuesTooltipContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 16,
  padding: '4px 8px',
})

export const IssueItem = styled.div({
  display: 'flex',
  alignItems: 'center',
  columnGap: 8,
  fontSize: '1rem',
  fontWeight: fontWeight.light,
})
