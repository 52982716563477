import { Card } from 'components'
import { useIsMobile } from 'hooks'

type Props = {
  onClose?: () => void
  isLoading?: boolean
}

const ProductTeam = ({ onClose, isLoading = false }: Props) => {
  const { isMobile } = useIsMobile()

  return (
    <Card
      subTitle='Meet our Product Team!'
      description={
        isMobile
          ? 'Share your experience using the RCC.'
          : 'Schedule a call with us to share your experience using the RCC, guiding our efforts to build a better product for you.'
      }
      buttonText='Schedule a call'
      link={process.env.REACT_APP_CHILIPIPER_CALENDAR_LINK}
      isLoading={isLoading}
      onClose={onClose}
    />
  )
}

export default ProductTeam
