import { WidgetMetrics } from 'api/digitalMarketing/types'
import moment from 'moment'
import {
  getGoogleMetrics,
  getFBCampaignsMetrics,
  getAllPaidAdsMetrics,
  Platform,
  TimeFrame,
} from 'api/reporting'
import { useCallback, useEffect, useState } from 'react'
import { uniqBy, includes, isEmpty  } from 'lodash'
import { appSettings } from 'config'

type UseGetPaidAdsWidgetMetrics = (
  businessId: string,
  platform: Platform,
  timeframe: TimeFrame,
) => [
  {
    isLoading: boolean
    isError: boolean
    metrics?: WidgetMetrics
  },
]

const useGetPaidAdsWidgetMetrics: UseGetPaidAdsWidgetMetrics = (
  businessId: string,
  platform: Platform,
  timeframe: TimeFrame
) => {
  const [isLoading, setIsLoading] = useState(isEmpty(businessId))
  const [isError, setIsError] = useState(false)
  const [metrics, setMetrics] = useState<WidgetMetrics | undefined>(undefined)

  const formattedNumber = (number: string | number, dollarPrefix = false) => {
    if (number === undefined || number === null) return dollarPrefix ? '$0' : 0

    const roundedNumber = parseFloat(number.toString()).toFixed(2)

    const numberStr = roundedNumber.toString()
    const parts = numberStr.split('.')

    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    let fNumber
    if (parts.length === 2) {
      let decimalPart = parts[1]
      decimalPart = parseInt(decimalPart, 10) === 0 ? '' : `.${decimalPart}`
      fNumber = `${dollarPrefix ? '$' : ''}${integerPart}${decimalPart}`
    } else {
      fNumber = `${dollarPrefix ? '$' : ''}${integerPart}`
    }
    return fNumber
  }

  const getTimeframeFilters = useCallback(() => {
    const toYesterday = moment(moment().format('YYYY-MM-DD')).subtract(1, 'days').format('YYYY-MM-DD')
    const from30 = moment(toYesterday).subtract(29, 'days').format('YYYY-MM-DD')
    const from14 = moment(toYesterday).subtract(13, 'days').format('YYYY-MM-DD')
    const from7 = moment(toYesterday).subtract(6, 'days').format('YYYY-MM-DD')
    const fromBeginning = moment(toYesterday).subtract(1500, 'days').format('YYYY-MM-DD')

    if (timeframe === 'last-30-days') {
      return {
        startDate: from30,
        endDate: toYesterday,
      }
    }
    if (timeframe === 'last-14-days') {
      return {
        startDate: from14,
        endDate: toYesterday,
      }
    }
    if (timeframe === 'last-7-days') {
      return {
        startDate: from7,
        endDate: toYesterday,
      }
    }
    if (includes(['this_month', 'last_month', 'this_week_sun_today', 'last_week_sun_sat'], timeframe)) {
      if (platform === 'all') {
        return {
          datePreset: timeframe,
          predefinedDateRange: timeframe,
        }
      }
      if (platform === 'facebook') {
        return {
          datePreset: timeframe,
        }
      }
      return {
        predefinedDateRange: timeframe,
      }
    }
    if (timeframe === 'lifetime' && (platform === 'google' || platform === 'all')) {
      return {
        startDate: fromBeginning,
        endDate: toYesterday,
      }
    }
    return null
  }, [timeframe, platform])

  const getApiUrl = () => {
    const baseDomain = 'targetable.io'
    if (appSettings.isProduction) {
      return `https://api.${baseDomain}`
    }
    return `https://api.${appSettings.tgtEnvName.toLowerCase()}.${baseDomain}`
  }

  const fetchMetrics = useCallback(async () => {
    setIsLoading(true)
    try {
      const metricsFn = {
        facebook: async () => getFBCampaignsMetrics({
          businessId,
          startDate: getTimeframeFilters()?.startDate,
          endDate: getTimeframeFilters()?.endDate,
          datePreset: getTimeframeFilters()?.datePreset,
          type: 'byStore',
        }),
        google: async () => getGoogleMetrics({
          businessId,
          from: getTimeframeFilters()?.startDate,
          to: getTimeframeFilters()?.endDate,
          predefinedDateRange: getTimeframeFilters()?.predefinedDateRange,
          apiURL: getApiUrl(),
        }),
        all: async () => getAllPaidAdsMetrics({
          businessId,
          type: 'byStore',
          startDate: getTimeframeFilters()?.startDate,
          endDate: getTimeframeFilters()?.endDate,
          datePreset: getTimeframeFilters()?.datePreset,
          predefinedDateRange: getTimeframeFilters()?.predefinedDateRange,
          apiURL: getApiUrl(),
          timeframe,
        }),
      }
      const mtrs = metricsFn[platform] ? await metricsFn[platform]() : []
      const widgetMetrics: WidgetMetrics = {
        dailyBudget: formattedNumber(mtrs.filter(item => item.budgetPeriod === 'daily')
          .map(item => item.adBudget)
          .reduce((prev, curr) => prev + curr, 0), true),
        lifetimeBudget: formattedNumber(mtrs.filter(item => item.budgetPeriod === 'lifetime')
          .map(item => item.adBudget)
          .reduce((prev, curr) => prev + curr, 0), true),
        totalBudget: formattedNumber(mtrs.map(item => item.adBudget)
          .reduce((prev, curr) => prev + curr, 0), true),
        spend: formattedNumber(mtrs.map(item => item.spendThisPeriod)
          .reduce((prev, curr) => prev + curr, 0), true),
        campaigns: uniqBy(mtrs, 'name')?.length || 0,
      }
      setMetrics(widgetMetrics)
      setIsLoading(false)
    } catch (err) {
      console.error(err)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [businessId, platform, timeframe])

  useEffect(() => {
    if (!isEmpty(businessId)) {
      fetchMetrics()
    }
  }, [businessId, platform, timeframe])

  return [
    {
      isLoading,
      isError,
      metrics,
    },
  ]
}

export default useGetPaidAdsWidgetMetrics