import { useEffect } from 'react'
import { useMatch } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { activePartnerIdState } from 'state/atoms'

const useRouteMenuSectionId = () => {
  const matchedResult = useMatch('/manage-locations/:locationId/menus/:menuSectionId/*')
  const setActivePartnerId = useSetRecoilState(activePartnerIdState)
  const locationId = matchedResult?.params.locationId
  const menuSectionId = matchedResult?.params.menuSectionId

  useEffect(() => {
    if (locationId) {
      setActivePartnerId(locationId)
    }
  }, [locationId])

  return { locationId, menuSectionId }
}

export default useRouteMenuSectionId
