import axios from 'axios'
import { chowlyApi } from 'config'
import { generatePath } from 'react-router-dom'

import client from '../chowly'
import { JsonApiResource, JsonApiResponse } from '../models'

type ToosOauthUrlResponse = {
  toosOauthUrl: string
}

export interface Location {
  id: string
  name: string
  address: string
  is_eligible_to_onboard: boolean
}

export type LocationsResponse = JsonApiResponse<Array<LocationResource>>
export type LocationResource = JsonApiResource<'location', Location>

export const startToosOauth = async ({
  accessToken,
  partnerId,
  toosUrlKey,
  redirectUrl,
}: {
  accessToken: string
  partnerId: number
  toosUrlKey: keyof typeof chowlyApi
  redirectUrl?: string
}) => {
  const { data } = await axios.get<ToosOauthUrlResponse>(
    chowlyApi[toosUrlKey as keyof typeof chowlyApi],
    {
      baseURL: chowlyApi.hostUrl,
      params: {
        app_redirect_url: redirectUrl || window.location.href,
        access_token: accessToken,
        partner_id: partnerId,
      },
    },
  )
  return data.toosOauthUrl
}

export const signupUrl = async ({ partnerId, type }: { partnerId: number; type?: string }) => {
  const url = generatePath(chowlyApi.signupUrl, { id: String(partnerId) })
  const { data } = await client.post(url, { type: type })
  return data
}

export const retrieveToosLocations = async ({
  accessToken,
  partnerId,
  orderPlatformId,
}: {
  accessToken: string
  partnerId: number
  orderPlatformId: string
}) => {
  const { data } = await axios.get<LocationsResponse>(chowlyApi.toosLocationsUrl, {
    baseURL: chowlyApi.hostUrl,
    params: {
      partner_id: partnerId,
      access_token: accessToken,
      order_platform_id: orderPlatformId,
    },
  })

  return data
}
