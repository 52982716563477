import WowSampleImage from 'assets/embedImages/WowSampleData.png'
import { SigmaEmbed, SkeletonLoader } from 'components'
import { useLocations } from 'components/MainLayout/MainLayout'
import { sigmaPages } from 'config'
import { useEmbedUrl } from 'hooks'

function Wow() {
  const { locationsData } = useLocations()
  const { locations, isLoading } = locationsData

  const url = useEmbedUrl(sigmaPages.wowReport)

  return (
    <>
      {isLoading ? (
        <SkeletonLoader />
      ) : locations.length > 0 ? (
        <SigmaEmbed url={url} title='Week Over Week Report' />
      ) : (
        <img src={WowSampleImage} alt='Week Over Week Sample Data Image' />
      )}
    </>
  )
}

export default Wow
