import styled from 'styled-components'
import { colors, fontWeight } from 'theme'

const borderColorByType: Record<string, string> = {
  info: colors.blue[700],
  success: '#32965D',
  warning: '#FFBE0B',
  error: '#C13030',
}

export const Container = styled.div<{ $type: string }>(
  {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#FEFEFE',
    borderLeft: `6px solid ${colors.blue[700]}`,
    borderRadius: 2,
    padding: 24,
    paddingLeft: 18,
    columnGap: 12,
  },
  ({ $type }) => ({ borderLeftColor: borderColorByType[$type ?? 'info'] }),
)

export const Content = styled.div({
  flex: 1,
})

export const Message = styled.div({
  fontSize: '1rem',
  lineHeight: '1.5rem',
})

export const Button = styled.button({
  marginTop: '1rem',
  border: 'none',
  backgroundColor: 'transparent',
  color: colors.blue['700'],
  fontWeight: fontWeight.semibold,
  display: 'flex',
  alignItems: 'center',
})
