function HowToVideoModal() {
  return (
    <iframe
      width='100%'
      height='600px'
      title='How To Video'
      src='//www.youtube.com/embed/uS5lk8JNzGU'
      allowFullScreen
    />
  )
}

export default HowToVideoModal
