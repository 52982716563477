import { doorDashStatusFeedback, doordashExclusionCodeMessage } from './locationConstants'
import * as Styled from './styles'

type ProvisionStatusType = {
  errorType: string
  doordashExclusionCode: string
  requestedMenuImport: boolean
  reSyncDoordash: () => void
}

export const DoordashFeedbackSection = ({
  errorType,
  doordashExclusionCode,
  requestedMenuImport,
  reSyncDoordash,
}: ProvisionStatusType) => (
  <Styled.DoordashMenuErrorContainer>
    <Styled.FeedbackSection>
      <Styled.AlertIcon />
      <Styled.TextContainer>
        <Styled.FeedbackMessage>
          {errorType === 'menu_error'
            ? doordashExclusionCodeMessage(doordashExclusionCode)
            : errorType === 'error'
            ? doorDashStatusFeedback.activation_block.message
            : null}
        </Styled.FeedbackMessage>
        <Styled.FeedbackMessage>
          {errorType === 'menu_error' && (
            <>
              {doorDashStatusFeedback.menu_error.footerMessage} This process can take up to an hour
              to complete.
              <Styled.ResyncActivateButtonWrapper>
                <Styled.DoordashWarningToastButton
                  disabled={requestedMenuImport}
                  onClick={reSyncDoordash}
                >
                  Import Menu & Request Pull
                  <Styled.ChevronRightIcon />
                </Styled.DoordashWarningToastButton>
              </Styled.ResyncActivateButtonWrapper>
            </>
          )}
        </Styled.FeedbackMessage>
      </Styled.TextContainer>
    </Styled.FeedbackSection>
  </Styled.DoordashMenuErrorContainer>
)
