import { SquareLocation } from 'api'

import * as Styled from './styles'

const formatLocationName = ({ name, merchant_id }: SquareLocation) => `${name} (${merchant_id})`

type Props = {
  locations: SquareLocation[]
  onSelect: (location: SquareLocation) => void | Promise<void>
}

const SelectPosLocation = ({ locations, onSelect }: Props) => (
  <Styled.Row>
    <Styled.Label>Select a POS location</Styled.Label>
    <div>
      {locations.map((location) => (
        <Styled.RadioWrapper key={location.id}>
          <Styled.RadioInput
            name='pos_location'
            value={location.id}
            onChange={() => onSelect(location)}
          />
          <Styled.Value>{formatLocationName(location)}</Styled.Value>
        </Styled.RadioWrapper>
      ))}
    </div>
  </Styled.Row>
)

export default SelectPosLocation
