import { FC } from 'react'

import * as Styled from './styles'

type Props = {
  messages?: string | string[]
  type?: 'primary' | 'secondary' | 'success' | 'danger'
}

const AlertContainer: FC<Props> = ({ messages, type }) => {
  return (
    <Styled.MessagesContainer variant={type}>
      {messages &&
        [messages].flat().map((msg, idx) => (
          <div key={idx}>
            <div>{msg}</div>
          </div>
        ))}
    </Styled.MessagesContainer>
  )
}

AlertContainer.displayName = 'AlertContainer'

export default AlertContainer
