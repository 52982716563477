import { FormattedAvailabilities } from 'types'

const validateAvailabilities = (availabilities: FormattedAvailabilities): boolean => {
  let validTimePeriods = true

  Object.values(availabilities).forEach((timePeriodValues) => {
    timePeriodValues.forEach((timePeriod) => {
      if (timePeriod.start_time && !timePeriod.end_time) {
        validTimePeriods = false
      }

      if (!timePeriod.start_time && timePeriod.end_time) {
        validTimePeriods = false
      }

      if (
        timePeriod?.start_time &&
        timePeriod?.end_time &&
        timePeriod.start_time >= timePeriod.end_time
      ) {
        validTimePeriods = false
      }
    })
  })

  return validTimePeriods
}

export default validateAvailabilities
