import * as Sentry from '@sentry/react'
import UberEatsAuthLinkCheck from 'features/auth/UberEatsAuthLinkCheck'
import { DesignSystemView, NotificationBannerView, ToastView } from 'features/DesignSystemView'
import CreateNewMenu from 'features/manage-locations/IndividualMenu/CreateMenu/CreateNewMenu'
import ManagePlatform from 'features/manage-locations/ManagePlatform'
import DeliveryBuyDown from 'features/reporting/DeliveryBuyDown'
import DigitalMarketing from 'features/reporting/DigitalMarketing/DigitalMarketing'
import { Suspense } from 'react'
import { Flags, FlagsProvider } from 'react-feature-flags'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from 'styled-components'

import { ErrorMessage, LoadingScreen, MainLayout, NotFound, PrivateRoute } from './components'
import SignupConfirmation from './components/ManageAccount/SignupConfirmation'
import Success from './components/Success/Success'
import { appSettings, flags } from './config'
import Login from './features/auth/Login'
import Logout from './features/auth/Logout'
import Register from './features/auth/Register'
import Verify from './features/auth/Verify'
import Health from './features/health/Health'
import AddLocation from './features/manage-locations/AddLocation/AddLocation'
import IndividualLocation from './features/manage-locations/IndividualLocation/IndividualLocation'
import { IndividualMenu } from './features/manage-locations/IndividualMenu'
import ManageLocations from './features/manage-locations/ManageLocations'
import AdvancedOrders from './features/reporting/AdvancedOrders'
import Disputes from './features/reporting/Disputes'
import Locations from './features/reporting/Locations'
import Orders from './features/reporting/Orders'
import ProductMix from './features/reporting/ProductMix'
import Reconciliation from './features/reporting/Reconciliation'
import Reporting from './features/reporting/Reporting'
import Sales from './features/reporting/Sales'
import SmartPricing from './features/reporting/SmartPricing'
import Billing from 'features/billing//Billing'
import Wow from './features/reporting/Wow'
import theme from './theme'

function App() {
  return (
    <Sentry.ErrorBoundary fallback={ErrorMessage}>
      <FlagsProvider value={flags}>
        <RecoilRoot>
          <ThemeProvider theme={theme}>
            <Suspense fallback={<LoadingScreen title='Loading...' />}>
              <BrowserRouter>
                <Routes>
                  <Route path='login' element={<Login />} />
                  <Route path='logout' element={<Logout />} />
                  <Route path='verify' element={<Verify />} />
                  <Route path='register' element={<Register />} />
                  <Route path='confirmation' element={<SignupConfirmation />} />
                  <Route
                    path='doordash_success'
                    element={
                      <Success
                        title={'Doordash Authenticated'}
                        message={
                          'please notify your chowly contact that you have successfully authenticated with doordash'
                        }
                      />
                    }
                  />
                  <Route path='ubereats_auth_link_check' element={<UberEatsAuthLinkCheck />} />
                  <Route
                    path='/'
                    element={
                      <PrivateRoute>
                        <MainLayout />
                      </PrivateRoute>
                    }
                  >
                    <Route index element={<Navigate to='/home' replace />}></Route>
                    <Route path='home' element={<Health />} />
                    <Route path='locations' element={<Locations />} />
                    <Route path='sales' element={<Sales />} />
                    <Route path='orders' element={<Orders />} />
                    <Route path='product-mix' element={<ProductMix />} />
                    <Route path='reporting' element={<Reporting />} />
                    <Route path='reconciliation' element={<Reconciliation />} />
                    <Route path='disputes' element={<Disputes />} />
                    <Route path='smart-pricing' element={<SmartPricing />} />
                    <Route path='wow' element={<Wow />} />
                    <Route path='delivery-buy-down' element={<DeliveryBuyDown />} />
                    <Route path='advanced-orders' element={<AdvancedOrders />} />
                    <Route path='manage-locations' element={<ManageLocations />} />
                    <Route path='digital-marketing' element={<DigitalMarketing />} />
                    <Route path='billing' element={<Billing />} />

                    <Route
                      path='add-location'
                      element={
                        <Flags authorizedFlags={['developmentOnly', 'stagingOnly']}>
                          <AddLocation />
                        </Flags>
                      }
                    />

                    <Route path='manage-locations/:locationId' element={<IndividualLocation />} />
                    <Route
                      path='manage-locations/:locationId/menus/:menuSectionId/preview'
                      element={<IndividualMenu />}
                    />
                    <Route
                      path='manage-locations/:locationId/menus/new'
                      element={
                        <Flags authorizedFlags={['developmentOnly']}>
                          <CreateNewMenu />
                        </Flags>
                      }
                    />
                    <Route path='manage-platform/:platformId' element={<ManagePlatform />} />
                    {/* Routes for reviewing design style components */}
                    {!appSettings.isProduction && (
                      <Route path='design-system' element={<DesignSystemView />}>
                        <Route path='toast' element={<ToastView />} />
                        <Route path='notification-banner' element={<NotificationBannerView />} />
                      </Route>
                    )}
                  </Route>

                  <Route path='*' element={<NotFound />} />
                </Routes>
              </BrowserRouter>
              <ToastContainer autoClose={false} draggable={false} />
            </Suspense>
          </ThemeProvider>
        </RecoilRoot>
      </FlagsProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
