import { Consumer, Subscription, createConsumer } from '@rails/actioncable'
import { getPlatformsForPartner } from 'api'
import { ChowlyUser } from 'libs/auth0-react/auth-state'
import { MutableRefObject } from 'react'
import { PartnerChannelType } from 'types/Partner'

import { chowlyApi } from '../../config'
import { PlatformName, PlatformSummary } from '../../types'

const cableUrl = chowlyApi.websocketUrl
const cableChannel = 'PartnerChannel'
const actionCableConnection = async (
  subscriptionRef: MutableRefObject<Subscription<Consumer> | undefined>,
  user: ChowlyUser | null | undefined,
  accessToken: string | null | undefined,
  locationID: string | null,
  setMenuImportTimestamp: (timestamp: string | null) => void,
  setMenuImportEnqueued: (enqueued: boolean) => void,
  setMenuPlatforms: (platforms: PlatformSummary[] | undefined) => void,
  setMenuImportSuccessful: (successful: boolean | undefined) => void,
  setDoorDashOnboardingStatusWebHook: (obj: { type: string; time: number }) => void,
  setUberEatsPublishSuccessfulWebHook: (obj: { publish_successful: boolean | null }) => void,
  setOrderPlatformConnected: (connected: boolean) => void,
) => {
  if (!user?.email || !locationID) return

  const cable = createConsumer(
    `${cableUrl}?access_token=${accessToken}&user_email=${encodeURIComponent(user.email)}`,
  )
  if (cable.ensureActiveConnection()) {
    subscriptionRef.current = cable.subscriptions.create(
      {
        channel: cableChannel,
      },
      {
        disconnected: (reason?: unknown) => {
          console.warn('cable disconnected', reason)
        },
        received: async (data: PartnerChannelType) => {
          // only update if locationID (individual location page) is still loaded
          // and the incoming data is for that location
          const url = window.location.href
          const currentLocationId = url.substring(url.lastIndexOf('/') + 1)
          if (data.partner_id.toString() != currentLocationId) return

          if (data.platform_name === 'UberEats') {
            const uberHookMessage = { publish_successful: data.publish_successful }
            setUberEatsPublishSuccessfulWebHook(uberHookMessage)
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (data.type === 'onboarding_status') {
            const customHookMessage = { type: data.type, time: Date.now() }
            setDoorDashOnboardingStatusWebHook(customHookMessage)
          } else if (data.type === 'order_platform_connect') {
            setOrderPlatformConnected(true)
          } else if (
            data.message === 'success' &&
            data.menu_sync_timestamp &&
            currentLocationId == data.partner_id.toString()
          ) {
            setMenuImportTimestamp(data.menu_sync_timestamp || null)
            setMenuImportEnqueued(false)
            setMenuImportSuccessful(data.import_successful ?? false)
          } else if (
            data.type === 'publish' &&
            currentLocationId == data.partner_id.toString() &&
            data.platform_name != PlatformName.CHOWLY
          ) {
            const platforms = await getPlatformsForPartner(currentLocationId)
            setMenuPlatforms(platforms)
          }
        },
        rejected: () => {
          // do something else
        },
      },
    )
  } else {
    // do something else
  }
}

export default actionCableConnection
