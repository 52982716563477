import { User } from '@auth0/auth0-spa-js'
import { UserRole } from 'config'
import { FeatureFlag } from 'types/react-feature-flags/FeatureFlags'

export interface ChowlyUser extends User {
  user_roles?: UserRole[]
}

/**
 * The auth state which, when combined with the auth methods, make up the return object of the `useAuth0` hook.
 */
export interface AuthState {
  error?: Error
  isAuthenticated: boolean
  isLoading: boolean
  user?: ChowlyUser
  accessToken?: string
  authorizedFeatures?: FeatureFlag[]
}

/**
 * The initial auth state.
 */
export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isLoading: true,
  authorizedFeatures: [],
}
