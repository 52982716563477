import { chowlyApi } from 'config'
import { generatePath } from 'react-router-dom'
import isNil from 'lodash/isNil'
import groupBy from 'lodash/groupBy'

import client from '../chowly'
import { 
  FacebookMetricsResponse,
  MarketingChannelsResponse, 
  AggregateResponse,
  FacebookReport,
  GoogleReport,
  GoogleMetric,
  PaidAdMetric,
  Request,
} from 'api/digitalMarketing/types'

const formatData = (data: string) => {
  return data
    .replace(/nil/g, 'null')
    .replace(/=>/g, ':')
    .replace(/([{,])\s*"([^"]+)"/g, '$1"$2"')
    .replace(/:\s*([^",\s]+)/g, ': $1')
    .replace(/([{,])\s*([^"\s]+)(?=\s*:)/g, '$1"$2"')
    .replace(/:\s*"(\d+(\.\d+)?)"/g, ': $1')
    .trim()
}

export type Platform = 'all' | 'facebook' | 'google'
export type TimeFrame = 'lifetime' | 'last-30-days' | 'last-14-days' | 'last-7-days' | 'this_month' | 'last_month' | 'this_week_sun_today' | 'last_week_sun_sat'

export type RequestsQueryParams = {
  businessId: string
  from: string | undefined
  to: string | undefined
  predefinedDateRange: string | undefined
  apiURL: string
}

export const getRequests = async (params: RequestsQueryParams): Promise<{ requests: Array<{ method: string, url: string }>, channelsIds: string[] } | null> => {
  try {
    const url = generatePath(`${chowlyApi.targetableApi}/v1/marketing/channels/${encodeURIComponent(params.businessId)}`)
    const { data: mktChannels } = await client.get<MarketingChannelsResponse>(url, { transformResponse: [(data) => {
      return JSON.parse(formatData(data))
    }]})
    const mktChannelsId = mktChannels.result.marketingChannels
      .filter((item) => item.type === 'google')
      .map((i) => i.id)

    if (params.predefinedDateRange) {
      const requests = mktChannelsId.map((channelId: string) => ({
        method: 'get',
        url: `${params.apiURL}/v1/marketing/google/ad/metrics?marketingChannelId=${channelId}&predefinedDateRange=${params.predefinedDateRange}&getBudget=true`,
      }))
      return { requests, channelsIds: mktChannelsId }
    }

    if (params.from && params.to) {
      const requests = mktChannelsId.map((channelId: string) => ({
        method: 'get',
        url: `${params.apiURL}/v1/marketing/google/ad/metrics?marketingChannelId=${channelId}&from=${params.from}&to=${params.to}&getBudget=true`,
      }))
      return { requests, channelsIds: mktChannelsId }
    }

    return null
  } catch (e) {
    return null
  }
}

export type GoogleMetricsQueryParams = {
  businessId: string
  from: string | undefined
  to: string | undefined
  predefinedDateRange: string | undefined
  apiURL: string
}

export const getGoogleMetrics = async (params: GoogleMetricsQueryParams): Promise<GoogleMetric[]> => {
  const { businessId, from, to, predefinedDateRange, apiURL } = params
  let requests: Request[] = []
  let channelsIds: string[] = []

  try {
    const response = await getRequests({ businessId, from, to, predefinedDateRange, apiURL })
    if (response) {
      requests = response.requests || []
      channelsIds = response.channelsIds || []
    }
  } catch (error) {
    console.error('Error fetching requests:', error)
    return []
  }

  let responses: AggregateResponse
  const url = generatePath(`${chowlyApi.targetableApi}/v1/infrastructure/aggregate`)

  try {
    const postResponse = await client.post<AggregateResponse>(url, { requests }, {
      transformResponse: [(data) => JSON.parse(formatData(data))],
    })
    responses = postResponse.data
  } catch (error) {
    console.error('Error fetching aggregate data:', error)
    return []
  }

  const data: GoogleReport[] = []

  for (let i = 0; i < responses.responses.length; i += 1) {
    const item = responses.responses[i]
    if (item.status === 200) {
      const channelId = channelsIds[i]
      data.push(...item.data.map((dataItem: GoogleReport) => ({
        ...dataItem,
        marketingChannelId: channelId,
      })))
    }
  }

  const result: GoogleMetric[] = []

  const groupByMKTCampaignName = groupBy(data, (item) => `${item.marketingChannelId}_${item.campaignName}`)
  Object.entries(groupByMKTCampaignName).forEach(([, value]) => {
    const res: GoogleMetric = {
      name: value[0]?.campaignName || '',
      marketingChannelId: value[0]?.marketingChannelId || '',
      adPlatform: 'Google Search',
      budgetPeriod: 'Daily',
      adBudget: Number(value[0]?.campaignBudget?.amountMicros) || 0,
      adBudgetDaily: Number(value[0]?.campaignBudget?.amountMicros) || 0,
      spendThisPeriod: Math.floor(
        value.map((item) => item?.cost || 0).reduce((prev, curr) => prev + curr, 0) * 100
      ) / 100,
      startDate: value[0]?.campaignStartDate || '',
      endDate: value[0]?.campaignEndDate || 'No End Date',
    }
    result.push(res)
  })

  return result
}

export type FBCampaignsMetricsQueryParams = {
  businessId: string
  type: string | undefined
  startDate: string | undefined
  endDate: string | undefined
  datePreset: string | undefined
}

export const getFBCampaignsMetrics = async (params: FBCampaignsMetricsQueryParams): Promise<FacebookReport[]> => {
  const url = generatePath(`${chowlyApi.targetableApi}/v1/reports/business/${encodeURIComponent(params.businessId)}/campaigns/metrics`)

  const { data: fbMetrics } = await client.get<FacebookMetricsResponse>(url, {
    params: {
      ...(isNil(params.type) ? {} : { type: encodeURIComponent(params.type) }),
      ...(isNil(params.startDate) ? {} : { startDate: encodeURIComponent(params.startDate) }),
      ...(isNil(params.endDate) ? {} : { endDate: encodeURIComponent(params.endDate) }),
      ...(isNil(params.datePreset) ? {} : { datePreset: encodeURIComponent(params.datePreset) }),
    },
    transformResponse: [(data) => {
      return JSON.parse(formatData(data));
    }],
  })

  return fbMetrics?.result?.reports || []
}

export type PaidAdsWidgetMetricsQueryParams = {
  businessId: string
  type: string | undefined
  startDate: string | undefined
  endDate: string | undefined
  predefinedDateRange: string | undefined
  datePreset: string | undefined
  timeframe: string
  apiURL: string
}

export const getAllPaidAdsMetrics = async (params: PaidAdsWidgetMetricsQueryParams): Promise<PaidAdMetric[]> => {
  try {
    const startDateFB = params.timeframe === 'lifetime' ? undefined : params.startDate
    const endDateFB = params.timeframe === 'lifetime' ? undefined : params.endDate
    const fbMetrics: FacebookReport[] = await getFBCampaignsMetrics({
      businessId: params.businessId,
      type: params.type,
      startDate: startDateFB,
      endDate: endDateFB,
      datePreset: params.datePreset,
    })
    const googleMetrics: GoogleMetric[] = await getGoogleMetrics({
      businessId: params.businessId,
      from: params.startDate,
      to: params.endDate,
      predefinedDateRange: params.predefinedDateRange,
      apiURL: params.apiURL,
    })
    const facebookResults: PaidAdMetric[] = fbMetrics?.map(report => ({
      ...report,
      adPlatform: 'Facebook'
    }))
    const googleResults: PaidAdMetric[] = googleMetrics?.map(metric => ({
      ...metric,
      adPlatform: 'Google Search'
    }))
    return [...facebookResults, ...googleResults]
  } catch (e) {
    return []
  }
}