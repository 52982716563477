import AlertCircle from 'assets/icons/alert-circle.svg'
import { Button, Modal } from 'components'

import * as Styled from './styles'

type Props = {
  isOpen: boolean
  title: string
  message: string
  buttonTitle: string
  showInfoIcon: boolean
  onClose: () => void
  buttonAction: () => void
}

const AlertModal = ({ isOpen, title, message, buttonTitle, buttonAction, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen} cardProps={{ borderRadius: 8 }}>
      <Styled.ModalBox>
        <Styled.AlertCircle src={AlertCircle} />
        <Styled.TextContainer>
          <div>{title}</div>
          <div>{message.split("\n").map((t,key) => <div key={key}><br/>{t}</div>)}</div>
          <Styled.ButtonContainer>
            <Button variant='textonly' size='small' onClick={onClose}>
              Cancel
            </Button>
            <Button size='small' onClick={buttonAction}>
              {buttonTitle}
            </Button>
          </Styled.ButtonContainer>
        </Styled.TextContainer>
      </Styled.ModalBox>
    </Modal>
  )
}

export default AlertModal
