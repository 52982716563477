import { 
    colors, 
    fontFamily, 
    fontWeight,
    typography
} from 'theme'
import styled from 'styled-components'


export const MenuImportContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '12px',
    position: 'relative'
})

export const MenuImportHeader = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    width: 'inherit',
    alignItems: 'baseline',
    marginBottom: '20px'
})

export const Title = styled.label({
    color: colors.primary.medium,
    fontFamily: fontFamily.inter,
    fontSize: typography.semiXS.fontSize,
    fonStyle: 'normal',
    fontWeight: fontWeight.bold,
    lineHeight: '150%', /* 22.5px */
})
 
export const DetailsRow = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '12px',
    alignItems: 'center',
    gap: '36px',
    alignSelf: 'stretch',
    borderBottom: `1px solid ${colors.gray.medium}`,
    '&:last-child': {
        borderBottom: `0px`,
    },
})

export const Overlay = styled.div<{lastRow?: boolean}>({
    display: 'flex',
    position: 'absolute',
    bottom: '-20px',
    background: 'linear-gradient(4deg, #FFFFFF -11.68%, rgba(255, 255, 255, 0.00) 129.72%)',
    width:'100%',
    height:'12%',
})

export const ButtonContainer = styled.div({
    position: 'absolute',
    right: '0px',
    top: '-8px',
})

export const StatusContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
})

export const DateContainer = styled.div({
    color: colors.gray.mediumLight,
    fontFamily: fontFamily.inter,
    fontSize: typography.xxxs.fontSize,
    fontStyle: 'normal',
    fontWeight: fontWeight.normal,
    lineHeight: '150%', /* 15px */
})

export const Icon = styled.img<{ iconSize?: string }>(
    {
      maargin: '3rem',
    },
    ({ iconSize }) =>
      iconSize && {
        width: `${iconSize}px` || '6px',
        height: `${iconSize}px` || '6px',
    },
)

export const Status = styled.div<{ status?: boolean }>(
    {
        textAlign: 'right',
        fontFamily: fontFamily.inter,
        fontSize: typography.xxxs.fontSize,
        fontStyle: 'normal',
        fontWeight: fontWeight.medium,
        lineHeight: '150%', /* 15px */
    },
    ({ status }) =>
      status 
        ? {
            color: colors.green.darkGreen,
            }
        : {
            color: colors.red.darkRed,
            }
)

