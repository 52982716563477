import styled from 'styled-components'
import { borderRadius, colors } from 'theme'

export const Containter = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: 'auto',
  border: `1px solid ${colors.blue['300']}`,
  borderRadius: borderRadius.xxl,
  backgroundColor: colors.white,
  rowGap: '0.5rem',
  padding: '2.5rem 1rem',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: colors.blue['100'],
  },
  '&:hover .menuIcon': {
    display: 'none',
  },
  '&:hover .deleteIcon': {
    display: 'block',
  },
  '&:hover .editIcon': {
    display: 'block',
  },
  '&:hover .menuName': {
    display: 'none',
  },
})

export const Icons = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

export const IconContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '0.5rem',
})

export const HiddenIcon = styled.img({
  width: '7rem',
  height: '2rem',
  display: 'none',
})

export const HiddenIconText = styled.div({
  fontSize: '1.25rem',
  color: colors.primary.light,
  display: 'none',
})

export const Title = styled.div({
  fontSize: '1.25rem',
  color: colors.primary.light,
})
export const Icon = styled.img({
  width: '7rem',
  height: '2rem',
})
