import * as React from 'react'

import { DialogContext } from './DialogContext'
import { DialogOptions } from './types'
import { useDialog } from './useDialog'

export function Dialog({
  children,
  ...options
}: {
  children: React.ReactNode
} & DialogOptions) {
  const dialog = useDialog(options)
  return <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>
}
