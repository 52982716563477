import { TableColumn } from './MultipleLocations'
import * as Styled from './styles'
import { LocationSummary } from 'types'
import ellipse_2_red from 'assets/icons/ellipse-2-red.svg'
import ellipse_2_green from 'assets/icons/ellipse-2-green.svg'

export const getMultipleLocationsRows = (): Array<TableColumn<LocationSummary>>  => [
    {
        dataIndex: 'locationHeader',
        key: 'locationHeader',
        title: '',
        render: (row) => (                
            <Styled.HeaderGroup>
                <Styled.CardHeader>
                    <Styled.Location>{row.name}</Styled.Location>
                    <Styled.StatusGroup>
                        <Styled.Icon src={row.status === 'Enabled' ? ellipse_2_green : ellipse_2_red} alt='ellipseIcon' />
                        {row.status === 'Enabled' 
                            ? <Styled.GreenStatus>{row.status}</Styled.GreenStatus>
                            : <Styled.RedStatus>{row.status}</Styled.RedStatus>
                        }
                    </Styled.StatusGroup>
                </Styled.CardHeader>
                <Styled.Address>{`${row.city}, ${row.state}`}</Styled.Address>                
            </Styled.HeaderGroup>

        )
    },
    {
        dataIndex: 'locationDetails',
        key: 'locationDetails',
        title: '',
        render: (row) => (
            <>
                {row.enabledPlatforms.length > 0 && 
                    <Styled.PillGroup>
                        <Styled.PillStatus color={'green'}>
                            Enabled
                        </Styled.PillStatus>
                            {row.enabledPlatforms.map((platform) => {
                                return (
                                <Styled.PlatformPill key={platform.id}>
                                    {platform.attributes?.name}
                                </Styled.PlatformPill>
                                )
                            })}
                    </Styled.PillGroup>
                }
                {row.disabledPlatforms.length > 0 && 
                    <Styled.PillGroup>
                        <Styled.PillStatus color={'red'}>
                            Disabled
                        </Styled.PillStatus>
                            {row.disabledPlatforms.map((platform) => {
                                return (
                                <Styled.PlatformPill key={platform.id}>
                                    {platform.attributes?.name}
                                </Styled.PlatformPill>
                                )
                            })}
                    </Styled.PillGroup>
                }
                {row.onboardingPlatforms.length > 0 && 
                    <Styled.PillGroup>
                        <Styled.PillStatus color={'yellow'}>
                            Onboarding
                        </Styled.PillStatus>
                            {row.onboardingPlatforms.map((platform) => {
                                return (
                                <Styled.PlatformPill key={platform.id}>
                                    {platform.attributes?.name}
                                </Styled.PlatformPill>
                                )
                            })}
                    </Styled.PillGroup>
                }
            </>
        )
    }
]