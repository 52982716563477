import styled from 'styled-components'
import { borderRadius, colors, shadow } from 'theme'

const Box = styled.div({
  backgroundColor: colors.white,
  boxShadow: shadow.md,
  borderRadius: borderRadius.xxl,
})

export default Box
