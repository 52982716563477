import { Key, ReactNode } from 'react'
import * as Styled from './styles'
import { LocationSummary } from 'types'
import { Link } from 'react-router-dom'

export type TableColumn<T> = {
    key: Key
    title: ReactNode
    dataIndex: keyof T | string
    render?: (row: T) => ReactNode
}
    
type Props<LocationSummary> = {
    data: LocationSummary[] | undefined
    locationRows: Array<TableColumn<LocationSummary>>
}
  
function MultipleLocations ({ data, locationRows }: Props<LocationSummary>) {
    return (
            <Styled.CardContainer>
                {data?.map((row) => (
                    <Link to={`/manage-locations/${row.id}`} key={row.id}>
                        <Styled.Card>
                            {locationRows.map(({ key, render }) => {
                                return (
                                    <Styled.CardBody key={key}>
                                        {render ? render(row) : ''}
                                    </Styled.CardBody>
                                )
                            })}
                        </Styled.Card>
                    </Link>
                ))}
            </Styled.CardContainer>
    )
}

export default MultipleLocations