
import styled from 'styled-components'
import { colors } from 'theme'

export const Slider = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  height: 8px;
  width: 100%;
  border-radius: 40px;
  background: ${(props) =>
    `linear-gradient(to right, ${colors.blue[700]} 0%, ${colors.blue[700]} ${props.value}%, #fff ${props.value}%, #fff 100%);`};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    background-image: radial-gradient(circle, ${colors.blue[700]} 40%, ${colors.blue[700]} 45%);
    border-radius: 50%;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);
  }
  ::-moz-range-thumb {
    width: 18px;
    height: 18px;
    -moz-appearance: none;
    background-image: radial-gradient(circle, ${colors.blue[700]} 40%, ${colors.blue[700]} 45%);
    border-radius: 50%;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);
  }
`

export const SliderWrapper = styled.div({
  height: '24px'
})
