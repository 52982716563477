import { useGetLocationPartners, useGetMenuMappingSections } from 'hooks'
import { Link } from 'react-router-dom'
import { LocationSummary } from 'types'

import * as Styled from './styles'

type Props = {
  selectedLocation: LocationSummary
}

const MenuShortcut = ({ selectedLocation }: Props) => {
  const { menuMappingMenuId } = useGetLocationPartners(
    selectedLocation.id.toString(), // Removed conditional chaining
  )

  const { menuMappingSections: menuSections } = useGetMenuMappingSections(menuMappingMenuId)

  return (
    <>
      <Styled.MenuImportHeader>Menu</Styled.MenuImportHeader>
      {menuSections?.map((section) => (
        <Link
          key={section.id}
          to={`/manage-locations/${selectedLocation.id}/menus/${section.id}/preview`}
        >
          <Styled.MenuPill>{section.attributes.title}</Styled.MenuPill>
        </Link>
      ))}
    </>
  )
}

export default MenuShortcut
