import * as Styled from './style'
import { StatusResource } from 'api'
import ellipse_2_green from 'assets/icons/ellipse-2-green.svg'
import ellipse_2_red from 'assets/icons/ellipse-2-red.svg'
import { formatDate } from 'utils'

type Props = {
    item: StatusResource
}

const MenuImportRow = ({ item }: Props) => {
    const { attributes } = item || {}
    const status = attributes.success
    const timestamp = attributes.created_at

    return (
        <Styled.DetailsRow>
            <Styled.DateContainer>
                {formatDate(
                    timestamp,
                    'dddd, MM/DD/YYYY, h:mm A',
                )}
            </Styled.DateContainer>
            <Styled.StatusContainer>
                <Styled.Icon src={status ? ellipse_2_green : ellipse_2_red} />
                <Styled.Status status={status}>{status ? 'Success' : 'Failure'}</Styled.Status>
            </Styled.StatusContainer>
        </Styled.DetailsRow>
    )
}

export default MenuImportRow