import menuIcon from 'assets/icons/hamburger-menu-icon.svg'

import * as Styled from './styles'

const HamburgerButton = ({ onClick }) => (
  <Styled.Container>
    <Styled.Button onClick={onClick}>
      <img src={menuIcon} alt='menu icon' />
    </Styled.Button>
  </Styled.Container>
)

export default HamburgerButton
