import 'react-datepicker/dist/react-datepicker.css'
// CSS Modules, react-datepicker-cssmodules.css//
import 'react-datepicker/dist/react-datepicker-cssmodules.css'

// @ts-ignore
import DatePicker from 'react-datepicker'

type Props = {
  selectedDate: Date | null
  setSelectedDate: (date: Date) => void
}

const CalendarPicker = ({ selectedDate, setSelectedDate }: Props) => {
  return (
    <DatePicker
      selected={selectedDate}
      onChange={(date: Date) => setSelectedDate(date)}
      showTimeSelect
      dateFormat='MMMM d, yyyy h:mm aa'
      popperPlacement='bottom-start'
    />
  )
}

export default CalendarPicker
