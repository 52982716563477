import { UserInput } from 'api'
import axios from 'axios'
import { Button, FormikPhoneField, Hero, PrivacyTerms, TextField } from 'components'
import { auth0 } from 'config'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import * as Styled from './styles'

type SignupResponse = {
  data: { _id: string; email: string; email_verified: boolean }
}

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(72, 'Password must not be longer than 72 characters')
    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .required('You must confirm your password')
    .oneOf([Yup.ref('password'), null], 'Password and confirm password must match'),
  phone: Yup.string()
    .trim()
    .matches(/^\(\d{3}\)\s\d{3}\s-\s\d{4,9}$/, 'Phone number must be between 10 and 15 digits')
    .required('Phone is required'),
  email: Yup.string().trim().email('Valid email format is required').required('Email is required'),
  restaurantName: Yup.string()
    .trim()
    .max(255, 'Restaurant name must be 255 characters or less')
    .required('Restaurant name is required'),
})

function Register() {
  const initialValues = {
    name: '',
    phone: '',
    email: '',
    restaurantName: '',
    password: '',
    passwordConfirmation: '',
  }

  const navigate = useNavigate()

  const handleSignup = async (values: UserInput) => {
    const phoneDigitsOnly = values.phone.replace(/\D/g, '').substring(0, 13)
    try {
      const response: SignupResponse = await axios.post(
        `https://${auth0.domain}/dbconnections/signup`,
        {
          client_id: auth0.clientId,
          connection: auth0.connection,
          email: values.email,
          password: values.password,
          user_metadata: {
            restaurantName: values.restaurantName,
            phone: phoneDigitsOnly,
            name: values.name,
          },
        },
      )

      navigate(`/verify?email=${values.email}&user_id=${response.data._id}`)
    } catch (err: unknown) {
      // ESLint says I can only use "any" or "unknown".
      // When I use "any", ESLint complains again that I can't use "any" and
      // when I use "unknown", then ESLint complains about using "response" on "err".
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      toast.error(err?.response?.data?.description)
    }
  }

  return (
    <Styled.Container>
      <Styled.SectionLeft>
        <Hero />
      </Styled.SectionLeft>
      <Styled.SectionRight>
        <Styled.ContentWrapper>
          <Styled.Header>
            <Styled.Logo />

            <Styled.SignUpLinkWrap>
              <Styled.SignUpLink to='/login'>I Already Have an Account</Styled.SignUpLink>
            </Styled.SignUpLinkWrap>
          </Styled.Header>

          <Styled.Content>
            <Styled.Title>Welcome</Styled.Title>
            <Styled.Description>
              Please fill in the information below to get started creating your account
            </Styled.Description>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSignup}
            >
              <Styled.Form>
                <Styled.FieldsWrap>
                  <TextField label='Full Name' type='text' name='name' placeholder='First Last' />
                  <FormikPhoneField label='Phone' name='phone' placeholder='(123) 456 - 7890' />

                  <TextField
                    label='Email'
                    type='email'
                    name='email'
                    placeholder='youremail@email.com'
                  />
                  <TextField
                    label='Name of Restaurant'
                    type='text'
                    name='restaurantName'
                    placeholder='Restaurant Inc.'
                  />
                  <TextField
                    label='Password'
                    name='password'
                    type='password'
                    placeholder='Enter a strong password'
                  />
                  <TextField
                    label='Confirm Password'
                    name='passwordConfirmation'
                    type='password'
                    placeholder='Confirm your password'
                  />
                </Styled.FieldsWrap>
                <Button type='submit'>Create Account</Button>
              </Styled.Form>
            </Formik>
            <PrivacyTerms />
          </Styled.Content>
        </Styled.ContentWrapper>
      </Styled.SectionRight>
    </Styled.Container>
  )
}

export default Register
