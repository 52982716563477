import { CSSProperties, FC } from 'react'

import * as Styled from './styles'

type Props = {
  navigation?: () => void
  disabled?: boolean | false
  type?: 'submit' | 'reset' | 'button' | undefined
  buttonText?: string
  textTransform?: CSSProperties['textTransform']
}

const SubmitButton: FC<Props> = ({ navigation, disabled, type, buttonText, ...props }) => {
  return (
    <Styled.SubmitButton
      onClick={navigation}
      disabled={disabled}
      type={type || 'button'}
      {...props}
    >
      {buttonText || 'Continue'}
    </Styled.SubmitButton>
  )
}

export default SubmitButton
