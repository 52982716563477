import styled, { CSSObject } from 'styled-components'
import Tippy from '@tippyjs/react'
import { breakpoints } from 'theme'

export const Container = styled.div({
  marginTop: 30,
  padding: 20,
  backgroundColor: '#FEFEFE',
  borderRadius: 8,
  fontFamily: 'Inter',
  color: '#292929',
});

export const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 25,
  [`@media ${breakpoints.xxs}`]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

export const Title = styled.h2({
  fontSize: 16,
  fontWeight: 600,
  color: '#292929',
  [`@media ${breakpoints.xxs}`]: {
    fontSize: 14,
    width: '100%',
  },
});

export const Filters = styled.div({
  display: 'flex',
  gap: 10,
  [`@media ${breakpoints.xxs}`]: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    marginTop: 10,
  },
});

export const Select = styled.select({
  padding: '8px 12px',
  border: '1px solid #D7D7D7',
  borderRadius: 6,
  fontSize: 10,
  fontWeight: 400,
  cursor: 'pointer',
  color: '#484848',
  [`@media ${breakpoints.xxs}`]: {
    width: '48%',
  },
});

export const selectContainer: CSSObject = {
  height: 24,
  fontSize: 10,
};

export const selectControl: CSSObject = {
  height: 24,
  width: 125,
  maxWidth: 200,
  minHeight: 30,
  background: '#FEFEFE',
  [`@media ${breakpoints.xxs}`]: {
    width: '100%',
  },
};

export const selectMenu: CSSObject = {
  width: '100%',
};

export const selectMenuList: CSSObject = {
  paddingTop: 0,
  paddingBottom: 0,
};

export const selectSingleValue: CSSObject = {
  fontSize: 10,
  fontWeight: 400,
  color: '#484848',
  lineHeight: '12px',
};

export const selectPlaceholder: CSSObject = {
  fontSize: 10,
  fontWeight: 400,
  lineHeight: '12px',
};

export const selectOption: CSSObject = {
  fontSize: 10,
  fontWeight: 400,
};

export const Grid = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 199px)',
  gap: 20,
  justifyContent: 'center',
  [`@media ${breakpoints.xxs}`]: {
    gridTemplateColumns: '1fr',
    gap: 10,
  },
});

export const Card = styled.div({
  width: 199,
  height: 80,
  backgroundColor: '#fff',
  border: '1px solid #D6D6D6',
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 10,
  position: 'relative',
  [`@media ${breakpoints.xxs}`]: {
    width: '100%', 
    height: 'auto',
  },
});

export const CardHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const CardTitle = styled.p({
  fontFamily: 'Inter',
  fontSize: 13,
  fontWeight: 600,
  color: '#292929',
});

export const Amount = styled.span({
  display: 'flex',
  alignItems: 'center',
  maxHeight: 20,
  marginTop: 10,
  fontFamily: 'Inter',
  fontSize: 13,
  fontWeight: 400,
  color: '#292929',
});

export const InfoTooltip = styled(Tippy)({
  padding: 5,
  color: '#292929',
  fontSize: 11,
  fontWeight: 600,
  backgroundColor: "#FEFEFE",
  borderRadius: 16,
  boxShadow: '0px 4px 60px 0px rgba(0, 0, 0, 0.20)',
  '.tippy-arrow': {
    color: "#FEFEFE",
    width: 16,
    height: 16,
  },
})

export const InfoIcon = styled.img({
  width: 16,
  height: 16,
  margin: '0 0.12rem',
})




