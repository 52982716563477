import { chowlyApi } from 'config'
import { generatePath } from 'react-router'

import client from '../chowly'
import { MenuMappingItemResponse } from '../models'

type QueryParams = { include: string }

export const getMenuMappingItem = async (menuMappingItemId: string, params?: QueryParams) => {
  const url = generatePath(chowlyApi.menuMappingItem, { id: menuMappingItemId })
  const { data } = await client.get<MenuMappingItemResponse>(url, { params })
  return data
}
