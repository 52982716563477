import styled from 'styled-components'
import { colors, fontWeight } from 'theme'

export const AvailabilityInputRow = styled.div({
  display: 'grid',
  gridTemplateColumns: '6rem 5rem 1fr',
})

export const WeekDayLabel = styled.div({
  fontWeight: fontWeight.normal,
  color: colors.primary.light,
  height: '3.5rem',
  display: 'inline-flex',
  alignItems: 'center',
})

export const ToggleWrap = styled.div({
  height: '3.5rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const UnavailableText = styled.div({
  display: 'flex',
  alignItems: 'center',
})
