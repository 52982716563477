import { AxiosResponse } from 'axios'
import { chowlyApi } from 'config'
import { generatePath } from 'react-router-dom'

import { retrievePlatforms } from '../../utils/apiUtils'
import client from '../chowly'
import { PartnerMetadata, PartnerResponse, PartnerUpdateInput, PartnersResponse } from '../models'

export type PartnerQueryParams = {
  include: string
}

export const getPartner = async (id: number | string, params?: Partial<PartnerQueryParams>) => {
  const url = generatePath(chowlyApi.partner, { id })

  const { data } = await client.get<PartnerResponse>(url, { params })
  return data
}

type PartnersQueryParams = {
  meta_only: boolean
  partner_status: 'not_churned'
}

type PartnersMetadataResponse = {
  meta: PartnerMetadata
}

export const getPartners = async (params?: Partial<PartnersQueryParams>) => {
  const { data } = await client.get<PartnersMetadataResponse>(chowlyApi.partners, { params })
  return data.meta
}

export const requestMenuImport = async (
  partnerId: number | string,
  partial_import = false,
  push_menu = false,
) => {
  const { data } = await client.post<PartnerResponse>(`${chowlyApi.partners}/${partnerId}/import`, {
    partial_import,
    push_menu,
  })
  return data
}

export const requestCategoriesFetch = async (id: number | string) => {
  const url = generatePath(chowlyApi.partnerCategoriesFetch, { id })
  const { data } = await client.post<PartnerResponse>(url)
  return data
}

export const updatePartner = async (
  partnerId: number | string,
  updateValues: PartnerUpdateInput,
) => {
  const { data } = await client.put<PartnerResponse>(`${chowlyApi.partners}/${partnerId}`, {
    data: updateValues,
  })
  return data
}

export const getAllPartnerLocations = async (page = 1, query = '', params = {}) => {
  const combinedParams = {
    ...params,
    partner_status: 'not_churned',
    ...(page > 1 && { page }),
    ...(query.length > 0 && { q: query }),
  }
  const { data } = await client.get<PartnersResponse>(chowlyApi.partners, {
    params: combinedParams,
  })
  return data
}

export const getPlatformsForPartner = async (partnerId: string, includeArchived = false) => {
  const response = await getPartner(partnerId, {
    include: 'order_platforms,order_platforms.menu_publish_status,menu_import_status',
  })

  if (response) {
    const { included } = response
    return retrievePlatforms(included, includeArchived)
  }
}

export const getPosProvidesStockUpdates = async (partnerId: number) => {
  const url = generatePath(chowlyApi.posStockUpdates)

  let response: AxiosResponse | undefined

  try {
    response = await client.get(`${url}?partner_id=${partnerId}`)
  } catch (err) {
    console.log('Error fetching POS stock update status', err)
  }
  return response?.data
}
