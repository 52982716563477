import { MenuMappingScheduleCreateInput, PartnerResponse, TypeName } from 'api'
import groupBy from 'lodash/groupBy'
import moment from 'moment'
import { BusinessHoursType, DayOfWeekName, MenuAvailabilityData, TimeSlot } from 'types'

import { momentFormat, momentSecondsFormat, weekdaysMapping } from './DateTime'
import ServiceAvailability, { TimePeriod } from './ServiceAvailability'

export const getParsedMenuAvailabilitiesFromPartner = (
  partner: PartnerResponse | null,
): MenuAvailabilityData => {
  if (!partner?.data) {
    return {}
  }
  const { default_hours } = partner.data.attributes

  const periods = default_hours

  if (!periods?.length) {
    return {}
  }

  const businessHours: BusinessHoursType[] = periods.map((dateTime, i) => ({
    day: dateTime.day_of_week,
    startTime: dateTime.start_local_time,
    endTime: dateTime.end_local_time,
    id: i,
  }))

  const groupedBusinessHours = groupBy(businessHours, 'day')

  return Object.entries(groupedBusinessHours).reduce(
    (acc, [day, av]) => ({
      ...acc,
      [weekdaysMapping[day]]: {
        id: `${av[0].id}`,
        persisted: false,
        isActive: av.length > 0,
        timeSlots: av.map(({ id, startTime, endTime }) => ({
          startTime: moment(startTime, momentSecondsFormat).format(momentFormat),
          endTime: moment(endTime, momentSecondsFormat).format(momentFormat),
          id: String(id),
          persisted: false,
        })),
      },
    }),
    {},
  )
}

export const createMenuMappingScheduleInput = (
  sectionId: string,
  availability: MenuAvailabilityData,
) => {
  const newSchedule: MenuMappingScheduleCreateInput = {
    data: {
      type: TypeName.MenuMappingSchedule,
      attributes: {},
      relationships: {
        schedulable: {
          data: { id: sectionId, type: TypeName.MenuMappingOverrideSection },
        },
        service_availabilities: {
          data: [],
        },
      },
    },
    included: [],
  }

  ;(Object.keys(availability) as DayOfWeekName[]).forEach((dayOfWeek) => {
    const dayId = availability[dayOfWeek]?.id
    if (!dayId) {
      return
    }

    const validTimeSlots = availability[dayOfWeek]?.timeSlots.filter((obj): obj is TimeSlot =>
      Boolean(obj.startTime && obj.endTime),
    )
    if (!validTimeSlots?.length) {
      return
    }

    validTimeSlots.forEach(({ startTime, endTime, id, persisted }) => {
      const timePeriod = new TimePeriod(
        id,
        startTime,
        endTime,
        persisted,
        dayId,
        Boolean(availability[dayOfWeek]?.persisted),
      )
      newSchedule.included.push(timePeriod)
    })

    const serviceAvailability = new ServiceAvailability(
      dayId,
      dayOfWeek,
      Boolean(availability[dayOfWeek]?.persisted),
    )
    newSchedule.included.push(serviceAvailability)
  })

  return newSchedule
}
