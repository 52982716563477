import Tippy from '@tippyjs/react'
import { FormattedMenuSectionInfo, ItemModifier, MenuMappingItemDetails, TypeName } from 'api'
import clock_icon_gray from 'assets/icons/clock-gray.svg'
import clock_icon_green from 'assets/icons/clock-green.svg'
import ellipse_2_green from 'assets/icons/ellipse-2-green.svg'
import ellipse_2_red from 'assets/icons/ellipse-2-red.svg'
import PLUS_ICON from 'assets/icons/plus-icon.svg'
import { LoadingSkeleton, Toggle } from 'components'
import { INDEFINITELY_86ED } from 'features/manage-locations/IndividualMenu/MenuSectionPreview'
import { useIsMobile } from 'hooks'
import { Dispatch, SetStateAction } from 'react'
import { colors } from 'theme'

import * as Styled from './styles'
import { formatItemDescription, formatItemPrice } from './util/formatMenuText'

type Props = {
  item: FormattedMenuSectionInfo
  itemIdx: number
  isSelected: boolean
  isExpanded: boolean
  onItemToggle: (id: MenuMappingItemDetails['id']) => void
  isPreviewOnly?: boolean
  onRowExpand?: () => void
  modifiersExist?: boolean
  setSuspendUntilItem: Dispatch<
    SetStateAction<
      | {
          id: MenuMappingItemDetails['id'] | ItemModifier['id']
          type: TypeName.Item | TypeName.CustomizationOption
        }
      | undefined
    >
  >
  existingSuspendUntil?: string | null | undefined
  checkBoxContainerStyle?: React.CSSProperties
  isModifiersLoading: boolean
  menuItemStyle?: React.CSSProperties
  menuItemTextStyle?: React.CSSProperties
  setSelectedItemInModal: (item: FormattedMenuSectionInfo) => void
}

const MenuItemRow = ({
  item,
  itemIdx,
  isSelected,
  isExpanded,
  onItemToggle,
  isPreviewOnly,
  onRowExpand,
  modifiersExist,
  setSuspendUntilItem,
  existingSuspendUntil,
  checkBoxContainerStyle,
  isModifiersLoading,
  menuItemStyle = {},
  menuItemTextStyle = {},
  setSelectedItemInModal,
}: Props) => {
  const { isMobile } = useIsMobile()

  return (
    <Styled.ItemRow
      key={item.id}
      isHighlighted={itemIdx % 2 === 0}
      isExpanded={isExpanded}
      style={menuItemStyle}
      onClick={() => setSelectedItemInModal(item)}
    >
      {!isPreviewOnly && (
        <Styled.CheckboxContainer
          style={checkBoxContainerStyle}
          onClick={(e) => e.stopPropagation()}
        >
          <Toggle
            id={`toggle-${item.id}`}
            isToggled={isSelected}
            onToggle={() => {
              onItemToggle(item.id)
            }}
            activeColor={colors.blue[700]}
          />
        </Styled.CheckboxContainer>
      )}
      <Styled.ImgContainer padding={isPreviewOnly ? '0 12px 0 12px' : null}>
        <Styled.ItemImg src={item.attributes.image_url} />
      </Styled.ImgContainer>
      <div style={{ flexBasis: '100%' }}>
        <Styled.ItemTitle style={menuItemTextStyle}>{item.attributes.title}</Styled.ItemTitle>
        {!isMobile && (
          <Styled.ItemDescription>
            {formatItemDescription(item.attributes.description)}
          </Styled.ItemDescription>
        )}
      </div>
      {!isSelected && !isMobile && (
        <Styled.Status active={isSelected}>
          <Styled.Icon src={isSelected ? ellipse_2_green : ellipse_2_red} alt='ellipse icon' />
          inactive
        </Styled.Status>
      )}
      {/* row spacer */}
      {isSelected && !isMobile && <div style={{ flexBasis: '20%' }} />}

      <Styled.InputPriceContainer isMobile={isMobile}>
        <Styled.Input
          placeholder={formatItemPrice(item.attributes.price)}
          type='price'
          disabled={true} // when we build the creator, make this an editable field
        ></Styled.Input>
      </Styled.InputPriceContainer>
      <Styled.OptionsContainer
        onClick={(e) => {
          if (onRowExpand) {
            e.stopPropagation()
            onRowExpand()
          }
        }}
      >
        {isModifiersLoading && <LoadingSkeleton isFlex={true} />}
        {modifiersExist && !isPreviewOnly ? (
          <Styled.OptionsIcon src={PLUS_ICON} alt='ellipse_menu' />
        ) : null}
      </Styled.OptionsContainer>
      {!isSelected ? (
        <div
          style={{ flexBasis: '56px' }}
          onClick={(e) => {
            if (item) {
              e.stopPropagation()
              setSuspendUntilItem({ id: item.id, type: TypeName.Item })
            }
          }}
        >
          <Tippy content={existingSuspendUntil}>
            <Styled.Icon
              src={existingSuspendUntil !== INDEFINITELY_86ED ? clock_icon_green : clock_icon_gray}
              alt='clock icon'
              iconSize='16'
            />
          </Tippy>
        </div>
      ) : (
        <div style={{ flexBasis: '56px' }} />
      )}
    </Styled.ItemRow>
  )
}

export default MenuItemRow
