import { DialogClose, DialogContent, DialogDescription, DialogHeading } from 'components/Dialog'
import styled, { css } from 'styled-components'
import { borderRadius, colors, fontWeight, shadow, transition, typography } from 'theme'

export const Content = styled(DialogContent)({
  backgroundColor: colors.white,
  padding: '2rem',
  borderRadius: '0.75rem',
  textAlign: 'center',
  maxWidth: 500,
})

export const Heading = styled(DialogHeading)({
  ...typography.xxl,
  fontWeight: fontWeight.bold,
})

export const Description = styled(DialogDescription)({
  marginTop: '1.5rem',
  marginBottom: '2rem',
})

export const Footer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  columnGap: '0.75rem',
})

const buttonStyles = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: borderRadius.lg,
  color: colors.white,
  backgroundColor: colors.primary.light,
  boxShadow: shadow.DEFAULT,
  fontWeight: fontWeight.semibold,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  padding: '0.75rem 0.5rem',
  columnGap: '0.75rem',
  letterSpacing: '0.05rem',
  minWidth: '8rem',
  whiteSpace: 'nowrap',
  ...transition,
  '&:hover': {
    backgroundColor: colors.primary.dark,
  },
})

const outlineStyle = css({
  backgroundColor: colors.white,
  color: colors.primary.DEFAULT,
  borderColor: colors.primary.light,
  borderWidth: 1,
  '&:hover': {
    backgroundColor: colors.blue[100],
  },
})

const dangerStyle = css({
  backgroundColor: colors.red[500],
  '&:hover': {
    backgroundColor: colors.red.DEFAULT,
  },
})

export const OutlineButton = styled(DialogClose)(() => buttonStyles, outlineStyle)

export const PrimaryButton = styled(DialogClose)<{ $danger?: boolean }>(
  () => buttonStyles,
  ({ $danger }) => $danger && dangerStyle,
)

export const DeliveryBuyDownContent = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.white,
  borderRadius: '0.75rem',
  padding: '2rem',
  width: '100%',
  maxWidth: 600,
})

export const DeliveryBuyDownHeading = styled(DialogHeading)({
  ...typography.xxl,
})

export const DeliveryBuyDownDescription = styled(DialogDescription)({
  display: 'flex',
  flexDirection: 'column',
  margin: '1.5rem 0 2rem 0',
  fontWeight: fontWeight.bold,
})

export const ToggleContainer = styled.div({
  margin: '.5rem 0 2rem 0',
})

export const InputBuydownMinContainer = styled.div({
  display: 'inline-block',
  position: 'relative',
  marginBottom: '1rem',
  width: '35%',
  '&::before': {
    position: 'absolute',
    top: '12px',
    left: '.5em',
    color: colors.gray[500],
    content: '"$"',
  },
})

export const Input = styled.input({
  width: '100%',
  padding: '0.75rem 2.6rem .75rem 1rem',
  backgroundColor: colors.white,
  appearance: 'none',
  borderWidth: 1,
  borderColor: colors.blue['300'],
  borderRadius: borderRadius.lg,
  textIndent: '.3rem',
  fontSize: '1rem',
  lineHeight: '1.3rem',
})

export const InputUpchargeContainer = styled.div({
  display: 'inline-block',
  position: 'relative',
  '&::before': {
    position: 'absolute',
    top: '12px',
    left: '.5em',
    color: colors.gray[500],
    content: '"$"',
  },
})

export const InputDisclaimer = styled.p({
  fontSize: '10px',
  fontWeight: fontWeight.light,
})

export const DeliveryBuyDownFooter = styled.div({
  display: 'flex',
  justifyContent: 'right',
  columnGap: '0.75rem',
})
