import {
  CustomizationGroupByCustomizationId,
  ExtendedMenuMappingItemDetails,
  FormattedMenuSectionInfo,
  ItemModifierGroup,
  MenuMappingSectionFormatted,
  SectionModifier,
} from 'api'

export const formatMenuSection = (
  menuSection: MenuMappingSectionFormatted,
  modifierGroups?: { [customizationId: string]: ItemModifierGroup },
): FormattedMenuSectionInfo => {
  const combinedData = {
    type: menuSection.type,
    id: menuSection.id,
    attributes: {
      ...menuSection.attributes,
      partnerId: menuSection.partnerId,
      availabilities: menuSection.availabilities,
    },
    children: {
      subsections: menuSection.subsections.map((subsection) => ({
        type: subsection.type,
        id: subsection.id,
        attributes: {
          title: subsection.title,
          sort_position: subsection.sort_position,
        },
        children: {
          items: subsection.items.map((item) => ({
            type: item.type,
            id: item.id,
            attributes: {
              title: item.title,
              description: item.description,
              plu: item.plu,
              suspend_until: item.suspend_until,
              image_url: item.image_url,
              price: item.item_price,
            },
            children: {
              customizations: mapItemToModifierGroups(item, modifierGroups),
            },
          })),
        },
      })),
    },
  }

  return combinedData
}

export const mapItemToModifierGroups = (
  item: ExtendedMenuMappingItemDetails,
  modifierGroups?: CustomizationGroupByCustomizationId | undefined,
): FormattedMenuSectionInfo[] => {
  if (!item.itemCustomizations || !modifierGroups) {
    return [] // Handle case where itemCustomizations or modifiersGroup are undefined
  }

  // Filter itemCustomizations based on existence in modifiersGroup and map to ItemModifierGroup
  const filteredGroupModifiers: FormattedMenuSectionInfo[] = item.itemCustomizations
    .filter(({ id }) => modifierGroups[id] !== undefined)
    .map(({ id }) => {
      const modifierGroup = modifierGroups[id]
      if (!modifierGroup) {
        throw new Error(`Modifier group with id ${id} not found in modifierGroups.`)
      }

      return {
        id: modifierGroup.id,
        type: modifierGroup.type,
        attributes: modifierGroup.attributes,
        children: {
          customization_options: getNestedCustomizationOptions(
            modifierGroup.children.customization_options,
          ),
        },
      }
    })

  return filteredGroupModifiers
}

// recursive function that gets all the nested layers for customizations and customization options
// our api can only go 2 levels deep so it shouldn't recur more than that
export const getNestedCustomizationOptions = (
  customizationOptions?: SectionModifier[],
): FormattedMenuSectionInfo[] | undefined => {
  if (!customizationOptions) {
    return
  }

  return customizationOptions.map((option) => ({
    id: option.id,
    type: option.type,
    attributes: option.attributes,
    children: {
      customizations: option.children?.customizations?.map((customization) => ({
        id: customization.id,
        type: customization.type,
        attributes: customization.attributes,
        children: {
          customization_options: getNestedCustomizationOptions(
            customization.children?.customization_options,
          ),
        },
      })),
    },
  }))
}
