import { Availabilities } from 'api/menuMapping/types'
import { Button } from 'components'
import Modal from 'features/modal/Modal'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FormattedAvailabilities } from 'types/Menu'
import { DAYS_OF_WEEK } from 'utils'

import * as Styled from '../styles'
import updateAvailabilitiesForMenuSection from '../util/updateAvailabilitiesForMenuSection'
import validateAvailabilities from '../util/validateAvailabilities'
import MenuAvailabilityTable from './MenuAvailabilityTable'

type Props = {
  isOpen: boolean
  onClose: () => void
  availabilities: Availabilities[]
  menuSectionId: string
  refetchMenu: () => void
}
const MenuAvailabilityModal = ({
  isOpen,
  onClose,
  availabilities,
  menuSectionId,
  refetchMenu,
}: Props) => {
  const [newAvailabilities, setNewAvailabilities] = useState<FormattedAvailabilities>({})
  const [availabilitiesToDelete, setAvailabilitiesToDelete] = useState<Availabilities['id'][] | []>(
    [],
  )

  useEffect(() => {
    const formattedAvailabilities: FormattedAvailabilities = {}

    // Prefill availabilities object with existing availabilities
    DAYS_OF_WEEK.forEach((day) => {
      const existingAvailability = availabilities.find((avail) => avail.dayOfWeek === day)

      if (existingAvailability?.enabled) {
        formattedAvailabilities[existingAvailability.dayOfWeek] =
          existingAvailability.timePeriods.map((timePeriod) => ({
            id: timePeriod.id,
            start_time: timePeriod.attributes.start_time,
            end_time: timePeriod.attributes.end_time,
          }))
      } else {
        // if we are missing time periods for this day, prefill empty time periods to display options anyways
        formattedAvailabilities[day] = []
      }
    })

    setNewAvailabilities(formattedAvailabilities)
  }, [availabilities])

  const saveNewAvailabilities = async () => {
    // Persist all the days and time periods of a menu section
    const result = await updateAvailabilitiesForMenuSection(
      availabilities,
      newAvailabilities,
      menuSectionId,
      availabilitiesToDelete,
    )

    if (result) {
      toast.success('Success! Your availability has been updated.', { autoClose: 5000 })
      refetchMenu()
      // close the modal
      onClose()
    }
  }

  const timePeriodsError = validateAvailabilities(newAvailabilities) === false

  if (!isOpen) {
    return null
  } else {
    return (
      <Modal width='600px' height='auto' borderRadius='8px' isOpen={isOpen} onClose={onClose}>
        <div style={{ width: '100%', padding: '32px', maxHeight: '100vh', overflow: 'scroll' }}>
          <Styled.MenuAvailabilityTitle>Menu Availability</Styled.MenuAvailabilityTitle>
          <div style={{ margin: '40px 0' }}>
            <MenuAvailabilityTable
              availabilities={availabilities}
              newAvailabilities={newAvailabilities}
              setNewAvailabilities={setNewAvailabilities}
              isModal={true}
              availabilitiesToDelete={availabilitiesToDelete}
              setAvailabilitiesToDelete={setAvailabilitiesToDelete}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={saveNewAvailabilities} disabled={timePeriodsError}>
              Save
            </Button>
          </div>
          {timePeriodsError && (
            <Styled.TopRowText color='red' textAlign='end'>
              Please fix the errors above before proceeding.
            </Styled.TopRowText>
          )}
        </div>
      </Modal>
    )
  }
}

export default MenuAvailabilityModal
