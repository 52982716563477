import Tippy from '@tippyjs/react'
import { updateDeliveryBuyDown } from 'api'
import { ReactComponent as DeliveryCarIcon } from 'assets/icons/delivery-car-icon.svg'
import { Button, Toggle } from 'components'
import { Dialog, DialogTrigger } from 'components/Dialog'
import { useIsMobile } from 'hooks'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { PlatformSummary } from 'types'
import { showError } from 'utils'

import * as Styled from './styles'

type Props = {
  title: string
  description: string
  isDisabled?: boolean
  isDanger?: boolean
  okText?: string
  cancelText?: string
  onOk?: () => void
  onCancel?: () => void
  modalHeader?: string
  row?: PlatformSummary
}

const DeliveryBuyDownButton = ({
  title,
  description,
  okText = 'OK',
  cancelText = 'Cancel',
  isDisabled,
  isDanger,
  modalHeader,
  onCancel,
  onOk,
  row,
}: Props) => {
  const [isActive, setIsActive] = useState(false)
  // eslint-disable-next-line
  const [buyDownFee, setBuyDownFee] = useState<any>()
  // eslint-disable-next-line
  const [deliveryBuydownMin, setDeliveryBuydownMin] = useState<any>()
  const { isMobile } = useIsMobile()

  useEffect(() => {
    if (row?.buydown) {
      setIsActive(row.buydown)
    }
    if (row?.buydown_fee) {
      setBuyDownFee(row.buydown_fee / 100)
    }
    if (row?.minimum_delivery_subtotal) {
      setDeliveryBuydownMin(row.minimum_delivery_subtotal / 100)
    }
  }, [row])

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { target } = event
    const value = (target as HTMLButtonElement).value
    setBuyDownFee(value)
  }
  const handleChangeDeliveryBuydownMin = (event: React.FormEvent<HTMLInputElement>) => {
    const { target } = event
    const value = (target as HTMLButtonElement).value
    setDeliveryBuydownMin(value)
  }

  const onSubmitBuyDown = async () => {
    if (!row?.id) return
    if (row?.id) {
      try {
        if (buyDownFee) {
          await updateDeliveryBuyDown(row.id, isActive, buyDownFee * 100, deliveryBuydownMin * 100)
          toast.success('Saved!')

          onOk && onOk()
        }
      } catch (e) {
        showError(e)
        console.error('Unable to update your buydown settings')
      }
    }
  }
  return (
    <Dialog>
      {/* Don't show tooltip on mobile */}
      {!isMobile ? (
        <Tippy content={description}>
          <DialogTrigger asChild={true}>
            <Button
              danger={isDanger}
              narrow={true}
              nowrap={true}
              disabled={isDisabled}
              size='small'
              variant='outline'
            >
              <DeliveryCarIcon />
              {title}
            </Button>
          </DialogTrigger>
        </Tippy>
      ) : (
        <DialogTrigger asChild={true}>
          <Button
            danger={isDanger}
            narrow={true}
            nowrap={true}
            disabled={isDisabled}
            size='small'
            variant='outline'
          >
            <DeliveryCarIcon />
            {title}
          </Button>
        </DialogTrigger>
      )}

      <Styled.DeliveryBuyDownContent>
        <Styled.DeliveryBuyDownHeading>{modalHeader}</Styled.DeliveryBuyDownHeading>
        <Styled.DeliveryBuyDownDescription>
          {description}
          <Styled.ToggleContainer>
            {' '}
            <Toggle id={undefined} isToggled={isActive} onToggle={() => setIsActive(!isActive)} />
          </Styled.ToggleContainer>
          Delivery minimum total
          <Styled.InputBuydownMinContainer>
            <Styled.Input
              value={deliveryBuydownMin}
              type='number'
              pattern='[0-9]*'
              onChange={handleChangeDeliveryBuydownMin}
              disabled={!isActive}
            />
          </Styled.InputBuydownMinContainer>
          What delivery fee would you like your customers to pay?
          <Styled.InputUpchargeContainer>
            <Styled.Input
              value={buyDownFee}
              type='number'
              pattern='[0-9]*'
              onChange={handleChange}
              disabled={!isActive}
            />
          </Styled.InputUpchargeContainer>
          <Styled.InputDisclaimer>
            Please note delivery fees can range between $5.25 and $8.00 depending distance/time. You
            will be responsible for the remainder of delivery fees that exceed the amount set above.
          </Styled.InputDisclaimer>
        </Styled.DeliveryBuyDownDescription>
        <Styled.DeliveryBuyDownFooter>
          <Styled.OutlineButton onClick={onCancel}>{cancelText}</Styled.OutlineButton>
          <Styled.PrimaryButton $danger={isDanger} onClick={onSubmitBuyDown}>
            {okText}
          </Styled.PrimaryButton>
        </Styled.DeliveryBuyDownFooter>
      </Styled.DeliveryBuyDownContent>
    </Dialog>
  )
}

export default DeliveryBuyDownButton
