import { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'

import { modalState } from '../state/atoms'

const useCloseModal = () => {
  const setModalState = useSetRecoilState(modalState)
  return useCallback(() => {
    setModalState({ isOpen: false, modalName: null })
  }, [setModalState])
}

export default useCloseModal
