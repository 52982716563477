export const formatItemDescription = (text?: string) => {
  if (!text?.length) {
    return ''
  }
  if (text.length > 100) {
    return `${text.slice(0, 100)}...`
  }
  return text
}

export const formatItemPrice = (price?: number) => {
  if (!price) {
    return 'N/A'
  }

  return `$${price.toString()}`
}

/**
 * @param updatedItemsLength - num of newly suspended and unsuspended items
 * @param updatedModifiersLength - num of newly suspended and unsuspended modifiers
 * @returns text to display in header rows for num of items suspended
 */
export const getRowText = (updatedItemsLength: number, updatedModifiersLength: number) => {
  let numUpdatedStr = ''

  if (updatedItemsLength > 0) {
    numUpdatedStr = numUpdatedStr.concat(`${updatedItemsLength} Product(s)`)
  }

  if (updatedModifiersLength > 0) {
    if (updatedItemsLength > 0) {
      numUpdatedStr = numUpdatedStr.concat(' and ')
    }
    numUpdatedStr = numUpdatedStr.concat(`${updatedModifiersLength} Modifier(s)`)
  }

  if (numUpdatedStr.length) {
    return `${numUpdatedStr} Updated`
  }
  return 'No Changes'
}
