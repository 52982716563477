export const doordashExclusionCodeMessage = (code: string | undefined) => {
  switch (code) {
    case 'MENU_JOB_FAILURE':
      return 'Menu Job failed due to issues with the menu. This may be due to missing contents, null category/item names, or other issues with the menu.'
    case 'MENU_COMPLIANCE_FAILURE':
      return 'Menu Job failed due to identified as having non-compliant items. Please ensure you have completed the proper compliance forms on DoorDash or remove the non-compliant items, and retry the menu ingestion. <- Note, this exclusion is coming in H2 2023.'
    case 'MENU_BLANK_FAILURE':
      return 'Menu Job succeeded, but the menu has no items. Please ensure the menu(s) being ingested have valid contents and retry the menu ingestion.'
    case 'STORE_HOURS_NOT_POPULATED_FAILURE':
      return 'Menu Job failed due to missing menu hours. Please ensure the menu(s) being ingested have valid hours and retry the menu ingestion.'
    case 'MENU_PULLING_REQUEST_FAILURE':
      return 'Menu Job failed due to internal issue. Please retry the menu ingestion.'
    default:
      return 'It looks like there was a problem your DoorDash integration. Please click on the yellow “C” icon in the lower left side of your screen to contact Chowly Support for assistance.'
  }
}

export const doorDashStatusFeedback = {
  menu_error: {
    footerMessage:
      'When resolved, please use the button below to import the menu and request another menu pull from DoorDash.',
  },
  menu_qualified: {
    message:
      'Please Activate your DoorDash location by clicking on the button below.  Please note that DoorDash must review your menus before activating your integration. This will take no longer than 48 hours. If this DoorDash location is currently integrated on a different platform, that integration will be discontinued as soon as DoorDash activates this one.',
  },
  activation_block: {
    message:
      'It looks like there was a problem activating your DoorDash integration. Please click on the yellow “C” icon in the lower left side of your screen to contact Chowly Support for assistance.',
  },
}
