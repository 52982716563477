import { chowlyApi } from 'config'
import { generatePath } from 'react-router'

import client from '../chowly'
import {
  MenuMappingServiceAvailability,
  MenuMappingServiceAvailabilityInput,
  MenuMappingServiceAvailabilityResource,
  MenuMappingTimePeriod,
  MenuMappingTimePeriodInput,
  ResourceIdentifier,
  TypeName,
} from '../models'

// creates a new day (Monday, Tuesday, etc.)
export const createMenuMappingServiceAvailability = async (
  payload: MenuMappingServiceAvailabilityInput,
) => {
  const url = chowlyApi.menuMappingServiceAvailabilities
  const { data } = await client.post(url, payload)
  return data
}

export const updateMenuMappingServiceAvailability = async (
  id: MenuMappingServiceAvailability['id'],
  payload: MenuMappingServiceAvailabilityInput,
) => {
  const url = generatePath(chowlyApi.menuMappingServiceAvailability, { id })
  const { data } = await client.put(url, payload)

  return data
}

// Update a single time period Example: ({ start_time; 10am, end_time: 6pm })
export const updateMenuMappingTimePeriod = async (
  id: MenuMappingTimePeriod['id'],
  payload: MenuMappingTimePeriodInput,
) => {
  const url = generatePath(chowlyApi.menuMappingTimePeriod, { id })
  const { data } = await client.put(url, payload)

  return data
}

// Create a single time period
export const createMenuMappingTimePeriod = async (payload: MenuMappingTimePeriodInput) => {
  const url = chowlyApi.menuMappingTimePeriods

  const { data } = await client.post(url, payload)
  return data
}

export const deleteMenuMappingTimePeriod = async (id: MenuMappingTimePeriod['id']) => {
  const url = generatePath(chowlyApi.menuMappingTimePeriod, { id })

  const response = await client.delete(url)

  return response
}

export const formatServiceAvailabilityPayload = (
  attributes: MenuMappingServiceAvailability,
  serviceAvailabilityableId?: string,
  timePeriods?: ResourceIdentifier[],
): MenuMappingServiceAvailabilityInput => {
  return {
    data: {
      type: TypeName.MenuMappingServiceAvailability,
      attributes,
      relationships: {
        ...(serviceAvailabilityableId && {
          service_availabilityable: {
            data: {
              id: serviceAvailabilityableId,
              type: 'menu-mapping-sections', // This is what the controller expects. It doesn't correlate to a type we have in the db.
            },
          },
        }),
        ...(timePeriods && {
          time_periods: {
            data: timePeriods,
          },
        }),
      },
    },
  }
}

export const formatTimePeriodPayload = (
  attributes: MenuMappingTimePeriod,
  serviceAvailabilityId?: MenuMappingServiceAvailabilityResource['id'],
): MenuMappingTimePeriodInput => {
  return {
    data: {
      type: TypeName.MenuMappingTimePeriod,
      attributes,
      relationships: {
        ...(serviceAvailabilityId && {
          service_availability: {
            data: {
              id: serviceAvailabilityId,
              type: TypeName.MenuMappingServiceAvailability,
            },
          },
        }),
      },
    },
  }
}
