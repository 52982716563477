import * as Styled from './styles'

type ProvisionStatusType = {
  uberEatsNeedsActivationCheck: boolean
  handleActivateDoordash: () => void
  handleActivateUberEats: () => void
  doordashNeedsActivationCheck: boolean
}

export const ActivatePlatformComponent = ({
  uberEatsNeedsActivationCheck,
  handleActivateDoordash,
  handleActivateUberEats,
  doordashNeedsActivationCheck,
}: ProvisionStatusType) => (
  <Styled.ActivatePlatformSection>
    <Styled.ActivateWrapper>
      <Styled.AlertIcon />
      <Styled.ActivateMessage>
        {doordashNeedsActivationCheck && uberEatsNeedsActivationCheck
          ? 'Please activate the platform for your location below. Activation will be requested immediately and responded to shortly. If this location is currently integrated on a different platform, that integration will be discontinued as soon as Uber Eats and DoorDash activate these.'
          : uberEatsNeedsActivationCheck
          ? 'Please activate the platform for your location below. Please note if this Uber Eats location is currently integrated on a different platform, that integration will be discontinued as soon as Uber Eats activates this one.'
          : doordashNeedsActivationCheck
          ? 'Please activate the platform for your location below. Activation will be requested immediately and responded to shortly. If this location is currently integrated on a different platform, that integration will be discontinued as soon as DoorDash activates this one.'
          : 'There are no platforms that require activation'}
      </Styled.ActivateMessage>
    </Styled.ActivateWrapper>
    <Styled.ActivateLinkSection>
      {doordashNeedsActivationCheck && (
        <Styled.ActivateLink onClick={handleActivateDoordash}>
          Activate Doordash <Styled.ChevronRightIcon />
        </Styled.ActivateLink>
      )}
      {uberEatsNeedsActivationCheck && (
        <Styled.ActivateLink onClick={handleActivateUberEats}>
          Activate Uber Eats <Styled.ChevronRightIcon />
        </Styled.ActivateLink>
      )}
    </Styled.ActivateLinkSection>
  </Styled.ActivatePlatformSection>
)
