const ToosConfig: { enabled: { [key: string]: string[] } } = {
  enabled: {
    doordash: ['onboarding_status'],
  },
}

export const isOnboardingStatusEnabled: (feature: string, toos: string | undefined) => boolean = (
  feature,
  toos,
) => {
  if (toos && toos.toLowerCase() in ToosConfig.enabled) {
    return ToosConfig.enabled[toos.toLowerCase()].includes(feature)
  }
  return false
}
