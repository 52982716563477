import styled from 'styled-components'
import { breakpoints, colors, fontWeight } from 'theme'

export const Container = styled.header({
  display: 'flex',
  zIndex: 10,
  justifyContent: 'space-between',
  alignItems: 'center',
  [`@media ${breakpoints.md}`]: {
    alignItems: 'center',
    marginTop: 0,
  },
})

export const Title = styled.h1({
  color: colors.primary.dark,
  textTransform: 'uppercase',
  fontWeight: fontWeight.bold,
  fontSize: '1rem',
  lineHeight: '1.75rem',
  margin: 0,
  [`@media ${breakpoints.md}`]: {
    fontSize: '1.5rem',
    lineHeight: '2.25rem',
  },
  [`@media ${breakpoints.lg}`]: {
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
  },
  [`@media ${breakpoints.xl}`]: {
    fontSize: '2.25rem',
    lineHeight: '2.5rem',
  },
})

export const LocationsBackContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

export const LeftArrowImg = styled.img({
  paddingRight: '5px',
})

export const ToolBar = styled.div({
  display: 'flex',
  alignItems: 'center',
  columnGap: '0.75rem',
})

export const LocationHeaderText = styled.div({
  fontSize: '1.25rem',
  fontWeight: fontWeight.normal,
  textTransform: 'none',
  marginLeft: '0.5rem',
})
