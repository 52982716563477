import styled from 'styled-components'
import { borderRadius, fontWeight } from 'theme'

export const Button = styled.button({
  display: 'flex',
  columnGap: '1rem',
  alignItems: 'center',
  padding: '1rem',
  borderColor: 'rgb(0, 41, 107)',
  borderWidth: 1,
  borderRadius: borderRadius.xxl,
  marginTop: '2.5rem',
})

export const TextWrapper = styled.div({
  textAlign: 'left',
})

export const Title = styled.div({
  color: 'rgb(33, 33, 33)',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
})

export const Description = styled.div({
  color: 'rgb(0, 80, 157)',
  opacity: '.68',
  fontWeight: fontWeight.medium,
  fontSize: '0.75rem',
  lineHeight: '1rem',
})
