import Tippy from '@tippyjs/react'
import { FormattedMenuSectionInfo, ItemModifier, MenuMappingItemDetails, TypeName } from 'api'
import clock_icon_gray from 'assets/icons/clock-gray.svg'
import clock_icon_green from 'assets/icons/clock-green.svg'
import ellipse_2_green from 'assets/icons/ellipse-2-green.svg'
import ellipse_2_red from 'assets/icons/ellipse-2-red.svg'
import PLUS_ICON from 'assets/icons/plus-icon.svg'
import { Toggle } from 'components'
import { INDEFINITELY_86ED } from 'features/manage-locations/IndividualMenu/MenuSectionPreview'
import { useIsMobile } from 'hooks'
import { Dispatch, SetStateAction } from 'react'
import { colors } from 'theme'

import * as Styled from './styles'
import { formatItemPrice } from './util/formatMenuText'

type Props = {
  modifier: FormattedMenuSectionInfo
  isSelected?: boolean
  isExpanded?: boolean
  onRowClick?: () => void
  onModifierClick?: (id: ItemModifier['id']) => void
  isPreviewOnly?: boolean
  isNested?: boolean
  setSuspendUntilItem?: Dispatch<
    SetStateAction<
      | {
          id: MenuMappingItemDetails['id'] | ItemModifier['id']
          type: TypeName.Item | TypeName.CustomizationOption
        }
      | undefined
    >
  >
  existingSuspendUntil?: string | null | undefined
  checkBoxContainerStyle?: React.CSSProperties
  modifiersExist?: boolean
  isItemPreview?: boolean
}

const ItemModifierRow = ({
  modifier,
  isSelected,
  isExpanded,
  isItemPreview,
  onModifierClick,
  onRowClick,
  isPreviewOnly,
  isNested,
  setSuspendUntilItem,
  existingSuspendUntil,
  checkBoxContainerStyle,
  modifiersExist = false,
}: Props) => {
  const { isMobile } = useIsMobile()
  return (
    <Styled.ItemRow
      key={modifier.id}
      isMobile={isMobile}
      isModifier={!isNested}
      isNestedModifier={isNested}
      isExpanded={isExpanded}
    >
      {!isPreviewOnly && !isItemPreview ? (
        <Styled.CheckboxModifierContainer style={checkBoxContainerStyle}>
          <Toggle
            id={`toggle-${modifier.id}`}
            isToggled={isSelected}
            onToggle={() => onModifierClick && onModifierClick(modifier.id)}
            activeColor={colors.blue[700]}
          />
        </Styled.CheckboxModifierContainer>
      ) : (
        <div style={{ flexBasis: '72px' }} />
      )}
      <Styled.ItemTitle style={{ flexBasis: '100%' }}>{modifier.attributes.title}</Styled.ItemTitle>
      {!isSelected && !isItemPreview && !isMobile ? (
        <Styled.Status active={isSelected}>
          <Styled.Icon src={isSelected ? ellipse_2_green : ellipse_2_red} alt='ellipseIcon' />
          {isSelected ? 'active' : 'inactive'}
        </Styled.Status>
      ) : (
        <div style={{ flexBasis: '20%' }} />
      )}
      <Styled.InputPriceContainer isMobile={isMobile}>
        <Styled.Input
          placeholder={formatItemPrice(modifier.attributes.price)}
          type='price'
          disabled={true} // when we build the creator, make this an editable field
        ></Styled.Input>
      </Styled.InputPriceContainer>
      {/* Another nested level of modifiers exists */}
      {onRowClick && modifiersExist && !isPreviewOnly && (
        <Styled.OptionsContainer onClick={onRowClick}>
          <Styled.OptionsIcon src={PLUS_ICON} alt='ellipse_menu' />
        </Styled.OptionsContainer>
      )}
      {!isSelected && !isItemPreview ? (
        <div
          style={{ flexBasis: '56px', display: 'flex', justifyContent: 'center' }}
          onClick={() => {
            if (modifier && setSuspendUntilItem) {
              // sets the item in the modal
              setSuspendUntilItem({ id: modifier.id, type: TypeName.CustomizationOption })
            }
          }}
        >
          <Tippy content={existingSuspendUntil}>
            <Styled.Icon
              src={existingSuspendUntil !== INDEFINITELY_86ED ? clock_icon_green : clock_icon_gray}
              alt='clock icon'
              iconSize='16'
            />
          </Tippy>
        </div>
      ) : (
        <div style={{ flexBasis: '56px' }} />
      )}
    </Styled.ItemRow>
  )
}

export default ItemModifierRow
