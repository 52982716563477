import Tippy from '@tippyjs/react'
import { FC, useState } from 'react'

import ProvisionStatusDescriptions from '../../constants/ProvisionStatusDescriptions'
import Modal from '../../features/modal/Modal'
import DescriptionModal from '../DescriptionModal/DescriptionModal'
import * as Styled from './styles'

type Props = {
  enabled: boolean
  status: string | undefined
  fieldName: string
  fieldKey: string
}

const ProvisionStatus: FC<Props> = ({ enabled, status, fieldName, fieldKey }) => {
  const [renderDictionary, setrenderDictionary] = useState(false)
  const [description, setDescription] = useState('default')
  const [title, setTitle] = useState('')

  const iconClick = (event: string) => {
    const descriptionKey = Object.keys(ProvisionStatusDescriptions).find((key) => key === event)
    if (descriptionKey) {
      const typeDescription = descriptionKey as keyof typeof ProvisionStatusDescriptions
      setDescription(descriptionKey)

      setTitle(ProvisionStatusDescriptions[typeDescription].title)
    }
    setrenderDictionary(true)
  }
  const closeModal = () => {
    setrenderDictionary(false)
  }

  const statuses: () => { description: string; title: string }[] = () => {
    const typeDescription = description as keyof typeof ProvisionStatusDescriptions
    return (
      ProvisionStatusDescriptions[typeDescription]?.statuses ||
      ProvisionStatusDescriptions.default.statuses
    )
  }

  const content = `What does ${fieldName} mean?`

  return (
    <Styled.LocationFormItem>
      <Tippy content={content} placement='left' theme='light'>
        <Styled.InfoIcon
          onClick={() => {
            iconClick(fieldKey)
          }}
          role='button'
        />
      </Tippy>

      <Styled.FieldLabel>{fieldName}</Styled.FieldLabel>
      <Styled.StatusText isSuccess={enabled}>{status}</Styled.StatusText>
      <Modal isOpen={renderDictionary} onClose={closeModal}>
        <DescriptionModal
          title={title}
          descriptions={statuses()}
          updateRenderDictionary={setrenderDictionary}
        />
      </Modal>
    </Styled.LocationFormItem>
  )
}

export default ProvisionStatus
