import { chowlyApi } from 'config'
import { generatePath } from 'react-router'

import client from '../chowly'
import {
  MenuItemSuspensionUpdateInput,
  MenuItemSuspensionUpdateResponse,
  MenuMappingItemResource,
  MenuMappingMenuResource,
  MenuMappingMenuResponse,
  MenuMappingSection,
  MenuMappingSectionInput,
  MenuMappingSectionResource,
  MenuMappingSectionResponse,
  MenuMappingSubsectionItemResource,
  MenuMappingSubsectionResource,
  MenuPublishResponse,
  OrderPlatformResource,
  TypeName,
} from '../models'
import { getAvailabilities } from './helpers'
import { MenuMappingSectionFormatted } from './types'

type QueryParams = {
  include: string
}

export const getMenuMappingMenu = async (menuMappingMenuId: string, params?: QueryParams) => {
  const url = generatePath(chowlyApi.menuMappingMenu, { id: menuMappingMenuId })
  const { data } = await client.get<MenuMappingMenuResponse>(url, { params })
  return data
}

export const getMenuMappingSections = async (menuMappingMenuId: string) => {
  const url = generatePath(chowlyApi.menuMappingMenu, { id: menuMappingMenuId })
  try {
    const {
      data: { included },
    } = await client.get<MenuMappingMenuResponse>(url, { params: { include: 'sections' } })
    return (included ?? []).filter(
      (obj): obj is MenuMappingSectionResource => obj.type === TypeName.MenuMappingSection,
    )
  } catch (e) {
    return []
  }
}

export const getMenuMappingSection = async (
  menuMappingSectionId: string,
): Promise<MenuMappingSectionFormatted> => {
  const url = generatePath(chowlyApi.menuMappingSection, { id: menuMappingSectionId })
  const {
    data: { data, included },
  } = await client.get<MenuMappingSectionResponse>(url, {
    params: {
      include:
        'menu,menu.order_platform,subsections.items,subsections.subsection_items,service_availabilities.time_periods',
      'fields[order_platform]': 'partner_id',
    },
  })

  return {
    ...data,
    partnerId: included?.find(
      (obj): obj is OrderPlatformResource => obj.type === TypeName.OrderPlatform,
    )?.attributes?.partner_id,
    errors:
      included?.find((obj): obj is MenuMappingMenuResource => obj.type === TypeName.MenuMappingMenu)
        ?.attributes.errors ?? [],
    subsections: (included ?? [])
      .filter(
        (obj): obj is MenuMappingSubsectionResource => obj.type === TypeName.MenuMappingSubsection,
      )
      .map(({ id, type, attributes, relationships }) => ({
        type,
        ...attributes,
        id,
        items: relationships.items.data.map(({ id, type }) => {
          const menuMappingItem = (included ?? []).filter(
            (obj): obj is MenuMappingItemResource => obj.id === id && obj.type === type,
          )[0]
          const menuMappingSubsectionItem = (included ?? []).filter(
            (obj): obj is MenuMappingSubsectionItemResource =>
              obj.type === TypeName.MenuMappingSubsectionItem &&
              menuMappingItem?.relationships.subsection_items.data.some(({ id }) => id === obj.id),
          )[0]
          return {
            id,
            type,
            ...menuMappingItem?.attributes,
            ...menuMappingSubsectionItem?.attributes,
            itemCustomizations: menuMappingItem?.relationships?.customizations?.data,
          }
        }),
      })),
    availabilities: getAvailabilities(included),
  }
}

export const requestMenuPublish = async (
  menuMappingMenuId: string | undefined,
  onboarding?: boolean,
): Promise<MenuPublishResponse> => {
  const isOnboardingParam = onboarding ? '?is_onboarding=true' : ''

  return client.patch(
    `${generatePath(chowlyApi.publishUrl, {
      id: menuMappingMenuId,
    })}${isOnboardingParam}`,
    {},
  )
}

export const updateMenuItemSuspensionStatus = async (payload: MenuItemSuspensionUpdateInput) => {
  const { data } = await client.post<MenuItemSuspensionUpdateResponse>(
    chowlyApi.menuMappingSuspension,
    payload,
  )
  return data
}

export const createMenuMappingSection = async (payload: MenuMappingSectionInput) => {
  const { data } = await client.post(chowlyApi.menuMappingSections, payload)

  return data
}

export const formatMenuSectionPayload = (
  attributes: MenuMappingSection,
  menuId: MenuMappingMenuResource['id'],
): MenuMappingSectionInput => {
  return {
    data: {
      type: TypeName.MenuMappingSection,
      attributes,
      relationships: {
        menu: {
          data: {
            type: TypeName.MenuMappingMenu,
            id: menuId,
          },
        },
      },
    },
  }
}
