import { ReactComponent as AngleLeftIcon } from 'assets/icons/angle-left.svg'
import { ReactComponent as AngleRightIcon } from 'assets/icons/angle-right.svg'
import { ReactComponent as AnglesLeftIcon } from 'assets/icons/angles-left.svg'
import { ReactComponent as AnglesRightIcon } from 'assets/icons/angles-right.svg'
import { FC } from 'react'

import * as Styled from './styles'

type Props = {
  nextEnabled: boolean
  previousEnabled: boolean
  isLoading: boolean
  onFirstClick: () => void
  onLastClick: () => void
  onPrevClick: () => void
  onNextClick: () => void
}

const Pagination: FC<Props> = ({
  onFirstClick,
  onLastClick,
  onPrevClick,
  onNextClick,
  nextEnabled,
  previousEnabled,
  isLoading,
}) => (
  <Styled.Container>
    <Styled.Nav aria-label='Pagination'>
      <Styled.NavButton
        aria-label='First page'
        disabled={isLoading || !previousEnabled}
        onClick={onFirstClick}
      >
        <AnglesLeftIcon width={20} height={20} />
      </Styled.NavButton>
      <Styled.NavButton
        aria-label='Previous page'
        disabled={isLoading || !previousEnabled}
        onClick={onPrevClick}
      >
        <AngleLeftIcon width={20} height={20} />
      </Styled.NavButton>
      <Styled.NavButton
        aria-label='Next page'
        disabled={isLoading || !nextEnabled}
        onClick={onNextClick}
      >
        <AngleRightIcon width={20} height={20} />
      </Styled.NavButton>
      <Styled.NavButton
        aria-label='Last page'
        disabled={isLoading || !nextEnabled}
        onClick={onLastClick}
      >
        <AnglesRightIcon width={20} height={20} />
      </Styled.NavButton>
    </Styled.Nav>
  </Styled.Container>
)

Pagination.displayName = 'Pagination'

export default Pagination
