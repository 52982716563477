import { useLocations } from 'components/MainLayout/MainLayout'
import { useState } from 'react'
import { LocationSummary } from 'types'

import { ProductTeam as ProductTeamCard, SmartPricing as SmartPricingCard } from './ActionCards'
import SalesOrderReport from './SalesOrderReport'
import { ShortCut } from './Shortcuts'
import * as Styled from './styles'

const Health = () => {
  const { locationsData } = useLocations()
  const { locations, isLoading, isError } = locationsData

  const [selectedLocation, setSelectedLocation] = useState<LocationSummary>(locations[0])

  return (
    <Styled.HomeContainer>
      <ShortCut
        partnerData={{
          locations,
          isLoading,
          isError,
        }}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
      />
      <SalesOrderReport partnerId={selectedLocation?.id?.toString() || ''} />
      <ProductTeamCard isLoading={isLoading} />
      <SmartPricingCard isLoading={isLoading} />
    </Styled.HomeContainer>
  )
}

export default Health
