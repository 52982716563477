/* eslint-disable react/display-name */
import { ButtonHTMLAttributes, forwardRef } from 'react'
import { Link } from 'react-router-dom'

import LoadingIcon from '../LoadingIcon/LoadingIcon'
import * as Styled from './styles'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
  variant?: 'solid' | 'outline' | 'textonly'
  size?: 'small' | 'medium' | 'large'
  nowrap?: boolean
  narrow?: boolean
  danger?: boolean
  to?: string
}

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    { isLoading = false, disabled, children, to, variant, size, nowrap, narrow, danger, ...props },
    ref,
  ) => (
    <Styled.Button
      ref={ref}
      as={to ? Link : 'button'}
      to={to}
      disabled={disabled || isLoading}
      $narrow={narrow}
      $nowrap={nowrap}
      $variant={variant}
      $size={size}
      $danger={danger}
      {...props}
    >
      {isLoading && <LoadingIcon />}

      {children}
    </Styled.Button>
  ),
)

export default Button
