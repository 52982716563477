import { SigmaEmbed, SkeletonLoader } from 'components'
import { useLocations } from 'components/MainLayout/MainLayout'
import { sigmaPages } from 'config'
import { useEmbedUrl } from 'hooks'

function AdvancedOrders() {
  const { locationsData } = useLocations()
  const { isLoading, locations } = locationsData

  const url = useEmbedUrl(sigmaPages.advancedOrders)

  if (isLoading) return <SkeletonLoader />

  if (!locations?.length) {
    return <div>Please add a location before viewing this report.</div>
  }

  return <SigmaEmbed url={url} title='Advanced Orders' />
}

export default AdvancedOrders
