import styled from 'styled-components'
import { colors, fontWeight } from 'theme'

export const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

export const NavButton = styled.button({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: fontWeight.semibold,
  cursor: 'pointer',
  userSelect: 'none',
  padding: '0.5em 1em',
  backgroundColor: colors.white,
  color: colors.primary.light,
  '&:active:not([disabled])': {
    backgroundColor: colors.blue[100],
    svg: {
      transform: 'scale(.9)',
    },
  },
  ':disabled': {
    color: colors.gray.DEFAULT,
    cursor: 'not-allowed',
  },
})

export const Nav = styled.nav({
  display: 'flex',
  justifyContent: 'center',
  borderColor: colors.primary.light,
  borderWidth: 1,
  borderRadius: '0.5rem',
  overflow: 'hidden',
  [NavButton]: {
    borderRightWidth: 1,
    borderColor: colors.primary.light,
    ':last-of-type': {
      borderRightWidth: 0,
    },
  },
})
