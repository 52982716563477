import { OrderPlatformResource, PartnerResponse, TypeName } from 'api'
import { PlatformName } from 'types'

export const selectParentPlatform = (partner: PartnerResponse | null) => {
  const { included } = partner ?? {}
  const parentOrderPlatform = included?.find(
    (item): item is OrderPlatformResource =>
      item.type == TypeName.OrderPlatform && item.attributes.name === PlatformName.CHOWLY,
  )
  return parentOrderPlatform
}
