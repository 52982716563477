import styled from 'styled-components'
import { breakpoints, colors, elevation, fontWeight } from 'theme'

export const Container = styled.div<{ hasOpenBanner?: boolean }>(
  {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
    overflow: 'auto',
    backgroundColor: colors.white,
  },
  ({ hasOpenBanner }) =>
    hasOpenBanner && {
      paddingTop: '3rem',
    },
)

export const Aside = styled.aside({
  display: 'flex',
  position: 'fixed',
  width: 0,
  left: 0,
  zIndex: elevation.backdrop,
  [`@media ${breakpoints.md}`]: {
    width: 336,
  },
})

export const Main = styled.main({
  padding: '88px 2rem 2rem 2rem',
  justifyContent: 'center',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: colors.white,
  [`@media ${breakpoints.xxs}`]: {
    gridTemplateRows: '3rem 1fr',
  },
})

export const LoadingContent = styled.div({
  color: colors.primary.dark,
  fontWeight: fontWeight.bold,
  fontSize: '3rem',
  lineHeight: '19px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
})
