import { ErrorObject } from 'api'
import { ReactComponent as ErrorIcon } from 'assets/icons/issue-icon.svg'
import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon.svg'
import MenuIssueIcon from 'components/MenuIssueIcon/MenuIssueIcon'
import partition from 'lodash/partition'
import { useState } from 'react'
import { IssueType } from 'types'

import * as Styled from './styles'

export type IssueItem = {
  issueType: IssueType
  issueText: string
}

type IssuesCountByType = Record<IssueType, number>

const issueDescriptions = {
  [IssueType.ITEM_NO_IMAGE]: 'Items have no image',
  [IssueType.MODIFIER_MIN_MAX_DISPARITY]:
    '\u2022 Your menu uses different min/max values for the same modifier group. \nThis may cause order errors. Please change the modifier group in Square to prevent future order errors.',
  [IssueType.CATEGORY_NO_ITEMS]: 'Categories have no items',
  [IssueType.ITEM_NO_PRICE]: 'items have no price',
  [IssueType.ITEM_MISSING_OPTIONS]: 'Items are missing options',
}

const warningTypes = [IssueType.ITEM_NO_IMAGE]

const isWarning = (issue: IssueItem) => warningTypes.includes(issue.issueType)

type Props = {
  data: ErrorObject[] | undefined
}

const MenuIssuesPopover = ({ data }: Props) => {
  const issues = Object.entries(
    (data ?? []).reduce(
      (acc, { error }) => ({
        ...acc,
        [error]: (acc[error as IssueType] || 0) + 1,
      }),
      {} as IssuesCountByType,
    ),
  ).map(([issueType, errorsCount]) => ({
    issueType: issueType as IssueType,
    issueText: `${issueType === IssueType.MODIFIER_MIN_MAX_DISPARITY ? '' : errorsCount} ${
      issueDescriptions[issueType as IssueType]
    }`,
  }))
  const [warnings, errors] = partition(issues, isWarning)

  return (
    <>
      {renderIssues(errors)}
      {renderIssues(warnings, true)}
    </>
  )
}

const renderIssues = (issues: IssueItem[], isWarnings?: boolean) => {
  const [isIssuesVisible, setIsIssuesVisible] = useState(false)
  const toggleIssues = () => setIsIssuesVisible((v) => !v)
  const hideIssues = () => setIsIssuesVisible(false)
  const title = isWarnings ? `${issues.length} Warnings` : `${issues.length} Issues`

  return (
    issues.length > 0 && (
      <Styled.Container>
        <Styled.TotalIssues $isWarning={isWarnings}>{title}</Styled.TotalIssues>
        <Styled.IssuesTooltip
          $isWarning={isWarnings}
          content={
            <Styled.IssuesTooltipContent>
              {issues.map(({ issueText, issueType }) => (
                <Styled.IssueItem key={issueType}>
                  <MenuIssueIcon issueType={issueType} />
                  <span>{issueText}</span>
                </Styled.IssueItem>
              ))}
            </Styled.IssuesTooltipContent>
          }
          placement='bottom-end'
          visible={isIssuesVisible}
          role='button'
          onClickOutside={hideIssues}
        >
          {isWarnings ? (
            <WarningIcon onClick={toggleIssues} role='button' />
          ) : (
            <ErrorIcon onClick={toggleIssues} role='button' />
          )}
        </Styled.IssuesTooltip>
      </Styled.Container>
    )
  )
}

export default MenuIssuesPopover
