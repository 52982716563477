import { LoadingSkeleton } from 'components'
import { useIsMobile } from 'hooks'
import { Key, ReactNode, useEffect } from 'react'

import * as Styles from './styles'

export type TableColumn<T> = {
  key: Key
  title: ReactNode
  dataIndex: keyof T | string
  align?: 'left' | 'right' | 'center'
  headerAlign?: 'left' | 'right' | 'center'
  width?: string
  render?: (row: T) => ReactNode
}

type TableData = Record<string, string | number>

type Props<T> = {
  data: T[] | undefined
  columns: Array<TableColumn<T>>
  rowKey: string
  path: string
  width?: string
  isLoading?: boolean
}

function TableMobile<T = TableData>({ data, columns, rowKey, path, width, isLoading }: Props<T>) {
  let Styled = { ...Styles }

  const { isMobile } = useIsMobile()

  const loadStyle = async () => {
    try {
      Styled = await import(path)
    } catch {
      Styled = { ...Styles }
    }
  }

  useEffect(() => {
    loadStyle()
  }, [Styled])

  if (isLoading) {
    return <LoadingSkeleton lineCount={5} />
  }

  return (
    <Styled.Table width={width}>
      <thead>
        {!isMobile && (
          <tr>
            <Styled.HeaderCell>Platform</Styled.HeaderCell>
          </tr>
        )}
      </thead>

      <tbody>
        {data?.map((row) => (
          <Styled.MobileTableRow key={(row as unknown as TableData)[rowKey]}>
            {columns.map(({ dataIndex, key, align, render }) => {
              const value = (row as unknown as TableData)[dataIndex as keyof TableData]
              return (
                <Styled.BodyCell key={key} align={align}>
                  {render ? render(row) : value}
                </Styled.BodyCell>
              )
            })}
          </Styled.MobileTableRow>
        ))}
      </tbody>
    </Styled.Table>
  )
}

export default TableMobile
