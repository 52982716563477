import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { PlatformName } from 'types'

import { useGetPlatformsOnboarding } from '../../hooks'
import {
  doordashActivationLinkState,
  orderPlatformConnectedStatus,
  uberEatsActivationLinkState,
} from '../../state/atoms'
import * as Styled from './styles'

type Props = {
  type: PlatformName | undefined
  continueOnboarding: () => void
  onClose: () => void
}

const AdminActivation = ({ type, continueOnboarding, onClose }: Props) => {
  const {
    existingDoordashPlatform,
    existingUberPlatform,
    filterUberPlatforms,
    filterDoordashPlatforms,
    reload,
    isLoading,
  } = useGetPlatformsOnboarding()
  const doordashActivationLink = useRecoilValue(doordashActivationLinkState)
  const orderPlatformConnectedStatusState = useRecoilValue(orderPlatformConnectedStatus)
  const setOrderPlatformConnectedStatusState = useSetRecoilState(orderPlatformConnectedStatus)
  const uberEatsActivationLink = useRecoilValue(uberEatsActivationLinkState)
  const [copyButtonText, setCopyButtonText] = useState('Copy Auth Link')
  const [canContinueAdmin, setCanContinueAdmin] = useState(false)
  const link =
    type === PlatformName.DOORDASH
      ? doordashActivationLink
      : type === PlatformName.UBEREATS
      ? uberEatsActivationLink
      : ''
  const copyLink = () => {
    if (link) {
      navigator.clipboard.writeText(link)
      setCopyButtonText('Copied!')
    }
  }
  useEffect(() => {
    setOrderPlatformConnectedStatusState(false)
  }, [])

  useEffect(() => {
    if (orderPlatformConnectedStatusState) {
      reload()
    }
  }, [orderPlatformConnectedStatusState])

  useEffect(() => {
    if (!isLoading) {
      if (type === PlatformName.DOORDASH) {
        setCanContinueAdmin(
          existingDoordashPlatform &&
            !filterDoordashPlatforms[0].hasOnboardingId &&
            filterDoordashPlatforms[0].aasmState === 'onboarding' &&
            filterDoordashPlatforms[0].hasAccessToken,
        )
      } else if (type === PlatformName.UBEREATS) {
        setCanContinueAdmin(
          existingUberPlatform &&
            filterUberPlatforms[0].aasmState === 'onboarding' &&
            filterUberPlatforms[0].hasAccessToken,
        )
      }
    }
  }, [existingDoordashPlatform, existingUberPlatform, type, isLoading])

  const notAuthenticatedError =
    existingDoordashPlatform && !filterDoordashPlatforms[0].hasAccessToken

  // if onboarding id exists show this error instead.
  const platformExistsError =
    existingDoordashPlatform &&
    (filterDoordashPlatforms[0].hasOnboardingId ||
      filterDoordashPlatforms[0].aasmState !== 'onboarding')

  return (
    <Styled.Container>
      <Styled.AuthLinkTitle>Auth Link</Styled.AuthLinkTitle>
      <Styled.TextArea readOnly rows={3} value={link} />
      <Styled.BaseButton onClick={copyLink} narrow={true}>
        {copyButtonText}
      </Styled.BaseButton>
      <Styled.BttnWrapper>
        <Styled.BaseButton onClick={onClose} narrow={true}>
          Cancel
        </Styled.BaseButton>
        <Styled.BaseButton onClick={continueOnboarding} narrow={true} disabled={!canContinueAdmin}>
          Continue
        </Styled.BaseButton>
      </Styled.BttnWrapper>
      {notAuthenticatedError && (
        <Styled.PlatformWarningMessage>
          Platform has not been authenticated.
        </Styled.PlatformWarningMessage>
      )}
      {platformExistsError && (
        <Styled.PlatformWarningMessage>
          Platform already exists for this location.
        </Styled.PlatformWarningMessage>
      )}
    </Styled.Container>
  )
}
export default AdminActivation
