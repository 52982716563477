import { chowlyApi } from 'config'

import client from '../chowly'

type EmbedUrlResponse = {
  url: string
}

export const getSigmaUrl = async (embed_path: string, partnerIds?: string) => {
  let params: object = { embed_path }

  // TODO: add validation rules for partnerIds
  if (partnerIds?.length) {
    params = {
      ...params,
      partner_ids: partnerIds,
    }
  }

  const { data } = await client.get<EmbedUrlResponse>(chowlyApi.sigmaUrl, {
    params,
  })
  return data.url
}
