import { chowlyApi } from 'config'
import { generatePath } from 'react-router-dom'
import isNil from 'lodash/isNil'

import client from '../chowly'
import { 
  Invoice,
  InvoiceResponse,
} from 'api/billing/types'

const formatData = (data: string) => {
  return data
    .replace(/nil/g, 'null')
    .replace(/=>/g, ':')
    .replace(/([{,])\s*"([^"]+)"/g, '$1"$2"')
    .replace(/:\s*([^",\s]+)/g, ': $1')
    .replace(/([{,])\s*([^"\s]+)(?=\s*:)/g, '$1"$2"')
    .replace(/:\s*"(\d+(\.\d+)?)"/g, ': $1')
    .trim()
}

export type InvoicesQueryParams = {
  partnerId: string | number | null | undefined
}

export const getInvoices = async (params: InvoicesQueryParams): Promise<Invoice[]> => {
  const url = generatePath(chowlyApi.billingInvoices)

  const { data: invoices } = await client.get<InvoiceResponse>(url, {
    params: {
      ...(isNil(params.partnerId) ? {} : { partner_id: encodeURIComponent(params.partnerId) }),
    },
    transformResponse: [(data) => {
      return JSON.parse(formatData(data));
    }],
  })

  return invoices?.data || []
}