import { useEffect, useState } from 'react'

import { getSigmaUrl } from '../api'

const useEmbedUrl = (embedPath: string, partnerIds?: string) => {
  const [embedUrl, setEmbedUrl] = useState('')

  useEffect(() => {
    // keep empty partnerIds if we need to implement a select dropdown for partners
    getSigmaUrl(embedPath, partnerIds).then(setEmbedUrl)
  }, [embedPath, partnerIds])

  return embedUrl
}

export default useEmbedUrl
