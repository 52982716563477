import { chowlyApi } from 'config'
import { generatePath } from 'react-router'

import client from '../chowly'
import { MenuMappingCustomizationResponse } from '../models'

type QueryParams = { include: 'customization_options' }

export const getMenuMappingCustomization = async (id: string, params?: QueryParams) => {
  const url = generatePath(chowlyApi.menuMappingCustomization, { id })
  const { data } = await client.get<MenuMappingCustomizationResponse>(url, { params })
  return data
}
