import Tippy from '@tippyjs/react'
import styled from 'styled-components'
import { colors, fontWeight } from 'theme'

export const AvailabilityTooltip = styled(Tippy)({
  backgroundColor: colors.blue['60'],
  borderRadius: 16,
  '.tippy-arrow': {
    color: colors.blue['60'],
    width: 16,
    height: 16,
  },
})

export const AvailabilityTooltipContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '1rem',
  padding: '1rem',
})

export const TextWrap = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
})

export const SectionTitle = styled.div({
  width: '100%',
  fontWeight: fontWeight.bold,
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  color: colors.primary.light,
})

export const DayOfWeek = styled.span({
  fontSize: '1rem',
  fontWeight: fontWeight.bold,
  color: colors.gray['900'],
  lineHeight: '1.1875rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

export const TimePeriod = styled.span({
  color: colors.primary.light,
  marginLeft: '0.5rem',
  fontSize: '1rem',
})
