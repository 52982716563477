import { ButtonHTMLAttributes, forwardRef } from 'react'

import { useDialogContext } from './DialogContext'

export const DialogClose = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
  function DialogClose({ children, ...props }, ref) {
    const { setOpen } = useDialogContext()
    return (
      <button
        type='button'
        {...props}
        ref={ref}
        onClick={(event) => {
          setOpen(false)
          props.onClick?.(event)
        }}
      >
        {children}
      </button>
    )
  },
)
