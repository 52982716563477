import styled from 'styled-components'
import { helpers } from 'theme'

export const Container = styled.div({
  position: 'fixed',
  zIndex: 20,
  ...helpers.mdHidden,
  marginLeft: '1rem',
})

export const Button = styled.button({
  padding: '1rem',
  ':focus': {
    outline: '2px solid transparent',
    outlineOffset: 2,
  },
})
