import styled from 'styled-components'
import { colors, fontWeight } from 'theme'

export const Container = styled.div({
  flex: 1,
})

export const Message = styled.div({
  fontSize: '1rem',
  lineHeight: '1.5rem',
})

export const Button = styled.button({
  marginTop: '1rem',
  border: 'none',
  backgroundColor: 'transparent',
  color: colors.blue['700'],
  fontWeight: fontWeight.semibold,
  display: 'flex',
  alignItems: 'center',
})
