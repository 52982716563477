import { Form as FormDefault } from 'formik'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { borderRadius, breakpoints, colors } from 'theme'

export const Container = styled.div({
  flexDirection: 'column',
  backgroundColor: colors.white,
  height: '500px',
  width: '95%',
  float: 'right',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '25px',
})

export const Title = styled.h2({
  color: colors.primary.dark,
  fontWeight: '700',
  fontSize: '2rem',
  lineHeight: '2.75rem',
  margin: 0,
  textAlign: 'center',
  paddingTop: 50,
  marginBottom: 15,
})

export const Button = styled.button({
  flexDirection: 'row',
  textAlign: 'center',
  padding: '13px 75px',
  fontSize: '16px',
  backgroundColor: colors.primary.light,
  borderRadius: borderRadius.DEFAULT,
  color: colors.white,
  marginTop: '25px',
  width: '200px',
  marginLeft: '30px',
})

export const Form = styled(FormDefault)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  paddingTop: '0.75rem',
  [`@media ${breakpoints.sm}`]: {
    paddingTop: '1rem',
  },
})

export const FieldsWrap = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
})

export const ButtonDiv = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
})

export const Subtitle = styled.h4({
  fontSize: '0.9rem',
  lineHeight: '2rem',
  fontWeight: 500,
  margin: 0,
  marginTop: '1rem',
})

export const SentMessage = styled.h4({
  fontSize: '0.9rem',
  lineHeight: '2rem',
  fontWeight: 400,
  margin: '10px 70px 10px 50px',
  marginTop: '1rem',
})

export const Header = styled.div({
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignSelf: 'center',
  margin: '30px 0px 20px 20px',
})

export const LoginLink = styled(Link)({
  color: colors.primary.DEFAULT,
  fontWeight: 600,
  fontSize: '1.2rem',
  display: 'flex',
  justifyContent: 'center',
  margin: '0 auto',
  width: '100px',
  border: '1px solid #00296b',
})

export const FormContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})
