import { Button } from 'components'
import { Dialog, DialogTrigger } from 'components/Dialog'

import * as Styled from './styles'

type Props = {
  title: string
  description: string
  isDisabled?: boolean
  isDanger?: boolean
  okText?: string
  cancelText?: string
  variant?: 'solid' | 'outline' | 'textonly'
  onOk?: () => void
  onCancel?: () => void
}

const ButtonWithConfirmationDialog = ({
  title,
  description,
  okText = 'OK',
  cancelText = 'Cancel',
  isDisabled,
  isDanger,
  variant,
  onOk,
  onCancel,
}: Props) => {
  return (
    <Dialog>
      <DialogTrigger asChild={true}>
        <Button
          danger={isDanger}
          variant={variant}
          narrow={true}
          nowrap={true}
          disabled={isDisabled}
          size='small'
        >
          {title}
        </Button>
      </DialogTrigger>
      <Styled.Content>
        <Styled.Heading>{title}</Styled.Heading>
        <Styled.Description>{description}</Styled.Description>
        <Styled.Footer>
          <Styled.OutlineButton onClick={onCancel}>{cancelText}</Styled.OutlineButton>
          <Styled.PrimaryButton $danger={isDanger} onClick={onOk}>
            {okText}
          </Styled.PrimaryButton>
        </Styled.Footer>
      </Styled.Content>
    </Dialog>
  )
}

export default ButtonWithConfirmationDialog
