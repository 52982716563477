// import search from 'assets/icons/search-blue.svg'

import { useIsMobile } from 'hooks'

import * as Styled from './styles'

type Props = {
  handleOnSearchChange: (
    event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLImageElement>,
  ) => void
  input: string
}

const MenuSearch = ({ handleOnSearchChange, input }: Props) => {
  const { isMobile } = useIsMobile()
  return (
    <Styled.InputSearchContainer>
      <Styled.Input
        placeholder={isMobile ? 'Search' : 'Search Menu'}
        type='search'
        onChange={(e) => handleOnSearchChange(e)}
        value={input}
        // src={search}
      ></Styled.Input>
    </Styled.InputSearchContainer>
  )
}

export default MenuSearch
