import {
  CustomizationGroupByCustomizationId,
  CustomizationOptionsByCustomizationId,
  FormattedMenuSectionInfo,
  ItemModifier,
  MenuMappingItemDetails,
  MenuMappingSubsection,
  TypeName,
} from 'api'
import { Toggle } from 'components'
import { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from 'react'
import { colors } from 'theme'
import { TabType } from 'types'

import { TABS } from './constants'
import MenuItemRow from './MenuItemRow'
import ModifierSubsection from './ModifierSubsection'
import * as Styled from './styles'
import { getRowText } from './util/formatMenuText'
import get86ingPreviewFilteredItemsModifiers from './util/get86ingPreviewFilteredItemsModifiers'
import getSearchFilteredItemsModifiers from './util/getSearchFilteredItemsModifiers'
import getTabFilteredItemsModifiers from './util/getTabFilteredItemsModifiers'

type Props = {
  tab: TabType
  subsection: FormattedMenuSectionInfo
  onItemToggle: (itemId: MenuMappingItemDetails['id']) => void
  checkedItemIds: MenuMappingItemDetails['id'][]
  checkedModifierIds: ItemModifier['id'][]
  onSubsectionClick: (subsectionId: MenuMappingSubsection['id']) => void
  checkedSubsectionIds: MenuMappingSubsection['id'][]
  isPreviewOnly: boolean
  itemsToSuspend: MenuMappingItemDetails['id'][]
  itemsToUnsuspend: MenuMappingItemDetails['id'][]
  itemModifiers?: CustomizationOptionsByCustomizationId
  onModifierClick: (modifierId: ItemModifier['id']) => void
  modifiersToSuspend: ItemModifier['id'][]
  groupModifiers: CustomizationGroupByCustomizationId | undefined
  modifiersToUnsuspend: ItemModifier['id'][]
  expandAllModifiers: boolean
  searchInput: string
  setSuspendUntilItem: Dispatch<
    SetStateAction<
      | {
          id: MenuMappingItemDetails['id'] | ItemModifier['id']
          type: TypeName.Item | TypeName.CustomizationOption
        }
      | undefined
    >
  >
  checkSuspendUntilStatus: (
    type: TypeName.Item | TypeName.CustomizationOption,
    id: MenuMappingItemDetails['id'] | ItemModifier['id'],
  ) => string
  isModifiersLoading: boolean
  menuStyle?: React.CSSProperties
  menuTextStyle?: React.CSSProperties
  menuItemStyle?: React.CSSProperties
  menuItemTextStyle?: React.CSSProperties
  setSelectedItemInModal: (item: FormattedMenuSectionInfo) => void
}

type ExpandModifiersMap = {
  [itemId: MenuMappingItemDetails['id']]: boolean
}

const MenuSubsection: FC<Props> = ({
  tab,
  subsection,
  onItemToggle,
  checkedItemIds,
  onSubsectionClick,
  checkedSubsectionIds,
  searchInput,
  isPreviewOnly,
  itemsToSuspend,
  itemsToUnsuspend,
  checkedModifierIds,
  onModifierClick,
  modifiersToSuspend,
  modifiersToUnsuspend,
  setSuspendUntilItem,
  checkSuspendUntilStatus,
  isModifiersLoading,
  menuStyle = {},
  expandAllModifiers,
  setSelectedItemInModal,
}) => {
  const { title } = subsection.attributes
  const { items } = subsection.children
  const isSectionSelected = checkedSubsectionIds.includes(subsection.id)

  const [expandModifiers, setExpandModifiers] = useState<ExpandModifiersMap>({})
  const [itemsToDisplay, setItemsToDisplay] = useState<FormattedMenuSectionInfo[]>([])
  const [groupsToDisplay, setGroupsToDisplay] = useState<{
    [id: string]: FormattedMenuSectionInfo[]
  }>({})
  const [modifiersToDisplay, setModifiersToDisplay] = useState<{
    [id: string]: FormattedMenuSectionInfo[]
  }>({})
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false)

  const [showHeader, setShowHeader] = useState(false)

  const updatedItemIds = [...itemsToSuspend, ...itemsToUnsuspend]
  const updatedModifierIds = [...modifiersToSuspend, ...modifiersToUnsuspend]

  // TAB FILTER
  useEffect(() => {
    let filteredItems = items || []
    let filteredGroups = {}
    let filteredModifiers = {}
    let filterApplied = false
    let filteredModifierIds: string[] = []

    // TAB FILTER
    if (tab !== TABS.ALL_PRODUCTS && filteredItems.length) {
      const filteredData = getTabFilteredItemsModifiers(
        filteredItems,
        tab,
        checkedItemIds,
        checkedModifierIds,
        filteredModifierIds,
      )

      if (filteredData) {
        const {
          filteredItems: tabFilteredItems,
          filteredModifierGroups: tabFilteredModifierGroups,
          filteredModifiers: tabFilteredModifiers,
        } = filteredData

        const filteredModifierIdsToInclude: string[] = []
        // save as list of ids to include in additional filters
        if (Object.keys(tabFilteredModifiers).length) {
          Object.values(tabFilteredModifiers).forEach((modifierGroups) =>
            modifierGroups.forEach((mod) => filteredModifierIdsToInclude.push(mod.id)),
          )
        }

        filteredItems = tabFilteredItems
        filteredGroups = tabFilteredModifierGroups
        filteredModifiers = tabFilteredModifiers
        filterApplied = true
        filteredModifierIds = filteredModifierIdsToInclude
      }
    }

    // SEARCH FILTER
    if (searchInput.length && filteredItems.length) {
      const searchFilteredData = getSearchFilteredItemsModifiers(
        searchInput,
        filteredItems,
        filteredModifierIds,
      )

      if (searchFilteredData) {
        const {
          displayOnlyItems,
          matchingItems,
          filteredModifierGroups: searchFilteredModifierGroups,
          filteredModifiers: searchFilteredModifiers,
        } = searchFilteredData
        const searchFilteredItems = [...displayOnlyItems, ...matchingItems]

        const filteredModifierIdsToInclude: string[] = []

        if (Object.keys(searchFilteredModifiers).length) {
          Object.values(searchFilteredModifiers).forEach((modifierGroups) =>
            modifierGroups.forEach((mod) => filteredModifierIdsToInclude.push(mod.id)),
          )
        }

        filteredItems = searchFilteredItems
        filteredGroups = searchFilteredModifierGroups
        filteredModifiers = searchFilteredModifiers
        filterApplied = true
        filteredModifierIds = filteredModifierIdsToInclude
      }
    }

    // 86-ING PREVIEW FILTER
    if (isPreviewOnly && filteredItems?.length) {
      const {
        filteredItems: previewFilteredItems,
        filteredModifierGroups: previewFilteredModifierGroups,
        filteredModifiers: previewFilteredModifiers,
      } = get86ingPreviewFilteredItemsModifiers(
        filteredItems,
        updatedItemIds,
        updatedModifierIds,
        filteredModifierIds,
      )

      const filteredModifierIdsToInclude: string[] = []

      if (Object.keys(previewFilteredModifiers).length) {
        Object.values(previewFilteredModifiers).forEach((modifierGroups) =>
          modifierGroups.forEach((mod) => filteredModifierIdsToInclude.push(mod.id)),
        )
      }

      filteredItems = previewFilteredItems
      filteredGroups = previewFilteredModifierGroups
      filteredModifiers = previewFilteredModifiers
      filterApplied = true
      filteredModifierIds = filteredModifierIdsToInclude
    }

    setItemsToDisplay(filteredItems)
    setGroupsToDisplay(filteredGroups)
    setModifiersToDisplay(filteredModifiers)
    setIsFilterApplied(filterApplied)
  }, [tab, searchInput, isPreviewOnly])

  useEffect(() => {
    if (itemsToDisplay && itemsToDisplay.length > 0 && tab === TABS.ALL_PRODUCTS) {
      setShowHeader(true)
    } else {
      setShowHeader(false)
    }
  }, [itemsToDisplay, tab])

  useEffect(() => {
    if (expandAllModifiers || tab !== TABS.ALL_PRODUCTS || searchInput.length || isPreviewOnly) {
      const allItemsWithChildrenIds = itemsToDisplay
        ?.filter((item) => item.children.customizations?.length)
        ?.map((item) => item.id)
      const allItemsMap: ExpandModifiersMap = {}
      allItemsWithChildrenIds?.forEach((id) => {
        allItemsMap[id] = true
      })

      setExpandModifiers(allItemsMap)
    } else {
      setExpandModifiers({})
    }
  }, [expandAllModifiers, tab, searchInput, isPreviewOnly])

  useEffect(() => {
    if (items) {
      setItemsToDisplay(items)
    }
  }, [items])

  const getPreviewSectionText = () => {
    const changedItemsCount =
      itemsToDisplay?.filter((item) => updatedItemIds.includes(item.id))?.length || 0

    let flatModifiers: FormattedMenuSectionInfo[] = []

    Object.values(modifiersToDisplay).map((modifiers) => {
      flatModifiers = [...flatModifiers, ...modifiers]
    })

    const changedModsCount = flatModifiers.filter((mod) =>
      updatedModifierIds.includes(mod.id),
    )?.length

    return getRowText(changedItemsCount, changedModsCount)
  }

  return (
    <Styled.SubsectionContainer key={subsection.id}>
      <>
        {showHeader && (
          <Styled.HeaderRow style={menuStyle}>
            <>
              {!isPreviewOnly && (
                <Styled.CheckboxContainer>
                  <Toggle
                    id={`section-toggle-${subsection.id}`}
                    isToggled={isSectionSelected}
                    onToggle={() => onSubsectionClick(subsection.id)}
                    activeColor={colors.blue[700]}
                  />
                </Styled.CheckboxContainer>
              )}
              <Styled.SectionTitle>{title}</Styled.SectionTitle>
            </>
            {isPreviewOnly ? (
              <Styled.HeaderText>{getPreviewSectionText()}</Styled.HeaderText>
            ) : null}
          </Styled.HeaderRow>
        )}

        {itemsToDisplay?.map((item, idx) => {
          const modifiersGroupToDisplay: FormattedMenuSectionInfo[] | undefined = isFilterApplied
            ? groupsToDisplay[item.id]
            : item.children.customizations

          return (
            <Fragment key={item.id}>
              <MenuItemRow
                key={item.id}
                item={item}
                itemIdx={idx}
                isSelected={checkedItemIds.includes(item.id)}
                onItemToggle={onItemToggle}
                isPreviewOnly={isPreviewOnly}
                setSuspendUntilItem={setSuspendUntilItem}
                existingSuspendUntil={checkSuspendUntilStatus(TypeName.Item, item.id)}
                isModifiersLoading={isModifiersLoading}
                modifiersExist={!!modifiersGroupToDisplay?.length}
                onRowExpand={() =>
                  setExpandModifiers((prevExpandStatus) => ({
                    ...prevExpandStatus,
                    [item.id]: !prevExpandStatus[item.id],
                  }))
                }
                isExpanded={expandModifiers[item.id]}
                setSelectedItemInModal={setSelectedItemInModal}
              />

              {/* Customization (modifier group) with its customization options (modifiers) */}
              {expandModifiers[item.id]
                ? modifiersGroupToDisplay?.map((modifierGroup) => {
                    const groupModifiers = isFilterApplied
                      ? modifiersToDisplay[modifierGroup.id]
                      : modifierGroup.children.customization_options

                    return (
                      <ModifierSubsection
                        key={modifierGroup.id}
                        subsection={modifierGroup}
                        isPreviewOnly={isPreviewOnly}
                        groupModifiers={groupModifiers}
                        checkedModifierIds={checkedModifierIds}
                        onModifierClick={onModifierClick}
                        setSuspendUntilItem={setSuspendUntilItem}
                        checkSuspendUntilStatus={checkSuspendUntilStatus}
                        expandAllModifiers={expandAllModifiers}
                      />
                    )
                  })
                : null}
            </Fragment>
          )
        })}
      </>
    </Styled.SubsectionContainer>
  )
}

MenuSubsection.displayName = 'MenuSubsection'

export default MenuSubsection
