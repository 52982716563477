import { FC } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

type Props = {
  lineCount?: number
  isFlex?: boolean
}

const LoadingSkeleton: FC<Props> = ({ lineCount = 1, isFlex = false }) => {
  return (
    <SkeletonTheme>
      <Skeleton
        count={lineCount}
        containerClassName={isFlex ? 'loading-skeleton-flex' : undefined}
      />
    </SkeletonTheme>
  )
}

export default LoadingSkeleton
