import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg'
import { ReactComponent as DragHandleIcon } from 'assets/icons/drag-handle.svg'
import { ReactComponent as ErrorIcon } from 'assets/icons/notification-error.svg'
import { ReactComponent as SuccessIcon } from 'assets/icons/notification-success.svg'
import { ReactComponent as WarningIcon } from 'assets/icons/notification-warn.svg'

import * as Styled from './styles'

export type NotificationType = 'primary' | 'loading' | 'success' | 'info' | 'error' | 'warning'

const getIconByType = (type?: NotificationType) => {
  switch (type) {
    case 'success':
      return <SuccessIcon />
    case 'error':
      return <ErrorIcon />
    case 'warning':
      return <WarningIcon />
    case 'loading':
      // use toastify spinner icon for matching with toast notification loading icon.
      return (
        <div className='Toastify__toast-icon'>
          <div className='Toastify__spinner'></div>
        </div>
      )
    default:
      return <DragHandleIcon />
  }
}

export type NotificationBannerProps = {
  type?: NotificationType
  message: string
  icon?: boolean
  buttonTitle?: string
  onButtonClick?: () => void
}

const NotificationBanner = ({
  type,
  message,
  icon,
  buttonTitle,
  onButtonClick,
}: NotificationBannerProps) => {
  return (
    <Styled.Container $type={type ?? 'primary'}>
      {icon && getIconByType(type)}
      <Styled.Content>
        <Styled.Message>{message}</Styled.Message>
        {buttonTitle && (
          <Styled.Button onClick={onButtonClick}>
            <div>{buttonTitle}</div>
            <ChevronRightIcon />
          </Styled.Button>
        )}
      </Styled.Content>
    </Styled.Container>
  )
}

export default NotificationBanner
