import { ReactComponent as ChowlyLogo } from 'assets/logo.svg'
import { Button } from 'components'
import { Form as FormDefault } from 'formik'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { breakpoints, colors, fontWeight } from 'theme'

export const Container = styled.div({
  display: 'grid',
  height: '100vh',
  gridTemplateColumns: '1fr',
  [`@media ${breakpoints.md}`]: {
    gridTemplateColumns: '1fr 1fr',
  },
})

export const VerifyEmailContainer = styled.div({
  padding: '5rem',
  backgroundColor: colors.gray[300],
  border: `1px solid ${colors.gray.dark}`,
  display: 'flex',
  justifyContent: 'center',
})

export const VerifyContainer = styled.div({
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  padding: '0rem 5rem',
})

export const SectionLeft = styled.section({
  display: 'none',
  [`@media ${breakpoints.md}`]: {
    display: 'block',
  },
})

export const SectionRight = styled.section({
  display: 'flex',
  justifyContent: 'center',
  padding: '2.5rem',
  overflow: 'auto',
})

export const ContentWrapper = styled.div({
  width: '100%',
  maxWidth: '35rem',
  height: '100%',
  display: 'grid',
  gridTemplateRows: '3rem 1fr',
})

export const Header = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignSelf: 'center',
})

export const VerifyHeader = styled.div({
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignSelf: 'center',
  margin: '30px 0px 20px 20px',
})

export const VerifyTitle = styled.h2({
  color: colors.primary.dark,
  fontWeight: fontWeight.bold,
  fontSize: '2rem',
  lineHeight: '2.75rem',
  textAlign: 'center',
  paddingTop: 50,
  marginBottom: 15,
  justifyContent: 'center',
})

export const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  justifySelf: 'center',
  width: '100%',
  paddingTop: '2.5rem',
  paddingBottom: '10rem',
  maxWidth: '35rem',
})

export const LogoContainer = styled.div({
  margin: '0 auto 5rem auto',
})

export const Logo = styled(ChowlyLogo)({
  display: 'block',
  [`@media ${breakpoints.md}`]: {
    display: 'none',
  },
})

export const SignUpLinkWrap = styled.div({
  width: '100%',
  textAlign: 'right',
  fontSize: '1rem',
  lineHeight: '1.5rem',
})

export const SignUpLink = styled(Link)({
  fontWeight: 700,
  color: colors.primary.light,
  '&:hover': {
    color: colors.primary.dark,
  },
})

export const AnchorSignUpLink = styled.a({
  fontWeight: 700,
  color: colors.primary.light,
  '&:hover': {
    color: colors.primary.dark,
  },
})

export const Title = styled.h1({
  fontSize: '2rem',
  lineHeight: '2.375rem',
  fontWeight: 700,
  color: colors.primary.light,
  margin: '0 auto 0 auto',
})

export const TitleTest = styled.h1({
  fontSize: '1.5rem',
  lineHeight: '2.375rem',
  fontWeight: 800,
  color: colors.blue['900'],
})

export const Description = styled.p<{ isCentered?: boolean }>(
  {
    margin: '0.5rem auto 0 auto',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  ({ isCentered }) => isCentered && { textAlign: 'center' },
)

export const Form = styled(FormDefault)({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '0.75rem',
  [`@media ${breakpoints.md}`]: {
    paddingTop: '2rem',
  },
})

export const FieldsWrap = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr',
  columnGap: 25,
  marginBottom: '1rem',
  [`@media ${breakpoints.lg}`]: {
    gridTemplateColumns: '1fr 1fr',
  },
})

export const Error = styled.p({
  padding: 10,
  color: colors.red.DEFAULT,
})

export const TextButton = styled.button({
  marginTop: '2rem',
  fontWeight: fontWeight.bold,
  lineHeight: '1.5rem',
  color: colors.primary.light,
})

export const VerifyButton = styled.button({
  background: colors.green.DEFAULT,
  color: colors.white,
  fontSize: '18px',
  padding: '0.5rem 3rem',
  borderRadius: '5px',
  border: 'none',
  marginTop: '40px',
})

export const OuterLink = styled.a({
  marginTop: '1rem',
  fontSize: '0.875rem',
  textAlign: 'left',
  color: colors.blue[700],
  fontWeight: fontWeight.bold,
  textDecoration: 'underline',
  '&:active,&:hover': {
    textDecoration: 'none',
  },
})

export const LoginButton = styled(Button)({ marginTop: '2rem' })
