import styled from 'styled-components'
import { breakpoints, colors, shadow } from 'theme'

export const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
  padding: '1.5rem',
  width: '100%',
})

export const Box = styled.div({
  padding: '5rem 10rem',
  borderRadius: '0.4rem',
  boxShadow: shadow.xl,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'white',
})

export const Title = styled.h1({
  marginTop: '2.5rem',
  fontSize: '1.5rem',
  lineHeight: '2rem',
  fontWeight: 700,
  [`@media ${breakpoints.md}`]: {
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
  },
})

export const Red = styled.span({
  color: colors.red.DEFAULT,
})

export const Description = styled.p({
  marginTop: '0.5rem',
  marginBottom: '2rem',
  color: colors.gray.dark,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  [`@media ${breakpoints.md}`]: {
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
  },
})
