import styled from 'styled-components'
import { breakpoints, colors, shadow } from 'theme'

export const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100vw',
  height: '100vh',
  backgroundImage: `linear-gradient(to right, ${colors.primary.dark}, ${colors.primary.light})`,
})

export const Box = styled.div({
  width: '90%',
  maxWidth: 640,
  height: 'auto',
  paddingTop: '5rem',
  paddingBottom: '5rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  boxShadow: shadow.xl,
  borderRadius: '0.375rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.white,
})

export const Title = styled.h1<{ variant?: 'success' | 'error' }>(
  {
    marginTop: '2.5rem',
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: 700,
    textAlign: 'center',
    color: colors.black,
    [`@media ${breakpoints.md}`]: {
      fontSize: '1.875rem',
      lineHeight: '2.25rem',
    },
  },
  ({ variant }) =>
    variant === 'success' && {
      color: colors.green.DEFAULT,
    },
  ({ variant }) =>
    variant === 'error' && {
      color: colors.red.DEFAULT,
    },
)

export const Description = styled.p({
  marginTop: '0.5rem',
  color: colors.gray.dark,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  [`@media ${breakpoints.md}`]: {
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
  },
})
