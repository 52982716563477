import SmartPricingImg from 'assets/icons/smart-pricing.svg'
import { Card } from 'components'


type Props = {
  onClose?: () => void
  isLoading?: boolean
}

const SmartPricing = ({ onClose, isLoading = false }: Props) => {
  return (
    <Card
      title='Chowly Smart Pricing'
      subTitle='You could be earning additional income per month with Chowly Smart Pricing*'
      description='With Chowly’s Smart Pricing solution, restaurants can maximize their revenue and profits with a right price at the right time model and keep their income stream consistent.'
      buttonText='Learn More'
      link={process.env.REACT_APP_CHILIPIPER_CALENDAR_LINK_QUEUE}
      imageUrl={SmartPricingImg}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default SmartPricing
