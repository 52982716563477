import { chowlyApi } from 'config'
import { generatePath } from 'react-router-dom'

import client from '../chowly'
import {
  OnboardingLocationCreateInput,
  OnboardingLocationResponse,
  OnboardingLocationUpdateInput,
  OnboardingLocationsResponse,
} from '../models'

export const createOnboardingLocation = async (payload: OnboardingLocationCreateInput) => {
  const { data } = await client.post<OnboardingLocationResponse>(
    chowlyApi.onboardingLocations,
    payload,
  )
  return data
}

export const updateOnboardingLocation = async (
  id: string,
  payload: OnboardingLocationUpdateInput,
) => {
  const url = generatePath(chowlyApi.onboardingLocation, { id })
  const { data } = await client.put<OnboardingLocationResponse>(url, payload)
  return data
}

export const updateCurrentStep = (onboardingLocationId: string, current_step: string) => {
  return updateOnboardingLocation(onboardingLocationId, { current_step })
}

export const resetOnboarding = async (id: string) => {
  const url = generatePath(chowlyApi.onboardingLocation, { id })
  const { data } = await client.delete<OnboardingLocationResponse>(url)
  return data
}

export const finalizeLocation = (onboardingLocationId: string, finalize: boolean) => {
  return updateOnboardingLocation(onboardingLocationId, { finalize })
}

export const completeLocation = (onboardingLocationId: string, completed: boolean) => {
  return updateOnboardingLocation(onboardingLocationId, { completed })
}

export const getAllOnboardingLocations = async () => {
  const { data } = await client.get<OnboardingLocationsResponse>(chowlyApi.onboardingLocations)
  return data
}

export const getOnboardingLocation = async (id: string) => {
  const url = generatePath(chowlyApi.onboardingLocation, { id })
  const { data } = await client.get<OnboardingLocationResponse>(url)
  return data
}

export const getMainOnboardingLocation = async () => {
  const results = await getAllOnboardingLocations()
  const allLocations = results.data.map(({ attributes }) => attributes)
  const completedLocation = allLocations.find(({ completed }) => completed)
  const firstLocation = allLocations[0]
  return completedLocation ?? firstLocation ?? null
}
