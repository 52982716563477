import { add } from 'date-fns'
import { toDate } from 'date-fns-tz'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import moment, { Moment } from 'moment'
import { DayOfWeekName } from 'types'

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)

export const weekdays = [
  DayOfWeekName.Monday,
  DayOfWeekName.Tuesday,
  DayOfWeekName.Wednesday,
  DayOfWeekName.Thursday,
  DayOfWeekName.Friday,
  DayOfWeekName.Saturday,
  DayOfWeekName.Sunday,
]

export const weekdaysMapping: Record<string, DayOfWeekName> = {
  MON: DayOfWeekName.Monday,
  TUE: DayOfWeekName.Tuesday,
  WED: DayOfWeekName.Wednesday,
  THU: DayOfWeekName.Thursday,
  FRI: DayOfWeekName.Friday,
  SAT: DayOfWeekName.Saturday,
  SUN: DayOfWeekName.Sunday,
}

export const dayIndex: Record<string, number> = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
}

export const getStartOfDay = () => dayjs().startOf('day')

export const halfHourTimePoints = Array.from({ length: 49 }, (_, i) => {
  const value = getStartOfDay()
    .add(i * 30, 'minute')
    .format('hh:mmA')
  return { value, label: value }
})

export const to24HourTimeFrom12Hour = (time: string) => dayjs(time, 'hh:mmA').format('HH:mm')
export const to12HourTimeFrom24Hour = (time: string) => dayjs(time, 'HH:mm').format('hh:mmA')

export const formatDate = (date: Date | string | number, format?: string) => {
  return dayjs(date).format(format)
}

export const minuteOptions = [
  { label: 'N/A', value: 'N/A' },
  { label: '0', value: '0' },
  { label: '5', value: '5' },
  { label: '15', value: '15' },
  { label: '30', value: '30' },
  { label: '45', value: '45' },
  { label: '60', value: '60' },
]

export const getMinuteOption = (v: number | null | undefined) => {
  const value = typeof v === 'number' ? String(v) : 'N/A'
  return minuteOptions.find((option) => option.value === value)
}

export const allTimezones = {
  'pacific/midway': 'Midway Island',
  'pacific/pago_pago': 'American Samoa',
  'pacific/honolulu': 'Hawaii',
  'america/juneau': 'Alaska',
  'america/los_angeles': 'Pacific Time (US & Canada)',
  'america/tijuana': 'Tijuana',
  'america/denver': 'Mountain Time (US & Canada)',
  'america/phoenix': 'Arizona',
  'america/chihuahua': 'Chihuahua',
  'america/mazatlan': 'Mazatlan',
  'america/chicago': 'Central Time (US & Canada)',
  'america/regina': 'Saskatchewan',
  'america/mexico_city': 'Mexico City',
  'america/monterrey': 'Monterrey',
  'america/guatemala': 'Central America',
  'america/new_york': 'Eastern Time (US & Canada)',
  'America/indiana/indianapolis': 'Indiana (East)',
  'america/bogota': 'Bogota',
  'america/lima': 'Lima',
  'america/halifax': 'Atlantic Time (Canada)',
  'america/caracas': 'Caracas',
  'america/la_paz': 'La Paz',
  'america/santiago': 'Santiago',
  'america/st_johns': 'Newfoundland',
  'america/sao_paulo': 'Brasilia',
  'America/argentina/buenos_aires': 'Buenos Aires',
  'america/montevideo': 'Montevideo',
  'america/guyana': 'Georgetown',
  'america/puerto_rico': 'Puerto Rico',
  'america/godthab': 'Greenland',
  'atlantic/south_georgia': 'Mid-Atlantic',
  'atlantic/azores': 'Azores',
  'atlantic/cape_verde': 'Cape Verde Is.',
  'europe/dublin': 'Dublin',
  'europe/lisbon': 'Lisbon',
  'europe/london': 'London',
  'africa/casablanca': 'Casablanca',
  'africa/monrovia': 'Monrovia',
  'etc/utc': 'UTC',
  'europe/belgrade': 'Belgrade',
  'europe/bratislava': 'Bratislava',
  'europe/budapest': 'Budapest',
  'europe/ljubljana': 'Ljubljana',
  'europe/prague': 'Prague',
  'europe/sarajevo': 'Sarajevo',
  'europe/skopje': 'Skopje',
  'europe/warsaw': 'Warsaw',
  'europe/zagreb': 'Zagreb',
  'europe/brussels': 'Brussels',
  'europe/copenhagen': 'Copenhagen',
  'europe/madrid': 'Madrid',
  'europe/paris': 'Paris',
  'europe/amsterdam': 'Amsterdam',
  'europe/berlin': 'Berlin',
  'europe/zurich': 'Zurich',
  'europe/rome': 'Rome',
  'europe/stockholm': 'Stockholm',
  'europe/vienna': 'Vienna',
  'africa/algiers': 'West Central Africa',
  'europe/bucharest': 'Bucharest',
  'africa/cairo': 'Cairo',
  'europe/helsinki': 'Helsinki',
  'europe/kiev': 'Kyiv',
  'europe/riga': 'Riga',
  'europe/sofia': 'Sofia',
  'europe/tallinn': 'Tallinn',
  'europe/vilnius': 'Vilnius',
  'europe/athens': 'Athens',
  'europe/istanbul': 'Istanbul',
  'europe/minsk': 'Minsk',
  'asia/jerusalem': 'Jerusalem',
  'africa/harare': 'Harare',
  'africa/johannesburg': 'Pretoria',
  'europe/kaliningrad': 'Kaliningrad',
  'europe/moscow': 'Moscow',
  'europe/volgograd': 'Volgograd',
  'europe/samara': 'Samara',
  'asia/kuwait': 'Kuwait',
  'asia/riyadh': 'Riyadh',
  'africa/nairobi': 'Nairobi',
  'asia/baghdad': 'Baghdad',
  'asia/tehran': 'Tehran',
  'asia/muscat': 'Abu Dhabi',
  'asia/baku': 'Baku',
  'asia/tbilisi': 'Tbilisi',
  'asia/yerevan': 'Yerevan',
  'asia/kabul': 'Kabul',
  'asia/yekaterinburg': 'Ekaterinburg',
  'asia/karachi': 'Karachi',
  'asia/tashkent': 'Tashkent',
  'asia/kolkata': 'Kolkata',
  'asia/kathmandu': 'Kathmandu',
  'asia/dhaka': 'Dhaka',
  'asia/colombo': 'Sri Jayawardenepura',
  'asia/almaty': 'Almaty',
  'asia/novosibirsk': 'Novosibirsk',
  'asia/rangoon': 'Rangoon',
  'asia/bangkok': 'Bangkok',
  'asia/jakarta': 'Jakarta',
  'asia/krasnoyarsk': 'Krasnoyarsk',
  'asia/shanghai': 'Beijing',
  'asia/chongqing': 'Chongqing',
  'asia/hong_kong': 'Hong Kong',
  'asia/urumqi': 'Urumqi',
  'asia/kuala_lumpur': 'Kuala Lumpur',
  'asia/singapore': 'Singapore',
  'asia/taipei': 'Taipei',
  'australia/perth': 'Perth',
  'asia/irkutsk': 'Irkutsk',
  'asia/ulaanbaatar': 'Ulaanbaatar',
  'asia/seoul': 'Seoul',
  'asia/tokyo': 'Tokyo',
  'asia/yakutsk': 'Yakutsk',
  'australia/darwin': 'Darwin',
  'australia/adelaide': 'Adelaide',
  'australia/melbourne': 'Melbourne',
  'australia/sydney': 'Sydney',
  'australia/brisbane': 'Brisbane',
  'australia/hobart': 'Hobart',
  'asia/vladivostok': 'Vladivostok',
  'pacific/guam': 'Guam',
  'pacific/port_moresby': 'Port Moresby',
  'asia/magadan': 'Magadan',
  'asia/srednekolymsk': 'Srednekolymsk',
  'pacific/guadalcanal': 'Solomon Is.',
  'pacific/noumea': 'New Caledonia',
  'pacific/fiji': 'Fiji',
  'asia/kamchatka': 'Kamchatka',
  'pacific/majuro': 'Marshall Is.',
  'pacific/auckland': 'Auckland',
  'pacific/tongatapu': "Nuku'alofa",
  'pacific/fakaofo': 'Tokelau Is.',
  'pacific/chatham': 'Chatham Is.',
  'pacific/apia': 'Samoa',
}

export const getTzValueByKey = (tzValue: string) => {
  const idxOfEntry = Object.values(allTimezones).indexOf(tzValue)

  if (idxOfEntry !== -1) {
    return Object.keys(allTimezones)[idxOfEntry]
  }

  return null
}

export const getDateStr = (localDateTime: Date) => {
  // parse out year, month, date, time from client local date
  const year = localDateTime.getFullYear().toString()
  const month = (localDateTime.getMonth() + 1).toString() // starts from 0
  const date = localDateTime.getDate().toString()
  const hour = localDateTime.getHours().toString()
  const minutes = localDateTime.getMinutes().toString()

  const dateString = `${year}-${month.length > 1 ? month : `0${month}`}-${
    date.length > 1 ? date : `0${date}`
  }T${hour.length > 1 ? hour : `0${hour}`}:${minutes.length > 1 ? minutes : `0${minutes}`}:00`

  return dateString
}

// client's are seeing their browser timezone, but location timezone may be different. Make sure we always use the location's timezone.
export const convertClientToPartnerTimezone = (
  localDateString: string,
  partnerTimeZone: string,
) => {
  // converts to client timezone with the correctly set time.
  const dateInLocalTimezone = toDate(localDateString, {
    timeZone: partnerTimeZone,
  })

  return dateInLocalTimezone
}

// converts local date to UTC before passing to the BE
export const convertToUtc = (localDate: Date) => localDate.toISOString()

export const convertUTCToPartnerTimezone = (utcDate: string | Date, partnerTimezone: string) => {
  const date = new Date(utcDate)
  const dateInPartnerTimezone = date.toLocaleString('en-US', { timeZone: partnerTimezone })

  // display in partner's timezone, not client browser's
  return dateInPartnerTimezone
}

// currently the default suspend until time is 86-ing an item to midnight of the next day.
export const getSuspendUntilDefaultTime = (partnerTimezone: string) => {
  const localDateStr = getDateStr(new Date(new Date().setHours(0, 0, 0)))
  const partnerTzDate = convertClientToPartnerTimezone(localDateStr, partnerTimezone)
  // set to next day at midnight of the location time.
  const partnerTzNextDay = add(partnerTzDate, { days: 1 })
  const nextDayUtc = convertToUtc(partnerTzNextDay)

  return nextDayUtc
}

// Note: Chowly api stores dates in Central Time, so that will never match to a UTC string
// We convert both date strings to epoch value to compare
export const isDateEqual = (dateStr1: string | null, dateStr2: string | null) => {
  if (dateStr1 && dateStr2) {
    return new Date(dateStr1).valueOf() !== new Date(dateStr2).valueOf()
  }
  return false
}

export const momentFormat = 'HH:mm'
export const momentSecondsFormat = 'HH:mm:ss'

export const DAYS_OF_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const convertTimePeriodToMoment = (time?: string): Moment | undefined => {
  if (!time) {
    return
  }

  const timeArr = time?.split(':')
  const hours = parseInt(timeArr[0])
  const minutes = parseInt(timeArr[1])

  const momentTime = moment().hour(hours).minute(minutes)

  return momentTime
}

export const convertMomentToStringTimePeriod = (momentTime?: Moment): string | undefined => {
  if (!momentTime) {
    return
  }
  return moment(momentTime).format('HH:mm')
}
