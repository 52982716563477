import x_close from 'assets/icons/x-close-blue.svg'
import React from 'react'
import { SkeletonLoader } from 'components'

import {
  CardContainer,
  Content,
  Description,
  Icon,
  StyledButton,
  StyledImage,
  SubTitle,
  Title,
} from './styles'

interface CardProps {
  title?: string
  subTitle: string
  description: string
  buttonText?: string
  link?: string
  imageUrl?: string
  isLoading?: boolean
  onClose?: () => void
}

const Card: React.FC<CardProps> = ({
  title,
  subTitle,
  description,
  buttonText,
  link,
  imageUrl,
  isLoading = false,
  onClose,
}) => {
  return (
    <CardContainer>
      {onClose && <Icon src={x_close} iconSize='20' alt='closeIcon' onClick={onClose} />}
      <Content>
        {title && <Title>{title}</Title>}
        <SubTitle>{subTitle}</SubTitle>
        {isLoading ? (
          <SkeletonLoader />
        ) : (
          <Description>{description}</Description>
        )}
        {buttonText && link && <StyledButton href={isLoading ? undefined : link} disabled={isLoading}>{buttonText}</StyledButton>}
      </Content>
      {imageUrl && !isLoading && <StyledImage src={imageUrl} alt='card-image' />}
    </CardContainer>
  )
}

export default Card
