import { CSSProperties } from 'react'
import styled, { css } from 'styled-components'
import { borderRadius, colors, fontWeight, typography } from 'theme'

type ButtonProps = { size?: string; text?: string }

const modalButtonStyle = css({
  display: 'inline-flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
  height: 39,
  fontSize: '1rem',
  fontWeight: 700,
  borderRadius: borderRadius.lg,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: colors.primary.light,
  ':disabled': {
    backgroundColor: colors.gray[300],
    borderColor: colors.gray[300],
    color: colors.gray.dark,
    img: {
      color: colors.gray.dark,
    },
  },
})

export const WarningDiv = styled.div({
  position: 'absolute',
  width: '100%',
  margin: '0 auto',
  textAlign: 'center',
  backgroundColor: colors.white,
})

export const WarningMessage = styled.div({
  width: '100%',
  color: 'red',
  fontSize: '0.9rem',
  margin: '0 auto',
})

export const SubmitButton = styled.button<{ textTransform?: CSSProperties['textTransform'] }>(
  {
    color: colors.gray[450],
    backgroundColor: colors.blue[700],
    fontWeight: fontWeight.semibold,
    fontSize: typography.semiXS.fontSize,
  },
  modalButtonStyle,
  ({ textTransform }) => ({ textTransform }),
)

export const BackButton = styled.button<ButtonProps>(
  {
    backgroundColor: colors.white,
    color: colors.primary.light,
  },
  modalButtonStyle,
  ({ size }) =>
    size === 'small' && {
      height: 40,
    },
  ({ text }) =>
    (text === 'Pause' || text === 'Resume') && {
      borderRadius: borderRadius.xxxl,
      width: 100,
      fontWeight: fontWeight.medium,
      color: text === 'Resume' ? colors.red.DEFAULT : colors.primary.light,
    },
)

export const SmallYesButton = styled.button<ButtonProps>({
  marginTop: '10px',
  backgroundColor: colors.primary.light,
  color: colors.white,
  height: 40,
  width: 100,
  borderRadius: borderRadius.xxxl,
  fontWeight: fontWeight.medium,
  marginLeft: '20px',
})

export const SmallNoButton = styled.button<ButtonProps>({
  backgroundColor: colors.white,
  color: colors.primary.light,
  border: '1px solid #003f88',
  borderRadius: borderRadius.xxxl,
  height: 40,
  width: 100,
  fontWeight: fontWeight.medium,
  marginLeft: '20px',
})

export const Footer = styled.div({
  position: 'absolute',
  width: '100%',
  bottom: 0,
  left: '0.5rem',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  columnGap: '1.5rem',
  paddingTop: '1rem',
})

export const IconContainer = styled.button<ButtonProps>(
  {
    margin: '.3rem',
    img: {
      height: 25,
      width: 25,
    },
  },
  {
    ':disabled': {
      backgroundColor: colors.gray[300],
      borderColor: colors.gray[300],
      color: colors.gray.dark,
      img: {
        color: colors.gray.dark,
      },
    },
  },
)

export const Icon = styled.img({
  width: '6px',
  height: '6px',
})
