import { User } from '@auth0/auth0-spa-js'
import { FeatureFlag } from 'types/react-feature-flags/FeatureFlags'

import { AuthState } from './auth-state'

type Action =
  | { type: 'LOGIN_POPUP_STARTED' }
  | {
      type:
        | 'INITIALISED'
        | 'LOGIN_POPUP_COMPLETE'
        | 'GET_ACCESS_TOKEN_COMPLETE'
        | 'HANDLE_REDIRECT_COMPLETE'
      user?: User
      accessToken?: string
      authorizedFeatures?: FeatureFlag[]
    }
  | { type: 'LOGOUT' }
  | { type: 'ERROR'; error: Error }

/**
 * Handles how that state changes in the `useAuth0` hook.
 */
export const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'LOGIN_POPUP_STARTED':
      return {
        ...state,
        isLoading: true,
      }
    case 'LOGIN_POPUP_COMPLETE':
    case 'INITIALISED':
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user,
        authorizedFeatures: action.authorizedFeatures,
        accessToken: action.accessToken,
        isLoading: false,
        error: undefined,
      }
    case 'HANDLE_REDIRECT_COMPLETE':
    case 'GET_ACCESS_TOKEN_COMPLETE':
      if (state.user === action.user) {
        return state
      }
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user,
        accessToken: action.accessToken,
      }
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: undefined,
        accessToken: undefined,
        authorizedFeatures: undefined,
      }
    case 'ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      }
  }
}
