import styled from 'styled-components'

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const Row = styled.div({
  display: 'flex',
  alignItems: 'center',
  columnGap: '1rem',
})

export const IconButton = styled.button({
  padding: 2,
  width: 38,
  height: 38,
})
