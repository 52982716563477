import { LoadingScreen } from 'components'
import { useAuth0 } from 'libs/auth0-react'
import { FC, ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

type Props = {
  children?: ReactNode
}
const PrivateRoute: FC<Props> = ({ children }) => {
  const location = useLocation()
  const { isAuthenticated, error, isLoading, accessToken } = useAuth0()

  if (error) {
    toast.error(error.message)
  }

  if (isLoading) {
    return <LoadingScreen title='Loading...' />
  }
  if (!accessToken || !isAuthenticated) {
    return <Navigate to='/login' replace state={{ from: location }} />
  }
  return <>{children}</>
}

export default PrivateRoute
