import styled, { css } from 'styled-components'
import { borderRadius, colors, fontWeight, shadow, transition } from 'theme'

const outlineStyle = css({
  backgroundColor: colors.white,
  color: colors.primary.DEFAULT,
  borderColor: colors.primary.light,
  borderWidth: 1,
  '&:hover': {
    backgroundColor: colors.blue[100],
  },
})

const textOnlyStyle = css({
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  boxShadow: 'unset',
  color: colors.blue[700],
  '&:hover': {
    color: colors.white,
    backgroundColor: colors.blue[700],
  },
})

const compactStyle = css({
  fontSize: '0.875rem',
  lineHeight: 1.2,
  padding: '0.75rem',
})

const mediumSizeStyle = css({
  fontSize: '0.875rem',
  lineHeight: 2.2,
  width: '35%',
})

export const Button = styled.button<{
  $variant?: 'solid' | 'outline' | 'textonly'
  $size?: 'small' | 'medium' | 'large'
  $nowrap?: boolean
  $narrow?: boolean
  $danger?: boolean
}>(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: borderRadius.lg,
    color: colors.white,
    backgroundColor: colors.blue[700],
    boxShadow: shadow.DEFAULT,
    fontWeight: fontWeight.semibold,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    padding: '0.75rem 1.5rem',
    columnGap: '0.75rem',
    letterSpacing: '0.05rem',
    minWidth: '10rem',
    height: '39px',
    ...transition,
    '&:hover': {
      backgroundColor: colors.primary.dark,
    },
    ':disabled': {
      backgroundColor: colors.gray.DEFAULT,
      border: 'unset',
      color: colors.gray.mediumLight,
    },
  },
  ({ $variant }) =>
    $variant === 'outline' ? outlineStyle : $variant === 'textonly' ? textOnlyStyle : null,
  ({ $size }) => ($size === 'small' ? compactStyle : $size === 'medium' ? mediumSizeStyle : null),
  ({ $nowrap }) => $nowrap && { whiteSpace: 'nowrap' },
  ({ $narrow }) => $narrow && { padding: '0.5rem 1rem', minWidth: 'fit-content' },
  ({ $danger }) =>
    $danger && {
      color: colors.red[500],
      '&:hover': {
        backgroundColor: colors.red.DEFAULT,
      },
    },
)
