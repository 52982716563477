import { FC, ReactNode } from 'react'

import * as Styled from './styles'

type Props = {
  isOpen: boolean
  children?: ReactNode
  cardProps?: object
}

const Modal: FC<Props> = ({ isOpen, children, cardProps }) => {
  return isOpen ? (
    <Styled.Overlay>
      <Styled.Card {...cardProps}>{children}</Styled.Card>
    </Styled.Overlay>
  ) : (
    <></>
  )
}

export default Modal
