import {
  OrderPlatformErrorResponse,
  deleteOrderPlatform,
  initiateDoorDashOnboarding,
  requestPlatformPublish,
  updateOrderPlatform,
} from 'api'
import { isAxiosError } from 'axios'
import { ButtonWithConfirmationDialog } from 'components'
import { useActionProgressListener, useGetLocationPartners, useGetToosPartnerLocation } from 'hooks'
import { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { menuPlatformsState, platformDrawerState } from 'state/atoms'
import { ActionStatus, PlatformName } from 'types'

import * as Styled from './styles'

type Props = {
  resetEnablePlatform: () => void
  platformName: string
}

const AddToosPlatform: FC<Props> = ({ resetEnablePlatform, platformName }) => {
  const { platforms, refetch } = useGetLocationPartners()
  const [menuPlatforms, setMenuPlatforms] = useRecoilState(menuPlatformsState)
  const { locations, orderPlatformId, partnerId } = useGetToosPartnerLocation()
  const setPlatformDrawerState = useSetRecoilState(platformDrawerState)
  const [checked, setChecked] = useState<boolean>(false)
  const [locationId, setLocationId] = useState('')
  const [integrationCompleted, setIntegrationCompleted] = useState(false)
  const { isOpen } = useRecoilValue(platformDrawerState)
  useEffect(() => {
    setMenuPlatforms(platforms)
  }, [platforms])

  // even though we import as part of publishing, but import isn't our end target
  // we need to wait for the publish status
  // so we should set the actionType for publish before stopping the progress bar
  const { runProgressListener, progress, status } = useActionProgressListener({
    actionType: 'publish',
    partnerId: partnerId as number,
    platformName: platformName,
  })
  const closeDrawer = () => {
    setPlatformDrawerState({ isOpen: !isOpen })
    setLocationId('')
    setChecked(false)
    resetEnablePlatform()
    refetch()
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setLocationId(value)
    setChecked(true)
  }

  const handleSync = async () => {
    try {
      if (partnerId) {
        const isDoordash = platformName === PlatformName.DOORDASH
        const pushMenu = !isDoordash
        if (pushMenu && orderPlatformId) {
          await requestPlatformPublish(orderPlatformId)
        }
        if (isDoordash) {
          toast.success(
            'Menu Sync requested successfully! This process can take up to an hour to complete',
          )
          setIntegrationCompleted(true)
        } else {
          runProgressListener()
        }
      }
    } catch (error) {
      if (isAxiosError(error)) {
        toast.error(
          `Publish action didn’t start due to ${
            error.response?.data ? JSON.stringify(error.response?.data) : error.message
          }`,
        )
      } else {
        toast.error(`Unknown error: ${String(error)}`)
      }
    }
  }

  const handleSubmit = async () => {
    if (!orderPlatformId) return
    try {
      await updateOrderPlatform(Number(orderPlatformId), locationId)
      if (platformName === PlatformName.DOORDASH) {
        await initiateDoorDashOnboarding(Number(orderPlatformId))
      }
      handleSync()
    } catch (error) {
      if (isAxiosError<OrderPlatformErrorResponse>(error)) {
        const allErrors = (error.response?.data.errors ?? []).map(({ detail }) => detail)
        toast.error('Unable to set location')
        allErrors.forEach((item) => toast.error(item))
      } else {
        toast.error(`Unknown error: ${String(error)}`)
      }
    }
  }

  const handleCancelIntegration = async () => {
    if (!orderPlatformId) return
    try {
      await deleteOrderPlatform(Number(orderPlatformId))
      const removedPlatform = menuPlatforms?.filter(
        (platform) => platform.id !== Number(orderPlatformId),
      )
      setMenuPlatforms(removedPlatform)
      closeDrawer()
    } catch (error) {
      if (isAxiosError<OrderPlatformErrorResponse>(error)) {
        const allErrors = (error.response?.data.errors ?? []).map(({ detail }) => detail)
        toast.error('Unable to delete orderplatform')
        allErrors.forEach((item) => toast.error(item))
      } else {
        toast.error(`Unknown error: ${String(error)}`)
      }
    }
  }

  return (
    <div>
      <Styled.ContainerWithBottomActions>
        <Styled.PlatformSection>
          <Styled.SectionColumn>
            <Styled.SectionDataFields>
              {locations?.map((location, locationIndex) => (
                <Styled.LocationRadioLabel key={locationIndex}>
                  <Styled.LocationRadioInput
                    name='pos_location'
                    value={location['id']}
                    type='radio'
                    disabled={!location['is_eligible_to_onboard']}
                    data-testid='radio-select'
                    onChange={(event) => handleSelectChange(event)}
                  />
                  {location.name} - {location.address}
                </Styled.LocationRadioLabel>
              ))}
            </Styled.SectionDataFields>
          </Styled.SectionColumn>
        </Styled.PlatformSection>
        {status !== ActionStatus.NotStarted && (
          <Styled.ProgressContainer>
            <Styled.Progress id='sync' data-testid='syncProgress' value={progress} max={100} />
            <Styled.ProgressBarLabel htmlFor='sync'>
              Publishing Categories : {progress}%
            </Styled.ProgressBarLabel>
          </Styled.ProgressContainer>
        )}
        <Styled.StatusText
          hasError={status === ActionStatus.Failed}
          success={status === ActionStatus.Complete}
        >
          {status !== ActionStatus.NotStarted && status !== ActionStatus.Started && status}
          {status === ActionStatus.Failed && (
            <Styled.RetryButton onClick={handleSync}>Retry</Styled.RetryButton>
          )}
        </Styled.StatusText>
      </Styled.ContainerWithBottomActions>
      <Styled.DrawerFooter>
        <Styled.ButtonSection>
          {status !== ActionStatus.Complete && (
            <ButtonWithConfirmationDialog
              title='Cancel'
              description={`Progress you’ve made so far will not be saved. You can restart the integration at any time.`}
              okText='Yes cancel'
              variant='textonly'
              isDanger={true}
              onOk={handleCancelIntegration}
              cancelText='Do not cancel'
            />
          )}
          {platformName === PlatformName.DOORDASH ? (
            integrationCompleted ? (
              <Styled.BaseButton onClick={closeDrawer} data-testid='completeBttn' variant='solid'>
                Exit to Location
              </Styled.BaseButton>
            ) : (
              <Styled.BaseButton
                disabled={!checked}
                data-testid='submitBttn'
                onClick={handleSubmit}
                variant='solid'
              >
                Continue
              </Styled.BaseButton>
            )
          ) : status === ActionStatus.Failed ||
            status === ActionStatus.Complete ||
            status === ActionStatus.Started ? (
            <Styled.BaseButton data-testid='completeBttn' onClick={closeDrawer} variant='solid'>
              Exit to Location
            </Styled.BaseButton>
          ) : (
            <Styled.BaseButton
              disabled={!checked}
              data-testid='submitBttn'
              onClick={handleSubmit}
              variant='solid'
            >
              Continue
            </Styled.BaseButton>
          )}
        </Styled.ButtonSection>
        {locationId.length > 0 && (
          <Styled.PlatformWarningContainer>
            By clicking "Continue", I authorize Chowly to publish my menu to this location and send
            an authorization request to {platformName} to activate the integration specific to this
            location. This process can take up to an hour to complete.
          </Styled.PlatformWarningContainer>
        )}
      </Styled.DrawerFooter>
    </div>
  )
}

export default AddToosPlatform
