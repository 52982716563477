import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { borderRadius, colors, fontWeight } from 'theme'

export const Container = styled.div({
  flexDirection: 'column',
  backgroundColor: colors.white,
  height: '500px',
  width: '100%',
  float: 'right',
  alignItems: 'center',
  paddingLeft: '50px',
})

export const Title = styled.h2({
  color: colors.primary.dark,
  fontWeight: fontWeight.bold,
  fontSize: '2rem',
  lineHeight: '2.75rem',
  margin: '0 0 15 0',
  textAlign: 'center',
  paddingTop: 50,
})

export const Button = styled.button({
  flexDirection: 'row',
  textAlign: 'center',
  padding: '13px 75px',
  fontSize: '16px',
  backgroundColor: colors.primary.light,
  borderRadius: borderRadius.DEFAULT,
  color: colors.white,
  margin: '25px 0px 0px 30px',
  width: '200px',
})

export const Subtitle = styled.h4({
  fontSize: '0.9rem',
  lineHeight: '2rem',
  fontWeight: fontWeight.medium,
  margin: '1rem 0 0 0',
})

export const Header = styled.div({
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignSelf: 'center',
  margin: '30px 0px 20px 20px',
})

export const LoginLink = styled(Link)({
  color: colors.primary.DEFAULT,
  fontWeight: fontWeight.semibold,
  fontSize: '1.2rem',
  display: 'flex',
  justifyContent: 'center',
  margin: '0 auto',
  width: '100px',
  border: '1px solid #00296b',
})
