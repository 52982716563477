import * as Styled from './styles'

const Toggle = ({ id, isToggled, onToggle, activeColor = '' }) => (
  <Styled.Wrapper htmlFor={`toggle-${id}`}>
    <Styled.Checkbox
      id={`toggle-${id}`}
      checked={isToggled}
      onChange={(e) => {
        e.stopPropagation()
        onToggle(e.target.checked)
      }}
    />
    <Styled.Track isToggled={isToggled} activeColor={activeColor} />
    <Styled.Knob isToggled={isToggled} />
  </Styled.Wrapper>
)

export default Toggle
