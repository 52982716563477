import { LoadingSkeleton } from "components";
import ButtonWithConfirmationDialog from "components/ButtonWithConfirmationDialog/ButtonWithConfirmationDialog";
import { FormValueTypes } from "types";

import MenuImportRow from "./MenuImportRow";
import * as Styled from "./style";
import { useGetMenuImportStatuses } from "../../hooks";

type Props = {
  resyncSumbit: () => void
  upCharge: number | string
  onSubmit: (value: FormValueTypes) => Promise<void>
  locationId: number
}

const MenuImport = ({ resyncSumbit,  locationId  }: Props) => {
  const [{ menuImportStatuses, isLoading }] = useGetMenuImportStatuses(
    locationId?.toString(),
    4,
  )
  return (
    <Styled.MenuImportContainer>
      <Styled.Overlay />
      <Styled.ButtonContainer>
        <ButtonWithConfirmationDialog
          title='Resync'
          description={`We’re retrieving your latest POS menu(s) and updating your ordering platforms. While this action is immediate, depending on the size of your menu(s), it may take up to an hour for your menus to publish across all platforms.`}
          okText='Resync Menus'
          isDisabled={!menuImportStatuses || isLoading}
          isDanger={false}
          onOk={resyncSumbit}
        />
      </Styled.ButtonContainer>
      <Styled.MenuImportHeader>
        <Styled.Title>Menu Import Status</Styled.Title>
      </Styled.MenuImportHeader>
      {isLoading ? (
        <LoadingSkeleton isFlex lineCount={5} />
      ) : (
        menuImportStatuses?.map((item) => <MenuImportRow key={item.id} item={item} />)
      )}
    </Styled.MenuImportContainer>
  )
}

export default MenuImport
