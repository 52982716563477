import { isAxiosError } from 'axios'
import { toast } from 'react-toastify'

export const showError = (error: unknown) => {
  console.error(error)
  let errorMessage = String(error)
  if (isAxiosError(error)) {
    const errors = error.response?.data?.errors
    // error serializer from Chowly BE api returns errors as the key and array of objects as the value
    if (errors && Array.isArray(errors)) {
      const messageKey = errors[0].message ? 'message' : 'detail'
      const errorMessages = errors
        .filter((obj) => typeof obj[messageKey] === 'string')
        .map((obj) => obj[messageKey])

      errorMessage = errorMessages.length > 0 ? errorMessages.join(' ') : error.message
    } else {
      errorMessage = error.response?.data ? JSON.stringify(error.response?.data) : error.message
    }
  } else if (error instanceof Error) {
    errorMessage = error.message
  }

  toast.error(
    `Please contact Chowly Support using the “C” icon in the lower right corner of your screen. Error: ${errorMessage}`,
    { position: 'top-center' },
  )
}

const height = 680
const width = 680
const left = window.screenX + (window.outerWidth - width) / 2
const top = window.screenY + (window.outerHeight - height) / 2.5

export const toosPopupWindowDimensions = { height, width, top, left }
