import styled, { CSSObject } from 'styled-components'

export const BillingContainer = styled.div({
  width: '80%',
  margin: 'auto',
  padding: 20,
  ['@media (max-width: 610px)']: {
    width: '100%',
    padding: "20px 0",
  },
})

export const LocationsContainer = styled.div({
  width: '100%',
  marginBottom: 25,
})

export const selectContainer: CSSObject = {
  height: 24,
  fontSize: 10,
}

export const selectControl: CSSObject = {
  height: 24,
  width: 200,
  maxWidth: 200,
  minHeight: 30,
  background: '#FEFEFE',
}

export const selectMenu: CSSObject = {
  width: '100%',
}

export const selectMenuList: CSSObject = {
  paddingTop: 0,
  paddingBottom: 0,
}

export const selectSingleValue: CSSObject = {
  fontSize: 10,
  fontWeight: 400,
  color: '#484848',
  lineHeight: '12px',
}

export const selectPlaceholder: CSSObject = {
  fontSize: 10,
  fontWeight: 400,
  lineHeight: '12px',
}

export const selectOption: CSSObject = {
  fontSize: 10,
  fontWeight: 400,
}

export const InvoiceContainer = styled.div({
  width: '100%',
  margin: 'auto',
  padding: '20px 15px',
  background: '#FEFEFE',
})

export const BillingTitle = styled.h1({
  fontSize: 20,
  fontWeight: 600,
  marginBottom: 20,
  color: '#292929',
})

export const InvoiceTitle = styled.h2({
  fontSize: 16,
  fontWeight: 600,
  marginLeft: 6,
  marginBottom: 10,
  color: '#292929',
  [`@media (max-width: 440px)`]: {
    marginLeft: 2,
    fontSize: 14,
  },
})

export const InvoiceTable = styled.table({
  width: '100%',
  borderCollapse: 'collapse',
  textAlign: 'left',
})

export const InvoiceTableHeaderCell = styled.th({
  padding: 12,
  fontSize: 12,
  fontWeight: 600,
  color: '#777',
  textAlign: 'left',
  borderBottom: '1px solid #ddd',
  [`@media (max-width: 440px)`]: {
    padding: '8px',
    fontSize: 10,
  },
  '&:last-child': {
    textAlign: 'right',
    width: '40px',
  },
})

export const InvoiceTableCell = styled.td({
  padding: 12,
  fontSize: 12,
  fontWeight: 500,
  color: '#292929',
  borderBottom: '1px solid #ddd',
  [`@media (max-width: 440px)`]: {
    padding: '8px',
    fontSize: 10,
  },
  '&:last-child': {
    textAlign: 'right',
    width: '40px',
  },
})

export const PaidStatus = styled.span({
  backgroundColor: '#EAF4EE',
  color: '#32965D',
  padding: '5px 10px',
  borderRadius: 5,
  fontWeight: 500,
  fontSize: 12,
  display: 'inline-block',
  [`@media (max-width: 440px)`]: {
    fontSize: 10,
  },
})

export const CurrentStatus = styled.span({
  backgroundColor: '#F4F4F4',
  color: '#777',
  padding: '5px 10px',
  borderRadius: 5,
  fontWeight: 500,
  fontSize: 12,
  display: 'inline-block',
  [`@media (max-width: 440px)`]: {
    fontSize: 10,
  },
})

export const DownloadIcon = styled.span({
  fontSize: 14,
  cursor: 'pointer',
  [`@media (max-width: 440px)`]: {
    fontSize: 12,
  },
})

export const InvoiceTableCellNoData = styled.td({
  textAlign: 'center',
  padding: 12,
  fontSize: 12,
  fontWeight: 500,
  color: '#777',
  borderBottom: '1px solid #ddd',
  [`@media (max-width: 440px)`]: {
    padding: '8px',
    fontSize: 10,
  },
})