import squareIcon from 'assets/icons/square.svg'
import ubereatsIcon from 'assets/platforms/UberEats.svg'
import { SupportedPOS } from 'types'

import * as Styled from './styles'

const supportedPoses = [SupportedPOS.SQUARE]
const iconsByPos = {
  [SupportedPOS.SQUARE]: squareIcon,
  [SupportedPOS.UBEREATS]: ubereatsIcon,
}

type Props = {
  onSelect: (pos: SupportedPOS) => void
}

const SelectPosSystem = ({ onSelect }: Props) => {
  return (
    <>
      <Styled.Row>
        <Styled.Label>Select a Supported POS</Styled.Label>
        <Styled.HelperText>More coming soon!</Styled.HelperText>
      </Styled.Row>

      {supportedPoses.map((pos) => (
        <Styled.RadioWrapper key={pos}>
          <Styled.RadioInput
            name='pos'
            value={pos}
            onChange={({ currentTarget }) => {
              if (currentTarget.value === pos) {
                onSelect(pos)
              }
            }}
          />
          <Styled.Icon src={iconsByPos[pos]} alt='nav' />
        </Styled.RadioWrapper>
      ))}
    </>
  )
}

export default SelectPosSystem
