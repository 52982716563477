import { chowlyApi } from 'config'
import { generatePath } from 'react-router-dom'
import { selectParentPlatform } from 'utils'

import client from '../chowly'
import { OrderPlatformResponse } from '../models'
import { getPartner } from '../partners'

type orderPlatformType = {
  upcharge: number | null
  pickup_time_estimate: number | null
  delivery_time_estimate: number | null
}

export const updateOrderPlatform = async (
  orderPlatformId: number,
  locationId: string | undefined,
  partnerData?: orderPlatformType,
) => {
  const data = { attributes: { external_id: locationId, ...partnerData } }
  const url = generatePath(chowlyApi.orderPlatform, { id: orderPlatformId })
  const response = await client.put<OrderPlatformResponse>(url, { data })
  return response.data
}

export const deleteOrderPlatform = async (orderPlatformId: number) => {
  const url = generatePath(chowlyApi.orderPlatform, { id: orderPlatformId })
  const response = await client.delete<OrderPlatformResponse>(url)
  return response.data
}

export const initiateDoorDashOnboarding = async (orderPlatformId: number) => {
  const url = generatePath(chowlyApi.initiateDoorDash, { id: orderPlatformId })
  const response = await client.post(url)
  return response.data
}

type OpenStatusUpdateInput = {
  open_availability: boolean
  temporary_closure?: string
}

export const updatePlatformOpenStatus = async (
  orderPlatformId: number,
  updateData: OpenStatusUpdateInput,
) => {
  const url = generatePath(chowlyApi.updateOpenStatus, { id: orderPlatformId })
  const { data } = await client.post(url, updateData)
  return data
}

export const getParentPlatform = async (partnerId: number | string) => {
  const partner = await getPartner(partnerId, { include: 'order_platforms' })
  return selectParentPlatform(partner)
}

export const getOrderPlatform = async (id: number | string) => {
  const url = generatePath(chowlyApi.orderPlatform, { id })
  const { data } = await client.get<OrderPlatformResponse>(`${url}?include=partner`)
  return data
}

export const requestPlatformPublish = (orderPlatformId: string) => {
  if (orderPlatformId) {
    const url = generatePath(chowlyApi.platformPublishUrl, { id: orderPlatformId })
    return client.patch(url, {})
  }
}

export const deleteCheck = async (id: number | string) => {
  const url = generatePath(chowlyApi.deleteCheck, { id })
  const { data } = await client.get(`${url}?include=partner`)
  return data.may_destroy
}

export const updateDeliveryBuyDown = async (
  orderPlatformId: number,
  buydown: boolean | undefined | null,
  buydown_fee: number | null,
  minimum_delivery_subtotal: number | null,
) => {
  const data = {
    attributes: {
      buydown: buydown,
      buydown_fee: buydown_fee,
      minimum_delivery_subtotal: minimum_delivery_subtotal,
    },
  }
  const url = generatePath(chowlyApi.orderPlatform, { id: orderPlatformId })
  const response = await client.put<OrderPlatformResponse>(url, { data })
  return response.data
}
