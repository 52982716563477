import styled from 'styled-components'
import { breakpoints, colors } from 'theme'

export const HomeContainer = styled.div({
  display: 'flex',
  maxWidth: '668px',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '24px',
  margin: '35px 0 0 0'
})

/* Full page cards (Chilli Piper link, Smart Pricing, etc.) */
export const FullWidthCard = styled.div({
  display: 'flex',
  maxWidth: '668px',
  width: '100%',
  padding: '24px',
  borderRadius: '8px',
  background: colors.gray['450'],
})

/* Half width cards (Total Sales, Avg. Order val, etc) */
export const HalfWidthCard = styled.div({
  display: 'flex',
  width: '322px',
  height: '269px',
  padding: '24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
})

export const CardsContainer = styled.div({
  display: 'flex',
  margin: '0 48px',
  gap: 10,
  [`@media ${breakpoints.xxs}`]: {
    flexDirection: 'column',
    margin: 'unset',
  },
})

export const SalesOrderReportContainer = styled.div({
  width: '100%',
  height: '350px',
  overflow: 'scroll',
  padding: '24px',
  background: colors.gray['450'],
})

export const SalesOrderReportFrame = styled.iframe({
  width: '100%',
  height: '100%',
})
