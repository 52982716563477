import styled from 'styled-components'
import { animation, borderRadius, colors } from 'theme'

const slateBarStyles = {
  backgroundColor: colors.slate[200],
  borderRadius: borderRadius.DEFAULT,
  height: '0.5rem',
}

export const Container = styled.div({
  padding: '15px 0',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
})

export const PlatformContainer = styled.div({
  ...Container,
  padding: '0',
})

export const TitleContainer = styled.div({
  padding: '10px 0 0 0',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
})

export const Animated = styled.div(
  {
    display: 'flex',
    columnGap: '1rem',
  },
  animation.pulse,
)

export const SideLeft = styled.div({
  width: '2.5rem',
  height: '2.5rem',
  backgroundColor: colors.slate[200],
  borderRadius: borderRadius.full,
})

export const SideRight = styled.div({
  paddingTop: '0.25rem',
  paddingBottom: '0.25rem',
  flex: '1 1',
  rowGap: '1.5rem',
})

export const SlateBar = styled.div(
  slateBarStyles,
  ({ colspan }) =>
    colspan && {
      gridColumn: `span ${colspan} / span ${colspan}`,
    },
)

export const ShortcutSlateBar = styled.div({
  ...slateBarStyles,
  height: '80px',
})

export const PlatformSlateBar = styled.div({
  ...slateBarStyles,
  height: '30px',
})

export const GridLine = styled.div({
  display: 'grid',
  gridTemplateColumns: ({ cols = 3 }) => `repeat(${cols}, minmax(0, 1fr))`,
  gap: '1rem',
  marginTop: '15px',
})

export const PlatformGridLine = styled.div({
  display: 'grid',
  gridTemplateColumns: ({ cols = 7 }) => `repeat(${cols}, minmax(0, 1fr))`,
  gap: '1rem',
  marginTop: '0',
})
