import styled from 'styled-components'
import { colors, fontWeight, typography } from 'theme'

export const StatusType = styled.h3({
  fontWeight: fontWeight.bold,
  ...typography.xl,
})

export const StatusName = styled.h3({
  fontWeight: fontWeight.bold,
  paddingTop: '1rem',
})

export const ExitLink = styled.h2({
  fontWeight: fontWeight.bold,
  ...typography.xl,
  display: 'flex',
  justifyContent: 'center',
  marginTop: '2rem',
  color: colors.blue[700],
  '&:hover': {
    cursor: 'pointer',
  },
})

export const Container = styled.div({
  padding: '1rem',
  width: 'auto',
  display: 'flex',
  flexDirection: 'column',
})
