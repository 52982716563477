import { FC, useEffect, useState } from 'react'

import * as Styled from './styles'
import Tag from './Tag'

type Props = {
  value: string[]
  options: string[]
}

const TagsGroup: FC<Props> = ({ options, value }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([])

  useEffect(() => {
    setSelectedValues(selectedValues)
  }, [value])

  return (
    <Styled.Group>
      {options.map((option) => {
        return <Tag key={option} name={option} />
      })}
      {options.length === 0 && <Tag name='None' />}
    </Styled.Group>
  )
}

TagsGroup.displayName = 'TagsGroup'

export default TagsGroup
