import { MenuMappingItemDetails } from 'api'
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-back.svg'
import { ReactComponent as WarningIcon } from 'assets/icons/warning-icon.svg'
import { SkeletonLoader, TagsGroup } from 'components'
import { useMenuMappingItemCustomizations } from 'hooks'
import { FC, Fragment } from 'react'

import * as Styled from './styles'

type Props = {
  itemId: MenuMappingItemDetails['id'] | null
  onBack?: () => void
}

const MenuItemCustomization: FC<Props> = ({ itemId, onBack }) => {
  const itemDetailsWithCustomizations = useMenuMappingItemCustomizations(itemId)
  const { title, image_url, customizations } = itemDetailsWithCustomizations ?? {}

  return (
    <>
      {Boolean(itemId) && (
        <Styled.OverlayContainer>
          <div>
            <Styled.TextButton onClick={onBack} data-testid='back-nav'>
              <ArrowBackIcon />
              Back
            </Styled.TextButton>
          </div>
          {itemDetailsWithCustomizations ? (
            <>
              <Styled.Header>
                <Styled.ImageThumb>
                  {image_url ? (
                    <img src={image_url} data-testid='item-image' />
                  ) : (
                    <WarningIcon data-testid='warning-icon' />
                  )}
                </Styled.ImageThumb>
                <Styled.HeaderContent>
                  <Styled.Title data-testid='item-title' title={title}>
                    {title}
                  </Styled.Title>
                  <Styled.Subtitle data-testid='item-subtitle'>Customizations</Styled.Subtitle>
                </Styled.HeaderContent>
              </Styled.Header>
              <Styled.Content>
                {customizations?.map(({ title, min_permitted, max_permitted, options }) => (
                  <Fragment key={title}>
                    <Styled.Section>
                      <Styled.SectionHeader>
                        <Styled.SectionHeaderBoldText isError={options.length < 1}>
                          {title}
                        </Styled.SectionHeaderBoldText>
                        <i data-testid='permitted'>{`${
                          min_permitted ? `Min ${min_permitted}, ` : ''
                        }${max_permitted ? `Max ${max_permitted}` : ''}`}</i>
                      </Styled.SectionHeader>
                      <TagsGroup options={options.map(({ title }) => title) ?? []} value={[]} />
                    </Styled.Section>
                  </Fragment>
                ))}
              </Styled.Content>
            </>
          ) : (
            <SkeletonLoader />
          )}
        </Styled.OverlayContainer>
      )}
    </>
  )
}

MenuItemCustomization.displayName = 'MenuItemCustomization'

export default MenuItemCustomization
