import { chowlyApi } from 'config'
import { generatePath } from 'react-router'

import client from '../chowly'
import { MenuMappingSubsectionResponse } from '../models'

type QueryParams = { include: 'items' }

export const getMenuMappingSubsection = async (
  menuMappingSubsectionId: string,
  params?: QueryParams,
) => {
  const url = generatePath(chowlyApi.menuMappingSubsection, { id: menuMappingSubsectionId })
  const { data } = await client.get<MenuMappingSubsectionResponse>(url, { params })
  return data
}
