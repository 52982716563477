import { Subscription, createConsumer } from '@rails/actioncable'
import { chowlyApi } from 'config'
import { useAuth0 } from 'libs/auth0-react'
import capitalize from 'lodash/capitalize'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ActionStatus } from 'types'
import { PartnerChannelType } from 'types/Partner'

const DEFAULT_CHANNEL = 'PartnerChannel'
const TIMEOUT = 60000 // timeout milliseconds for importing
const UPDATE_CYCLE = 500 // what milliseconds will update progress per

const getCableUrl = (accessToken: string, userEmail: string) =>
  `${chowlyApi.websocketUrl}?access_token=${accessToken}&user_email=${encodeURIComponent(
    userEmail,
  )}`

type Props = {
  partnerId: number | undefined
  actionType: 'import' | 'publish'
  channel?: string
  platformName?: string
}

const useActionProgressListener = ({
  partnerId,
  channel = DEFAULT_CHANNEL,
  actionType,
  platformName,
}: Props) => {
  const { accessToken, user } = useAuth0()
  const [status, setStatus] = useState(ActionStatus.NotStarted)
  const [progress, setProgress] = useState(0)
  const [error, setError] = useState<string>()
  const timerRef = useRef<number | null>(null)
  const subscriptionRef = useRef<Subscription>()
  const timeoutRef = useRef<number | null>(null)

  const startProgressTimer = useCallback(() => {
    setProgress(0)
    timerRef.current = window.setInterval(() => {
      setProgress((old) => (old < 99 ? old + 1 : old))
    }, UPDATE_CYCLE)

    timeoutRef.current = window.setTimeout(() => {
      const errorMessage = `${capitalize(actionType)} didn’t complete for 60 seconds`
      setError(errorMessage)
      setStatus(ActionStatus.Failed)
      stopProgressTimer()
    }, TIMEOUT)
  }, [])

  const stopProgressTimer = useCallback(() => {
    if (timerRef.current) {
      window.clearInterval(timerRef.current)
      timerRef.current = null
    }
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }, [])

  const handleSyncError = useCallback(() => {
    setError(`${capitalize(actionType)} didn’t complete. Reason: Cable rejected`)
    setStatus(ActionStatus.Failed)
    stopProgressTimer()
  }, [])

  const runProgressListener = useCallback(() => {
    try {
      setError('')
      setStatus(ActionStatus.NotStarted)
      if (!partnerId) {
        throw new Error('no partner_id')
      }
      if (!accessToken) {
        throw new Error('no access_token')
      }
      if (!user?.email) {
        throw new Error('no user info')
      }
      setStatus(ActionStatus.Started)
      const cable = createConsumer(getCableUrl(accessToken, user.email))
      if (!cable.ensureActiveConnection()) {
        throw new Error('Failure to connect websocket.')
      }
      startProgressTimer()
      subscriptionRef.current = cable.subscriptions.create(
        {
          channel,
          partner_id: partnerId,
        },
        {
          disconnected: (reason?: unknown) => {
            setError(`${capitalize(actionType)} didn’t complete. Reason: cable disconnected`)
            console.warn('cable disconnected', reason)
            setStatus(ActionStatus.Failed)
            stopProgressTimer()
          },
          received: ({ type, message, platform_name, partner_id }: PartnerChannelType) => {
            if (type !== actionType) return
            // if partner id from server does not match the partner id for what this file was initiated for
            if (partner_id != partnerId) return
            // when publishing we should only trigger the handler if we are publishing to the one are intending to
            // i.e. we shouldn't trigger the handler if we are publishing to UberEats and we get a message from Doordash
            if (
              type == 'publish' &&
              platformName &&
              platform_name?.toLowerCase() != platformName.toLowerCase()
            ) {
              return
            }
            if (message === 'success') {
              setStatus(ActionStatus.Complete)
              stopProgressTimer()
              setProgress(100)
            } else {
              handleSyncError()
            }
          },
          rejected: () => {
            handleSyncError()
          },
        },
      )
    } catch (error) {
      console.error(error)
      setStatus(ActionStatus.Failed)
      setError(`${capitalize(actionType)} didn’t start. Reason: ${(error as Error).message}`)
    }
  }, [partnerId, actionType, accessToken, user?.email])

  useEffect(() => {
    return () => {
      subscriptionRef.current?.unsubscribe()
      stopProgressTimer()
    }
  }, [])

  return {
    runProgressListener,
    error,
    status,
    progress,
    setStatus,
    setError,
    setProgress,
    stopProgressTimer,
  }
}

export default useActionProgressListener
