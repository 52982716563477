import styled from 'styled-components'
import { borderRadius, colors, fontWeight } from 'theme'

type PhoneProps = { hasError: boolean }

export const Field = styled.div({
  marginBottom: '1.25rem',
})

export const FieldLabel = styled.label({
  display: 'block',
  fontSize: '1rem',
  fontWeight: fontWeight.bold,
  color: colors.gray.darkest,
  marginBottom: '0.25rem',
})

export const Input = styled.input<PhoneProps>(
  {
    width: '100%',
    padding: '0.75rem',
    backgroundColor: colors.white,
    appearance: 'none',
    borderWidth: 1,
    borderColor: colors.gray.dark,
    borderRadius: borderRadius.lg,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: fontWeight.bold,
  },
  ({ hasError }) =>
    hasError && {
      borderColor: colors.red.DEFAULT,
    },
)

export const FieldError = styled.span({
  color: colors.red.DEFAULT,
  marginBottom: '0.5rem',
})
