import { SVGAttributes, forwardRef } from 'react'
import styled from 'styled-components'
import { animation, colors } from 'theme'

const Svg = styled.svg(
  {
    width: '1.25rem',
    height: '1.25rem',
  },
  animation.spin.linear,
)

const LoadingIcon = forwardRef<SVGSVGElement, SVGAttributes<SVGSVGElement>>((props, ref) => (
  <Svg fill='none' viewBox='0 0 24 24' color={colors.white} {...props} ref={ref}>
    <circle cx={12} cy={12} r={10} stroke='currentColor' strokeWidth={4} opacity={0.25} />
    <path
      fill='currentColor'
      opacity={0.75}
      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
    ></path>
  </Svg>
))

LoadingIcon.displayName = 'LoadingIcon'

export default LoadingIcon
