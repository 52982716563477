import { useEffect, useState } from 'react'

import {
  MenuMappingCustomization,
  MenuMappingCustomizationOption,
  MenuMappingCustomizationResource,
  MenuMappingItem,
  MenuMappingItemDetails,
  getMenuMappingCustomization,
  getMenuMappingItem,
} from '../api'

type CustomizationDetails = MenuMappingCustomization & { options: MenuMappingCustomizationOption[] }

type ItemDetailsWithCustomizations = MenuMappingItem & {
  customizations: CustomizationDetails[]
}

const useMenuMappingItemCustomizations = (
  menuMappingItemId: MenuMappingItemDetails['id'] | null,
) => {
  const [itemDetails, setItemDetails] = useState<ItemDetailsWithCustomizations | null>(null)

  useEffect(() => {
    ;(async () => {
      try {
        if (!menuMappingItemId) {
          return
        }
        const { data, included } = await getMenuMappingItem(menuMappingItemId, {
          include: 'customizations',
        })
        setItemDetails({ ...data.attributes, customizations: [] })
        if (included) {
          const customizations = await Promise.all<CustomizationDetails>(
            (included as MenuMappingCustomizationResource[]).map(async ({ id }) => {
              const { data, included } = await getMenuMappingCustomization(id, {
                include: 'customization_options',
              })
              const customizationDetails: CustomizationDetails = {
                ...data.attributes,
                options: included?.map(({ attributes }) => attributes) ?? [],
              }
              return customizationDetails
            }),
          )
          setItemDetails({ ...data.attributes, customizations })
        }
      } catch (error) {
        console.error('Failed in fetching the customizations and options. Reason:', error)
      }
    })()
  }, [menuMappingItemId])

  return itemDetails
}

export default useMenuMappingItemCustomizations
