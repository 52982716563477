import styled from 'styled-components'
import { colors, fontWeight } from 'theme'

export const Tag = styled.div({
  borderRadius: '1.5rem',
  borderColor: colors.primary.light,
  borderWidth: 1,
  padding: '0.25rem 1rem',
  fontWeight: fontWeight.medium,
  backgroundColor: colors.white,
  color: colors.primary.light,
})

export const Group = styled.div({
  display: 'flex',
  columnGap: '0.5rem',
  flexWrap: 'wrap',
})
