import { ReactComponent as ChowlyLogo } from 'assets/logo.svg'

import * as Styled from './styles'

type Props = {
  error?: Error
}

const ErrorMessage = ({ error }: Props) => {
  return (
    <Styled.Container>
      <Styled.Box>
        <ChowlyLogo />

        <Styled.Title>
          <Styled.Red>You have encountered an error</Styled.Red>
        </Styled.Title>

        <div>{error?.toString()}</div>

        <Styled.Description>
          An error occurred while loading this page. Try again. For additional help please contact
          Chowly Support using the "C" icon in the lower right corner of your screen.
        </Styled.Description>
      </Styled.Box>
    </Styled.Container>
  )
}

export default ErrorMessage
