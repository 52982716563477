import {
  retrieveSquareLocations,
  startSquareOauth,
  submitSquareLocation as submitLocation,
} from 'api'
import PopupWindow from 'components/PopupWindow/PopupWindow'
import { useAuth0 } from 'libs/auth0-react'
import { useCallback } from 'react'

enum ResponseKey {
  ONBOARDING_LOCATION_ID = 'onboarding_location_id',
}

enum LocationKeys {
  search = 'search',
  hash = 'hash',
}

type Response = {
  onboarding_location_id: string
}

const locationKey = LocationKeys.search
const isCrossOrigin = false

const getPopupLayout = () => {
  const width = Math.min(680, window.innerWidth)
  const height = Math.min(680, window.innerHeight)
  return {
    height,
    width,
    left: window.screenX + (window.outerWidth - width) / 2,
    top: window.screenY + (window.outerHeight - height) / 2,
  }
}

const useSquareApi = () => {
  const { accessToken } = useAuth0()

  const loginSquare = useCallback(
    (onboardingLocationId: string) => {
      return new Promise((resolve, reject) => {
        if (!accessToken) {
          return reject(new Error('No access token'))
        }
        startSquareOauth(accessToken, onboardingLocationId)
          .then((squareUrl) => {
            const popup = PopupWindow.open('SquareLogin', squareUrl, getPopupLayout(), {
              locationKey,
              isCrossOrigin,
            })
            popup.then((data: Response) => {
              const responseKey = ResponseKey.ONBOARDING_LOCATION_ID
              if (!isCrossOrigin && !data[responseKey]) {
                reject(new Error(`"${responseKey}" not found in received data`))
              } else {
                resolve(data)
              }
            })
          })
          .catch(reject)
      })
    },
    [accessToken],
  )

  const getSquareLocations = useCallback(
    async (onboardingLocationId: string) => {
      if (!accessToken) {
        throw new Error('No access token')
      }
      return retrieveSquareLocations(accessToken, onboardingLocationId)
    },
    [accessToken],
  )

  const submitSquareLocation = useCallback(
    async (onboardingLocationId: string, squareLocationId: string) => {
      if (!accessToken) {
        throw new Error('No access token')
      }
      return submitLocation(accessToken, onboardingLocationId, squareLocationId)
    },
    [accessToken],
  )

  return { loginSquare, getSquareLocations, submitSquareLocation }
}

export default useSquareApi
