import styled from 'styled-components'
import { colors } from 'theme'

export const ActionContainer = styled.div<{ position: 'top' | 'bottom'; padding?: string }>(
  {
    position: 'fixed',
    width: '100%',
    background: colors.gray[450],
    left: 0,
    height: '63px',
    boxShadow: '0px -4px 32px 0px #29292912',
    padding: '16px 24px',
  },
  ({ position }) => (position === 'top' ? { top: 0 } : { bottom: 0 }),
  ({ padding }) => padding && { padding },
)
