import { MenuMappingTimePeriodResource } from 'api/models'
import { TABS } from 'features/manage-locations/IndividualMenu/constants'

import { DayOfWeekName } from './DateTime'

export type TimeSlot = {
  startTime: string
  endTime: string
  id: string
  persisted: boolean
}

export type BusinessHoursType = {
  day: string
  startTime: string
  endTime: string
  id: number
}

export type DayOfWeekProps = {
  id: string
  persisted: boolean
  isActive: boolean
  timeSlots: TimeSlot[]
}

export type MenuAvailabilityData = Partial<Record<DayOfWeekName, DayOfWeekProps>>

export enum IssueType {
  ITEM_NO_IMAGE = 'ITEM_NO_IMAGE',
  ITEM_MISSING_OPTIONS = 'ITEM_MISSING_OPTIONS',
  ITEM_NO_PRICE = 'ITEM_NO_PRICE',
  CATEGORY_NO_ITEMS = 'CATEGORY_NO_ITEMS',
  MODIFIER_MIN_MAX_DISPARITY = 'MODIFIER_MIN_MAX_DISPARITY',
}

export enum ActionStatus {
  NotStarted = 'NotStarted',
  Started = 'Started',
  Failed = 'Failed',
  Complete = 'Complete',
}

export type TabType = typeof TABS.ALL_PRODUCTS | typeof TABS.ACTIVE | typeof TABS.INACTIVE

export type TimePeriodValues = {
  id?: MenuMappingTimePeriodResource['id']
  day_of_week?: string
  start_time?: string
  end_time?: string
}

export type FormattedAvailabilities = {
  [key: string]: TimePeriodValues[]
}
