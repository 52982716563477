import { OrderPlatformResponse, getOrderPlatform } from 'api'
import { useCallback, useEffect, useState } from 'react'

const useGetOrderPlatform = (platformId: number | string | undefined) => {
  const [data, setData] = useState<OrderPlatformResponse>()
  const [loading, setLoading] = useState(false)

  const fetch = useCallback(async () => {
    try {
      if (!platformId) {
        return
      }
      setLoading(true)
      const response = await getOrderPlatform(Number(platformId))
      setData(response)
    } catch (err) {
      console.error('Failed in fetching an order platform', err)
    } finally {
      setLoading(false)
    }
  }, [platformId])

  useEffect(() => {
    fetch()
  }, [fetch])

  return {
    data,
    loading,
  }
}

export default useGetOrderPlatform
