import { isAxiosError } from 'axios'
import { toast } from 'react-toastify'
import { PlatformSummary } from 'types'

import { OrderPlatformErrorResponse, deleteOrderPlatform } from 'api';
import * as Styled from './styles'
import { useRecoilValue } from 'recoil';
import { mayDeletePlatform } from 'state/atoms';

type Props = {
  existingPlatform: boolean
  platform: PlatformSummary
  reload: () => void
  platformName: string
}

const ResetOnboardingPlatform = ({ platform, reload }: Props) => {
  const getMayDeletePlatform = useRecoilValue(mayDeletePlatform(platform.id))

  const handleDelete = async (orderPlatformId: number | undefined) => {
    if (!orderPlatformId) return
    try {
      await deleteOrderPlatform(Number(orderPlatformId))
      reload()
    } catch (error) {
      if (isAxiosError<OrderPlatformErrorResponse>(error)) {
        const allErrors = (error.response?.data.errors ?? []).map(({ detail }) => detail)
        toast.error('Unable to delete orderplatform')
        allErrors.forEach((item) => toast.error(item))
      } else {
        toast.error(`Unknown error: ${String(error)}`)
      }
    }
  }


  if (!getMayDeletePlatform) {
    return null
  }

  return (
    <Styled.BaseButton onClick={() => handleDelete(platform.id)} variant='outline' remove={true}>
      Remove Platform
    </Styled.BaseButton>
  )
}
export default ResetOnboardingPlatform
