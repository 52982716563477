import capitalize from 'lodash/capitalize'

import { OrderPlatformResource, PartnerResponse, StatusResource, TypeName } from '../api'
import { PlatformName, PlatformSummary } from '../types'

export const retrievePlatforms = (
  included: PartnerResponse['included'],
  includeArchived: boolean,
) => {
  if (included?.length) {
    return included
      .filter(
        (item): item is OrderPlatformResource =>
          item.type === TypeName.OrderPlatform &&
          item.attributes.name !== PlatformName.CHOWLY &&
          item.attributes.name !== PlatformName.GARCON &&
          (includeArchived || item.attributes.aasm_state !== 'archived'),
      )
      .map<PlatformSummary>((item) => {
        const statusId = item.relationships.menu_publish_status.data?.id
        const status = capitalize(item.attributes.aasm_state)
        return {
          id: item.attributes.id,
          aasmState: item.attributes.aasm_state,
          name: item.attributes.name,
          internalName: item.attributes.internal_name,
          type: item.type,
          hasAccessToken: item.attributes.has_access_token,
          hasOnboardingId: item.attributes.has_onboarding_id,
          status,
          menuPublishStatus: statusId
            ? included.find((obj): obj is StatusResource => obj.id === statusId)
            : undefined,
          availability:
            status === 'Enabled'
              ? item.attributes.temporary_closure
                ? 'Paused'
                : 'Not Paused'
              : 'Unavailable',
          reopenTime: item.attributes.temporary_closure,
          pausable: item.attributes.pausable && status === 'Enabled',
          provisioned: item.attributes.provisioned,
          buydown: item.attributes.buydown,
          buydown_fee: item.attributes.buydown_fee,
          minimum_delivery_subtotal: item.attributes.minimum_delivery_subtotal,
          coo_url: item.attributes?.coo_url,
        }
      })
  }
}

export const retrieveMenuImportStatuses = (included: PartnerResponse['included']) => {
  return included?.filter((item): item is StatusResource => item.type === 'import_menu_status')
}
