import { Field } from 'formik'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import {
  borderRadius,
  breakpoints,
  colors,
  fontFamily,
  fontWeight,
  shadow,
  transition,
  typography,
} from 'theme'

type StatusProps = { isSuccess: boolean }
type EnqueuedProps = { isVisible: boolean }

const saveButtonStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
}

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '668px',
  rowGap: '1rem',
  paddingBottom: 'calc(2vh + 24px)',
  height: 'auto',
  marginTop: '60px',
  [`@media ${breakpoints.xxs}`]: {
    marginTop: '28px'
  },
})

export const Grid = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 1fr)',
  gap: '.5rem',
  height: 'auto',
  width: '100%',
  [`@media ${breakpoints.md}`]: {
    maxWidth: '60rem',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
})

export const FlexContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '.5rem',
  height: 'auto',
  width: '100%',
  [`@media ${breakpoints.md}`]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
})

export const FlexRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '.5rem',
  height: 'auto',
  width: '100%',
  [`@media ${breakpoints.md}`]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
})

export const LocationContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '64rem',
})

export const PlatformContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '50rem',
})

export const LocationName = styled.div({
  fontSize: '2rem',
  lineHeight: '45px',
  fontWeight: fontWeight.bold,
  maxWidth: '70%',
  marginBottom: '25px',
})

export const LocationFormItem = styled.div({
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '85vw',
  [`@media ${breakpoints.md}`]: {
    maxWidth: '60vw',
  },
})

export const LocationConnectionContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '85vw',
  [`@media ${breakpoints.md}`]: {
    maxWidth: '70vw',
  },
})

export const EditLabel = styled.label({
  width: '36%',
  maxWidth: '40%',
  display: 'inline-block',
  position: 'relative',
  fontSize: '1rem',
  color: colors.gray.darkest,
  margin: '0rem 0 .5rem .5rem',
})

export const FieldLabel = styled.label({
  width: 160,
  maxWidth: '50%',
  display: 'inline-block',
  position: 'relative',
  fontSize: '1rem',
  fontWeight: fontWeight.bold,
  color: colors.gray.darkest,
  margin: '.5rem 0',
})

export const EditMenuLabel = styled(FieldLabel)({
  margin: '.5rem 0 0 0',
})

export const MenuImportLabel = styled.label({
  width: '37%',
  display: 'inline-block',
  position: 'relative',
  fontSize: '1rem',
  fontWeight: fontWeight.bold,
  color: colors.gray.darkest,
  padding: '.5rem',
  [`@media ${breakpoints.md}`]: {
    width: '53%',
  },
})

export const InputUpchargeContainer = styled.div({
  display: 'inline-block',
  position: 'relative',
  width: 128,
  '&::before': {
    position: 'absolute',
    top: '12px',
    right: '.5em',
    content: '"%"',
  },
})

export const InputMinContainer = styled.div({})

export const Input = styled(Field)({
  width: '100%',
  padding: '0.75rem 2.6rem .75rem 1rem',
  backgroundColor: colors.white,
  appearance: 'none',
  borderWidth: 1,
  borderColor: colors.blue['300'],
  borderRadius: borderRadius.lg,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: fontWeight.bold,
})

export const InputWarning = styled.h4({
  fontStyle: 'italic',
  color: colors.blue[700],
})

export const SmallText = styled.span<EnqueuedProps>(
  {
    display: 'none',
    fontSize: '0.7rem',
    color: 'black',
    textTransform: 'none',
    marginLeft: '5px',
  },
  ({ isVisible }) =>
    isVisible && {
      display: 'inline',
    },
)

export const StatusText = styled.h4<StatusProps>(
  {
    width: '60%',
    display: 'block',
    fontSize: '1rem',
    fontWeight: fontWeight.bold,
    color: colors.red.DEFAULT,
    marginTop: '.5rem',
    textTransform: 'uppercase',
  },
  ({ isSuccess }) =>
    isSuccess && {
      color: colors.green[500],
    },
)

export const ImportStatusText = styled(StatusText)({
  width: '90%',
})

export const RightColumn = styled.div({
  width: '60%',
  display: 'block',
  fontSize: '1rem',
  marginTop: '.5rem',
})

export const RightStatusColumn = styled(RightColumn)({
  marginTop: '0rem',
})

export const LocationStatusContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '10px',
  columnGap: '0.5rem',
})

export const TooltipBox = styled.div({
  position: 'absolute',
  left: '30px',
  visibility: 'hidden',
  color: 'black',
  backgroundColor: 'transparent',
  width: '150px',
  padding: '5px 5px',
  borderRadius: '4px',
  fontWeight: fontWeight.medium,
  zIndex: 1,
})

export const TooltipCard = styled.div({
  position: 'relative',
  '&:hover .tooltipBox': {
    backgroundColor: 'white',
    visibility: 'visible',
    width: '300px',
    padding: '8px 8px',
  },
})

export const ButtonContainer = styled(LocationStatusContainer)({
  marginBottom: 0,
})

export const MenuImportSection = styled.div({
  width: '40vw',
  display: 'flex',
  flexDirection: 'column',
})

export const TimeStampText = styled.p({
  width: '100%',
  display: 'block',
  fontSize: '1rem',
  fontWeight: fontWeight.medium,
  color: colors.blue[700],
  margin: '.5rem 0',
})
export const SaveBttnParent = styled.div({
  ...saveButtonStyles,
  [`@media ${breakpoints.lg}`]: {
    width: '95%',
  },
})

export const PlatformSaveBttn = styled.div({
  width: '75%',
  marginTop: '2rem',
  textAlign: 'right',
})

export const SaveBttnContainer = styled.div({
  display: 'flex',
  flexDirection: 'column-reverse',
  width: '76%',
  [`@media ${breakpoints.md}`]: {
    width: '100%',
  },
})
export const BttnWrapper = styled.div({
  display: 'flex',
  maxWidth: 150,
})

export const ToolBar = styled.div({
  display: 'flex',
  columnGap: '3rem',
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight.semibold,
  fontSize: typography.xl.fontSize,
  lineHeight: typography.xl.lineHeight,
  color: colors.primary.medium,
  alignItems: 'end',
})

export const Content = styled.div({
  marginTop: '.5rem',
  overflow: 'auto',
  minHeight: '35%',
  [`@media ${breakpoints.lg}`]: {
    minHeight: '44%',
  },
})

export const AvailabilityContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const RedStatus = styled.div({
  color: colors.red[500],
})

export const SuccessStatus = styled.div({
  color: colors.green[500],
})

export const AvailabilityTimeStamp = styled.div({
  ...typography.xs,
})

export const ModalContent = styled.div({
  padding: '2rem 1.5rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 540,
})

export const ModalTitle = styled.div({
  fontSize: '2rem',
  fontWeight: fontWeight.bold,
  lineHeight: 1.1875,
  textAlign: 'center',
  marginBottom: '1rem',
})

export const ModalText = styled.p({
  marginTop: '1.5rem',
  marginBottom: '1.5rem',
  textAlign: 'center',
})

export const Flex = styled.div({
  display: 'grid',
  columnGap: '1rem',
  width: '100%',
  gridTemplateColumns: '1fr 1fr',
})

const inputFieldStyle = {
  borderWidth: 1,
  borderColor: colors.blue['300'],
  borderRadius: 8,
  padding: 13,
  backgroundColor: colors.blue['40'],
  width: '100%',
  color: colors.gray.darkest,
}

export const InputFieldLabel = styled.label({
  fontWeight: fontWeight.bold,
  lineHeight: '2rem',
})

export const ErrorLabel = styled.label({
  fontWeight: fontWeight.bold,
  lineHeight: '2rem',
  color: colors.red.DEFAULT,
})

export const DateInput = styled.input.attrs({ type: 'date' })(inputFieldStyle)

export const TimeInput = styled.input.attrs({ type: 'time' })(inputFieldStyle)

export const ModalActions = styled.div({
  marginTop: '2.25rem',
  display: 'flex',
  gap: '1rem',
  flexWrap: 'wrap',
  width: '100%',
})

export const TextButton = styled.button({
  fontWeight: fontWeight.bold,
  color: colors.primary.DEFAULT,
  ':disabled': {
    color: colors.gray.DEFAULT,
  },
})

export const ContentBox = styled.div({
  display: 'flex',
  gap: '0.25rem',
  padding: '0.25rem',
  alignItems: 'center',
  gridArea: 'content',
  justifyContent: 'center',
})

export const Content1 = styled.div({
  background: '#a6b8b9',
  padding: '0.25rem',
  width: '100%',
  height: '100%',
})

export const Link = styled.a({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: borderRadius.lg,
  color: colors.primary.light,
  borderColor: colors.primary.light,
  borderWidth: '0.1rem',
  backgroundColor: colors.white,
  boxShadow: shadow.DEFAULT,
  fontWeight: fontWeight.semibold,
  fontSize: '1rem',
  lineHeight: '1rem',
  padding: '1.25rem 0rem',
  columnGap: '0.75rem',
  width: '15%',
  ...transition,
  '&:hover': {
    borderColor: colors.primary.dark,
  },
})

export const Search = styled.input({
  width: '100%',
  maxWidth: '20rem',
})

export const SearchLabel = styled.div({
  flex: 1,
})

export const RoundButtonLink = styled(RouterLink)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: borderRadius.full,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  backgroundColor: colors.primary.light,
  color: colors.white,
  padding: '0.75rem 1.5rem',
  boxShadow: shadow.DEFAULT,
  ...transition,
  '&:hover': {
    backgroundColor: colors.primary.dark,
  },
})
