import { Button, NotificationBanner } from 'components'
import { NotificationBannerProps } from 'components/NotificationBanner/NotificationBanner'
import { useState } from 'react'

import * as Styled from './styles'

const loremIpsum =
  'Lorem ipsum dolor sit amet consectetur. Aliquam in purus dis nec arcu enim cursus imperdiet libero. Sed ligula sem ullamcorper nisi turpis. Morbi libero tincidunt dictum sed dignissim ipsum feugiat sit placerat. Fusce curabitur laoreet lectus aliquam tempus posuere porttitor. Et nunc sed cursus augue venenatis turpis volutpat sit eget. Quisque rhoncus sagittis consectetur pulvinar nisi eu cras. Malesuada turpis malesuada enim maecenas venenatis sed mauris faucibus vestibulum. Sed non accumsan sed sed sit eget sed massa ut. Ultricies viverra est blandit tellus euismod nullam scelerisque urna.'

const NotificationBannerView = () => {
  const [notifications, setNotifications] = useState<
    Array<NotificationBannerProps & { id: number }>
  >([])
  const closeNotification = (id: number) => {
    setNotifications((state) => state.filter((obj) => obj.id !== id))
  }

  return (
    <Styled.Container>
      <Styled.Grid>
        <Button
          onClick={() => {
            const id = Date.now()
            setNotifications([
              ...notifications,
              {
                id,
                message: loremIpsum,
                buttonTitle: 'Button',
                onButtonClick: () => closeNotification(id),
              },
            ])
          }}
        >
          Add Standard Notification
        </Button>
        <Button
          onClick={() => {
            const id = Date.now()
            setNotifications([
              ...notifications,
              {
                id,
                type: 'info',
                message: loremIpsum,
                buttonTitle: 'Button',
                icon: true,
                onButtonClick: () => closeNotification(id),
              },
            ])
          }}
        >
          Add Info Notification
        </Button>
        <Button
          onClick={() => {
            const id = Date.now()
            setNotifications([
              ...notifications,
              {
                id,
                type: 'success',
                message: loremIpsum,
                buttonTitle: 'Button',
                icon: true,
                onButtonClick: () => closeNotification(id),
              },
            ])
          }}
        >
          Add Success Notification
        </Button>
        <Button
          onClick={() => {
            const id = Date.now()
            setNotifications([
              ...notifications,
              {
                id,
                type: 'warning',
                message: loremIpsum,
                buttonTitle: 'Button',
                icon: true,
                onButtonClick: () => closeNotification(id),
              },
            ])
          }}
        >
          Add Warning Notification
        </Button>
        <Button
          danger={true}
          onClick={() => {
            const id = new Date().getTime()
            setNotifications([
              ...notifications,
              {
                id,
                type: 'error',
                message: loremIpsum,
                buttonTitle: 'Button',
                icon: true,
                onButtonClick: () => closeNotification(id),
              },
            ])
          }}
        >
          Add Error Notification
        </Button>
        <Button
          onClick={() => {
            const id = new Date().getTime()
            setNotifications([
              ...notifications,
              {
                id,
                type: 'loading',
                message: loremIpsum,
                buttonTitle: 'Button',
                icon: true,
                onButtonClick: () => closeNotification(id),
              },
            ])
          }}
        >
          Add Loading Notification
        </Button>
      </Styled.Grid>

      {notifications.map(({ id, ...props }) => (
        <NotificationBanner key={id} {...props} />
      ))}
    </Styled.Container>
  )
}

export default NotificationBannerView
