import styled from 'styled-components'

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '1rem',
  padding: '2rem',
  overflowY: 'auto',
})

export const Grid = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  maxWidth: '20rem',
})
