import styled from 'styled-components'
import { animation, borderRadius, colors, inset0 } from 'theme'

const Overlay = styled.div({
  position: 'fixed',
  ...inset0,
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
})

const Spinning = styled.div<{ fullScreen?: boolean }>(
  {
    width: 30,
    height: 30,
    borderRadius: borderRadius.full,
    borderWidth: 2,
    borderColor: colors.primary.DEFAULT,
    position: 'relative',
    left: 'calc(50% - 30px)',
    top: 'calc(50% - 30px)',
    borderLeftColor: 'transparent',
  },
  animation.spin.ease,
  ({ fullScreen }) => fullScreen && { position: 'fixed' },
)

type Props = {
  fullScreen?: boolean
}

const Spinner = ({ fullScreen }: Props) => {
  if (fullScreen) {
    return (
      <Overlay>
        <Spinning />
      </Overlay>
    )
  } else {
    return <Spinning />
  }
}

export default Spinner
