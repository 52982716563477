import { StatusResource } from 'api'

/**
 * Order platform name. The values should match with the OrderPlatform.internal_name in the Chowly API
 */
export enum PlatformName {
  CHOWLY = 'Chowly',
  UBEREATS = 'Ubereats',
  DOORDASH = 'Doordash',
  GRUBHUB = 'GrubCentral',
  GARCON = 'Garcon',
  POSTMATES = 'Postmates',
  CHOWNOW = 'Chownow',
  DELIVERY = 'DeliveryDotCom',
  CAVIAR = 'Caviar',
  RITUAL = 'Custom',
  COO = 'Chowly Online Ordering',
}

export enum PlatformFriendlyName {
  CHOWLY = 'Chowly',
  UBEREATS = 'Uber Eats',
  DOORDASH = 'DoorDash',
  GRUBHUB = 'GrubCentral',
  GARCON = 'Garcon',
  CHOWNOW = 'Chownow',
  DELIVERY = 'DeliveryDotCom',
  CAVIAR = 'Caviar',
  RITUAL = 'Custom',
}

export type PlatformSummary = {
  name: string
  internalName: string
  status: string
  aasmState?: string
  menuPublishStatus?: StatusResource
  availability: string
  reopenTime?: string | null
  pausable: boolean
  id: number
  provisioned: boolean
  hasAccessToken: boolean
  hasOnboardingId: boolean
  buydown?: boolean | null
  buydown_fee?: number | null
  minimum_delivery_subtotal?: number | null
  coo_url?: string | null
}
