import styled from 'styled-components'
import { colors, fontWeight } from 'theme'

export const OverlayContainer = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: colors.white,
  paddingLeft: '1.5rem',
  paddingTop: '1rem',
  display: 'flex',
  flexDirection: 'column',
})

export const ImageThumb = styled.div({
  width: 72,
  height: 72,
  borderRadius: 16,
  backgroundColor: '#C4C4C4',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  img: {
    objectFit: 'cover',
    objectPosition: '50% 50%',
    width: '100%',
    height: '100%',
  },
})

export const TextButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  color: colors.red.DEFAULT,
  fontWeight: fontWeight.bold,
  columnGap: 5,
})

export const Header = styled.div({
  display: 'grid',
  gridTemplateColumns: '72px 1fr',
  columnGap: 12,
  paddingTop: '1rem',
  paddingBottom: '0.25rem',
})

export const HeaderContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'hidden',
})

export const Title = styled.h1({
  fontSize: '2rem',
  fontWeight: fontWeight.bold,
  lineHeight: '2.5rem',
  color: colors.blue.DEFAULT,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export const Subtitle = styled.div({
  fontSize: '1.25rem',
  fontWeight: fontWeight.medium,
  lineHeight: '1.5rem',
  color: colors.gray['900'],
})

export const Content = styled.div({
  flex: 1,
  width: '100%',
  overflowY: 'auto',
})

export const Section = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '0.75rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
})

export const SectionHeader = styled.div({
  display: 'flex',
  columnGap: '1rem',
})

export const SectionHeaderBoldText = styled.h2<{ isError: boolean }>(({ isError }) => ({
  fontSize: '1.25rem',
  lineHeight: 1.15,
  fontWeight: fontWeight.bold,
  color: isError ? colors.red.DEFAULT : colors.primary.light,
}))

export const Subsection = styled.div({
  display: 'grid',
  gridTemplateColumns: '30px 1fr',
  columnGap: '0.5rem',
  paddingBottom: '1rem',
})

export const SubsectionLeft = styled.div({})

export const SubsectionRight = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: 4,
})

export const SubsectionTitle = styled.h1({
  fontSize: '1.25rem',
  lineHeight: '25px',
  fontWeight: fontWeight.bold,
  color: colors.gray['900'],
})

export const SubsectionSubtitle = styled.div({
  display: 'flex',
  columnGap: '0.5rem',
  color: colors.gray['900'],
})

export const Separator = styled.hr({
  borderColor: colors.cyan['300'],
})
