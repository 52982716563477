import { FormattedMenuSectionInfo, MenuMappingItemDetails } from 'api'

type SearchResults = {
  displayOnlyItems: FormattedMenuSectionInfo[]
  matchingItems: FormattedMenuSectionInfo[]
  filteredModifierGroups: { [id: MenuMappingItemDetails['id']]: FormattedMenuSectionInfo[] } // map to Items
  filteredModifiers: { [id: FormattedMenuSectionInfo['id']]: FormattedMenuSectionInfo[] } // map to Modifier Groups
}

/**
 *
 * @param searchInput - the search string we're comparing items and modifiers against
 * @param items - all items with their nested mod groups and mods
 * @param filteredModifierIds - if we already applied a filter to modifiers, include that in here
 * @returns filteredItems: items to show in the table, that include the search keyword or items that have modifiers that match the search keyword (we want to show both cases)
 * filteredModifierGroups: groups to show in the table - if a modifier in a group matches the keyword, we show the group
 * filteredModifiers: mods to show in the table - only show the mod if the keyword matches it
 */
const getSearchFilteredItemsModifiers = (
  searchInput: string,
  items: FormattedMenuSectionInfo[],
  filteredModifierIds: FormattedMenuSectionInfo['id'][] = [], // modifiers that were already filtered out
): SearchResults | undefined => {
  const displayOnlyItems: FormattedMenuSectionInfo[] = [] // items that are shown in the table but don't match the keyword themselves
  const matchingItems: FormattedMenuSectionInfo[] = [] // items that match the keyword
  const filteredModifiers: { [id: FormattedMenuSectionInfo['id']]: FormattedMenuSectionInfo[] } = {} // map modifiers to their groups
  const filteredModifierGroups: { [id: MenuMappingItemDetails['id']]: FormattedMenuSectionInfo[] } =
    {} // map groups to their items

  if (!searchInput.length) {
    return
  }

  // if a modifier matches the search result, we want to display it with its item.
  items.forEach((item) => {
    const matchingItemModifierGroups: FormattedMenuSectionInfo[] = []

    // drill into the item, modifier group and modifier to check if there is a keyword match.
    item.children.customizations?.forEach((customization) => {
      const matchingItemModifiers: FormattedMenuSectionInfo[] = []

      // possible pre-existing modifier filter
      const customizationOptions = filteredModifierIds.length
        ? customization.children.customization_options?.filter((option) =>
            filteredModifierIds.includes(option.id),
          )
        : customization.children.customization_options

      customizationOptions?.forEach((option) => {
        if (option.attributes.title?.toLowerCase().includes(searchInput.toLowerCase())) {
          matchingItemModifiers.push(option)
        }
      })

      if (matchingItemModifiers.length) {
        matchingItemModifierGroups.push(customization)
        filteredModifiers[customization.id] = matchingItemModifiers
      }
    })

    // Finally, if the item itself matches the keyword, show the item.
    if (item.attributes.title?.toLowerCase().includes(searchInput.toLowerCase())) {
      matchingItems.push(item)
    } else {
      // Include the item even if it's title doesn't match, but it's modifiers do
      if (matchingItemModifierGroups.length) {
        filteredModifierGroups[item.id] = matchingItemModifierGroups
        displayOnlyItems.push(item)
      }
    }
  })

  return { displayOnlyItems, matchingItems, filteredModifierGroups, filteredModifiers }
}

export default getSearchFilteredItemsModifiers
