import Tippy from '@tippyjs/react'
import styled from 'styled-components'

import { colors } from '../../theme'

export const RefreshToolTip = styled(Tippy)({
  backgroundColor: colors.blue.DEFAULT,
  borderRadius: 16,
  '.tippy-arrow': {
    color: colors.blue.DEFAULT,
    width: 16,
    height: 16,
  },
})
export const RefreshButton = styled.button({
  padding: 2,
  marginLeft: 3,
})

export const ResyncIcon = styled.img({
  width: '1.5rem',
  height: '1.5rem',
  margin: '0 0.25rem',
})

export const RefreshContainer = styled.div({
  paddingTop: '1rem',
  width: '100%',
  fontWeight: 500,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: colors.gray['900'],
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
})
