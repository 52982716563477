import {
  createMenuMappingTimePeriod,
  deleteMenuMappingTimePeriod,
  formatServiceAvailabilityPayload,
  formatTimePeriodPayload,
  updateMenuMappingServiceAvailability,
  updateMenuMappingTimePeriod,
} from 'api/menuMapping/availabilities'
import { Availabilities } from 'api/menuMapping/types'
import { FormattedAvailabilities } from 'types/Menu'

import checkEmptyTimePeriods from './checkEmptyTimePeriods'

const updateAvailabilitiesForMenuSection = async (
  currentAvailabilities: Availabilities[],
  newAvailabilities: FormattedAvailabilities,
  menuSectionId: string,
  availabilitiesToDelete?: Availabilities['id'][],
) => {
  // update existing availabilities / add new ones
  const updateResult = await Promise.all(
    Object.entries(newAvailabilities).map(async ([dayOfWeek, timePeriods]) => {
      // we're making an assumption that if the first time period is empty, they all are.
      const allTimePeriodsEmpty = checkEmptyTimePeriods(timePeriods)

      const serviceAvailabilityToUpdate = currentAvailabilities.find(
        (avail) => avail.dayOfWeek === dayOfWeek,
      )
      const serviceAvailabilityId = serviceAvailabilityToUpdate?.id

      // This shouldn't happen b/c the DB should have service availabilities present for every day of the week at this point.
      if (!serviceAvailabilityId) {
        return Promise.reject(`The service availability does not exist for: ${dayOfWeek}`)
      }

      if (allTimePeriodsEmpty) {
        // we need to disable service availability since we cleared all of its time periods.
        if (serviceAvailabilityToUpdate.enabled) {
          const serviceAvailabilityPayload = formatServiceAvailabilityPayload(
            { day_of_week: dayOfWeek, enabled: false },
            menuSectionId,
          )

          // CREATE A NEW SERVICE AVAILABILITY (DAY OF WEEK)
          let serviceAvailabilityResult

          try {
            serviceAvailabilityResult = await updateMenuMappingServiceAvailability(
              serviceAvailabilityId,
              serviceAvailabilityPayload,
            )
          } catch (err) {
            return Promise.reject(`Failed to update service availability: ${dayOfWeek}`)
          }

          return Promise.resolve({
            disabledServiceAvailability: serviceAvailabilityResult,
          })
        }
      } else {
        // enable service availability and update/create time periods
        const serviceAvailabilityPayload = formatServiceAvailabilityPayload(
          { day_of_week: dayOfWeek, enabled: true },
          menuSectionId,
        )

        let serviceAvailabilityResult

        try {
          serviceAvailabilityResult = await updateMenuMappingServiceAvailability(
            serviceAvailabilityId,
            serviceAvailabilityPayload,
          )
        } catch (err) {
          return Promise.reject(`Failed to enable service availability for ${dayOfWeek}`)
        }

        // TODO: Account for deleting existing time periods.
        const timePeriodsPromisesResult = await Promise.all(
          timePeriods.map(async (timePeriod, idx) => {
            if (timePeriod.id) {
              if (timePeriod.start_time && timePeriod.end_time) {
                // Update an existing time period
                const attributes = {
                  start_time: timePeriod.start_time,
                  end_time: timePeriod.end_time,
                }

                const timePeriodPayload = formatTimePeriodPayload(attributes, serviceAvailabilityId)

                try {
                  const timePeriodResult = await updateMenuMappingTimePeriod(
                    timePeriod.id,
                    timePeriodPayload,
                  )

                  if (timePeriodResult?.data) {
                    return Promise.resolve(timePeriodResult.data)
                  }
                } catch (err) {
                  return Promise.reject(
                    `Failed to update time period for ${dayOfWeek}. Error: ${err}`,
                  )
                }
              }
            } else {
              if (timePeriod.start_time && timePeriod.end_time) {
                // Create new time period
                const attributes = {
                  start_time: timePeriod.start_time,
                  end_time: timePeriod.end_time,
                }
                const timePeriodPayload = formatTimePeriodPayload(attributes, serviceAvailabilityId)

                try {
                  const timePeriodResult = await createMenuMappingTimePeriod(timePeriodPayload)

                  if (timePeriodResult?.data) {
                    return Promise.resolve(timePeriodResult.data)
                  }
                } catch (err) {
                  return Promise.reject(`Failed to delete time period ${idx} for ${dayOfWeek}`)
                }
              }
            }
          }),
        )

        return Promise.resolve({
          serviceAvailability: serviceAvailabilityResult,
          timePeriods: timePeriodsPromisesResult,
        })
      }
    }),
  )

  if (availabilitiesToDelete?.length) {
    await Promise.all(
      availabilitiesToDelete.map(async (availId) => {
        try {
          const timePeriodResult = await deleteMenuMappingTimePeriod(availId)

          if (timePeriodResult?.data) {
            return Promise.resolve(timePeriodResult.data)
          }
        } catch (err) {
          return Promise.reject(`Failed to delete time period ${availId}`)
        }
      }),
    )
  }

  return updateResult
}

export default updateAvailabilitiesForMenuSection
