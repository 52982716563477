import { TimePeriodValues } from 'types/Menu'

const checkEmptyTimePeriods = (timePeriods: TimePeriodValues[]) => {
  let emptyCount = 0

  timePeriods.forEach((period) => {
    if (!period.start_time || !period.end_time) {
      emptyCount++
    }
  })

  if (timePeriods.length === emptyCount) {
    return true
  }
  return false
}

export default checkEmptyTimePeriods
