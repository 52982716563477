import styled from 'styled-components'
import { colors, fontWeight } from 'theme'

export const ItemCard = styled.div({
  display: 'grid',
  gridTemplateColumns: '72px 1fr',
  columnGap: 8,
})

export const ItemThumb = styled.div({
  width: 72,
  height: 72,
  borderRadius: 16,
  backgroundColor: '#C4C4C4',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  img: {
    objectFit: 'cover',
    objectPosition: '50% 50%',
    width: '100%',
    height: '100%',
  },
})

export const ItemContent = styled.div({
  overflow: 'hidden',
})

export const ItemName = styled.div({
  fontSize: '1rem',
  fontWeight: fontWeight.bold,
  color: colors.gray['900'],
  lineHeight: '1.1875rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

export const ItemDescription = styled.div({
  fontSize: '0.75rem',
  fontWeight: fontWeight.medium,
  color: colors.gray[500],
  lineHeight: '1rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

export const ItemPrice = styled.div<{ isError: boolean }>(
  {
    fontSize: '1rem',
    fontWeight: fontWeight.normal,
    lineHeight: '1.1875rem',
    marginTop: 4,
  },
  ({ isError }) => ({ color: isError ? colors.red.DEFAULT : colors.gray['900'] }),
)

export const ItemAction = styled.button<{ isError: boolean }>(
  {
    fontWeight: fontWeight.bold,
    marginTop: 11,
    float: 'left',
    svg: {
      width: '20px',
      height: '20px',
      float: 'right',
      marginLeft: '10px',
    },
  },
  ({ isError }) => ({ color: isError ? colors.red.DEFAULT : colors.primary.light }),
)
