import styled from 'styled-components'
import { colors, fontFamily, fontWeight } from 'theme'

export const ProgressContainer = styled.div({
  width: '90%',
  margin: '2rem auto 0',
})

export const Progress = styled.progress({
  width: '100%',
  transition: 'all .3s ease',
  '&::-moz-progress-bar': {
    background: colors.blue[100],
    borderRadius: '2rem',
    border: `1px solid ${colors.blue[100]}`,
  },
  '&::-moz-progress-value': {
    background: colors.primary.light,
    borderRadius: '2rem',
    border: `1px solid ${colors.primary.light}`,
  },
  '&::-webkit-progress-value': {
    background: colors.primary.light,
    borderRadius: '2rem',
    border: `1px solid ${colors.primary.light}`,
  },
  '&::-webkit-progress-bar': {
    background: colors.blue[100],
    borderRadius: '2rem',
    border: `1px solid ${colors.blue[100]}`,
  },
  color: colors.primary.light,
  borderRadius: '2rem',
  border: `1px solid ${colors.blue[100]}`,
  borderColor: colors.blue[100],
})

export const Label = styled.label({
  width: 'auto',
  textAlign: 'center',
  display: 'block',
  color: colors.primary.light,
  fontStyle: fontFamily.italic,
  fontSize: '0.90rem',
  lineHeight: 1,
})

export const StatusText = styled.div<{
  hasError?: boolean
  noTransform?: boolean
  hasSuccess?: boolean
}>(
  {
    fontWeight: fontWeight.normal,
    color: colors.black,
    textAlign: 'center',
    textTransform: 'capitalize',
    fontStyle: fontFamily.italic,
  },
  ({ noTransform }) => noTransform && { textTransform: 'none' },
  ({ hasError }) => hasError && { color: colors.red.DEFAULT, textTransform: 'none' },
  ({ hasSuccess }) => hasSuccess && { color: colors.green.DEFAULT, textTransform: 'none' },
)

export const TextButton = styled.button({
  marginLeft: '0.5rem',
  fontWeight: fontWeight.bold,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  color: colors.primary.light,
})
