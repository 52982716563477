import SalesSampleDataImg from 'assets/embedImages/SalesSampleData.png'
import { SigmaEmbed, SkeletonLoader } from 'components'
import { useLocations } from 'components/MainLayout/MainLayout'
import { sigmaPages } from 'config'
import { useEmbedUrl } from 'hooks'

function SalesReport() {
  const { locationsData } = useLocations()
  const { locations, isLoading } = locationsData

  const url = useEmbedUrl(sigmaPages.salesReport)

  if (isLoading) return <SkeletonLoader />
  else if (locations.length < 1)
    return <img src={SalesSampleDataImg} alt='Orders Sample Data Image' />
  return <SigmaEmbed url={url} title='Sales Report' />
}

export default SalesReport
