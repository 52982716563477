import styled from 'styled-components'
import { backdrop, colors } from 'theme'

export const Overlay = styled.div({
  ...backdrop,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const Card = styled.div<{
  width?: string | number
  height?: string | number
  minWidth?: string | number
  maxWidth?: string | number
  minHeight?: string | number
  maxHeight?: string | number
  borderRadius?: string | number
}>(
  {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.white,
  },
  ({ width, height, minWidth, maxWidth, minHeight, maxHeight, borderRadius }) => ({
    ...(width && { width }),
    ...(height && { height }),
    ...(minWidth && { minWidth }),
    ...(maxWidth && { maxWidth }),
    ...(minHeight && { minHeight }),
    ...(maxHeight && { maxHeight }),
    ...(borderRadius && { borderRadius }),
  }),
)
