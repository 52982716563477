import leftArrowIcon from 'assets/icons/left-arrow.svg'
import { useGetOrderPlatform, useRouteLocationId, useRoutePlatformId } from 'hooks'
import { Link, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { partnerQuery } from 'state/atoms'

import * as Styled from './styles'

function Header() {
  const { pathname } = useLocation()
  const locationId = useRouteLocationId()
  const platformId = useRoutePlatformId()
  const partner = useRecoilValue(partnerQuery(locationId))
  const { data: platformResponse } = useGetOrderPlatform(platformId)
  const partnerAttrs = platformResponse?.included?.find(({ type }) => type === 'partner')
  const platformLocationId = partnerAttrs?.id
  const locationName = partnerAttrs?.attributes.name
  const setPageTitle = (pathname: string) => {
    let title

    if (pathname === '/home') {
      title = 'Health Dashboard'
    } else if (pathname === '/wow') {
      title = 'Week Over Week'
    } else if (locationId || pathname === '/add-location') {
      if (pathname.includes('/preview') || pathname.includes('/menus')) {
        title = (
          <Link to={`/manage-locations/${locationId}`}>
            <Styled.LocationsBackContainer>
              <Styled.LeftArrowImg src={leftArrowIcon} alt='left arrow' />
              <Styled.LocationHeaderText>{`Location: ${partner?.data.attributes.name}`}</Styled.LocationHeaderText>
            </Styled.LocationsBackContainer>
          </Link>
        )
      } else {
        title = (
          <Link to='manage-locations'>
            <Styled.LocationsBackContainer>
              <Styled.LeftArrowImg src={leftArrowIcon} alt='down arrow' />
              <Styled.LocationHeaderText>Locations</Styled.LocationHeaderText>
            </Styled.LocationsBackContainer>
          </Link>
        )
      }
    } else if (platformId) {
      title = (
        <Link to={`manage-locations/${platformLocationId}`}>
          <Styled.LocationsBackContainer>
            <Styled.LeftArrowImg src={leftArrowIcon} alt='left arrow' />
            <Styled.LocationHeaderText>Manage Location : {locationName}</Styled.LocationHeaderText>
          </Styled.LocationsBackContainer>
        </Link>
      )
    } else {
      title = pathname.split('/').slice(-1)[0]?.replace(/[/-]/g, ' ').trim().toUpperCase()
    }
    return title
  }

  return (
    <Styled.Container>
      <Styled.Title>{setPageTitle(pathname)}</Styled.Title>

      {/* <Styled.ToolBar>
        <ChowlyHelpButton />
        <UserMenu />
      </Styled.ToolBar> */}
    </Styled.Container>
  )
}

export default Header
