import styled from 'styled-components'
import { fontWeight } from 'theme'

export const ItemsGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '1rem',
})

export const Title = styled.div({
  fontSize: '1.25rem',
  fontWeight: fontWeight.bold,
  span: {
    marginLeft: '0.5rem',
    fontSize: '1rem',
    fontWeight: fontWeight.normal,
  },
})
