import { FC } from 'react'

import * as Styled from './styles'

type Props = {
  navigation: () => void
  closeWarning: () => void
}

const ResetButtonGroup: FC<Props> = ({ navigation, closeWarning, ...props }) => {
  return (
    <Styled.WarningDiv>
      <Styled.WarningMessage>
        Starting over will clear all information you have provided so far.
      </Styled.WarningMessage>
      <Styled.WarningMessage>Do you want to start over?</Styled.WarningMessage>
      <Styled.SmallYesButton
        onClick={navigation}
        size={'small'}
        type='button'
        text={'yes'}
        {...props}
      >
        Yes
      </Styled.SmallYesButton>

      <Styled.SmallNoButton
        onClick={closeWarning}
        size={'small'}
        type='button'
        text={'No'}
        {...props}
      >
        No
      </Styled.SmallNoButton>
    </Styled.WarningDiv>
  )
}

export default ResetButtonGroup
