import 'react-toastify/dist/ReactToastify.min.css'
import 'rc-time-picker/assets/index.css'
import 'react-loading-skeleton/dist/skeleton.css'

import './styles/index.css'

import * as Sentry from '@sentry/react'
import { Auth0Provider } from 'libs/auth0-react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import App from './App.js'
import { appSettings, env, sentryDsn } from './config'
import * as serviceWorker from './serviceWorker'

if (!appSettings.isDevelopment && env !== 'test') {
  Sentry.init({
    dsn: sentryDsn,
    environment: String(env),
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.5,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')
const root = createRoot(rootElement)

root.render(
  <StrictMode>
    <Auth0Provider>
      <App />
    </Auth0Provider>
  </StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
