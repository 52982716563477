import Button from 'components/Button/Button'
import styled from 'styled-components'
import { borderRadius, breakpoints, colors, fontFamily, fontWeight, typography } from 'theme'

export const LocationStatusContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '0.5rem',
  width: '100%',
})

export const UpchargeLabelWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

export const UpchargeText = styled.div({
  fontSize: '10px',
  color: '#777777',
  fontWeight: fontWeight.normal,
  textAlign: 'end',
})

export const UpchargeContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  rowGap: '10px',
  bottom: 0,
})

export const SliderContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontSize: '10px',
  color: '#777777',
  fontWeight: fontWeight.normal,
  gap: '8px',
})

export const SliderText = styled.div({
  width: '100%',
  fontSize: '10px',
  color: '#777777',
  fontWeight: fontWeight.normal,
})

export const UpchargeHeader = styled.label({
  color: colors.primary.medium,
  fontFamily: fontFamily.inter,
  fontSize: typography.semiXS.fontSize,
  fontStyle: 'normal',
  fontWeight: fontWeight.bold,
  lineHeight: '150%' /* 22.5px */,
})

export const ButtonRowContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',
})

export const ApplyButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'right',
  marginTop: 'auto',
})

export const BaseButton = styled(Button)<{ variant?: string }>(
  {
    minWidth: 'auto',
    padding: '8px 16px 8px 16px',
    fontFamily: fontFamily.inter,
    fontSize: typography.semiXS.fontSize,
    fontWeight: fontWeight.semibold,
    lineHeight: typography.semiXS.lineHeight,
    textWrap: 'nowrap',
    letterSpacing: '0em',
    textAlign: 'left',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    color: colors.blue[700],
    borderColor: colors.primary.light,
    borderWidth: 1,
    height: '39px',
    '&:active': {
      backgroundColor: colors.blue[100],
    },
    [`@media ${breakpoints.xxs}`]: {
      fontSize: typography.xs.fontSize,
      lineHeight: typography.xs.lineHeight,
    },
  },
  ({ variant }) =>
    variant === 'solid' && {
      backgroundColor: colors.blue[700],
      color: colors.gray[450],
    },
)

export const Input = styled.input({
  width: '100%',
  padding: '0.75rem 2.6rem .75rem 1rem',
  backgroundColor: colors.white,
  appearance: 'none',
  borderWidth: 1,
  borderColor: colors.blue['300'],
  borderRadius: borderRadius.lg,
  textIndent: '.3rem',
  fontSize: '1rem',
  lineHeight: '1.3rem',
  float: 'right',
})
