import chevronDownUp from 'assets/icons/chevron-down-up.svg'
import { FC } from 'react'
import RSelect, { GroupBase, OptionsOrGroups } from 'react-select'
import { CSSObject } from 'styled-components'
import { colors } from 'theme'

export type Option = { label: string; value: string }

type Props = {
  ariaLabel?: string
  defaultValue?: string
  value?: Option
  options: OptionsOrGroups<Option, GroupBase<Option>>
  isDisabled?: boolean
  placeholder?: string
  name?: string
  styles?: {
    container?: CSSObject
    control?: CSSObject
    menu?: CSSObject
    menuList?: CSSObject
    option?: CSSObject
    singleValue?: CSSObject
    placeholder?: CSSObject
    indicatorSeparator?: CSSObject
    indicatorsContainer?: CSSObject
    valueContainer?: CSSObject
  }
  onChange?: (newValue: string) => void
  isError?: boolean
  dropdownIndicator?: string
  indicatorStyle?: CSSObject
}

const SingleSelect: FC<Props> = ({
  options,
  defaultValue,
  ariaLabel,
  value,
  placeholder,
  isDisabled,
  name,
  styles,
  onChange,
  isError,
  dropdownIndicator,
  indicatorStyle,
}) => {
  return (
    <RSelect
      aria-label={ariaLabel}
      name={name || ''}
      options={options}
      isDisabled={isDisabled}
      isClearable={false}
      isSearchable={false}
      placeholder={placeholder}
      menuPortalTarget={document.body}
      components={{
        DropdownIndicator: () => (
          <img src={dropdownIndicator ?? chevronDownUp} style={indicatorStyle} />
        ),
      }}
      styles={{
        container: (provided) => ({
          ...provided,
          ...styles?.container,
        }),
        control: (provided, { menuIsOpen }) => ({
          ...provided,
          display: 'flex',
          borderColor: colors.blue['300'],
          borderRadius: 8,
          ...(menuIsOpen && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }),
          backgroundColor: colors.blue['40'],
          boxShadow: 'none',
          ':hover': {
            borderColor: colors.blue['300'],
          },
          ...styles?.control,
          ...(isError && {
            borderColor: colors.red.DEFAULT,
          }),
        }),
        indicatorSeparator: () => ({
          width: 0,
          ...styles?.indicatorSeparator,
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          paddingRight: '1rem',
          ...styles?.indicatorsContainer,
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: 0,
          width: 128,
          zIndex: 1000,
          borderRadius: '0 0 8px 8px',
          overflow: 'hidden',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
          backdropFilter: 'blur(4px)',
          borderWidth: 1,
          borderTopWidth: 0,
          borderColor: colors.blue['300'],
          ...styles?.menu,
        }),
        menuList: (provided) => ({
          ...provided,
          maxHeight: 245,
          backgroundColor: colors.white,
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            width: 0,
          },
          ...styles?.menuList,
        }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 999,
        }),
        option: (provided, { isFocused, isSelected }) => ({
          ...provided,
          fontWeight: 500,
          ...((isFocused || isSelected) && {
            backgroundColor: colors.primary.light,
            color: colors.white,
          }),
          ':active': {
            ...provided[':active'],
            backgroundColor: colors.primary.light,
            color: colors.white,
          },
          ...styles?.option,
        }),
        singleValue: (provided) => ({
          ...provided,
          fontWeight: 700,
          color: colors.gray.darkest,
          ...styles?.singleValue,
        }),
        placeholder: (provided) => ({
          ...provided,
          fontWeight: 700,
          color: colors.gray.darkest,
          ...styles?.placeholder,
        }),
        valueContainer: (provided) => ({
          ...provided,
          ...styles?.valueContainer,
        }),
      }}
      value={value}
      defaultValue={defaultValue ? { value: defaultValue, label: defaultValue } : undefined}
      onChange={(selected) => {
        if (typeof selected === 'string') {
          onChange?.(selected)
        } else if (selected) {
          onChange?.(selected.value)
        }
      }}
    />
  )
}

export default SingleSelect
