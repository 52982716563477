import { useAuth0 } from 'libs/auth0-react'
import { useLocation } from 'react-router-dom'

function Logout() {
  const { logout } = useAuth0()
  const { search } = useLocation()
  const isInvalidGoogleUser = search.includes('invalid_google_user')

  logout({
    logoutParams: {
      returnTo: `${window.location.origin}/login${
        isInvalidGoogleUser ? '?invalid_google_user=true' : ''
      }`,
    },
  })

  return null
}

export default Logout
