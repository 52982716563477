import {
  CustomizationOptionsByCustomizationId,
  ItemModifier,
  ItemModifierGroup,
  MenuMappingCustomizationCustomizationOption,
  MenuMappingCustomizationOption,
  MenuMappingCustomizationOptionResource,
  MenuMappingCustomizationResource,
  MenuMappingNestedCustomization,
  SectionModifier,
  TypeName,
} from 'api'
import { chowlyApi } from 'config'
import { toast } from 'react-toastify'

import client from '../chowly'

// Separate endpoint for customization options: api/v3/menu_mapping/customization_options?menu_mapping_menu_id={ID} GET
// Customization Options = Modifers; Customizations = Modifier Groups
// The nesting for groups and modifiers can repeat 2 levels down, represented by nestedCustomizations, which have the customization option as the parent.
export const getMenuMappingCustomizationOptions = async (menuMappingMenuId: string) => {
  const url = chowlyApi.menuMappingCustomizationOptions

  // Initialize empty objects for formattedData and formattedGroup
  const formattedData: CustomizationOptionsByCustomizationId = {}
  const formattedGroups: { [key: string]: ItemModifierGroup } = {}

  try {
    const { data } = await client.get(
      `${url}?menu_mapping_menu_id=${menuMappingMenuId}&include=customizations,nested_customizations.customization_options,nested_customizations.customizations,customization_customization_options.customization_option_price`,
    )

    const customizationOptionsData: MenuMappingCustomizationOptionResource[] = data.data || []
    const includedData: any[] = data.included || []
    const combinedData = [...customizationOptionsData, ...includedData]

    // Map the data just like formatted data below, plus easy access later
    const modifierGroups: Record<string, MenuMappingCustomizationResource> = {}
    const customizationCustomizationOptions: Record<
      string,
      MenuMappingCustomizationCustomizationOption
    > = {}
    const nestedCustomizations: Record<string, MenuMappingNestedCustomization> = {}

    // Sort the included data out once into their own objects key/value pairs for O(1) access later
    includedData.forEach((dataField: any) => {
      if (dataField.type === TypeName.MenuMappingCustomization) {
        modifierGroups[dataField.id] = dataField
      }

      if (dataField.type === TypeName.MenuMappingCustomizationCustomizationOption) {
        customizationCustomizationOptions[dataField.id] = dataField
      }

      if (dataField.type === TypeName.MenuMappingNestedCustomization) {
        nestedCustomizations[dataField.id] = dataField
      }
    })

    const formatNestedOptionsData = (nestedModifiers?: MenuMappingCustomizationOption[]) => {
      const formattedOptionsData: SectionModifier[] = []

      if (!nestedModifiers?.length) {
        return []
      }

      nestedModifiers.forEach((modifier) => {
        const modifierObj = combinedData.find((entry) => entry.id === modifier.id)

        if (!modifierObj) {
          return null
        }

        const customizationCustomizationOptionId =
          modifierObj.relationships.customization_customization_options?.data?.[0]?.id

        let optionPrice

        // have to get the price through this join table
        if (customizationCustomizationOptionId) {
          optionPrice =
            customizationCustomizationOptions[customizationCustomizationOptionId]?.attributes
              .customization_option_price
        }

        if (modifierObj) {
          formattedOptionsData.push({
            id: modifierObj.id,
            type: modifierObj.type,
            attributes: {
              ...modifierObj.attributes,
              price: optionPrice,
            },
          })
        }
      })

      return formattedOptionsData
    }

    const formatNestedGroupsData = (
      nestedGroups?: MenuMappingNestedCustomization[],
    ): ItemModifierGroup[] => {
      const nestedModifierGroups: ItemModifierGroup[] = []

      nestedGroups?.forEach((group) => {
        const nestedGroupFullObj: MenuMappingNestedCustomization | undefined =
          nestedCustomizations[group.id]

        if (!nestedGroupFullObj) {
          return null
        }

        const customizationObj: MenuMappingCustomizationResource | undefined =
          modifierGroups[nestedGroupFullObj.relationships.customization?.data?.id]
        const groupCustomizationOptions: MenuMappingCustomizationOption[] | undefined =
          customizationObj?.relationships.customization_options?.data

        nestedModifierGroups.push({
          id: group.id,
          type: group.type,
          attributes: {
            sort_position: nestedGroupFullObj.attributes.sort_position,
            title: customizationObj?.attributes.title,
            max_permitted: customizationObj?.attributes.max_permitted,
            min_permitted: customizationObj?.attributes.min_permitted,
          },
          children: {
            customization_options: formatNestedOptionsData(groupCustomizationOptions),
          },
        })
      })

      return nestedModifierGroups
    }

    customizationOptionsData.forEach(
      (customizationOption: MenuMappingCustomizationOptionResource) => {
        const customizations = customizationOption.relationships?.customizations?.data
        const nestedCustomizations = customizationOption.relationships?.nested_customizations?.data
        const ccOptions =
          customizationOption.relationships?.customization_customization_options?.data
        const fullCcOptionObjs: MenuMappingCustomizationCustomizationOption[] = []

        // the price of the customization option (modifier) lives on the customizationCustomizationOption join table.

        // filter out full ccOptions belonging to this customizationOption
        ccOptions?.forEach((ccOption) => {
          // 0(1) lookup on main ccOption object
          if (customizationCustomizationOptions[ccOption.id]) {
            fullCcOptionObjs.push(customizationCustomizationOptions[ccOption.id])
          }
        })

        customizations?.forEach((customization) => {
          const customizationId = customization.id

          // find THE customizationCustomizationOption on this customization and customizationOption
          const matchingCcOption = fullCcOptionObjs.find(
            (ccOption) => ccOption.relationships.customization.data.id === customization.id,
          )
          const customizationOptionPrice = matchingCcOption?.attributes.customization_option_price

          if (customizationId) {
            const modifierData: ItemModifier = {
              id: customizationOption.id,
              type: customizationOption.type,
              attributes: {
                external_id: customizationOption.attributes.external_id,
                suspend_until: customizationOption.attributes.suspend_until,
                title: customizationOption.attributes.title,
                price: customizationOptionPrice,
              },
              children: {
                customizations: formatNestedGroupsData(nestedCustomizations),
              },
            }

            if (formattedData[customizationId]) {
              formattedData[customizationId]?.push(modifierData)
            } else {
              formattedData[customizationId] = [modifierData]
            }

            // Find the modifier group for the customizationId
            const modifierGroup = modifierGroups[customizationId]
            // If it exists, create or push to formattedGroup array
            if (modifierGroup) {
              // Create new modifier item with additional information
              formattedGroups[customizationId] = {
                ...modifierGroup,
                children: {
                  customization_options: formattedData[customizationId],
                },
              }
            }
          }
        })
      },
    )

    if (Object.keys(formattedData).length || Object.keys(formattedGroups).length) {
      return { formattedData, formattedGroups }
    } else {
      throw new Error('No data available')
    }
  } catch (err) {
    console.log('Error fetching customization options', err)
    toast.error('Error fetching modifiers. Please try again later.')
    throw err // Re-throw the error to indicate failure
  }
}
