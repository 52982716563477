import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { fontWeight, transition, typography } from 'theme'

import Box from '../Box/Box'

export const Link = styled(RouterLink)({
  ...transition,
  transitionDuration: '300ms',
  '&:hover': {
    transform: 'scaleX(1.05) scaleY(1.05);',
  },
})

export const Card = styled(Box)({
  padding: '1.25rem',
  height: '15rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  columnGap: '0.75rem',
})

export const IconWrap = styled.div({
  padding: '2.5rem',
  img: {
    width: '5.5rem',
    height: '5.5rem',
  },
})

export const TitleContainer = styled.div({
  width: '100%',
})

export const Title = styled.h4({
  ...typography.xxl,
  lineHeight: 1,
  fontWeight: fontWeight.bold,
})
