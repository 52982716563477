import { EmailVerificationInput, sendVerificationEmail } from 'api'
import { ReactComponent as ChowlyLogo } from 'assets/logo.svg'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import * as Styled from './styles'

const onVerify = async (email: string | null, userId: string | null) => {
  if (!email || !userId) {
    return
  }

  const payload: EmailVerificationInput = {
    data: {
      attributes: {
        email: email,
        user_id: userId,
      },
    },
  }
  await sendVerificationEmail(payload)
  toast.success('Verification email sent!')
}

const VerifyEmail: React.FC = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email')
  const userId = searchParams.get('user_id')

  return (
    <Styled.VerifyContainer>
      <Styled.VerifyHeader>
        <ChowlyLogo />
      </Styled.VerifyHeader>
      <Styled.VerifyEmailContainer>
        <Styled.VerifyTitle>
          <p>
            Please verify your email <span>{email}</span> in order to login.
          </p>
          <p>If you are having trouble finding verification email, click below to send again: </p>
          <Styled.VerifyButton type={'button'} onClick={() => onVerify(email, userId)}>
            Resend Verification Email
          </Styled.VerifyButton>
        </Styled.VerifyTitle>
        <p>
          <Styled.AnchorSignUpLink href={'/login'}>Login</Styled.AnchorSignUpLink>
        </p>
      </Styled.VerifyEmailContainer>
    </Styled.VerifyContainer>
  )
}

export default VerifyEmail
