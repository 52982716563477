import { getActivationCodes, startToosOauth } from 'api'
import ellipse_2_green from 'assets/icons/ellipse-2-green.svg'
import ellipse_2_red from 'assets/icons/ellipse-2-red.svg'
import x_close from 'assets/icons/x-close.svg'
import PopupWindow from 'components/PopupWindow/PopupWindow'
import Toast from 'components/Toast/Toast'
import { appUrl, chowlyApi } from 'config'
import AddToosPlatform from 'features/AddPlatformSideDrawer/AddToosPlatform'
import AdminActivation from 'features/AddPlatformSideDrawer/AdminActivation'
import ResetOnboardingPlatform from 'features/AddPlatformSideDrawer/ResetOnboardingPlatform'
import { useCloseModal, useGetLocationPartners, useGetPlatformsOnboarding } from 'hooks'
import { useAuth0 } from 'libs/auth0-react'
import { useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  activePlatformIdState,
  doordashActivationLinkState, mayDeletePlatform,
  uberEatsActivationLinkState
} from 'state/atoms';
import { PlatformName } from 'types'
import { showError, toosPopupWindowDimensions } from 'utils'

import * as Styled from './styles'

export type Response = {
  order_platform_id: string
}

const PLATFORM_STATUS = {
  ENABLED: 'enabled',
  ONBOARDING: 'onboarding',
  DISABLED: 'disabled',
  ARCHIVED: 'archived',
}

enum LocationKeys {
  search = 'search',
  hash = 'hash',
}

enum ResponseKey {
  ORDER_PLATFORM_ID = 'order_platform_id',
}

function AddPlatformModal() {
  const { user, accessToken } = useAuth0()
  //   const [menuPlatforms, setMenuPlatforms] = useRecoilState(menuPlatformsState)
  const [onboardingPlatformName, setOnboardingPlatformName] = useState<string | null>(null)
  const setDoordashActivationLink = useSetRecoilState(doordashActivationLinkState)
  const setUberEatsActivationLink = useSetRecoilState(uberEatsActivationLinkState)
  const [isGeneratingUberEatsActivationLink, setIsGeneratingUberEatsActivationLink] =
    useState(false)
  const [toosType, setToosType] = useState<PlatformName>()
  const setOrderPlatformState = useSetRecoilState(activePlatformIdState)
  const [enablePlatform, setEnablePlatform] = useState(false)
  const [adminOpen, setAdminOpen] = useState(false)
  //   const [platformDropdownOpen, setPlatformDropdownOpen] = useState(false)

  const { partner } = useGetLocationPartners()

  const {
    filterUberPlatforms,
    filterDoordashPlatforms,
    existingUberPlatform,
    existingDoordashPlatform,
    reload,
    isLoading: platformsIsLoading,
  } = useGetPlatformsOnboarding()

  const isAdminUser = user?.email?.includes('@chowlyinc.com')
  const partnerId = partner?.attributes?.id
  const partnerName = partner?.attributes?.name
  //   const addPlatformUserCheck = isAdminUser || appSettings.isDevelopment || appSettings.isStaging
  const closeModal = useCloseModal()

  const locationKey = LocationKeys.search
  const isCrossOrigin = false

  // TODO: Simplify this condition in the future on the backend.
  // If has onboardingId and accessToken remove Add Platform button shows up.
  // Remove platform button should clear accessToken.
  const canStartDoorDash = !useRecoilValue(mayDeletePlatform(filterDoordashPlatforms[0]?.id))

  const canStartUberEats =
    !existingUberPlatform ||
    (filterUberPlatforms[0].aasmState === PLATFORM_STATUS.ONBOARDING &&
      !filterUberPlatforms[0].hasAccessToken)

  const navigateToLocations = async (toosUrlKey: keyof typeof chowlyApi) => {
    if (!accessToken || !partnerId) return

    const toosOauthUrl = await startToosOauth({
      accessToken,
      partnerId,
      toosUrlKey: toosUrlKey,
    })

    const popup = PopupWindow.open('UberLogin', toosOauthUrl, toosPopupWindowDimensions, {
      locationKey,
      isCrossOrigin,
    })

    const onFailure = (error: Error) => {
      console.error('Ubereats failure. reason:', error)
      showError(error)
    }

    popup.then((data: Response) => {
      const responseKey = ResponseKey.ORDER_PLATFORM_ID
      if (!isCrossOrigin && !data[responseKey]) {
        onFailure(new Error(`'${responseKey}' not found in received data`))
      } else {
        setOrderPlatformState(data[responseKey])
        setEnablePlatform(true)
      }
    })
  }

  const navigateToDoordashLocations = async () => {
    setOnboardingPlatformName(PlatformName.DOORDASH)
    await navigateToLocations('doordashUrl')
  }

  const navigateToUbereatsLocations = async () => {
    setOnboardingPlatformName(PlatformName.UBEREATS)
    await navigateToLocations('ubereatsUrl')
  }

  const generateDoordashActivationLink = async () => {
    if (!accessToken || !partnerId) {
      return
    }
    setDoordashActivationLink(
      await startToosOauth({
        accessToken,
        partnerId,
        toosUrlKey: 'doordashUrl',
        redirectUrl: `${window.location.origin}/doordash_success`,
      }),
    )
    setToosType(PlatformName.DOORDASH)
    setAdminOpen(true)
  }

  const generateUberEatsActivationLink = async () => {
    try {
      if (!partnerId) {
        throw new Error('Location ID is undefined')
      }
      setIsGeneratingUberEatsActivationLink(true)
      const { data } = await getActivationCodes({ partnerId, kind: 'ubereats' })
      setUberEatsActivationLink(`${appUrl}/ubereats_auth_link_check?link=${data.attributes.url}`)
      setIsGeneratingUberEatsActivationLink(false)
      setToosType(PlatformName.UBEREATS)
      setAdminOpen(true)
    } catch (error) {
      setIsGeneratingUberEatsActivationLink(false)
      Toast.error({ message: (error as Error).message ?? 'Something went wrong' })
    }
  }

  const resetEnablePlatform = () => {
    setEnablePlatform(false)
  }

  const setEnable = () => {
    if (toosType === PlatformName.UBEREATS) {
      if (filterUberPlatforms && filterUberPlatforms[0]?.id) {
        setOrderPlatformState(String(filterUberPlatforms[0].id))
        setEnablePlatform(true)
        setOnboardingPlatformName(PlatformName.UBEREATS)
      }
    } else if (toosType === PlatformName.DOORDASH) {
      if (filterDoordashPlatforms && filterDoordashPlatforms[0]?.id) {
        setOrderPlatformState(String(filterDoordashPlatforms[0]?.id))
        setEnablePlatform(true)
        setOnboardingPlatformName(PlatformName.DOORDASH)
      }
    }
  }

  const platforms = [
    {
      name: 'Doordash',
      action: navigateToDoordashLocations,
      adminAction: generateDoordashActivationLink,
      disabled: !canStartDoorDash,
      status: filterDoordashPlatforms?.[0]?.aasmState,
      adminDisabled: false,
      canRemove: !canStartDoorDash,
      existingPlatform: existingDoordashPlatform,
      platform: filterDoordashPlatforms[0],
    },
    {
      name: 'Uber Eats',
      action: navigateToUbereatsLocations,
      adminAction: generateUberEatsActivationLink,
      disabled: !canStartUberEats, // temp, later change to !canStartUberEats
      status: filterUberPlatforms?.[0]?.aasmState,
      adminDisabled: isGeneratingUberEatsActivationLink,
      canRemove: !canStartUberEats,
      existingPlatform: existingUberPlatform,
      platform: filterUberPlatforms[0],
    },
    {
      name: 'Grubhub',
      placeholderText: 'Coming Soon',
    },
  ]

  return (
    <Styled.Container>
      {enablePlatform && onboardingPlatformName ? (
        <>
          <Styled.Header>
            Add {onboardingPlatformName}
            <Styled.Icon src={x_close} alt='closeIcon' onClick={closeModal} />
          </Styled.Header>
          <Styled.Subheader>
            {onboardingPlatformName} Account: {partnerName}
          </Styled.Subheader>
          <Styled.ColumnHeader>{onboardingPlatformName} Locations</Styled.ColumnHeader>
          <AddToosPlatform
            platformName={onboardingPlatformName}
            resetEnablePlatform={resetEnablePlatform}
          />
        </>
      ) : adminOpen ? (
        <>
          <Styled.Header>
            Manage Customer Platform Setup
            <Styled.Icon src={x_close} alt='closeIcon' onClick={closeModal} />
          </Styled.Header>
          <AdminActivation type={toosType} continueOnboarding={setEnable} onClose={closeModal} />
        </>
      ) : (
        <>
          <Styled.Header data-testid='addPlatformBttn'>
            Add Platform
            <Styled.Icon src={x_close} alt='closeIcon' onClick={closeModal} />
          </Styled.Header>
          <Styled.Subheader>Please select below from the available platforms.</Styled.Subheader>
          <Styled.ColumnHeader>Available Platforms:</Styled.ColumnHeader>
          {platforms.map((platform) => (
            <Styled.PlatformRow key={platform.name}>
              <Styled.StatusContainer>
                <Styled.PlatformName>{platform.name}</Styled.PlatformName>
                {platform.status && (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <Styled.StatusIcon
                      src={
                        platform.status === PLATFORM_STATUS.ENABLED
                          ? ellipse_2_green
                          : ellipse_2_red
                      }
                      alt='ellipseIcon'
                    />
                    <Styled.StatusText isSuccess={platform.status === PLATFORM_STATUS.ENABLED}>
                      {platform.status}
                    </Styled.StatusText>
                  </div>
                )}
              </Styled.StatusContainer>
              <Styled.PlatformButtons>
                {platform.action && !platformsIsLoading && !platform.disabled && (
                  <Styled.BaseButton onClick={platform.action} variant='solid' width='fit-content'>
                    Add Platform
                    {/*
                        This dropdown is for non-admins, enable later.
                        {!addPlatformUserCheck && platformDropdownOpen && (
                          <Styled.Dropdown>
                            <div>login</div>
                            <div>signup</div>
                          </Styled.Dropdown>
                        )} */}
                  </Styled.BaseButton>
                )}
                {platform.action && isAdminUser && !platformsIsLoading && !platform.adminDisabled && (
                  <Styled.BaseButton
                    onClick={platform.adminAction}
                    variant='outline'
                    width='fit-content'
                  >
                    Admin Auth
                  </Styled.BaseButton>
                )}
                {platform.canRemove && (
                  <ResetOnboardingPlatform
                    existingPlatform={platform.existingPlatform}
                    platform={platform.platform}
                    reload={reload}
                    platformName={platform.name}
                  />
                )}
              </Styled.PlatformButtons>
              {!platform.action && (
                <Styled.PlatformPlaceholder>{platform.placeholderText}</Styled.PlatformPlaceholder>
              )}
            </Styled.PlatformRow>
          ))}
        </>
      )}
    </Styled.Container>
  )
}

export default AddPlatformModal
