import styled from 'styled-components'
import { breakpoints } from 'theme'

export const IconWrapper = styled.a({
  width: '2rem',
  height: '2rem',
  display: 'none',
  // borderRadius: borderRadius.full,
  // boxShadow: shadow.lg,
  [`@media ${breakpoints.md}`]: {
    display: 'block',
    width: '32px',
    height: '32px',
  },
})

export const Avatar = styled.img({
  width: '2rem',
  height: '2rem',
  [`@media ${breakpoints.md}`]: {
    width: '32px',
    height: '32px',
    maxWidth: 'none',
  },
})
