import styled from 'styled-components'
import { colors } from 'theme'

export const AlertCircle = styled.img({
  width: 24,
  height: 24,
  color: colors.warning.DEFAULT,
})

export const ModalBox = styled.div({
  maxWidth: 600,
  borderRadius: '8px',
  display: 'flex',
  gap: 12,
  padding: 32,
})

export const TextContainer = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
})

export const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 24,
})
