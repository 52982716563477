import { ErrorMessage, ShortcutLoader } from 'components'
import LocationDropdown from 'components/LocationDropdown/LocationDropdown'
import { useGetLocationPartners, useGetMenuImportStatuses } from 'hooks'
import { useEffect } from 'react'
import { LocationSummary } from 'types'

import MenuImportShortcut from './MenuImportShortcut'
import MenuShortcut from './MenuShortcut'
import PlatformShortcut from './PlatformShortcut'
import * as Styled from './styles'

export type Platforms = {
  enabledPlatforms: LocationSummary['enabledPlatforms']
  disabledPlatforms: LocationSummary['disabledPlatforms']
  onboardingPlatforms?: LocationSummary['onboardingPlatforms']
}

type Props = {
  partnerData: {
    locations: LocationSummary[]
    isLoading: boolean
    isError: boolean
  }
  selectedLocation: LocationSummary
  setSelectedLocation: (location: LocationSummary) => void
}

const Shortcut = ({
  partnerData: { locations, isLoading, isError },
  selectedLocation,
  setSelectedLocation,
}: Props) => {
  const { partner, platforms, menuMappingMenuId, refetch } = useGetLocationPartners(
    selectedLocation?.id?.toString(),
  )

  const posName = partner?.attributes?.pos?.toLowerCase() || ''
  const isPrinterClient = posName.includes('epson') // We don't want to show printer clients the menu resync option

  const [{ menuImportStatuses, isLoading: isMenuStatusLoading }] = useGetMenuImportStatuses(
    selectedLocation?.id?.toString(),
    1,
  )

  useEffect(() => {
    setSelectedLocation(selectedLocation?.id ? selectedLocation : locations[0])
  }, [locations])

  return (
    <Styled.ShortcutContainer>
      {isError && <ErrorMessage />}
      {!isError && selectedLocation && (
        <>
          <Styled.ShortcutsHeader>Shortcuts</Styled.ShortcutsHeader>
          {isLoading ? (
            <ShortcutLoader />
          ) : (
            <LocationDropdown
              locations={locations}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
            />
          )}
          <MenuShortcut selectedLocation={selectedLocation} />

          {!isPrinterClient && (
            <MenuImportShortcut
              menuImportStatuses={menuImportStatuses}
              menuMappingMenuId={menuMappingMenuId}
              locationId={selectedLocation?.id}
              isMenuLoading={isMenuStatusLoading}
            />
          )}
          <PlatformShortcut platforms={platforms} refetch={refetch} />
        </>
      )}
    </Styled.ShortcutContainer>
  )
}

export default Shortcut
