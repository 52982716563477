import * as Styled from './styles'

type Props = {
  children: JSX.Element
  position: 'top' | 'bottom'
  padding?: string
}

const ActionBar = ({ children, position, padding }: Props) => {
  return (
    <Styled.ActionContainer position={position} padding={padding}>
      {children}
    </Styled.ActionContainer>
  )
}

export default ActionBar
