import { FC } from 'react'

import * as Styled from './styles'

type Props = {
  name: string
}

const Tag: FC<Props> = ({ name }) => <Styled.Tag>{name}</Styled.Tag>

Tag.displayName = 'Tag'

export default Tag
