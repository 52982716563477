import styled from 'styled-components'
import {
    colors, fontFamily, fontWeight, typography,
} from 'theme'

export const DropdownContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // gap: '12px',
    margin: '24px 0 24px',
    position: 'relative',
    width: '100%'
})

export const ViewLocation = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // gap: '12px',
    color: colors.blue['700'],
    textAlign: 'right',
    fontFamily: fontFamily.inter,
    fontSize: typography.xxs.fontSize,
    fontStyle: 'normal',
    fontWeight: fontWeight.normal,
    lineHeight: '150%', /* 19.5px */
    textDecorationLine: 'underline',
    marginTop: '12px'
})

export const Dropdown = styled.div<{singleLocation: boolean}>(
    {
        display: 'flex',
        padding: '12px',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: '16px',
        borderRadius: '8px',
        border: `1px solid ${colors.gray.medium}`,
        position: 'relative',
        width: '100%'
    },
    ({singleLocation}) => 
        singleLocation && {
            border: 0,
            textDecorationLine: 'underline',
            padding: '12px 12px 12px 0'
    }
)

export const DropDownOptions = styled.div<{ active: boolean }>(
    {
        position: 'absolute',
        top: '80px',
        backgroundColor: 'red',
        gap: 0,
        display: 'flex',
        padding: '4px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '8px',
        background: `var(--Global-Background-Tertiary, ${colors.gray['450']})`,
        boxShadow: '0px 4px 60px 0px rgba(0, 0, 0, 0.10)',
        width: '100%',
    },
    ({ active }) => 
        !active && {
            display: 'none'
        }
)

export const OptionsRow = styled.div({
    display: 'flex',
    padding: '6px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    ':hover': {
        borderRadius: '5px',
        background: 'rgba(27, 81, 164, 0.10)',
    }
})

export const RowDetails = styled.div({
    color: `var(--Global-Text-Primary, ${colors.primary.medium})`,
    textAlign: 'right',
    fontFamily: fontFamily.inter,
    fontSize: typography.xxs.fontSize,
    fontStyle: 'normal',
    fontWeight: fontWeight.normal,
    lineHeight: '150%', /* 19.5px */
})

export const Icon = styled.img<{singleLocation: boolean}>({
    width: '24px',
    height: '24px',
})

export const LocationContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '8px',
})

export const LocationHeader = styled.div({
    color: colors.primary.medium,
    fontFamily: fontFamily.inter,
    fontSize: typography.xl.fontSize,
    fontStyle: 'normal',
    fontWeight: fontWeight.semibold,
    lineHeight: '140%', /* 28px */
})

export const Location = styled.div({
    color: colors.gray['600'],
    fontFamily: fontFamily.inter,
    fontSize: typography.xs.fontSize,
    fontStyle: 'normal',
    fontWeight: fontWeight.normal,
    lineHeight: '150%', /* 18px */
})