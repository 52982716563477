import { Field, FieldAttributes, FieldProps } from 'formik'
import { KeyboardEvent, KeyboardEventHandler } from 'react'

import * as Styled from './styles'

const isNumericInput = (event: KeyboardEvent<HTMLInputElement>) => {
  const key = event.keyCode
  return (
    (key >= 48 && key <= 57) || // Allow number line
    (key >= 96 && key <= 105) // Allow number pad
  )
}

const isModifierKey = (event: KeyboardEvent<HTMLInputElement>) => {
  const key = event.keyCode
  return (
    event.shiftKey === true ||
    key === 35 ||
    key === 36 || // Allow Shift, Home, End
    key === 8 ||
    key === 9 ||
    key === 13 ||
    key === 189 ||
    key === 109 ||
    key === 190 ||
    key === 46 || // Allow Backspace, Tab, Enter, Delete
    (key > 36 && key < 41) || // Allow left, up, right, down
    // Allow Ctrl/Command + A,C,V,X,Z
    ((event.ctrlKey === true || event.metaKey === true) &&
      (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
  )
}

type Props = FieldAttributes<{ name: string }> & {
  label: string
}

function FormikPhoneField(props: Props) {
  const { label, name, ...rest } = props
  const id = rest.id ?? name
  return (
    <Field name={name} {...rest}>
      {({ field, meta: { error } }: FieldProps<string>) => {
        const enforceFormat: KeyboardEventHandler<HTMLInputElement> = (event) => {
          // Input must be of a valid number format or a modifier key, and not longer than ten digits
          if (!isNumericInput(event) && !isModifierKey(event)) {
            event.preventDefault()
          }
        }
        const formatToPhone: KeyboardEventHandler<HTMLInputElement> = (event) => {
          if (isModifierKey(event)) {
            return
          }

          const target = event.currentTarget
          const input = target.value.replace(/\D/g, '') // First thirteen digits of input only
          const zip = input.substring(0, 3)
          const middle = input.substring(3, 6)
          const last = input.substring(6)

          if (input.length > 6) {
            target.value = `(${zip}) ${middle} - ${last}`
          } else if (input.length > 3) {
            target.value = `(${zip}) ${middle}`
          } else if (input.length > 0) {
            target.value = `(${zip}`
          }
        }

        return (
          <Styled.Field>
            <Styled.FieldLabel htmlFor={id}>{label}</Styled.FieldLabel>
            <Styled.Input
              id={id}
              {...field}
              // placeholder={placeholder}
              onKeyDown={enforceFormat}
              onKeyUp={formatToPhone}
              hasError={!!error}
            />
            {error && <Styled.FieldError>{error.toString()}</Styled.FieldError>}
          </Styled.Field>
        )
      }}
    </Field>
  )
}
export default FormikPhoneField
