import { AxiosResponse } from 'axios'
import { Experiments, chowlyApi } from 'config'

import client from '../chowly'

export const getFeatureFlags = async () => {
  const url = chowlyApi.experimentAccess
  const experimentValues = Object.values(Experiments)

  const payload = {
    experiments: experimentValues,
  }

  let response: AxiosResponse | undefined
  try {
    response = await client.post(url, payload)
  } catch (err) {
    console.log('error fetching feature flags', err)
  }

  return response?.data?.experiments
}
