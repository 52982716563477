import styled from 'styled-components'
import { breakpoints, colors, fontFamily, fontWeight, typography } from 'theme'

export const Container = styled.div<{ width?: number | string }>(
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxWidth: '668px',
    width: '100%',
    paddingBottom: '5rem',
    backgroundColor: '#FAFAFA',
    gap: '24px',
    position: 'relative',
    marginTop: '60px',
    [`@media ${breakpoints.xxs}`]: {
      marginTop: '28px',
    },
  },
  ({ width }) => width && { width },
)

export const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

export const HeaderText = styled.div({
  display: 'flex',
  color: colors.gray[600],
  fontSize: typography.xxs.fontSize,
})

export const HeaderTitle = styled.div({
  fontWeight: fontWeight.semibold,
  fontSize: typography.xxl.fontSize,
  color: colors.primary.medium,
})

export const HeaderRight = styled.div({
  display: 'inline-flex',
  columnGap: '0.75rem',
})

export const Title = styled.h1({
  width: '100%',
  fontWeight: fontWeight.semibold,
  fontSize: '2rem',
  lineHeight: '2.5rem',
  color: colors.primary.light,
  display: 'flex',
  flexWrap: 'nowrap',
  columnGap: '1rem',
})

export const Subtitle = styled.div({
  width: '100%',
  fontWeight: fontWeight.semibold,
  fontSize: '31px',
  lineHeight: '140%',
  color: colors.blue[700],
  display: 'flex',
})

export const Subsections = styled.div({
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '4.5rem',
  position: 'relative',
})

export const FormContent = styled.div<{ noMarginBottom?: boolean }>(
  {
    flex: 1,
    width: '100%',
    marginTop: '1.5rem',
    marginBottom: '4.5rem',
  },
  ({ noMarginBottom }) => noMarginBottom && { marginBottom: 0 },
)

export const BackButton = styled.button({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontWeight: fontWeight.semibold,
  fontSize: '31px',
  lineHeight: '140%',
  fontColor: colors.blue[700],
})

export const MenuTable = styled.div<{ padding?: string }>(
  {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.gray[450],
    width: '100%',
    padding: '24px',
  },
  ({ padding }) => padding && { padding },
)

export const TopRow = styled.div<{ height?: string | number }>(
  {
    height: '46px',
    borderBottom: '1px solid #D7D7D7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  ({ height }) => height && { height },
)

export const ModifierContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 0,
  width: 90,
  [`@media ${breakpoints.sm}`]: {
    margin: '2px 0 0 25px',
    width: 'auto',
  },
})

export const CheckboxLabel = styled.div({
  fontSize: '12px',
})

export const CheckboxContainer = styled.div({
  textAlign: 'center',
  flexBasis: '42px',
  alignItems: 'center',
  height: '18px',
  paddingLeft: '12px',
  paddingRight: '12px',
})

export const CheckboxModifierContainer = styled.div({
  textAlign: 'center',
  flexBasis: '42px',
  alignItems: 'center',
  height: '18px',
  paddingLeft: 0,
  paddingRight: '12px',
  [`@media ${breakpoints.sm}`]: {
    paddingLeft: '54px',
  },
})

export const TopRowText = styled.div<{
  marginLeft?: number | string
  marginRight?: number | string
  padding?: string
  flexBasis?: string
  fontWeight?: number
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent'
  color?: string
}>(
  {
    fontFamily: fontFamily.inter,
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '19.5px',
    color: '#484848',
    flexBasis: '50%',
    minWidth: 'fit-content',
  },
  ({ marginLeft }) => marginLeft && { marginLeft },
  ({ marginRight }) => marginRight && { marginRight },
  ({ padding }) => padding && { padding },
  ({ flexBasis }) => flexBasis && { flexBasis },
  ({ fontWeight }) => fontWeight && { fontWeight },
  ({ textAlign }) => textAlign && { textAlign },
  ({ color }) => color && { color },
)

export const MenuTabs = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '4px',
  padding: '12px 5px',
  [`@media ${breakpoints.sm}`]: {
    padding: '12px',
  },
})

export const DesktopMenuTab = styled.div<{ isSelected: boolean; tabIndex: number }>(
  {
    fontFamily: fontFamily.inter,
    fontSize: typography.xxs.fontSize,
    fontWeight: fontWeight.semibold,
    padding: '6px 8px 6px 8px',
    borderRadius: '4px',
    gap: '10px',
    '&:hover': {
      cursor: 'pointer',
    },
    display: 'none',
    [`@media ${breakpoints.sm}`]: {
      display: 'flex',
    },
  },
  ({ tabIndex, isSelected }) =>
    tabIndex === 0 && {
      color: isSelected ? '#292929' : '#777777',
      background: isSelected ? '#D7D7D780' : 'transparent',
    },
  ({ tabIndex, isSelected }) =>
    tabIndex === 1 && {
      color: '#32965D',
      background: isSelected ? '#32965D26' : 'transparent',
    },
  ({ tabIndex, isSelected }) =>
    tabIndex === 2 && {
      color: '#C13030',
      background: isSelected ? '#C1303026' : 'transparent',
    },
)

export const MobileMenuTab = styled.div<{ isSelected: boolean; tabIndex: number }>(
  {
    fontFamily: fontFamily.inter,
    fontSize: typography.xxs.fontSize,
    fontWeight: fontWeight.semibold,
    padding: '6px 8px 6px 8px',
    borderRadius: '4px',
    gap: '10px',
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
    },
    [`@media ${breakpoints.sm}`]: {
      display: 'none',
    },
  },
  ({ tabIndex, isSelected }) =>
    tabIndex === 0 && {
      color: isSelected ? '#292929' : '#777777',
      background: isSelected ? '#D7D7D780' : 'transparent',
    },
  ({ tabIndex, isSelected }) =>
    tabIndex === 1 && {
      color: '#32965D',
      background: isSelected ? '#32965D26' : 'transparent',
    },
  ({ tabIndex, isSelected }) =>
    tabIndex === 2 && {
      color: '#C13030',
      background: isSelected ? '#C1303026' : 'transparent',
    },
)

export const SubsectionContainer = styled.div({
  width: '100%',
})

export const HeaderRow = styled.div<{ style?: React.CSSProperties }>(
  {
    height: '72px',
    borderBottom: `2px solid ${colors.blue[700]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  ({ style }) => ({
    ...style, // Merge incoming styles with the base styles
  }),
)

export const SectionTitle = styled.div({
  fontFamily: 'Inter',
  fontSize: '15px',
  fontWeight: fontWeight.bold,
  lineHeight: '150%',
  textAlign: 'left',
  marginLeft: '5px',
  marginRight: 'auto',
  color: colors.blue[700],
})

export const ModifierTitle = styled.div<{ marginLeft?: string }>(
  {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: fontWeight.bold,
    lineHeight: '21px',
    textAlign: 'left',
    marginLeft: '15px',
    marginRight: 'auto',
  },
  ({ marginLeft }) => ({ marginLeft }),
)

export const ItemRow = styled.div<{
  margin?: string
  style?: React.CSSProperties
  isModifier?: boolean
  isNestedModifier?: boolean
  isMobile?: boolean
  isHighlighted?: boolean
  isExpanded?: boolean
}>(({ margin, isModifier, isNestedModifier, isMobile, isHighlighted, isExpanded }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '69px',
  borderBottom: '1px solid #D7D7D7',
  ...(isHighlighted && {
    background: '#F5F5F5',
  }),
  ...(isExpanded && { background: 'rgba(27, 81, 167, 0.07)' }),
  ...(margin && { margin }),
  ...(isModifier && { paddingLeft: '45px' }),
  ...(isNestedModifier && { paddingLeft: '110px' }),
  ...(isMobile && { paddingLeft: 0 }),
}))

export const ModiferRow = styled.div<{ isSelected: boolean; margin?: string }>(
  {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '60px',
    borderBottom: '1px solid #D7D7D7',
  },
  ({ margin }) => margin && { margin },
)

export const ItemImg = styled.img({
  maxHeight: '40px',
  maxWidth: '40px',
  borderRadius: '8px',
  gap: '8px',
})

export const ImgContainer = styled.div<{ padding?: string | null }>(
  {
    flexBasis: '56px',
    marginRight: '10px',
  },
  ({ padding }) => padding && { padding },
)
export const ItemTitle = styled.div<{ padding?: string | null; style?: React.CSSProperties }>(
  ({ padding, style }) => ({
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'left',
    color: '#292929',
    flexBasis: '50%',
    display: '-webkit-box',
    maxWidth: '100%',
    margin: '0 auto',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...(padding && { padding }),
    ...style,
  }),
)

export const ItemDescription = styled.div({
  color: colors.gray[180],
  lineHeight: '150%',
  fontFamily: 'Inter',
  fontSize: '11px',
})

export const Status = styled.div<{ active?: boolean }>(
  {
    flexBasis: '20%',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    fontFamily: fontFamily.inter,
    fontSize: typography.xxxs.fontSize,
    fontWeight: fontWeight.medium,
    lineHeight: typography.xxxs.lineHeight,
    textTransform: 'capitalize',
    // text-align: right;
  },
  ({ active }) => (active ? { color: colors.green.darkGreen } : { color: colors.red.darkRed }),
)

export const OptionsContainer = styled.div({
  flexBasis: '56px',
  padding: '0 5px 0 5px',
})

export const OptionsIcon = styled.img({
  margin: 'auto',
})

export const Icon = styled.img<{ iconSize?: string }>(
  {
    width: '6px',
    height: '6px',
    maargin: '3rem',
  },
  ({ iconSize }) =>
    iconSize && {
      width: `${iconSize}px`,
      height: `${iconSize}px`,
    },
)

export const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '8px',
  height: '100%',
})

export const AvailabilityContainer = styled.div({
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
  alignItems: 'flex-end',
  textAlign: 'right',
  // right: 0,
})

export const DropdownContainer = styled.div({
  position: 'relative',
  width: 'max-content',
})

export const DropdownButton = styled.button({
  color: 'white',
  fontSize: '16px',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

export const DropdownContent = styled.div({
  position: 'absolute',
  backgroundColor: 'rgb(249, 249, 249)',
  minWidth: '250px',
  zIndex: 1,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: 0,
  top: '36px',
  borderRadius: 5,
  border: '1px solid rgb(217 217 217)',
})

export const TodaysHours = styled.span({
  display: 'inline',
  maxWidth: '200px',
  [`@media ${breakpoints.xxs}`]: {
    display: 'none',
  },
})

export const Chevron = styled.img<{ isOpen: boolean }>(({ isOpen }) => ({
  transition: 'transform 0.3s ease',
  transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
}))

export const AvailabilityHeader = styled.div({
  color: colors.primary.medium,
  fontFamily: fontFamily.inter,
  fontSize: typography.midXS.fontSize,
  fontStyle: 'normal',
  fontWeight: fontWeight.bold,
  lineHeight: '22.5px',
  margin: '0 5px 0 0',
})

export const DropdownItem = styled.div({
  padding: 0,
  textAlign: 'left',
  background: 'transparent',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flex-end',
})

export const AvailabilityRow = styled.div<{ active?: boolean }>(
  {
    display: 'flex',
    padding: '8px 20px',
    alignItems: 'center',
    gap: '8px',
    borderRadius: 0,
    width: 'fit-content',
  },
  ({ active }) =>
    active && {
      borderColor: colors.blue['700'],
    },
)

export const SelectedAvailabilityRow = styled.div<{ active?: boolean }>(
  {
    display: 'flex',
    padding: '8px 5px 8px 5px',
    alignItems: 'center',
    gap: '8px',
    borderRadius: 0,
    width: 'fit-content',
  },
  ({ active }) =>
    active && {
      borderColor: colors.blue['700'],
    },
)

const mediumText = {
  color: colors.primary.medium,
  fontFamily: fontFamily.inter,
  fontSize: '13px',
  fontStyle: 'normal',
}

export const AvailableDay = styled.div<{ isHighlighted?: boolean }>(({ isHighlighted }) => ({
  ...mediumText,
  fontWeight: isHighlighted ? fontWeight.bold : fontWeight.medium,
  lineHeight: '19.5px',
}))

export const AvailableTime = styled.div<{ isHighlighted?: boolean }>(({ isHighlighted }) => ({
  color: colors.primary.medium,
  fontFamily: fontFamily.inter,
  fontSize: typography.xxs.fontSize,
  fontStyle: 'normal',
  fontWeight: isHighlighted ? fontWeight.bold : fontWeight.normal,
  lineHeight: '19.5px',
}))

export const CreateMenuFormTitle = styled.div<{ isHighlighted?: boolean }>(({ isHighlighted }) => ({
  ...mediumText,
  fontWeight: isHighlighted ? fontWeight.bold : fontWeight.normal,
  lineHeight: '150%',
}))

export const SearchIconContainer = styled.div({
  borderRadius: '4px',
  border: `1px solid ${colors.gray.medium}`,
  padding: '4px',
})

export const InputContainer = styled.div<{ height?: string }>(
  {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: '8px',
    height: '33px',
  },
  ({ height }) => height && { height },
)

export const InputSearchContainer = styled.div({
  display: 'flex',
  width: '200px',
  alignItems: 'center',
  gap: '8px',
  height: '47px',
  // flex: 1,
})

export const Input = styled.input({
  border: `1px solid ${colors.gray.medium}`,
  width: '100%',
  height: '100%',
  borderRadius: '8px',
  boxShadow: '0px 2px 14px 0px rgba(0, 0, 0, 0.05)',
  color: colors.primary.medium,
  fontFamily: 'Inter',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: fontWeight.normal,
  lineHeight: '150%' /* 19.5px */,
  padding: '4px 12px',
  alignItems: 'flex-start',
})

export const TextArea = styled.textarea({
  border: `1px solid ${colors.gray.medium}`,
  width: '100%',
  height: '100%',
  borderRadius: '8px',
  boxShadow: '0px 2px 14px 0px rgba(0, 0, 0, 0.05)',
  color: colors.primary.medium,
  fontFamily: 'Inter',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: fontWeight.normal,
  lineHeight: '150%' /* 19.5px */,
  padding: '4px 12px',
  alignItems: 'flex-start',
})

export const InputPriceContainer = styled.div<{ isMobile?: boolean; isModifier?: boolean }>(
  {
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    height: '32px',
    flexBasis: '25%',
  },
  ({ isMobile }) =>
    isMobile && {
      flexBasis: '100%',
    },
  ({ isModifier }) => isModifier && { flexBasis: '80%' },
)

export const Label = styled.div({
  fontFamily: fontFamily.inter,
  fontSize: typography.midXS.fontSize,
  fontWeight: fontWeight.medium,
  color: colors.primary.medium,
})

export const SecondaryLabel = styled.div({
  fontFamily: fontFamily.inter,
  fontSize: typography.xxs.fontSize,
  color: colors.gray.mediumLight,
})

export const RadioInput = styled.input({
  width: '18px',
  height: '18px',
  color: colors.blue[700],
})

export const Box = styled.div<{ isMobile?: boolean }>(
  {
    width: '667px',
    padding: '24px',
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
  },
  ({ isMobile }) =>
    isMobile && {
      width: '100%',
    },
)

export const IconButton = styled.button.attrs({ type: 'button' })<{
  margin?: string
  width?: string
}>(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
  },
  ({ margin }) => margin && { margin },
  ({ width }) => width && { width },
)

export const ItemViewContainer = styled.div<{ isMobile?: boolean }>(
  {
    width: '668px',
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '90px',
  },
  ({ isMobile }) =>
    isMobile && {
      width: '100%',
    },
)

export const ItemViewProductTitle = styled.p({
  color: colors.primary.medium,
  fontSize: '13px',
  lineHeight: '150%',
  fontWeight: fontWeight.bold,
})

export const ItemViewProductDescription = styled.p({
  color: colors.gray.mediumLight,
  fontSize: '10px',
  lineHeight: '150%',
  fontWeight: fontWeight.normal,
})

export const ItemViewImageContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  maxWidth: '192px',
  flexBasis: '50%',
})

export const ItemViewImageBox = styled.div({
  padding: '24px 12px',
  borderWidth: '1px',
  borderStyle: 'dashed',
  borderColor: '#D7D7D7',
  borderRadius: '8px',
  height: '119px',
  maxWidth: '192px',
  position: 'relative',
})

export const ItemViewMenuTable = styled.div({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.gray[450],
  width: '100%',
})

export const ItemViewCount = styled.div({
  fontSize: typography.xl.fontSize,
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight.semibold,
  color: colors.gray.mediumLight,
  display: 'flex',
  alignItems: 'center',
})

export const MenuAvailabilityTitle = styled.div({
  fontSize: typography.xl.fontSize,
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight.semibold,
  color: colors.primary.medium,
})

export const LineSeparator = styled.div({
  height: '1px',
  width: '100%',
  background: colors.gray.medium,
})
