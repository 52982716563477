import { StatusResource, requestMenuImport } from "api"
import { AxiosError, isAxiosError } from "axios"
import { toast } from "react-toastify"

export const resyncSumbit = async (menuMappingMenuId: string, locationId: string ) => {
    if (menuMappingMenuId) {
      if (typeof locationId === 'string') {
        try {
          const { included } = await requestMenuImport(locationId, false, true)
          if (included) {
            const status = included.find(
              (item): item is StatusResource =>
                item.type === 'status' && item.attributes.category === 'menu_import',
            )
            if (status) {
              // setMenuImportTimestamp(status.attributes.enqueued_at)
              // setEnqueued(status.attributes.enqueued_at ? true : false)
            }
          }
        } catch (err) {
          if (isAxiosError(err)) {
            if (err.response?.status === 429) {
              toast.error(
                'A menu resync was enqueued less than 5 minutes ago. Please wait at least 5 minutes before resyncing again. For additional help please contact Chowly Support using the “C” icon in the lower right corner of your screen.',
              )
            } else {
              toast.error(`Resync action couldn’t start due to ${(err as AxiosError).message}`)
            }
          } else {
            toast.error(`Unknown error: ${String(err)}`)
          }
        }
      }
    }
  }