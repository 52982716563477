import { Availabilities } from 'api/menuMapping/types'
import MinusIcon from 'assets/icons/outline_minus_button.svg'
import PlusIcon from 'assets/icons/outline_plus_button.svg'
import { Moment } from 'moment'
import TimePicker from 'rc-time-picker'
import { Fragment } from 'react'
import { FormattedAvailabilities } from 'types/Menu'
import { DAYS_OF_WEEK } from 'utils'
import { convertMomentToStringTimePeriod, convertTimePeriodToMoment } from 'utils/DateTime'

import * as Styled from '../styles'

type Props = {
  availabilities?: Availabilities[]
  newAvailabilities: FormattedAvailabilities
  setNewAvailabilities: (newData: FormattedAvailabilities) => void
  isModal?: boolean
  availabilitiesToDelete?: Availabilities['id'][]
  setAvailabilitiesToDelete?: (ids: Availabilities['id'][]) => void
}

const isError = (startValue?: string, endValue?: string) => {
  if ((!startValue && endValue) || (startValue && !endValue)) {
    return true
  }

  if (startValue && endValue) {
    return startValue >= endValue
  }

  return false
}

const TIME_TYPE = {
  START: 'start',
  END: 'end',
}

const MenuAvailabilityTable = ({
  newAvailabilities,
  setNewAvailabilities,
  isModal,
  availabilitiesToDelete,
  setAvailabilitiesToDelete,
}: Props) => {
  const handleChangeTime = async (
    newValue: Moment,
    day: string,
    timePeriodIdx: number,
    type: string,
  ) => {
    const modifiedAvailabilityObj = { ...newAvailabilities }

    if (!modifiedAvailabilityObj[day]) {
      modifiedAvailabilityObj[day] = []
    }

    const currentTimePeriodValues = modifiedAvailabilityObj[day]?.[timePeriodIdx]

    // clear both start and end times for the period
    if (currentTimePeriodValues && !newValue) {
      currentTimePeriodValues.start_time = undefined
      currentTimePeriodValues.end_time = undefined

      // if this exists in the db, stage this time period id for deletion
      if (currentTimePeriodValues.id && availabilitiesToDelete && setAvailabilitiesToDelete) {
        setAvailabilitiesToDelete([
          ...new Set([...availabilitiesToDelete, currentTimePeriodValues.id]),
        ])
      }
    }

    const convertedTimeStr = convertMomentToStringTimePeriod(newValue)

    if (type === TIME_TYPE.START) {
      // update current value
      if (currentTimePeriodValues) {
        currentTimePeriodValues.start_time = convertedTimeStr
      } else {
        // add new time period for the day
        modifiedAvailabilityObj[day][timePeriodIdx] = {
          start_time: convertedTimeStr,
        }
      }
    }
    if (type === TIME_TYPE.END) {
      // update current value
      if (currentTimePeriodValues) {
        currentTimePeriodValues.end_time = convertedTimeStr
      } else {
        // add new time period for the day
        modifiedAvailabilityObj[day][timePeriodIdx] = {
          end_time: convertedTimeStr,
        }
      }
    }

    setNewAvailabilities(modifiedAvailabilityObj)
  }

  const addNewTimePeriodRow = (dayOfWeek: string) => {
    const updatedAvailabilities = { ...newAvailabilities }

    const currTimePeriods = updatedAvailabilities[dayOfWeek]
    // create empty time periods
    currTimePeriods.push({
      start_time: undefined,
      end_time: undefined,
    })

    updatedAvailabilities[dayOfWeek] = currTimePeriods
    setNewAvailabilities(updatedAvailabilities)
  }

  const removeTimePeriodRow = async (dayOfWeek: string, timePeriodIndex: number) => {
    if (timePeriodIndex === 0) {
      console.error('Cannot remove the first time period')
      return
    }

    const updatedAvailabilities = { ...newAvailabilities }
    const currTimePeriods = updatedAvailabilities[dayOfWeek]
    const existingTimePeriodId = currTimePeriods[timePeriodIndex]?.id

    if (existingTimePeriodId && availabilitiesToDelete && setAvailabilitiesToDelete) {
      setAvailabilitiesToDelete([...new Set([...availabilitiesToDelete, existingTimePeriodId])])
    }

    currTimePeriods.splice(timePeriodIndex, 1)
    updatedAvailabilities[dayOfWeek] = currTimePeriods
    setNewAvailabilities(updatedAvailabilities)
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        marginLeft: isModal ? 0 : '45px',
      }}
    >
      {DAYS_OF_WEEK.map((day) => {
        const firstTimePeriod = newAvailabilities[day]?.[0]
        const otherTimePeriods = newAvailabilities[day]?.slice(1)

        return (
          <Fragment key={day}>
            <div
              key={day}
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                height: '47px',
                gap: 16,
              }}
            >
              <div style={{ width: '115px' }}>{day}</div>
              <div style={{ display: 'flex', width: otherTimePeriods?.length ? '326px' : '365px' }}>
                <TimePicker
                  value={convertTimePeriodToMoment(firstTimePeriod?.start_time)}
                  onChange={(newValue) => handleChangeTime(newValue, day, 0, TIME_TYPE.START)}
                  showSecond={false}
                  placeholder='Select'
                  className={otherTimePeriods?.length ? 'rc-short-time-picker' : 'rc-time-picker'}
                  use12Hours
                />
                <div
                  style={{
                    display: 'flex',
                    flexBasis: '31px',
                    margin: '16px',
                    alignItems: 'center',
                  }}
                >
                  <Styled.LineSeparator />
                </div>
                <TimePicker
                  value={convertTimePeriodToMoment(firstTimePeriod?.end_time)}
                  onChange={(newValue) => handleChangeTime(newValue, day, 0, TIME_TYPE.END)}
                  showSecond={false}
                  placeholder='Select'
                  className={otherTimePeriods?.length ? 'rc-short-time-picker' : 'rc-time-picker'}
                  use12Hours
                />
              </div>
              <Styled.IconButton
                type='button'
                width='24px'
                onClick={() => addNewTimePeriodRow(day)}
              >
                <img src={PlusIcon} alt='expand_rows' />
              </Styled.IconButton>
            </div>
            {isError(firstTimePeriod?.start_time, firstTimePeriod?.end_time) && (
              <Styled.TopRowText color='red' textAlign='end'>
                Error: the end time has to be greater than the start time.
              </Styled.TopRowText>
            )}

            {otherTimePeriods?.map((timePeriod, idx) => {
              // excluding the first row
              const timePeriodIdx = idx + 1

              return (
                <>
                  <div
                    key={day}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '47px',
                      gap: 16,
                    }}
                  >
                    <div style={{ width: '115px' }} />
                    <div style={{ display: 'flex', width: '326px' }}>
                      <TimePicker
                        value={convertTimePeriodToMoment(timePeriod?.start_time)}
                        onChange={(newValue) =>
                          handleChangeTime(newValue, day, timePeriodIdx, TIME_TYPE.START)
                        }
                        showSecond={false}
                        placeholder='Select'
                        className='rc-short-time-picker'
                        use12Hours
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexBasis: '31px',
                          margin: '16px',
                          alignItems: 'center',
                        }}
                      >
                        <Styled.LineSeparator />
                      </div>
                      <TimePicker
                        value={convertTimePeriodToMoment(timePeriod?.end_time)}
                        onChange={(newValue) =>
                          handleChangeTime(newValue, day, timePeriodIdx, TIME_TYPE.END)
                        }
                        showSecond={false}
                        placeholder='Select'
                        className='rc-short-time-picker'
                        use12Hours
                      />
                    </div>
                    <div style={{ display: 'flex', gap: 12 }}>
                      <Styled.IconButton
                        type='button'
                        width='24px'
                        onClick={() => addNewTimePeriodRow(day)}
                      >
                        <img src={PlusIcon} alt='expand_rows' />
                      </Styled.IconButton>
                      <Styled.IconButton
                        type='button'
                        width='24px'
                        onClick={() => removeTimePeriodRow(day, timePeriodIdx)}
                      >
                        <img src={MinusIcon} alt='remove_row' />
                      </Styled.IconButton>
                    </div>
                  </div>
                  {isError(timePeriod?.start_time, timePeriod?.end_time) && (
                    <Styled.TopRowText color='red' textAlign='end'>
                      Error: the end time has to be greater than the start time.
                    </Styled.TopRowText>
                  )}
                  {timePeriodIdx === otherTimePeriods.length && (
                    <div
                      style={{
                        display: 'flex',
                        margin: '8px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Styled.LineSeparator />
                    </div>
                  )}
                </>
              )
            })}
          </Fragment>
        )
      })}
    </div>
  )
}

export default MenuAvailabilityTable
