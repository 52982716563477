import * as Styled from './styles'

const PrivacyTerms = () => (
  <Styled.Container>
    <Styled.BoldLink href='https://chowly.com/privacy-policy/' target='_blank' as='a'>
      Privacy Policy
    </Styled.BoldLink>{' '}
    and
    <Styled.BoldLink href='https://chowly.com/terms-and-conditions/' target='_blank' as='a'>
      {' '}
      Terms and Conditions
    </Styled.BoldLink>{' '}
    apply
  </Styled.Container>
)

export default PrivacyTerms
