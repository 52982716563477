import { ChowlyUser } from 'libs/auth0-react/auth-state'
import difference from 'lodash/difference'
import { matchPath } from 'react-router-dom'

export const auth0 = {
  domain: String(process.env.REACT_APP_AUTH0_DOMAIN),
  clientId: String(process.env.REACT_APP_AUTH0_CLIENT_ID),
  audience: String(process.env.REACT_APP_AUTH0_AUDIENCE),
  connection: String(process.env.REACT_APP_AUTH0_DB_NAME),
}

export const chowlyApi = {
  dashboardUsers: '/api/v3/dashboard/users',
  sendEmailVerification: '/api/v3/auth_zero/email_verifications',
  hostUrl: String(process.env.REACT_APP_API_HOST),
  websocketUrl: String(process.env.REACT_APP_WEBSOCKET_URL),
  onboardingLocations: '/api/v3/dashboard/onboarding_locations',
  onboardingLocation: '/api/v3/dashboard/onboarding_locations/:id',
  partners: '/api/v3/partners',
  partner: '/api/v3/partners/:id',
  partnerCategoriesFetch: '/api/v3/partners/:id/categories_fetch',
  sigmaUrl: '/api/v3/dashboard/sigma_url/reports',
  squareUrl: '/poses/square',
  squareLocationsUrl: '/api/v3/square/locations',
  me: '/api/v3/users/me',
  orderPlatforms: '/api/v3/order_platforms',
  orderPlatform: '/api/v3/order_platforms/:id',
  initiateDoorDash: 'api/v3/order_platforms/:id/initiate_onboarding',
  provision: '/api/v3/order_platforms/:id/provision',
  deprovision: '/api/v3/order_platforms/:id/deprovision',
  deleteCheck: 'api/v3/order_platforms/:id/destroy_check',
  provisioningStatus: '/api/v3/order_platforms/:id/provisioning_status',
  updateOpenStatus: '/api/v3/order_platforms/:id/update_open_status',
  triggerMenuUpdate: '/api/v3/order_platforms/:id/doordash_onboardable/trigger_menu_update',
  password: '/users/password',
  signupConfirmation: '/users/confirmation',
  ubereatsUrl: '/toos/ubereats',
  doordashUrl: '/toos/doordash',
  toosLocationsUrl: '/api/v3/toos/locations',
  publishUrl: '/api/v3/menu_mapping/menus/:id/publish',
  platformPublishUrl: 'api/v3/order_platforms/:id/publish',
  signupUrl: '/api/v3/partners/:id/toos_signup',
  locationsList: '/api/v3/partners?include=order_platforms&partner_status=not_churned',
  menuImportStatus: 'api/v3/reporting/import_menus',
  menuMappingMenus: '/api/v3/menu_mapping/menus',
  menuMappingMenu: '/api/v3/menu_mapping/menus/:id',
  menuMappingSections: './api/v3/menu_mapping/sections',
  menuMappingSection: '/api/v3/menu_mapping/sections/:id',
  menuMappingOverrideSections: '/api/v3/menu_mapping/override_sections',
  menuMappingOverrideSection: '/api/v3/menu_mapping/override_sections/:id',
  menuMappingSchedules: '/api/v3/menu_mapping/schedules',
  menuMappingSchedule: '/api/v3/menu_mapping/schedules/:id',
  menuMappingTimePeriods: '/api/v3/menu_mapping/time_periods',
  menuMappingTimePeriod: '/api/v3/menu_mapping/time_periods/:id',
  menuMappingServiceAvailabilities: '/api/v3/menu_mapping/service_availabilities',
  menuMappingServiceAvailability: '/api/v3/menu_mapping/service_availabilities/:id',
  menuMappingSubsection: '/api/v3/menu_mapping/subsections/:id',
  menuMappingItem: '/api/v3/menu_mapping/items/:id',
  menuMappingCustomization: '/api/v3/menu_mapping/customizations/:id',
  activationCodes: '/api/v3/activation_codes',
  experimentAccess: `/api/v3/users/me/experiments`,
  menuMappingSuspension: 'api/v3/menu_mapping/suspension/bulk_update_suspension',
  posStockUpdates: 'api/v3/point_of_sales',
  menuMappingCustomizationOptions: 'api/v3/menu_mapping/customization_options',
  targetableApi: 'targetable/api',
  billingInvoices: 'api/v3/billing/invoices',
}

const nonAuthApis = [
  chowlyApi.sendEmailVerification,
  chowlyApi.password,
  chowlyApi.signupConfirmation,
]

export const isAuthRequiredApi = (url: string) => {
  return !nonAuthApis.some((pattern) => matchPath(pattern, url))
}

export const env = process.env.REACT_APP_ENV
export const appSettings = {
  isDevelopment: String(env) === 'development',
  isProduction: String(env) === 'production',
  isStaging: String(env) === 'staging',
  tgtEnvName: (function() {
    if (String(env) === 'development') return 'dev'
    if (String(env) === 'staging') return 'stage'
    if (String(env) === 'production') return 'prod'
    return 'dev'
  })(),
}

export const googlePlaces = {
  token: String(process.env.REACT_APP_GOOGLE_API_KEY),
}

export const algolia = {
  apiSearchKey: String(process.env.REACT_APP_ALGOLIA_API_SEARCH_KEY),
  appId: String(process.env.REACT_APP_ALGOLIA_APP_ID),
}

export const sigmaPages = {
  healthDashboard: String(process.env.REACT_APP_SIGMA_HEALTH_DASHBOARD),
  locationsReport: String(process.env.REACT_APP_SIGMA_LOCATIONS_REPORT),
  ordersReport: String(process.env.REACT_APP_SIGMA_ORDERS_REPORT),
  productMix: String(process.env.REACT_APP_SIGMA_PRODUCT_MIX),
  salesReport: String(process.env.REACT_APP_SIGMA_SALES_REPORT),
  smartPricingReport: String(process.env.REACT_APP_SIGMA_SMART_PRICING_REPORT),
  wowReport: String(process.env.REACT_APP_SIGMA_WOW),
  deliveryBuyDown: String(process.env.REACT_APP_SIGMA_DELIVERY_BUYDOWN),
  salesOrderByPlatform: String(process.env.REACT_APP_SIGMA_SALES_ORDER_PLATFORM),
  advancedOrders: String(process.env.REACT_APP_SIGMA_ADVANCED_ORDERS),
  disputes: String(process.env.REACT_APP_SIGMA_DISPUTES),
  reconciliation: String(process.env.REACT_APP_SIGMA_RECONCILIATION),
}

export const flags = [
  {
    name: 'developmentOnly',
    isActive: appSettings.isDevelopment,
  },
  {
    name: 'stagingOnly',
    isActive: appSettings.isStaging,
  },
  {
    name: 'productionOnly',
    isActive: appSettings.isProduction,
  },
]

// must match experiment names we want to check for in flipper: https://admin.chowlyinc.com/flipper/features
export enum Experiments {
  MENU_REDESIGN_86ING = 'menu_redesign_86ing',
  LANDING_PAGE_REDESIGN = 'rcc_landing_page_redesign',
  ADVANCED_ORDERS_REPORT = 'rcc_advanced_orders_report_experiment',
  RRR_REPORT = 'rcc_rrr_report_experiment',
  ANALYTICS_DIGITAL_MARKETING = 'analytics_digital_marketing',
  BILLING_NAVIGATION = 'rcc_billing_v1',
}

export const googleReviewLink = String(process.env.REACT_APP_GOOGLE_REVIEW_LINK)
export const onlineOrderingLink = String(process.env.REACT_APP_ONLINE_ORDERING_LINK)
export const faqAddThirdPartyLink = String(process.env.REACT_APP_FAQ_ADD_THIRD_PARTY_LINK)

export const sentryDsn = String(process.env.REACT_APP_SENTRY_DSN)

export enum AppFeatureName {
  HEALTH_DASHBOARD = 'Health Dashboard',
  REPORT_LOCATIONS = 'Reporting: Locations',
  REPORT_SALES = 'Reporting: Sales',
  REPORT_ORDERS = 'Reporting: Orders',
  REPORT_PRODUCT_MIX = 'Reporting: Product Mix',
  REPORT_WOW = 'Reporting: Week over Week',
  MANAGE_LOCATIONS_VIEW = 'Manage Locations',
  MANAGE_LOCATIONS_SEARCH = 'Manage Locations: Search',
  MANAGE_LOCATIONS_ADD_LOCATION = 'Manage Locations: Add Location',
  MANAGE_LOCATIONS_SELECT_LOCATION = 'Manage Locations: Select Location',
  MANAGE_LOCATION_UPCHARGE = 'Manage Location: Location Upcharge',
  MANAGE_LOCATION_RESYNC_MENUS = 'Manage Location: Resync Menus',
  MANAGE_LOCATION_PAUSE_ALL_PLATFORMS = 'Manage Location: Pause All Platforms',
  MANAGE_LOCATION_PAUSE_SINGLE_PLATFORM = 'Manage Location: Pause Single Platform',
  MANAGE_LOCATION_ADD_PLATFORM = 'Manage Location: Add Platform',
  MANAGE_LOCATION_SELECT_PLATFORM = 'Manage Location: Select Platform',
  MANAGE_LOCATION_ADD_MENU = 'Manage Location: Add Menu',
  MANAGE_LOCATION_EDIT_MENU = 'Manage Location: Edit Menu',
  MANAGE_LOCATION_PREVIEW_MENU = 'Manage Location: Preview Menu',
  MANAGE_LOCATION_DELETE_MENU = 'Manage Location: Delete Menu',
  MANAGE_PLATFORM_UPCHARGE = 'Manage Platform: Platform Upcharge',
  MANAGE_LOCATIONS_ACCESS_ALL = 'Access to all locations on Manage Locations page',
  VIEW_COO_BRAND_CONFIG_INFO = 'View COO Brand & Config Info',
}

export enum UserRole {
  CHOWLY_ADMIN = 'Chowly Admin',
  CHOWLY_SUPPORT = 'Chowly Support',
  RESTAURANT_MANAGER = 'Restaurant Manager',
  RESTAURANT_EMPLOYEE = 'Restaurant Employee',
}

export const permittedFeaturesByRole: Record<UserRole, Array<AppFeatureName>> = {
  [UserRole.CHOWLY_ADMIN]: Object.values(AppFeatureName),
  [UserRole.CHOWLY_SUPPORT]: difference(Object.values(AppFeatureName), [
    AppFeatureName.MANAGE_LOCATIONS_ADD_LOCATION,
    AppFeatureName.MANAGE_LOCATION_ADD_PLATFORM,
    AppFeatureName.MANAGE_LOCATIONS_ACCESS_ALL,
  ]),
  [UserRole.RESTAURANT_MANAGER]: difference(Object.values(AppFeatureName), [
    AppFeatureName.MANAGE_LOCATIONS_ACCESS_ALL,
  ]),
  [UserRole.RESTAURANT_EMPLOYEE]: [
    AppFeatureName.MANAGE_LOCATIONS_VIEW,
    AppFeatureName.MANAGE_LOCATIONS_SEARCH,
    AppFeatureName.MANAGE_LOCATIONS_SELECT_LOCATION,
    AppFeatureName.MANAGE_LOCATION_RESYNC_MENUS,
    AppFeatureName.MANAGE_LOCATION_SELECT_PLATFORM,
  ],
}

export const hasPermissionToReporting = (user: ChowlyUser | undefined) => {
  return (
    user?.user_roles?.some(
      (role) =>
        role === UserRole.CHOWLY_ADMIN ||
        role === UserRole.CHOWLY_SUPPORT ||
        role === UserRole.RESTAURANT_MANAGER,
    ) ?? false
  )
}

export const appUrl = process.env.REACT_APP_URL
