import LocSampleDataImg from 'assets/embedImages/LocSampleData.png'
import { SigmaEmbed, SkeletonLoader } from 'components'
import { useLocations } from 'components/MainLayout/MainLayout'
import { sigmaPages } from 'config'
import { useEmbedUrl } from 'hooks'

function LocationsReport() {
  const { locationsData } = useLocations()
  const { locations, isLoading } = locationsData

  const url = useEmbedUrl(sigmaPages.locationsReport)

  if (isLoading) return <SkeletonLoader />
  else if (locations.length < 1)
    return <img src={LocSampleDataImg} alt='Location Sample Data Image' />
  else return <SigmaEmbed url={url} title='Locations Report' />
}

export default LocationsReport
