import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { borderRadius, colors, fontWeight, transition, typography } from 'theme'

export const Container = styled.div({
  width: '100%',
  maxWidth: '48rem',
  paddingLeft: '2rem',
  paddingRight: '2rem',
})

export const Title = styled.h1({
  marginBottom: '1rem',
  ...typography.xxl,
  fontWeight: fontWeight.semibold,
})

export const Row = styled.div({
  display: 'flex',
  columnGap: '2rem',
  marginBottom: '1rem',
})

export const RadioWrapper = styled.div({
  display: 'flex',
  columnGap: '1rem',
  marginBottom: '0.5rem',
})

export const Label = styled.div({
  color: colors.primary.light,
})

export const Value = styled.div({
  fontWeight: fontWeight.medium,
})

export const HelperText = styled.div({
  fontStyle: 'italic',
  color: colors.gray.DEFAULT,
})

export const TextButton = styled.button({
  color: colors.primary.light,
  textDecoration: 'underline',
})

export const RadioInput = styled.input.attrs({ type: 'radio' })({
  width: 20,
  accentColor: colors.primary.light,
})

export const Icon = styled.img({
  width: 'auto',
  height: '2rem',
})

export const Spacer = styled.div({
  width: '100%',
  height: '1rem',
})

export const Description = styled.p({
  color: colors.primary.light,
  fontSize: '0.875rem',
})

export const ProgressContainer = styled.div({
  margin: 'auto',
  width: '90%',
})

export const Progress = styled.progress({
  width: '100%',
  transition: 'all .3s ease',
  '&::-moz-progress-bar': {
    background: colors.blue[100],
    borderRadius: '2rem',
    border: `1px solid ${colors.blue[100]}`,
  },
  '&::-moz-progress-value': {
    background: colors.primary.light,
    borderRadius: '2rem',
    border: `1px solid ${colors.primary.light}`,
  },
  '&::-webkit-progress-value': {
    background: colors.primary.light,
    borderRadius: '2rem',
    border: `1px solid ${colors.primary.light}`,
  },
  '&::-webkit-progress-bar': {
    background: colors.blue[100],
    borderRadius: '2rem',
    border: `1px solid ${colors.blue[100]}`,
  },
  color: colors.primary.light,
  borderRadius: '2rem',
  border: `1px solid ${colors.blue[100]}`,
  borderColor: colors.blue[100],
})

export const ProgressLabel = styled.label({
  width: 'auto',
  textAlign: 'center',
  display: 'block',
  color: colors.primary.light,
  fontWeight: fontWeight.bold,
  fontSize: 24,
  lineHeight: 1,
  marginTop: '1rem',
})

export const StatusText = styled.div<{ hasError?: boolean; noTransform?: boolean }>(
  {
    fontWeight: fontWeight.normal,
    color: colors.black,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  ({ noTransform }) => noTransform && { textTransform: 'none' },
  ({ hasError }) => hasError && { color: colors.red.DEFAULT, textTransform: 'none' },
)

export const FooterButtons = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  columnGap: '2rem',
  marginTop: '1.5rem',
})

export const LinkButton = styled(Link)({
  borderWidth: 1,
  borderColor: colors.primary.light,
  borderStyle: 'solid',
  borderRadius: borderRadius.DEFAULT,
  color: colors.primary.light,
  padding: '0.5rem 1rem',
  ...transition,
  '&:hover': {
    backgroundColor: colors.white,
  },
})
