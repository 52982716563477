import styled from 'styled-components'
import { colors, fontWeight } from 'theme'

export const Container = styled.div({
  marginBottom: '1rem',
})

export const Toggle = styled.button<{ isExpanded: boolean; isError: boolean }>(
  ({ isExpanded, isError }) => ({
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    columnGap: 6,
    color: isError ? colors.red.DEFAULT : colors.primary.light,
    svg: {
      transform: isExpanded ? 'rotate(0)' : 'rotate(-90deg)',
      transitionProperty: 'transform',
      transitionDuration: '0.3s',
    },
  }),
)

export const Title = styled.div({
  fontSize: '1.25rem',
  fontWeight: fontWeight.bold,
})

export const Content = styled.div<{ $height: number | string }>(
  {
    overflow: 'hidden',
    transition: 'height 0.2s ease',
    width: '100%',
    marginTop: '1rem',
  },
  ({ $height }) => ({ height: $height }),
)
