import styled from 'styled-components'
import { breakpoints, colors } from 'theme'

export const Title = styled.h1({
  fontSize: '13px',
  margin: '0',
  marginBottom: '4px',
  fontWeight: 400,
  color: '#777777',
})

export const CardContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '8px',
  background: '#fff',
  padding: '24px',
  width: '100%',
})

export const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: 1,
})

export const SubTitle = styled.h2({
  fontSize: '15px',
  color: '#292929',
  fontWeight: 700,
  lineHeight: '25px',
})

export const Description = styled.p({
  fontSize: '12px',
  margin: '8px 0 20px 0',
  color: '#292929',
})

export const StyledButton = styled.a<{ disabled?: boolean }>(
  ({ disabled }) => ({
  padding: '8px 12px',
  backgroundColor: disabled ? colors.gray.DEFAULT : colors.blue[700],
  color: disabled ? colors.gray.mediumLight : 'white',
  fontWeight: 600,
  fontSize: 15,
  textDecoration: 'none',
  borderRadius: '8px',
  display: 'inline-block',
  lineSpacing: '0.2px',
  margin: '0',
  cursor: disabled ? 'not-allowed' : 'pointer',
  })
)

export const StyledImage = styled.img({
  width: '131px',
  height: '132px',
  display: 'none',
  [`@media ${breakpoints.sm}`]: {
    display: 'flex',
  },
})

export const Icon = styled.img<{ iconSize?: string }>(
  {
    width: '6px',
    height: '6px',
    maargin: '3rem',
  },
  ({ iconSize }) =>
    iconSize && {
      width: `${iconSize}px`,
      height: `${iconSize}px`,
    },
)
