import styled from 'styled-components'
import { breakpoints, colors, fontFamily, fontWeight, typography } from 'theme'

export const CardContainer = styled.div({
  width: '670px',
  maxWidth: '100%',
  gap: '24px',
  display: 'grid',
  [`@media ${breakpoints.xxs}`]: {
    width: '372px',
  },
})

export const Card = styled.div({
  gap: '48px',
  borderRadius: '8px',
  borderStyle: 'solid',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.gray['450'],
  [`@media ${breakpoints.xxs}`]: {
    gap: '24px',
  },
})

export const CardHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  gap: '12px',
})

export const HeaderGroup = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
})

export const StatusGroup = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
})

export const Address = styled.div({
  color: colors.gray['600'],
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight.normal,
  fontSize: typography.xxs.fontSize,
  lineHeight: '19.5px',
})

export const Location = styled.div({
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight.bold,
  fontSize: typography.semiXS.fontSize,
  lineHeight: '22.5px',
  color: colors.primary.medium,
})

export const Icon = styled.img({
  width: '6px',
  height: '6px',
})

export const RedStatus = styled.div({
  color: colors.red.darkRed,
  fontFamily: fontFamily.inter,
  fontSize: typography.xxs.fontSize,
  fontWeight: fontWeight.medium,
  lineHeight: '19.5px',
  alignContent: 'right',
  gap: '4px',
})

export const GreenStatus = styled.div({
  color: colors.green.darkGreen,
  fontFamily: fontFamily.inter,
  fontSize: typography.xxs.fontSize,
  fontWeight: fontWeight.medium,
  lineHeight: '19.5px',
  alignContent: 'right',
})

export const CardBody = styled.div({
  gap: '12px',
  display: 'flex',
  flexDirection: 'column',
})

export const PillGroup = styled.div({
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight.normal,
  fontSize: typography.xxxs.fontSize,
  lineHeight: typography.xxxs.lineHeight,
  gap: '12px',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
})

export const PillStatus = styled.div<{ color?: string }>(
  {
    [`@media ${breakpoints.xxs}`]: {
      minWidth: '100%',
    },
  },
  ({ color }) =>
    color && {
      color:
        color === 'green'
          ? colors.green.darkGreen
          : color === 'red'
          ? colors.red.darkRed
          : colors.yellow.darkYellow,
    },
)

export const PlatformPill = styled.div({
  gap: '4px',
  padding: '4px 8px 4px 8px',
  borderStyle: 'solid',
  borderRadius: '8px',
  borderWidth: '1px',
  height: '28px',
  borderColor: colors.gray.medium,
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight.normal,
  fontSize: typography.xxs.fontSize,
  lineHeight: '19.5px',
  color: colors.primary.medium,
  display: 'flex',
})
