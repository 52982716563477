import { ReactComponent as StoreSvg } from 'assets/icons/store.svg'
import { BackButton, Modal, SubmitButton } from 'components'
import { FC, useEffect, useState } from 'react'
import { PlatformSummary } from 'types'
import { formatDate } from 'utils'

import * as Styled from './styles'

type Props = {
  isOpen: boolean
  platforms: PlatformSummary[]
  timezone: string
  onSubmit: (reopenTime?: string) => void
  onClose: () => void
}

const PlatformCloseReopenModal: FC<Props> = ({
  isOpen,
  platforms,
  timezone,
  onSubmit,
  onClose,
}) => {
  const [date, setDate] = useState('')
  const [time, setTime] = useState('')
  const [IsDateTimeInvalid, setIsDateTimeInvalid] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setDate('')
      setTime('')
    }
  }, [isOpen])

  const isAllClosed = platforms.every(({ availability }) => availability === 'Paused')
  const openPlatforms = platforms.filter(({ availability }) => availability !== 'Paused')
  const closedPlatforms = platforms.filter(({ availability }) => availability === 'Paused')
  const title = `${isAllClosed ? 'Reopen' : 'Close'} this store on ${(isAllClosed
    ? closedPlatforms
    : openPlatforms
  )
    .map(({ name }) => name)
    .join(', ')}?`

  const closeReopenSubmit = () => {
    // if platform is closed, we don't need to select date and time
    // since if all platforms are closed, we hide the Reopen Date and Reopen Time fields
    if (isAllClosed || (date && time)) {
      setIsDateTimeInvalid(false)
      onSubmit(`${date} ${time}`)
    } else {
      setIsDateTimeInvalid(true)
    }
  }

  return (
    <Modal isOpen={isOpen}>
      <Styled.ModalContent>
        <Styled.ModalTitle>{title}</Styled.ModalTitle>
        <StoreSvg />
        <Styled.ModalText>
          {`Select a date and time for when the store will be reopened. Hours shown reflect the
          ${timezone} time zone.`}
        </Styled.ModalText>
        {!isAllClosed && (
          <Styled.Flex>
            <div>
              <Styled.InputFieldLabel>Reopen Date</Styled.InputFieldLabel>
              <Styled.DateInput
                value={date}
                min={formatDate(new Date(), 'YYYY-MM-DD')}
                onChange={({ currentTarget }) => setDate(currentTarget.value)}
              />
            </div>
            <div>
              <Styled.InputFieldLabel>Reopen Time</Styled.InputFieldLabel>
              <Styled.TimeInput
                value={time}
                onChange={({ currentTarget }) => setTime(currentTarget.value)}
              />
            </div>
          </Styled.Flex>
        )}
        {IsDateTimeInvalid && (
          <div>
            <Styled.ErrorLabel>You must select both Reopen Date and Reopen Time</Styled.ErrorLabel>
          </div>
        )}
        <Styled.ModalActions>
          <SubmitButton navigation={closeReopenSubmit} buttonText='Confirm' />
          <BackButton navigation={onClose} backText='Cancel' />
        </Styled.ModalActions>
      </Styled.ModalContent>
    </Modal>
  )
}

export default PlatformCloseReopenModal
