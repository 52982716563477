import { FormattedMenuSectionInfo, MenuMappingSectionResource } from 'api'
import backArrow from 'assets/icons/arrow-back.svg'
import downChevron from 'assets/icons/Chevron-Down.svg'
import { Button, LoadingSkeleton, Select } from 'components'
import type { Option } from 'components/Select'
import { useAuth0 } from 'libs/auth0-react'
import { useEffect, useState } from 'react'
import { colors, fontFamily } from 'theme'

import * as Styled from './styles'

type Props = {
  menuSectionData?: FormattedMenuSectionInfo
  is86PreviewOnly: boolean
  onBackClick: () => void
  menuSections?: MenuMappingSectionResource[]
  setSelectedMenuSectionId: (id: string) => void
  selectedMenuSectionId?: string
  setIsMenuAvailabilityModalOpen: (status: boolean) => void
  isPrinterClient?: boolean
}

const selectStyles = {
  container: {
    width: 231,
    height: 47,
  },
  control: {
    width: 231,
    height: 47,
    backgroundColor: colors.white,
    borderColor: colors.gray.medium,
  },
  menu: {
    width: 231,
  },
  valueContainer: {
    color: colors.primary.medium,
    fontFamily: fontFamily.inter,
    fontWeight: 'regular',
    fontSize: 15,
    lineHeight: '150%',
  },
  singleValue: {
    color: colors.primary.medium,
    fontFamily: fontFamily.inter,
    fontWeight: 'regular',
    fontSize: 15,
    lineHeight: '150%',
  },
}

const MenuHeader = ({
  menuSectionData,
  is86PreviewOnly,
  onBackClick,
  menuSections,
  setSelectedMenuSectionId,
  selectedMenuSectionId,
  setIsMenuAvailabilityModalOpen,
}: Props) => {
  const { user } = useAuth0()

  const [isOpen, setIsOpen] = useState(false)
  const today = new Date().toLocaleDateString('en-US', { weekday: 'long' })
  const selectedDay = menuSectionData?.attributes?.availabilities?.find(
    (day) => day.dayOfWeek === today,
  )
  const [menuSectionOptions, setMenuSectionOptions] = useState<Option[]>([])
  const isAdminUser = user?.email?.includes('@chowlyinc.com')

  useEffect(() => {
    if (menuSections?.length) {
      const options = menuSections.map((section) => ({
        value: section.id,
        label: section.attributes.title,
      }))

      setMenuSectionOptions(options)
    }
  }, [menuSections])

  const getButtonText = () => {
    return selectedDay ? (
      <Styled.SelectedAvailabilityRow>
        <Styled.AvailableDay>{selectedDay.dayOfWeek}:</Styled.AvailableDay>
        <Styled.AvailableTime>{selectedDay.hours}</Styled.AvailableTime>
      </Styled.SelectedAvailabilityRow>
    ) : (
      <Styled.AvailableTime>Not Open Today</Styled.AvailableTime>
    )
  }

  const filteredEnabledAvailabilities = () => {
    const enabledAvailabilities = menuSectionData?.attributes?.availabilities?.filter(
      (availability) => availability.enabled,
    )

    return enabledAvailabilities?.map(({ id, dayOfWeek, hours }) => (
      <Styled.DropdownItem key={id}>
        <Styled.AvailabilityRow>
          <Styled.AvailableDay isHighlighted={dayOfWeek.toLowerCase() === today.toLowerCase()}>
            {dayOfWeek}:
          </Styled.AvailableDay>
          <Styled.AvailableTime isHighlighted={dayOfWeek.toLowerCase() === today.toLowerCase()}>
            {hours}
          </Styled.AvailableTime>
        </Styled.AvailabilityRow>
      </Styled.DropdownItem>
    ))
  }

  if (is86PreviewOnly) {
    return (
      <>
        <Styled.Subtitle>
          <Styled.BackButton onClick={onBackClick}>
            <img src={backArrow} alt='back icon' width='24px' height='24px' />
            Continue Editing
          </Styled.BackButton>
        </Styled.Subtitle>
        <Styled.HeaderTitle>Publish Menu with Updated Item Availability</Styled.HeaderTitle>
        <Styled.HeaderText>
          Updating item availability requires the menu to publish to all platforms. Please confirm
          the preview of your menu below and click "Publish Menu" to proceed. Publishing the menu
          may take a few minutes.
        </Styled.HeaderText>
      </>
    )
  } else {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Styled.Subtitle>
            <Styled.BackButton onClick={() => history.back()}>Menu Preview</Styled.BackButton>
          </Styled.Subtitle>
          <Styled.AvailabilityContainer>
            <Styled.DropdownContainer>
              <Styled.DropdownButton onClick={() => setIsOpen(!isOpen)}>
                <Styled.AvailabilityHeader>Hours: </Styled.AvailabilityHeader>
                {menuSectionData ? (
                  <Styled.TodaysHours>{getButtonText()}</Styled.TodaysHours>
                ) : (
                  <LoadingSkeleton isFlex />
                )}
                <Styled.Chevron
                  src={downChevron}
                  alt='chevron icon'
                  isOpen={isOpen}
                  width='16px'
                  height='16px'
                />
              </Styled.DropdownButton>
              {isOpen && (
                <Styled.DropdownContent>
                  {menuSectionData ? filteredEnabledAvailabilities() : <LoadingSkeleton isFlex />}
                  {isAdminUser && (
                    <Button
                      onClick={() => setIsMenuAvailabilityModalOpen(true)}
                      size='small'
                      narrow
                      style={{ margin: '10px' }}
                    >
                      Edit Availability
                    </Button>
                  )}
                </Styled.DropdownContent>
              )}
            </Styled.DropdownContainer>
          </Styled.AvailabilityContainer>
        </div>
        <Styled.Header>
          {/* {menuSectionData ? (
            <Styled.Title>{menuSectionData?.attributes.title}</Styled.Title>
          ) : (
            <LoadingSkeleton isFlex />
          )} */}
          <Select
            options={menuSectionOptions}
            name='Menu Name (required)'
            value={
              menuSectionOptions.find((option) => option.value === selectedMenuSectionId) ?? {
                value: '',
                label: '',
              }
            }
            onChange={(option) => {
              setSelectedMenuSectionId(option)
            }}
            styles={selectStyles}
          />
        </Styled.Header>
      </>
    )
  }
}

export default MenuHeader
