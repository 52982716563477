import styled from 'styled-components'
import { breakpoints, colors, fontFamily, fontWeight, typography } from 'theme'

export const ShortcutContainer = styled.div({
  display: 'flex',
  padding: '24px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  backgroundColor: colors.gray['450'],
  borderRadius: '8px',
  border: `1px solid ${colors.gray['450']}`,
  '&nth-child(1)': {
    gap: '20px',
  },
})

export const ShortcutsHeader = styled.div({
  color: colors.primary.medium,
  fontFamily: fontFamily.inter,
  fontSize: typography.xl.fontSize,
  fontStyle: 'normal',
  fontWeight: fontWeight.semibold,
  lineHeight: '140%' /* 28px */,
})

export const MenuImportContainer = styled.div({
  display: 'flex',
  padding: '20px 0px',
  alignItems: 'center',
  gap: '16px',
  alignSelf: 'stretch',
  justifyContent: 'space-between',
  borderBottom: `1px solid var(--Global-Interaction-Inactive, ${colors.gray.medium})`,
})

export const MenuContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
})

export const MenuImportTime = styled.div({
  color: colors.gray.mediumLight,
  fontFamily: fontFamily.inter,
  fontSize: typography.xxs.fontSize,
  fontStyle: 'normal',
  fontWeight: fontWeight.normal,
  lineHeight: '150%' /* 19.5px */,
})

export const StatusContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
})

export const Icon = styled.img<{ status?: boolean; size?: string | number }>(
  ({ size }) =>
    size && {
      width: `${size}px`,
      height: `${size}px`,
    },
  ({ status }) =>
    status
      ? {
          color: colors.green.darkGreen,
        }
      : {
          color: colors.red.darkRed,
        },
)

export const DetailsContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flex: '1',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
})

export const Status = styled.div<{ status?: boolean }>(
  {
    textAlign: 'right',
    fontFamily: fontFamily.inter,
    fontSize: typography.xxxs.fontSize,
    fontStyle: 'normal',
    fontWeight: fontWeight.medium,
    lineHeight: '150%' /* 15px */,
  },
  ({ status }) =>
    status
      ? {
          color: colors.green.darkGreen,
        }
      : {
          color: colors.red.darkRed,
        },
)

export const ImportDetailsContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  alignSelf: 'stretch',
})

export const MenuImportHeader = styled.div({
  color: colors.primary.medium,
  fontFamily: fontFamily.inter,
  fontSize: typography.xxs.fontSize,
  fontStyle: 'normal',
  fontWeight: fontWeight.bold,
  lineHeight: '150%' /* 19.5px */,
})

export const PlatformContainer = styled.div({
  display: 'flex',
  padding: '20px 0 0 0',
  gap: '8px',
  alignSelf: 'stretch',
  flexDirection: 'column',
})

export const PillGroup = styled.div({
  fontFamily: fontFamily.inter,
  fontWeight: fontWeight.normal,
  fontSize: typography.xxxs.fontSize,
  lineHeight: typography.xxxs.lineHeight,
  gap: '4px',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
})

export const PillStatus = styled.div<{ color?: string }>(
  {
    [`@media ${breakpoints.xxs}`]: {
      minWidth: '100%',
    },
  },
  ({ color }) =>
    color && {
      color:
        color === 'green'
          ? colors.green.darkGreen
          : color === 'red'
          ? colors.red.darkRed
          : colors.yellow.darkYellow,
    },
)

export const PlatformHeader = styled.div({
  color: colors.primary.medium,
  fontFamily: fontFamily.inter,
  fontSize: typography.xxs.fontSize,
  fontStyle: 'normal',
  fontWeight: fontWeight.semibold,
  lineHeight: '140%' /* 18.2px */,
})

export const PlatformPill = styled.div<{ status?: boolean }>(
  {
    display: 'flex',
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '8px',
    border: `1px solid`,
  },
  ({ status }) =>
    status
      ? {
          borderColor: colors.green.darkGreen,
        }
      : {
          borderColor: colors.red.darkRed,
        },
)

export const MenuPill = styled.div<{ status?: boolean }>(
  {
    display: 'flex',
    padding: '4px 8px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    borderRadius: '8px',
    border: `1px solid`,
    borderColor: 'rgb(215, 215, 215)',
    fontSize: 12,
    marginTop: 5,
    cursor: 'pointer'
  }
)

export const PillText = styled.div({
  color: colors.primary.medium,
  fontFamily: fontFamily.inter,
  fontSize: typography.xxs.fontSize,
  fontStyle: 'normal',
  fontWeight: fontWeight.normal,
  lineHeight: '150%' /* 19.5px */,
})
