import chowlyImg from 'assets/chowly.png'
import styled from 'styled-components'
import { breakpoints } from 'theme'

const Hero = styled.img.attrs({ src: chowlyImg, alt: 'Chowly' })({
  width: '100%',
  height: '100vh',
  maxWidth: '100%',
  display: 'none',
  objectFit: 'cover',
  verticalAlign: 'middle',
  [`@media ${breakpoints.md}`]: {
    display: 'block',
  },
})

export default Hero
