import styled from 'styled-components'
import { colors, fontWeight } from 'theme'

export const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  columnGap: 8,
  height: 56,
})

export const TimePicker = styled.div({
  width: '80px',
})

export const Separator = styled.span({
  fontWeight: fontWeight.bold,
  color: colors.gray.darkest,
})
