import { ReactComponent as ChowlyLogo } from 'assets/logo.svg'

import AlertContainer from '../AlertContainer/AlertContainer'
import * as Styled from './styles'

type Props = {
  title: string
  message: string
}
const Success = ({ title, message }: Props) => {
  return (
    <Styled.Container>
      <Styled.Header>
        <ChowlyLogo />
      </Styled.Header>
      <Styled.Title>{title}</Styled.Title>
      <AlertContainer type={'success'} messages={message} />
      <Styled.LoginLink to='/login'>Login</Styled.LoginLink>
    </Styled.Container>
  )
}

export default Success
