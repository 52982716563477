import { useState } from 'react'
import { Link } from 'react-router-dom'
import { LocationSummary } from 'types'

import Chevron_Down from '../../assets/icons/Chevron-Down.svg'
import * as Styled from './styles'

type Props = {
  locations: LocationSummary[]
  setSelectedLocation: (val: LocationSummary) => void // Dispatch<SetStateAction<LocationSummary>>
  selectedLocation: LocationSummary
}

const LocationDropdown = ({ locations, selectedLocation, setSelectedLocation }: Props) => {
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const singleLocation = locations.length === 1

const handleSetLocation = (updatedLocation: LocationSummary) => {
  if (updatedLocation) {
    setSelectedLocation(updatedLocation);
    setDropDownOpen(false);
  }
};

  return (
    <Styled.DropdownContainer onClick={() => setDropDownOpen(!dropDownOpen)}>
      <Styled.Dropdown singleLocation={singleLocation}>
        <Styled.LocationContainer>
          <Styled.LocationHeader>{selectedLocation?.name}</Styled.LocationHeader>
          <Styled.Location>{`${selectedLocation?.city}, ${selectedLocation?.state}`}</Styled.Location>
        </Styled.LocationContainer>
        {!singleLocation && (
          <Styled.Icon
            src={Chevron_Down}
            alt='closeIcon'
            onClick={() => setDropDownOpen(!dropDownOpen)}
            singleLocation={singleLocation}
          />
        )}
      </Styled.Dropdown>
      <Styled.DropDownOptions active={dropDownOpen}>
        {locations?.map((location) => (
          <Styled.OptionsRow key={location.id} onClick={() => handleSetLocation(location)}>
            <Styled.RowDetails 
              data-value={location}
            >{`${location.name} - ${location.city}, ${location.state}`}</Styled.RowDetails>
          </Styled.OptionsRow>
        ))}
      </Styled.DropDownOptions>
      {!singleLocation && (
        <Link to={`/manage-locations/${selectedLocation?.id.toString()}`}>
          <Styled.ViewLocation>View Location</Styled.ViewLocation>
        </Link>
      )}
    </Styled.DropdownContainer>
  )
}

export default LocationDropdown
