import { ItemModifier, MenuMappingItemDetails, TypeName } from 'api'
import { Button, CalendarPicker, Modal } from 'components'
import * as AlertModalStyles from 'components/AlertModal/styles'
import { useIsMobile } from 'hooks'
import { useEffect, useState } from 'react'
import {
  convertClientToPartnerTimezone,
  convertToUtc,
  getDateStr,
  getSuspendUntilDefaultTime,
} from 'utils/DateTime'

import * as Styled from './styles'

type ItemToSuspend = {
  id: MenuMappingItemDetails['id'] | ItemModifier['id']
  type: TypeName.Item | TypeName.CustomizationOption
}

type Props = {
  isOpen: boolean
  onClose: () => void
  itemToSuspend: ItemToSuspend | undefined
  buttonAction: (
    type: TypeName.Item | TypeName.CustomizationOption,
    id: MenuMappingItemDetails['id'],
    suspendUntil: string | null,
  ) => void
  partnerTimezone: string
}

const SuspendUntilModal = ({
  isOpen,
  onClose,
  itemToSuspend,
  buttonAction,
  partnerTimezone,
}: Props) => {
  const { isMobile } = useIsMobile()
  const defaultNextDay = getSuspendUntilDefaultTime(partnerTimezone)

  const [selectedInput, setSelectedInput] = useState<number>(0)
  const [suspendUntil, setSuspendUntil] = useState<string | null>(defaultNextDay)
  const [customDate, setCustomDate] = useState<Date>(new Date())

  useEffect(() => {
    switch (selectedInput) {
      // case 1 handled separately for custom date
      case 0: {
        // set to midnight of next day or opening time, partner's (location) timezone
        setSuspendUntil(defaultNextDay)
        break
      }
      case 2: {
        // null is set indefinitely by default
        setSuspendUntil(null)
        break
      }
      default:
        return
    }
  }, [selectedInput])

  useEffect(() => {
    if (selectedInput === 1 && customDate) {
      const localDateStr = getDateStr(customDate)
      const partnerTzDate = convertClientToPartnerTimezone(localDateStr, partnerTimezone)
      const partnerTzUtc = convertToUtc(partnerTzDate)
      setSuspendUntil(partnerTzUtc)
    }
  }, [customDate, selectedInput])

  // this will be in client's timezone, we need to make sure it's converted to restaurant's timezone before sending to the backend.
  const setSelectedDate = (selectedDate: Date) => {
    setCustomDate(selectedDate)
  }

  return (
    <Modal isOpen={isOpen}>
      <Styled.Box isMobile={isMobile}>
        <AlertModalStyles.TextContainer>
          <Styled.Label>When would you like this item to be available again?</Styled.Label>
          <Styled.SecondaryLabel>Automatically mark it back in stock</Styled.SecondaryLabel>
          <Styled.InputContainer>
            <Styled.RadioInput
              name='time_option_next_day'
              value={0}
              type='radio'
              checked={selectedInput === 0}
              onChange={(event) => setSelectedInput(parseInt(event.target.value, 10))}
            />
            <Styled.Label>At the start of next open day</Styled.Label>
          </Styled.InputContainer>
          <Styled.InputContainer>
            <Styled.RadioInput
              name='time_option_custom'
              value={1}
              type='radio'
              checked={selectedInput === 1}
              onChange={(event) => setSelectedInput(parseInt(event.target.value, 10))}
            />
            <Styled.Label>At a custom date and time</Styled.Label>
            {selectedInput === 1 && (
              <CalendarPicker selectedDate={customDate} setSelectedDate={setSelectedDate} />
            )}
          </Styled.InputContainer>
        </AlertModalStyles.TextContainer>
        <AlertModalStyles.TextContainer>
          <Styled.SecondaryLabel>Out of stock indefinitely</Styled.SecondaryLabel>
          <Styled.InputContainer>
            <Styled.RadioInput
              name='time_option_indefinite'
              value={2}
              type='radio'
              checked={selectedInput === 2}
              onChange={(event) => setSelectedInput(parseInt(event.target.value, 10))}
            />
            <Styled.Label>I will mark this item back in stock in the RCC manually</Styled.Label>
          </Styled.InputContainer>
          <AlertModalStyles.ButtonContainer>
            <Button variant='textonly' size='small' onClick={onClose}>
              Cancel
            </Button>
            <Button
              size='small'
              disabled={!itemToSuspend}
              onClick={() => {
                if (itemToSuspend) {
                  buttonAction(itemToSuspend.type, itemToSuspend.id, suspendUntil)

                  onClose()
                }
              }}
            >
              Save
            </Button>
          </AlertModalStyles.ButtonContainer>
        </AlertModalStyles.TextContainer>
      </Styled.Box>
    </Modal>
  )
}

export default SuspendUntilModal
