import range from 'lodash/range'
import moment, { Moment } from 'moment'
import TimePicker from 'rc-time-picker'
import { FC } from 'react'
import { momentFormat } from 'utils'

import * as Styled from './styles'

type Props = {
  startTime?: string
  endTime?: string
  startTimeDisabledMinutes?: { [key: string]: number[] }
  startTimeDisabledHours?: number[]
  endTimeDisabledMinutes?: { [key: string]: number[] }
  endTimeDisabledHours?: number[]
  disabledHours?: string
  disabledMinutes?: string
  onStartTimeChange: (newVal: Moment) => void
  onEndTimeChange: (newVal: Moment) => void
}

const InputTimeSlot: FC<Props> = ({
  startTime,
  endTime,
  startTimeDisabledHours,
  startTimeDisabledMinutes,
  endTimeDisabledHours,
  endTimeDisabledMinutes,
  onStartTimeChange,
  onEndTimeChange,
}) => {
  const startDisabledHours = () => {
    return startTimeDisabledHours || []
  }

  const startDisabledMinutes = (h: number) => {
    if (!startTimeDisabledHours || !startTimeDisabledMinutes) {
      return []
    }
    // find first enabled hour
    if (startTimeDisabledHours.indexOf(h) > -1 || !h) {
      return range(0, 60)
    }
    return startTimeDisabledMinutes[h]
  }

  const endDisabledHours = () => {
    return endTimeDisabledHours || []
  }

  const endDisabledMinutes = (h: number) => {
    if (!endTimeDisabledHours || !endTimeDisabledMinutes) {
      return []
    }
    if (endTimeDisabledHours.indexOf(h) > -1 || !h) {
      return range(0, 60)
    }
    return endTimeDisabledMinutes[h]
  }

  return (
    <Styled.Container>
      <Styled.TimePicker>
        <TimePicker
          use12Hours={true}
          hideDisabledOptions={true}
          showSecond={false}
          disabledHours={startDisabledHours}
          disabledMinutes={startDisabledMinutes}
          onChange={onStartTimeChange}
          defaultValue={startTime ? moment(startTime, momentFormat) : undefined}
          inputReadOnly={true}
        />
      </Styled.TimePicker>

      <Styled.Separator>-</Styled.Separator>
      <Styled.TimePicker>
        <TimePicker
          use12Hours={true}
          hideDisabledOptions={true}
          showSecond={false}
          onChange={onEndTimeChange}
          disabledHours={endDisabledHours}
          disabledMinutes={endDisabledMinutes}
          defaultValue={endTime ? moment(endTime, momentFormat) : undefined}
          inputReadOnly={true}
        />
      </Styled.TimePicker>
    </Styled.Container>
  )
}

InputTimeSlot.displayName = 'InputTimeSlot'

export default InputTimeSlot
