import { ActionBar, Button } from 'components'

import * as Styled from './styles'

type Props = {
  isPreviewOnly: boolean
  onReset: () => void
  onPublishMenu: () => void
  onContinue: () => void
}

const MenuPreviewBottomActionBar = ({
  isPreviewOnly,
  onReset,
  onPublishMenu,
  onContinue,
}: Props) => {
  return (
    <ActionBar position='bottom'>
      <Styled.ButtonContainer>
        <Button
          variant='textonly'
          narrow
          onClick={onReset} // reset all menu updates
        >
          Cancel
        </Button>
        {isPreviewOnly ? (
          <Button variant='solid' narrow onClick={onPublishMenu}>
            Publish Menu
          </Button>
        ) : (
          <Button variant='solid' narrow onClick={onContinue}>
            Continue
          </Button>
        )}
      </Styled.ButtonContainer>
    </ActionBar>
  )
}

export default MenuPreviewBottomActionBar
