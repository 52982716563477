import { useEffect, useState } from 'react'

import {
  MenuMappingItem,
  MenuMappingItemDetails,
  MenuMappingSubsectionItem,
  MenuMappingSubsectionItemResource,
  TypeName,
  getMenuMappingItem,
  getMenuMappingSubsection,
} from '../api'

type UseGetMenuMappingItems = (subsectionId: string) => MenuMappingItemDetails[]

const useMenuMappingItems: UseGetMenuMappingItems = (subsectionId: string) => {
  const [items, setItems] = useState<MenuMappingItemDetails[]>([])

  useEffect(() => {
    ;(async () => {
      try {
        const { included } = await getMenuMappingSubsection(subsectionId, { include: 'items' })
        if (included) {
          const menuMappingItems = included
            .filter(({ type }) => type === TypeName.MenuMappingItem)
            .map(({ id, attributes, type }) => ({
              id,
              type,
              ...(attributes as MenuMappingItem),
            }))
          setItems(menuMappingItems)
          const results = await Promise.all<MenuMappingSubsectionItem | null>(
            menuMappingItems.map(async ({ id }) => {
              try {
                const { included } = await getMenuMappingItem(id, {
                  include: 'subsection_items',
                })
                if (included) {
                  const subsectionItems = included as Array<MenuMappingSubsectionItemResource>
                  return subsectionItems[0].attributes
                }
                return null
              } catch (error) {
                console.error(`Failed in fetching the menu_mapping_item:${id}. Reason:`, error)
                return null
              }
            }),
          )
          setItems(menuMappingItems.map((item, i) => ({ ...item, ...results[i] })))
        }
      } catch (error) {
        console.error('Failed in fetching the subsection items. Reason:', error)
      }
    })()
  }, [])

  return items
}

export default useMenuMappingItems
