import { SigmaEmbed, SkeletonLoader } from 'components'
import { useLocations } from 'components/MainLayout/MainLayout'
import { sigmaPages } from 'config'
import { useEmbedUrl } from 'hooks'

function Disputes() {
  const { locationsData } = useLocations()
  const { locations, isLoading } = locationsData

  const url = useEmbedUrl(sigmaPages.disputes)

  if (isLoading) return <SkeletonLoader />
  if (!locations?.length) {
    return <div>Please add a location before viewing this report.</div>
  }
  return <SigmaEmbed url={url} title='Disputes Report' />
}

export default Disputes
