import { FormattedMenuSectionInfo, ItemModifier, MenuMappingItemDetails } from 'api'
import { TabType } from 'types'

import { TABS } from '../constants'

type FilteredResults = {
  filteredItems: FormattedMenuSectionInfo[]
  filteredModifiers: { [id: FormattedMenuSectionInfo['id']]: FormattedMenuSectionInfo[] }
  filteredModifierGroups: { [id: MenuMappingItemDetails['id']]: FormattedMenuSectionInfo[] }
}

/**
 * @param items - all items with their nested mod groups and mods
 * @param tab - filtering for either Active or Inactive tab
 * @param selectedItemIds - all currently selected items to check against
 * @param selectedModifierIds - all currently selected modifiers to check against
 * @param filteredModifierIds - if we already applied a filter to modifiers, include that in here
 * @returns filteredItems - all items to display in this tab. If a modifier is in the tab but an items isn't, still show the item.
 * filteredModifierGroups - all modifier groups to show in the table that have modifiers in this tab
 * filteredModifiers - all modifiers that match the active/inactive status
 */
const getTabFilteredItemsModifiers = (
  items: FormattedMenuSectionInfo[],
  tab: TabType,
  selectedItemIds: MenuMappingItemDetails['id'][],
  selectedModifierIds: ItemModifier['id'][],
  filteredModifierIds: FormattedMenuSectionInfo['id'][] = [], // modifiers that were already filtered out
): FilteredResults | undefined => {
  // if we're looking at the all products tab, don't need to filter anything
  if (tab === TABS.ALL_PRODUCTS) {
    return
  }

  const filteredModifiers: { [id: FormattedMenuSectionInfo['id']]: FormattedMenuSectionInfo[] } = {} // map modifiers to their modifier groups
  const filteredModifierGroups: { [id: MenuMappingItemDetails['id']]: FormattedMenuSectionInfo[] } =
    {} // map modifier groups to items

  const filteredItems = items.filter((item) => {
    const matchingItemModifierGroups: FormattedMenuSectionInfo[] = []

    item.children.customizations?.forEach((customization) => {
      const matchingItemModifiers: FormattedMenuSectionInfo[] = []

      // apply pre-existing modifier filter if present
      const customizationOptions = filteredModifierIds.length
        ? customization.children.customization_options?.filter((option) =>
            filteredModifierIds.includes(option.id),
          )
        : customization.children.customization_options

      customizationOptions?.forEach((option) => {
        if (tab === TABS.ACTIVE && selectedModifierIds.includes(option.id)) {
          matchingItemModifiers.push(option)
        }

        if (tab === TABS.INACTIVE && !selectedModifierIds.includes(option.id)) {
          matchingItemModifiers.push(option)
        }
      })

      if (matchingItemModifiers.length) {
        matchingItemModifierGroups.push(customization)
        filteredModifiers[customization.id] = matchingItemModifiers
      }
    })

    // if there are modifier groups in this item that match, return item as well.
    if (matchingItemModifierGroups.length) {
      filteredModifierGroups[item.id] = matchingItemModifierGroups
      return true
    }

    // if the item itself matches, return it
    if (tab === TABS.ACTIVE && selectedItemIds.includes(item.id)) {
      return true
    }

    if (tab === TABS.INACTIVE && !selectedItemIds.includes(item.id)) {
      return true
    }

    return false
  })

  return { filteredItems, filteredModifierGroups, filteredModifiers }
}

export default getTabFilteredItemsModifiers
