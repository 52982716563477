import addIcon from 'assets/icons/add-menu-icon.svg'
import deleteIcon from 'assets/icons/delete.svg'
import { difference, findLast, forEach, intersection, range } from 'lodash'
import moment from 'moment'
import { FC, useState } from 'react'
import { TimeSlot } from 'types/Menu'
import { momentFormat } from 'utils'
import { v4 as uuidv4 } from 'uuid'

import InputTimeSlot from '../InputTimeSlot/InputTimeSlot'
import * as Styled from './styles'

export const getDefaultValue = (): TimeSlot => ({
  id: uuidv4(),
  persisted: false,
  startTime: '',
  endTime: '',
})

type Props = {
  value: TimeSlot[]
  onChange: (newValue: TimeSlot[]) => void
}

const InputMultipleTimeSlots: FC<Props> = ({ value, onChange }) => {
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>(value.length ? value : [getDefaultValue()])

  const startTimeDisabledHours = (index: number) => {
    const hours: number[] = availableTimes(index, 'start')['availableHours']
    let disabled: number[] = difference(range(0, 24), hours)

    const endTime = timeSlots[index].endTime
    if (endTime) {
      const momentEndTime = moment(endTime, momentFormat)
      const lastHour = momentEndTime.hour()
      const lastMinute = momentEndTime.minute()
      disabled = disabled.concat(
        hours.filter((hour) => {
          if (lastMinute == 0) {
            return hour >= lastHour
          } else {
            return hour > lastHour
          }
        }),
      )
    }
    return disabled
  }

  const startTimeDisabledMinutes = (index: number) => {
    const hourMinutesHash = availableTimes(index, 'start')['availableMinutes']

    const disabledHash: { [key: string]: number[] } = {}
    forEach(hourMinutesHash, (minutes, hour) => {
      disabledHash[hour] = difference(range(0, 60), minutes)
    })
    const endTime = timeSlots[index].endTime
    if (endTime) {
      const momentEndTime = moment(endTime, momentFormat)
      const lastMinute = momentEndTime.minute()
      const lastHour = momentEndTime.hour()
      const minutes = range(0, 60)
      const disabledMinutes = minutes.filter((minute) => {
        return minute > lastMinute
      })
      if (disabledHash[lastHour]) {
        disabledHash[lastHour] = disabledHash[lastHour].concat(disabledMinutes)
      } else {
        disabledHash[lastHour] = disabledMinutes
      }
    }
    return disabledHash
  }

  const endTimeDisabledHours = (index: number) => {
    const hours: number[] = availableTimes(index, 'end')['availableHours']
    let disabled: number[] = difference(range(0, 24), hours)

    const startTime = timeSlots[index].startTime
    if (startTime) {
      const momentStartTime = moment(startTime, momentFormat)
      const startHour = momentStartTime.hour()
      const startMinute = momentStartTime.minute()
      disabled = disabled.concat(
        hours.filter((hour) => {
          if (startMinute == 59) {
            return hour <= startHour
          } else {
            return hour < startHour
          }
        }),
      )
    }
    return disabled
  }

  const endTimeDisabledMinutes = (index: number) => {
    const hourMinutesHash = availableTimes(index, 'end')['availableMinutes']

    const disabledHash: { [key: string]: number[] } = {}
    forEach(hourMinutesHash, (minutes, hour) => {
      disabledHash[hour] = difference(range(0, 60), minutes)
    })

    const startTime = timeSlots[index].startTime
    if (startTime) {
      // reset disabled as original disabled takes previous endtime into account
      // but now we should take current start time into the account
      const minutes = range(0, 60)
      const momentStartTime = moment(startTime, momentFormat)
      const startMinute = momentStartTime.minute()
      const startHour = momentStartTime.hour()

      const disabledMinutes = minutes.filter((minute) => {
        return minute <= startMinute
      })
      if (disabledHash[startHour]) {
        disabledHash[startHour] = disabledHash[startHour].concat(disabledMinutes)
      } else {
        disabledHash[startHour] = disabledMinutes
      }
    }
    return disabledHash
  }

  const availableTimes = (index: number, type: 'start' | 'end') => {
    let avHours = range(0, 24)
    let avMinutes = range(0, 60)
    const avMinutesHash: { [key: string]: number[] } = {}
    if (timeSlots.length > 1) {
      const prevEndTime = findLast(timeSlots.slice(0, index), ({ endTime }) =>
        Boolean(endTime),
      )?.endTime
      if (prevEndTime) {
        const momentPrevEndTime = moment(prevEndTime, momentFormat)
        const hour = momentPrevEndTime.hour()
        const minute = momentPrevEndTime.minute()
        avHours = avHours.filter((avHour) => {
          if (minute == 59) {
            return avHour > hour
          } else {
            return avHour >= hour
          }
        })
        avMinutes = avMinutes.filter((avMinute) => {
          if (minute == 59) {
            return []
          }
          return avMinute > minute
        })
        avMinutesHash[avHours[0]] = avMinutes
      }
      const nextStartTime = findLast(timeSlots.slice(index + 1), ({ startTime }) =>
        Boolean(startTime),
      )?.startTime

      let startTime = ''
      if (type == 'start') {
        startTime = timeSlots[index].endTime || nextStartTime || ''
      } else {
        startTime = nextStartTime || ''
      }

      if (startTime) {
        avMinutes = range(0, 60)
        const momentNextStartTime = moment(startTime, momentFormat)
        const hour = momentNextStartTime.hour()
        const minute = momentNextStartTime.minute()
        avHours = avHours.filter((avHour) => {
          return avHour <= hour
        })
        avMinutes = avMinutes.filter((avMinute) => {
          return avMinute < minute
        })
        if (avMinutesHash[hour]) {
          // only pick hours that are available for both
          avMinutesHash[hour] = intersection(avMinutesHash[hour], avMinutes)
        } else {
          avMinutesHash[hour] = avMinutes
        }
      }
    }
    return { availableHours: avHours, availableMinutes: avMinutesHash }
  }

  const handleChange = (changed: TimeSlot) => {
    const index = timeSlots.findIndex(({ id }) => id === changed.id)
    const newTimeSlots = [...timeSlots]
    newTimeSlots.splice(index, 1, changed)
    setTimeSlots(newTimeSlots)
    if (changed.startTime && changed.endTime) {
      onChange(newTimeSlots)
    }
  }

  const handleAddTimeSlot = () => setTimeSlots([...timeSlots, getDefaultValue()])

  const handleDeleteTimeSlot = async (deleteId: string) => {
    const index = timeSlots.findIndex(({ id }) => id === deleteId)
    if (index > -1) {
      const newTimeSlots = [...timeSlots]
      newTimeSlots.splice(index, 1)
      setTimeSlots(newTimeSlots)
      onChange(newTimeSlots)
    }
  }

  return (
    <Styled.Container>
      {timeSlots.map(({ startTime, endTime, id, persisted }, i, arr) => (
        <Styled.Row key={id}>
          <InputTimeSlot
            startTime={startTime}
            endTime={endTime}
            startTimeDisabledHours={startTimeDisabledHours(i)}
            startTimeDisabledMinutes={startTimeDisabledMinutes(i)}
            endTimeDisabledHours={endTimeDisabledHours(i)}
            endTimeDisabledMinutes={endTimeDisabledMinutes(i)}
            onStartTimeChange={(newStartTime) => {
              handleChange({
                id,
                startTime: newStartTime?.format(momentFormat) || '',
                endTime,
                persisted,
              })
            }}
            onEndTimeChange={(newEndTime) => {
              handleChange({
                id,
                startTime,
                endTime: newEndTime?.format(momentFormat) || '',
                persisted,
              })
            }}
          />
          {i === arr.length - 1 ? (
            <Styled.IconButton type='button' onClick={handleAddTimeSlot}>
              <img src={addIcon} />
            </Styled.IconButton>
          ) : (
            <Styled.IconButton type='button' onClick={() => handleDeleteTimeSlot(id)}>
              <img src={deleteIcon} />
            </Styled.IconButton>
          )}
        </Styled.Row>
      ))}
    </Styled.Container>
  )
}

InputMultipleTimeSlots.displayName = 'InputMultipleTimeSlots'

export default InputMultipleTimeSlots
