import { resetPassword } from 'api'
import { Button, TextField } from 'components'
import { Formik } from 'formik'
import { useState } from 'react'
import { PasswordResetRequest } from 'types'
import * as Yup from 'yup'

import * as Styled from './styles'

const validationSchema = Yup.object({
  email: Yup.string().trim().email('Valid email format is required').required('Email is required'),
})

function ManageAccount() {
  const initialValues: PasswordResetRequest = {
    email: '',
  }

  const [isSent, setIsSent] = useState(false)

  const handleSubmit = async (values: PasswordResetRequest) => {
    try {
      const { data } = await resetPassword(values)

      if (data) {
        setIsSent(true)
      }
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <Styled.Container>
      {isSent ? (
        <>
          <Styled.Title>Check Your Email</Styled.Title>
          <Styled.SentMessage>
            If we find an account that matches the information you submitted, we will send password
            reset instructions to the email address you just entered.
          </Styled.SentMessage>
        </>
      ) : (
        <Styled.FormContainer>
          <Styled.Title>Change Password</Styled.Title>
          <Styled.Subtitle>Enter the email address linked to your Chowly Account</Styled.Subtitle>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Styled.Form>
              <TextField
                label='Email'
                id='email'
                type='email'
                name='email'
                placeholder='youremail@email.com'
              />
              <Styled.ButtonDiv>
                <Button type='submit'>Submit</Button>
              </Styled.ButtonDiv>
            </Styled.Form>
          </Formik>
        </Styled.FormContainer>
      )}
    </Styled.Container>
  )
}

export default ManageAccount
