const ProvisionStatusDescriptions = {
  default: { title: '', statuses: [{ title: '', description: '' }] },
  chowlyStatus: {
    title: 'Chowly Status',
    statuses: [
      {
        title: 'Enabled',
        description: 'the platform has been enabled by Chowly. ',
      },
      {
        title: 'Onboarding',
        description: 'Chowly has not yet enabled this platform.',
      },
      {
        title: 'Disabled',
        description: 'Chowly has disabled this platform.',
      },
    ],
  },
  provisionStatus: {
    title: 'Provision Status',
    statuses: [
      {
        title: 'Pending',
        description:
          'you need to update settings in your Uber Eats account. Please confirm in your UE account that 1) Auto-Accept Orders is enabled, and 2) your authorized ubereats account is a manager account',
      },
      {
        title: 'Requested',
        description:
          'Chowly sent the provisioning request to Uber Eats. Uber Eats has not yet processed the request.',
      },
    ],
  },
}

export default ProvisionStatusDescriptions
