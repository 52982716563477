import {
  MenuMappingItem,
  MenuMappingItemDetails,
  MenuMappingSubsection,
  MenuMappingSubsectionItem,
} from 'api'
import { Accordion, MenuItem } from 'components'
import { FC } from 'react'

import * as Styled from './styles'

type Props = {
  subsection: MenuMappingSubsection & {
    items: Array<MenuMappingItem & MenuMappingSubsectionItem & { id: string; type: string }>
  }
  onItemClick: (itemId: MenuMappingItemDetails['id']) => void
}

const MenuSubsection: FC<Props> = ({ subsection, onItemClick }) => {
  const { title, items } = subsection
  const noItems = items.length < 1
  const numberOfItems = noItems ? '(Category has no items)' : `(${items.length} Menu Items)`

  return (
    <Accordion
      expandByDefault
      isError={noItems}
      title={
        <Styled.Title>
          {title}
          <span>{numberOfItems}</span>
        </Styled.Title>
      }
      content={
        <Styled.ItemsGrid>
          {items.map((item) => (
            <MenuItem key={item.id} data={item} onClick={() => onItemClick(item.id)} />
          ))}
        </Styled.ItemsGrid>
      }
    />
  )
}

MenuSubsection.displayName = 'MenuSubsection'

export default MenuSubsection
