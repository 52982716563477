import { updatePlatformOpenStatus } from 'api'
import pause from 'assets/icons/Pause.svg'
import play from 'assets/icons/playButton.svg'
import { PlatformLoader } from 'components'
import PlatformCloseReopenModal from 'features/manage-locations/PlatformCloseReopenModal'
import { useCallback, useState } from 'react'
import { PlatformSummary } from 'types'
import { showError } from 'utils'

import * as Styled from './styles'

type Props = {
  platforms: PlatformSummary[] | undefined
  refetch: () => Promise<void>
}

const PlatformShortcut = ({ platforms, refetch }: Props) => {
  const [isCloseReopenModalOpen, setIsCloseReopenModalOpen] = useState(false)
  const [selectedPlatform, setSelectedPlatform] = useState<PlatformSummary>()
  const [isUpdating, setIsUpdating] = useState(false)

  const handleCloseReopen = async (temporary_closure?: string) => {
    if (!selectedPlatform || isUpdating) return
    try {
      setIsCloseReopenModalOpen(false)
      setIsUpdating(true)
      const { availability, id } = selectedPlatform
      await updatePlatformOpenStatus(id, {
        // if its currently paused reopen, else close
        open_availability: availability === 'Paused' ? true : false,
        ...(availability !== 'Paused' && {
          temporary_closure,
        }),
      })
      refetch()
    } catch (e) {
      showError(e)
    }
    setIsUpdating(false)
  }

  const handlePillClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, row?: PlatformSummary) => {
      const selectedPlatformId = (e.target as HTMLButtonElement).dataset.value
      if (platforms?.length) {
        const platform = platforms.find((p) => p.id.toString() == selectedPlatformId)
        setSelectedPlatform(row ? row : platform)
        setIsCloseReopenModalOpen(true)
      }
    },
    [platforms],
  )

  return (
    <Styled.PlatformContainer>
      <Styled.PlatformHeader>Platforms</Styled.PlatformHeader>
      {!platforms ? (
        <PlatformLoader />
      ) : (
        <Styled.PillGroup>
          {platforms &&
            platforms.map((platform) => {
              const isEnabled =
                platform.status === 'Disabled' || platform.availability === 'Not Paused'
              return (
                <Styled.PlatformPill key={platform.id} status={isEnabled}>
                  <Styled.PillText>{platform.name}</Styled.PillText>
                  <Styled.Icon
                    data-value={platform.id}
                    src={isEnabled ? pause : play}
                    alt={isEnabled ? 'enabled' : 'disabled'}
                    size={isEnabled ? '8' : '8.7'}
                    status={false}
                    onClick={(e) => handlePillClick(e)}
                  />
                </Styled.PlatformPill>
              )
            })}
        </Styled.PillGroup>
      )}
      {selectedPlatform && (
        <PlatformCloseReopenModal
          isOpen={isCloseReopenModalOpen}
          platforms={[selectedPlatform]}
          timezone={'UTC'}
          onClose={() => setIsCloseReopenModalOpen(false)}
          onSubmit={handleCloseReopen}
        />
      )}
    </Styled.PlatformContainer>
  )
}

export default PlatformShortcut
