import { Invoice } from 'api/billing/types'

import {
  getInvoices,
} from 'api/billing'
import { useCallback, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

type UseGetInvoicesConfig = (
  partnerId: string | number | null | undefined,
) => [
  {
    isLoading: boolean
    isError: boolean
    invoices?: Invoice[]
  },
]

const useGetInvoices: UseGetInvoicesConfig = (
  partnerId: string | number | null | undefined,
) => {
  const [isLoading, setIsLoading] = useState(isEmpty(partnerId))
  const [isError, setIsError] = useState(false)
  const [invoices, setInvoices] = useState<Invoice[] | undefined>(undefined)

  const fetchInvoices = useCallback(async () => {
    setIsLoading(true)
    try {
      const invoices = await getInvoices({ partnerId })
      setInvoices(invoices)
      setIsLoading(false)
    } catch (err) {
      console.error(err)
      setInvoices([])
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [partnerId])

  useEffect(() => {
    if (!isEmpty(partnerId)) {
      fetchInvoices()
    }
  }, [partnerId])

  return [
    {
      isLoading,
      isError,
      invoices,
    },
  ]
}

export default useGetInvoices