import styled from 'styled-components'
import { breakpoints } from 'theme'

export const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '2rem',
})

export const Grid = styled.div<{ hasThreeCols: boolean }>(
  {
    display: 'grid',
    gap: '1.25rem',
    [`@media ${breakpoints.lg}`]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
  },
  ({ hasThreeCols }) =>
    hasThreeCols && {
      [`@media ${breakpoints.xl}`]: {
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      },
    },
)
