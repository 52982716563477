import { FormattedMenuSectionInfo, ItemModifier, MenuMappingItemDetails, TypeName } from 'api'
import { useIsMobile } from 'hooks'
import React, { FC, Fragment, useEffect, useState } from 'react'
import theme from 'theme'

import ItemModifierRow from './ItemModifierRow'
import * as Styled from './styles'

type ExpandNestedModifiersMap = {
  [modifierId: ItemModifier['id']]: boolean
}

type Props = {
  subsection: FormattedMenuSectionInfo
  checkedModifierIds?: ItemModifier['id'][]
  isPreviewOnly?: boolean
  onModifierClick?: (modifierId: ItemModifier['id']) => void
  groupModifiers?: FormattedMenuSectionInfo[]
  setSuspendUntilItem?: React.Dispatch<
    React.SetStateAction<
      | {
          id: MenuMappingItemDetails['id'] | ItemModifier['id']
          type: TypeName.Item | TypeName.CustomizationOption
        }
      | undefined
    >
  >
  checkSuspendUntilStatus?: (
    type: TypeName.Item | TypeName.CustomizationOption,
    id: MenuMappingItemDetails['id'] | ItemModifier['id'],
  ) => string
  expandAllModifiers?: boolean
  isItemPreview?: boolean
}

const ModifierSubsection: FC<Props> = ({
  subsection,
  isPreviewOnly,
  checkedModifierIds,
  onModifierClick,
  setSuspendUntilItem,
  checkSuspendUntilStatus,
  groupModifiers,
  expandAllModifiers,
  isItemPreview,
}) => {
  const { isMobile } = useIsMobile()
  const [expandNestedModifiers, setExpandNestedModifiers] = useState<ExpandNestedModifiersMap>({})

  useEffect(() => {
    if (expandAllModifiers) {
      const allModifierWithChildrenIds = groupModifiers
        ?.filter((mod) => mod.children.customizations?.length)
        ?.map((mod) => mod.id)
      const allModifiersMap: ExpandNestedModifiersMap = {}
      allModifierWithChildrenIds?.forEach((id) => {
        allModifiersMap[id] = true
      })

      setExpandNestedModifiers(allModifiersMap)
    } else {
      setExpandNestedModifiers({})
    }
  }, [expandAllModifiers])

  return (
    <Styled.SubsectionContainer>
      <Styled.HeaderRow>
        <Styled.ModifierTitle>{subsection.attributes.title}</Styled.ModifierTitle>
        {isItemPreview && (
          <Styled.TopRowText textAlign='end' fontWeight={theme.fontWeight.normal}>
            Min: {subsection.attributes.min_permitted} / Max: {subsection.attributes.max_permitted}
          </Styled.TopRowText>
        )}
        {isPreviewOnly && (
          <Styled.HeaderText>
            {/* {getRowText(sectionProductsUpdatedCount, sectionModifierUpdatedCount)} */}
          </Styled.HeaderText>
        )}
      </Styled.HeaderRow>

      {groupModifiers?.map((modifier) => {
        return (
          <Fragment key={modifier.id}>
            <ItemModifierRow
              key={modifier.id}
              modifier={modifier}
              isSelected={checkedModifierIds && checkedModifierIds.includes(modifier.id)}
              onModifierClick={onModifierClick && onModifierClick}
              isPreviewOnly={isPreviewOnly}
              setSuspendUntilItem={setSuspendUntilItem && setSuspendUntilItem}
              checkBoxContainerStyle={{ paddingLeft: 12 }}
              existingSuspendUntil={
                checkSuspendUntilStatus &&
                checkSuspendUntilStatus(TypeName.CustomizationOption, modifier.id)
              }
              modifiersExist={!!modifier.children.customizations?.length}
              onRowClick={() =>
                setExpandNestedModifiers((prevExpandStatus) => ({
                  ...prevExpandStatus,
                  [modifier.id]: !prevExpandStatus[modifier.id],
                }))
              }
              isExpanded={expandNestedModifiers[modifier.id]}
              isItemPreview={isItemPreview}
            />

            {/* Another level of nesting: customization group & modifiers */}
            {(expandNestedModifiers[modifier.id] || isPreviewOnly) &&
              modifier.children.customizations?.map((customization) => (
                <Styled.SubsectionContainer key={customization.id}>
                  <Styled.HeaderRow>
                    <Styled.ModifierTitle marginLeft={isMobile ? '30px' : '120px'}>
                      {customization.attributes.title}
                    </Styled.ModifierTitle>
                  </Styled.HeaderRow>
                  {customization.children?.customization_options?.map((nestedMod) => (
                    <ItemModifierRow
                      key={nestedMod.id}
                      modifier={nestedMod}
                      isSelected={checkedModifierIds && checkedModifierIds.includes(nestedMod.id)}
                      onModifierClick={onModifierClick}
                      isPreviewOnly={isPreviewOnly}
                      setSuspendUntilItem={setSuspendUntilItem}
                      checkBoxContainerStyle={{ paddingLeft: 12 }}
                      existingSuspendUntil={
                        checkSuspendUntilStatus &&
                        checkSuspendUntilStatus(TypeName.CustomizationOption, nestedMod.id)
                      }
                      isNested={true}
                      isItemPreview={isItemPreview}
                    />
                  ))}
                </Styled.SubsectionContainer>
              ))}
          </Fragment>
        )
      })}

      {/* TODO: View by Modifier logic (flip modifiers and items)
         {expandItems[modifier.id] &&
          items &&
          items.map((item) => (
            <MenuItemRow
              key={item.id}
              item={item}
              isSelected={checkedItemIds.includes(item.id)}
              onItemClick={onItemClick}
              isPreviewOnly={isPreviewOnly}
              modifiersExist={false}
              setSuspendUntilItem={setSuspendUntilItem}
              checkBoxContainerStyle={{ paddingLeft: 54 }}
              existingSuspendUntil={checkSuspendUntilStatus(TypeName.Item, item.id)}
              isModifiersLoading={isModifiersLoading}
              menuItemStyle={menuItemStyle}
              menuItemTextStyle={menuItemTextStyle}
            />
          ))} */}
    </Styled.SubsectionContainer>
  )
}

ModifierSubsection.displayName = 'ModifierSubsection'

export default ModifierSubsection
