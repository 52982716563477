import { ReactComponent as ArrowDownBox } from 'assets/icons/arrow-down-box.svg'
import { FC, ReactNode, useRef, useState } from 'react'

import * as Styled from './styles'

type Props = {
  title: ReactNode
  content: ReactNode
  expandByDefault?: boolean
  isError?: boolean
}

const Accordion: FC<Props> = ({ title, content, expandByDefault, isError }) => {
  const [isExpanded, setIsExpanded] = useState(Boolean(expandByDefault))
  const contentRef = useRef<HTMLDivElement>(null)
  const scrollHeight = contentRef.current?.scrollHeight ?? '100%'

  return (
    <Styled.Container>
      <Styled.Toggle
        data-testid='accordion-toggle'
        isExpanded={isExpanded}
        isError={Boolean(isError)}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <ArrowDownBox />
        {typeof title === 'string' ? <Styled.Title>{title}</Styled.Title> : title}
      </Styled.Toggle>
      <Styled.Content $height={isExpanded ? scrollHeight : 0} ref={contentRef}>
        {content}
      </Styled.Content>
    </Styled.Container>
  )
}

Accordion.displayName = 'Accordion'

export default Accordion
