import styled from 'styled-components'
import { breakpoints, colors, shadow } from 'theme'

export const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100vw',
  height: '100vh',
  backgroundImage: `linear-gradient(to right, ${colors.primary.dark}, ${colors.primary.light})`,
})

export const Box = styled.div({
  paddingTop: '5rem',
  paddingBottom: '5rem',
  paddingLeft: '10rem',
  paddingRight: '10rem',
  boxShadow: shadow.xl,
  borderRadius: '0.375rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.white,
})

export const Title = styled.h1({
  marginTop: '2.5rem',
  fontSize: '1.5rem',
  lineHeight: '2rem',
  fontWeight: 700,
  [`@media ${breakpoints.md}`]: {
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
  },
})

export const Red = styled.span({
  color: colors.red.DEFAULT,
})

export const Description = styled.p({
  marginTop: '0.5rem',
  marginBottom: '2rem',
  color: colors.gray.dark,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  [`@media ${breakpoints.md}`]: {
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
  },
})
