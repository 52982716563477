import { ErrorMessage, Pagination, SkeletonLoader } from 'components'
import { useLocations } from 'components/MainLayout/MainLayout'
import chunk from 'lodash/chunk'
import { FC, useEffect, useMemo, useState } from 'react'

import MultipleLocations from './MultipleLocations/MultipleLocations'
import { getMultipleLocationsRows } from './MultipleLocations/rows'
import * as Styled from './styles'

const ManageLocations: FC = () => {
  const { locationsData, refetchLocations } = useLocations()
  const { locations, isLoading, isError, totalPages, pageSize } = locationsData
  const [currentPage, setCurrentPage] = useState(1)
  const nextEnabled = currentPage < totalPages
  const previousEnabled = currentPage > 1
  const [filteredLocations, setFilteredLocations] = useState(locations)
  const pageData = chunk(filteredLocations, pageSize)[currentPage - 1] ?? []
  // const [searchTerm, setSearchTerm] = useState('')
  // const locationCount = useRecoilValue(partnersMetadataQuery)

  const goToFirst = () => {
    setCurrentPage(1)
  }
  const goToLast = () => {
    if (chunk(locations, pageSize).length < totalPages) {
      refetchLocations(totalPages)
    }
    setCurrentPage(totalPages)
  }
  const goToNext = () => {
    if (currentPage < totalPages) {
      if (chunk(locations, pageSize).length < totalPages) {
        refetchLocations(currentPage + 1)
      }
      setCurrentPage(currentPage + 1)
    }
  }
  const goToPrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  /**
   * ToDo: Chowly users should be able to filter locations by location or status. Method TBD.
   */
  // const filterLocations = async (event: ChangeEvent<HTMLInputElement>) => {
  //   if ((locationCount?.total || 0) < 26) {
  //     const newLocations = locations.filter((location) =>
  //       location.name.toLowerCase().includes(searchTerm.toLowerCase()),
  //     )
  //     setFilteredLocations(newLocations)
  //   } else {
  //     const val = event.target.value
  //     await refetch(undefined, String(val))
  //     setFilteredLocations(locations)
  //   }
  // }

  // const functionDebounce = useCallback(
  //   debounce((event: ChangeEvent<HTMLInputElement>) => {
  //     filterLocations(event)
  //   }, 300),
  //   [],
  // )

  useEffect(() => {
    setFilteredLocations(locations)
  }, [locations])

  const locationRows = useMemo(() => {
    return getMultipleLocationsRows()
  }, [pageData])

  return (
    <Styled.Container>
      <Styled.ToolBar>Locations</Styled.ToolBar>
      <Styled.Content>
        {isError && <ErrorMessage />}
        {!isError && !isLoading && (
          <MultipleLocations data={pageData} locationRows={locationRows} />
        )}
        {isLoading && <SkeletonLoader />}
      </Styled.Content>
      {!isError && totalPages > 1 && (
        <Pagination
          nextEnabled={nextEnabled}
          previousEnabled={previousEnabled}
          isLoading={isLoading}
          onFirstClick={goToFirst}
          onLastClick={goToLast}
          onNextClick={goToNext}
          onPrevClick={goToPrevious}
        />
      )}
    </Styled.Container>
  )
}

ManageLocations.displayName = 'ManageLocations'

export default ManageLocations
