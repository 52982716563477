import styled from 'styled-components'
import { breakpoints, colors, fontFamily, fontWeight, typography } from 'theme'

export const Table = styled.table<{ width?: number | string }>(
  {
    // gap: '16px',
    // display: 'flex',
    // justifyContent: 'space-between',
    // flexDirection: 'column'
    width: 'auto',
    tableLayout: 'auto',
    // [`@media ${breakpoints.md}`]: {
    //  minWidth: '668px'
    // },
  },
  // ({ width }) => ({ width: width || '100%' })
)

export const TableRow = styled.tr({
  fontWeight: fontWeight.semibold,
  '&:nth-last-child(1)': {
    borderWidth: '0px 0px 0px 0px',
  },
  minHeight: '39px',
  // minHeight: '40px',
  borderWidth: '0px 0px 1px 0px',
  borderStyle: 'solid',
  borderColor: colors.gray.medium,
  // width:'620px',

  [`@media ${breakpoints.sm}`]: {
    // display: 'flex',
    justifyContent: 'space-between',
    minHeight: '39px',

    // flexDirection: 'column',
    // alignItems: 'right',
    // height: '107px',
    // minHeight: '158px',
    paddingBottom: '1rem',
    paddingTop: '1rem',
  },
  [`@media ${breakpoints.xxs}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '71px',
    alignItems: 'center',
    // flexDirection: 'column',
  },
})

export const MobileTableRow = styled.tr({
  fontWeight: fontWeight.semibold,
  borderWidth: '0px 0px 1px 0px',
  borderStyle: 'solid',
  borderColor: colors.gray.medium,
  '&:nth-last-child(1)': {
    borderWidth: '0px 0px 0px 0px',
  },
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 0px 24px 0px',
  '&:first-child': {
    padding: '0px 0px 24px 0px',
  },
})

export const HeaderCell = styled.th<{ width?: number | string, padding?: string }>(
  {
    lineHeight: '18px',
    color: colors.gray.mediumLight,
    fontFamily: fontFamily.inter,
    fontSize: typography.xs.fontSize,
    fontWeight: fontWeight.normal,
  },
  ({ width }) => ({ width }),
  ({ padding }) => ({ padding }),
)

export const BodyCell = styled.td({
  padding: '16px 10px 16px 10px',
  '&:nth-last-child(1)': {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0',
    justifyContent: 'flex-end',
  },
  '&:first-child': {
    padding: '16px 0',
  },
  // verticalAlign: 'middle',
  [`@media ${breakpoints.xxs}`]: {
    height: 'inherit',
    border: '0px 0px 1px 0px',
    padding: 0,
    '&:nth-last-child(1)': {
      paddingRight: '0',
    },
  },
})
